import { Component, OnInit, Input, Output, EventEmitter, } from '@angular/core';
import { FormGroup, FormBuilder, FormArray,  } from '@angular/forms';
import {CalendarModule} from 'primeng/calendar';
import {CheckboxModule} from 'primeng/checkbox';
import { SharedService } from '../../shared/shared.service';
import * as moment from 'moment';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';


@Component({
  selector: 'app-new-task-dialog',
  templateUrl: './new-task-dialog.component.html',
  styleUrls: ['new-task-dialog.component.scss']
})
export class NewTaskComponent implements OnInit {
  
  
  teamDropdown: any;
  addressDropdown: any;
  timezoneDropdown :any;
  starttimeDropdown :any;
  endtimeDropdown :any;
  items: any;
  selectReminder:boolean;
  tasktypeDropdown: any;
  relatedtoDropdown: any;
  
  
  
  @Input() newTask: boolean;
  @Output() displayChange = new EventEmitter();
  inviteForm: FormGroup;
  areaDropdown: any;
  eventObs: AngularFireList<any>;

  taskTitle: string = '';
  taskDate: any;
  titleError: boolean = false;
  startDateError: boolean = false;
  startTimeError: boolean = false;
  endTimeError: boolean = false;
  validLogoError: boolean = false;
  successcommon: boolean = false;
  taskStartTime: any;
  taskEndTime: any;
  taskLocation: string = "";
  taskNote: string = "";
  idGen: number = 100;
  taskReminder: boolean = false;
  taskType: string;
  successHeader: string = 'Activity';
  successMessag : string = 'Activity has been added successfully.';
  constructor(private formBuilder: FormBuilder, private _sharedService: SharedService,  private af: AngularFireDatabase, ) {
    // this.addressDropdown =[{name: '1330 simpsons Ave Salt Lake City Ut'}];
    // this.teamDropdown = [{ name: 'Iron ways' }];
    this.tasktypeDropdown = [{ name: 'Call' }, { name: 'Meeting' }, { name: 'Research' }, { name: 'Task' },{ name: 'Tour' }, { name: 'Other' }];
    // this.relatedtoDropdown = [{ name: 'Jennifer MC Spansy Dee Karter' }];
  }

  ngOnInit() {
    this.inviteForm = this.formBuilder.group({
      agents: this.formBuilder.array([this.createItem()])
    });
  }

  saveActivity(){
    const validate = this.validate(); 
    if(validate){
      const startTime = moment(this.taskStartTime).format("HH:mm");
      const endTime = moment(this.taskEndTime).format("HH:mm");
      const activityStartDate = `${moment(this.taskDate).format("YYYY-MM-DD")} ${startTime}`;
      const activityEndDate = `${moment(this.taskDate).format("YYYY-MM-DD")} ${endTime}`;
      const idGen = this.idGen++;
      const payload = {start: activityStartDate, end: activityEndDate, title: this.taskTitle, description: this.taskNote, reminder: this.taskReminder, location: this.taskLocation, type: this.taskType ? this.taskType['name']: '', id: idGen};
      let uid = this._sharedService.user.uid;
      let path = "/calendar/" + uid;
      this.eventObs = this.af.list(path);
      this.eventObs.push(payload).then((item)=>
      {
          if(item){
            this.eventObs.update(item.key, {id: item.key});
            this.displayChange.emit(false);
            this.successcommon = true;
          }
      });
    }
  }
  deleteTask(){

  }
  validate(){
    this.titleError = false;
    this.startDateError =false;
    this.startTimeError =false;
    this.endTimeError =false;
    if(!this.taskTitle){
      this.titleError = true;  
      return false;
    }
    if(!this.taskDate){
      this.startDateError = true;
      return false;
    } 
    if(!this.taskStartTime){
      this.startTimeError = true;
      return false;
    } 
    if(!this.taskEndTime){
      this.endTimeError = true;
      return false;
    }
    return true;
  }
  createItem(): FormGroup {
    return this.formBuilder.group({
      name: '',
      email: '',
      team: '',
      role: ''
    });
  }
  addItem(): void {
    this.items = this.inviteForm.get('agents') as FormArray;
    this.items.push(this.createItem());
  }

  deleteRow(index: number) {
    // control refers to your formarray
    this.items = this.inviteForm.get('agents') as FormArray;
    // remove the chosen row
    this.items.removeAt(index);
  }
  onFormSubmit(inviteForm) {
    console.log(inviteForm);
    /* Any API call logic via services goes here */
  }

  onClose() {
    this.displayChange.emit(false);
  }

  // Work against memory leak if component is destroyed
  ngOnDestroy() {
    // this.displayChange.unsubscribe();
  }
  onSuccesscommonClose(ev){
    this.successcommon =false;
  }
}
