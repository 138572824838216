import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ClientProfilesComponent } from './clientprofiles.component';
import {DetailsComponent} from "./details/details.component"

import { AuthGuard }      from '../shared/auth.guard';


@NgModule({
  imports: [
    RouterModule.forChild([
      //{ path: 'clientprofiles', component: ClientProfilesComponent,  canActivate: [AuthGuard] }
            { path: 'clientprofiles',  component: ClientProfilesComponent, canActivate: [AuthGuard]},
             {
    path: 'clientprofiles/details/:id', component: DetailsComponent, canActivate: [AuthGuard]
    
  }

    ])
  ],
  exports: [RouterModule]
})
export class ClientProfilesRoutingModule { 

  
}
