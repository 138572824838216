import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Pipe } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
////
import { AngularFireModule } from 'angularfire2';
import { angularConfig } from './shared/config/angularfire.config';
import { environment } from '../environments/environment';

import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireAuthModule } from 'angularfire2/auth';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './landing/landing.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { ChartModule, DropdownModule, ButtonModule } from 'primeng/primeng';
import { GaugeModule } from 'angular-gauge';
import { MessagesModule } from 'primeng/primeng';
import { LoginModule } from './login/login.module';
import { TasksModule } from './tasks/tasks.module';
import { ClientProfilesModule } from './clientprofiles/clientprofiles.module';
import { ProgressBarModule, SliderModule, InputMaskModule } from 'primeng/primeng';
import { PersonalCalendarModule } from './calendar/calendar.module';
import { FeedbackModule } from './feedback/feedback.module';
import { NewclientModule } from './newclient/newclient.module';
import { SettingsModule } from './settings/settings.module';
import { ImportclientsModule } from './importclients/importclients.module';
import { InactivereportModule } from './inactivereport/inactivereport.module';
import { RegisterModule } from './register/register.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {AutoCompleteModule} from 'primeng/autocomplete';
import * as moment from 'moment/moment';

import { MessagesService } from './shared/messages.service';
import { SharedService } from './shared/shared.service';
import { AuthGuard } from './shared/auth.guard';
import { LoginGuard } from './login/login.guard';
import { TooltipModule, DialogModule } from 'primeng/primeng';

import { ChatModule } from './chat/chat.module';
import { SharedFileModule } from './shared/shared.module';
import { SnapshotStatisticsComponent } from './landing/snapshot-statistics/snapshot-statistics.component';
import { ClientStatisticsComponent } from './landing/client-statistics/client-statistics.component';
import { TodayReportComponent } from './landing/today-report/today-report.component';
import { CommonSuccessDialogComponent } from './shared/common-success-dialog/common-success-dialog.component';
import { AccountDetailsComponent } from './settings/account-details/account-details.component';
import { BillingPlanComponent } from './settings/billing-plan/billing-plan.component';
import { NextBillComponent } from './settings/billing-plan/components/next-bill/next-bill.component';
import { BillingHistoryComponent } from './settings/billing-plan/components/billing-history/billing-history.component';
import { GlobalSettingsComponent } from './settings/billing-plan/components/global-settings/global-settings.component';
import { MyLeadsComponent } from './settings/billing-plan/components/my-leads/my-leads.component';

import { ManagePlanComponent } from './settings/billing-plan/components/manage-plan/manage-plan.component';
import { BillingMethodComponent } from './settings/billing-plan/components/billing-method/billing-method.component';
import { ViewAllHistoryComponent } from './settings/billing-plan/components/billing-history/view-all-history/view-all-history.component';
import { InvoiceDetailsDialogComponent } from './settings/billing-plan/components/billing-history/view-all-history/dilalog-components/invoice-details-dialog/invoice-details-dialog.component';
import { TeamInvoiceDetailsDialogComponent } from './settings/billing-plan/components/billing-history/view-all-history/dilalog-components/team-invoice-details-dialog/team-invoice-details-dialog.component';
import { TeamManagementComponent } from './settings/team-management/team-management.component';
import { TeamManagementSettingComponent } from './settings/team-management/team-management-setting/team-management-setting.component';
import { CreateTeamDialogComponent } from './settings/team-management/dialog-components/create-team-dialog/create-team-dialog.component';
import { InputTextModule } from 'primeng/primeng';
import { EditAgentDialogComponent } from './settings/team-management/dialog-components/edit-agent-dialog/edit-agent-dialog.component';
import { InviteDialogComponent } from './settings/team-management/dialog-components/invite-dialog/invite-dialog.component';
import { RemoveDialogComponent } from './settings/team-management/dialog-components/remove-dialog/remove-dialog.component';
import { ConfirmationDialogComponent } from './settings/team-management/dialog-components/confirmation-dialog/confirmation-dialog.component';

import { NotificationComponent } from './settings/notification/notification.component';
import { NotificationsModule } from './notifications/notifications.module';
import { PropertiesModule } from './properties/properties.module';
import { SubscriptionPayeeDialogComponent } from './settings/team-management/dialog-components/subscription-payee-dialog/subscription-payee-dialog.component';
import {SetBudgetDialogComponent} from './settings/billing-plan/components/dailog-components/set-budget/set-budget-dialog.component';
import { AdminRemovedDialog } from './landing/dialog-components/admin-removed-dialog/admin-removed-dialog.component';
import { SelectPlanDialogComponent } from './landing/dialog-components/select-plan-dialog/select-plan-dialog.component';
import { AdminConnectedDialog } from './landing/dialog-components/admin-connected-dialog/admin-connected-dialog.component';
import { SelectPlanComponent } from './shared/select-plan-component/select-plan.component';
import { CommonConfirmDialogComponent } from './shared/common-confirm-dialog/common-confirm-dialog.component';
import { SettingConfirmDialogComponent } from './settings/billing-plan/components/dailog-components/setting-confirm-dialog/setting-confirm-dialog.component';
import { SettingLeadDialogComponent } from './settings/billing-plan/components/dailog-components/setting-lead-dialog/setting-lead-dialog.component';
import { CardDetailsComponent } from './shared/card-details/card-details.component';
import { Ng5SliderModule } from 'ng5-slider';
import { SettingOverviewComponent } from './settings/setting-overview/setting-overview.component';
import { AccountDetailsDialogComponent } from './settings/setting-overview/dialog-component/account-details-dialog/account-details-dialog.component';
import { ImportClientsDialogComponent } from './settings/setting-overview/dialog-component/import-clients-dialog/import-clients-dialog.component';
import { SocialSharingDialogComponent } from './settings/setting-overview/dialog-component/social-sharing-dialog/social-sharing-dialog.component';

@Pipe({
  name: 'today',
  pure: false
})
export class TodayPipe {
  transform(values) {
    if (values) {
      return values.filter(x => {
        return moment(x.start).isSame(Date.now(), 'day');
      });
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    SnapshotStatisticsComponent,
    ClientStatisticsComponent,
    TodayReportComponent,
    HeaderComponent,
    MenuComponent,
    TodayPipe,
    AccountDetailsComponent,
    BillingPlanComponent,
    NextBillComponent,
    BillingHistoryComponent,
    GlobalSettingsComponent,
    MyLeadsComponent,
    ManagePlanComponent,
    BillingMethodComponent,
    ViewAllHistoryComponent,
    TeamInvoiceDetailsDialogComponent,
    InvoiceDetailsDialogComponent,
    TeamManagementComponent,
    TeamManagementSettingComponent,
    CreateTeamDialogComponent,
    EditAgentDialogComponent,
    SetBudgetDialogComponent,
    InviteDialogComponent,
    RemoveDialogComponent,
    NotificationComponent,
    SubscriptionPayeeDialogComponent,
    ConfirmationDialogComponent,
    AdminRemovedDialog,
    SelectPlanDialogComponent,
    AdminConnectedDialog,
    CommonSuccessDialogComponent,
    CommonConfirmDialogComponent,
    SettingConfirmDialogComponent,
    SettingLeadDialogComponent,
    CardDetailsComponent,
    SettingOverviewComponent, 
    AccountDetailsDialogComponent, 
    ImportClientsDialogComponent, 
    SocialSharingDialogComponent
    // SelectPlanComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // AngularFireModule.initializeApp(angularConfig),
    AngularFireModule.initializeApp(environment),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    ChartModule,
    GaugeModule.forRoot(),
    MessagesModule,
    LoginModule,
    TasksModule,
    BrowserAnimationsModule,
    TooltipModule,
    ClientProfilesModule,
    ProgressBarModule,
    FeedbackModule,
    NewclientModule,
    ImportclientsModule,
    InactivereportModule,
    PersonalCalendarModule,
    SettingsModule,
    SharedFileModule,
    RegisterModule,
    ChatModule,
    DropdownModule,
    ButtonModule,
    DialogModule,
    SliderModule,
    InputMaskModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    NotificationsModule,PropertiesModule, AutoCompleteModule,Ng5SliderModule
    ],
  providers: [MessagesService, SharedService, AuthGuard, LoginGuard],
  bootstrap: [AppComponent]
})
export class AppModule {}
