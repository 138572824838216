import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-today-report',
  templateUrl: './today-report.component.html',
  styleUrls: ['./today-report.component.css']
})
export class TodayReportComponent implements OnInit {
  items: any;
  events: any;
  tasks: any;
  curDate=new Date();
  constructor() {
    this.items = [
      {
        // time: 'Today 3:45 PM',
        // msg: 'New Message from Joel Anderson',
        // ack: true
      }
      
    ];
    this.events = [
      {
        // time: 'Today 3:45 PM',
        // msg: 'events',
        // ack: false
      }
    ];
    this.tasks = [
      {
        // time: 'June 4, 2018',
        // msg: 'Follow up with Jeff Daniels',
        // ack: false
      }
    ];
  }

  ngOnInit() {}
  
}
