import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-statistics',
  templateUrl: './client-statistics.component.html',
  styleUrls: ['./client-statistics.component.css']
})
export class ClientStatisticsComponent implements OnInit {
  data: any;
  stats: any;
  constructor() {
    this.data = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          // data: [65, 59, 80, 81, 56, 55, 40]
          data: [0, 0, 0, 0, 0, 0, 0]
        }
      ]
    };
    this.stats = [{ name: 'Agent Stats', code: 'AS' }, { name: 'Property Stats', code: 'PS' }];
  }

  ngOnInit() {}
}
