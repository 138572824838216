import { Component, OnInit, Input, ViewEncapsulation, ViewChild } from '@angular/core';
import { AngularFireDatabase, AngularFireObject, AngularFireList } from 'angularfire2/database';
import { LeadStatus } from "../../shared/leadstatus.enum";
import { CreditRating } from "../../shared/creditrating.enum";
import { SharedService } from '../../shared/shared.service';
import { Observable, Timestamp, Subscription } from 'rxjs';
import 'rxjs/add/observable/forkJoin';
import { map } from 'rxjs/operators';

import * as moment from 'moment';
import * as firebase from 'firebase/app';
import { HttpClient } from '@angular/common/http';
import { resource } from 'selenium-webdriver/http';


@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  clientProfileRef: AngularFireObject<any>;
  likedPropertiesRef: AngularFireList<any>;
  clientCommentsRef: AngularFireList<any>;

  clientProfile: Observable<any>
  likedProperties : Observable<any>
  clientComments : Observable<any>

  listings: Array<any>;
  leadStatus = LeadStatus;
  creditRating = CreditRating;
  creditValue = 50;
  active = null;
  show: boolean = false;
  firstName = "";
  lastName = "";
  currentItem;
  event: MyEvent = new MyEvent;
  edit = false;
  showCal = false;
  soldDialog = false;
  showAppointmentDialog = false;
  soldSaved = false;
  testBudget = 25000;
  dialogVisible = false;
  origStatus;
  


  creditTypes = [{ value: 'Excellent', label: "Excellent 710+" }, { value: 'Good', label: "Good 650-709" }, { value: 'Poor', label: "Poor Below 650" }];
  minPriceArray = [{ label: '$0', value: '0' }, { label: '$50,000', value: '50000' }, { label: '$75,000', value: '75000' }, { label: '$100,000', value: '100000' }, { label: '$150,000', value: '150000' }, { label: '$200000', value: '200000' }, { label: '$250,000', value: '250000' }, { label: '$300,000', value: '300000' }, { label: '$400,000', value: '400000' }, { label: '$500,000', value: '500000' }]
  maxPriceArray = [{ label: '$100,000', value: '100000' }, { label: '$200,000', value: '200000' }, { label: '$300,000', value: '300000' }, { label: '$400,000', value: '400000' }, { label: '$500,000', value: '500000' }, { label: '$600,000', value: '600000' }, { label: '$700,000', value: '700000' }, { label: '$800,000', value: '800000' }, { label: '$900,000', value: '900000' }]
  statusTypes = [{ label: "New Lead", value: 'New Lead' }, { label: "Qualified", value: 'Qualified' }, { label: "Offers Pending", value: 'Offers Pending' }, { label: "Closing", value: 'Closing' }, { label: "Sold", value: 'Sold' }, { label: "Not Looking", value: 'Not Looking' }];
  detailsTypes = [{ label: "Client Information", value: 'Client Information' }, { label: "Attached Documents", value: 'Attached Documents' }]
  
  lookingToBuyTypes = [{ label: "Very Soon 0-3 Months", value: 'Very Soon 0-3 Months' }, { label: "Soon 3-6 months", value: "Soon 3-6 months" }, { label: "Just Browsing", value: "Just Browsing" }]
  spokenWithLenderTypes = [{ label: "Yes", value: "true" }, { label: "No", value: "false" }];
  newComment = "";
  data;
  activityLabels = [];
  activityData = [];
  userUid;
  soldHomeValue = null;
  soldCommissionRate = null;
  soldMLS = null;
   today = new Date();
   inactive = this.today.setDate(this.today.getDate() - 7);
   messageList: any[] = [];
   messageText = '';
   chatRoom: any;
   chatRoomId: any;
   @Input() mapTimerIds: any = {};
   private message$: any;
   latest_msg_timstamp: any;
   latestTimeStampHandler: Subscription;
   lastTimeStamp: any;
   clientId: any;
   messageIndex = 1;

   className: string;
   percent: number = 0;
   snapShotInterval: any;
   deg: number;
   @Input() uid: string;
   @Input() appUid: any;
    profiles: Observable<any []>;
    invites: Observable<any []>;
    profilesArray: any;
    profilesArrayLenght: number = 0;
    appProfilesArray: any;
    newLeadsCount: number = 0;
    offerPending: number = 0;
    closingProp: number = 0;
    soldProperty: number = 0;
    notLooking: number = 0;
    favoriteList:Observable<any []>;
    favProp = [];
    
   @ViewChild('ppc') ppc;
   @ViewChild('progressChart') progressChart;

  constructor(private http: HttpClient, private _shared: SharedService, private af: AngularFireDatabase, private _sharedService: SharedService) {
    this.userUid = _shared.user.uid;
    // this._shared.getUserInfo().then((result) => {
    //   if (result) {
        
    //       this.chatRoomId: result
       
    //     }
    //   });
   


    // this.getChatRoom()
    this.uid = this._sharedService.user.uid;
    this.profiles = af.list('userGeneratedLeads/' + this.uid).snapshotChanges();
    this.profiles.map(actions => 
      actions.map(a => ({ key: a.key, ...a.payload.val() }))
    ).subscribe(items => {
      //this.profilesArray = items;

      this.profilesArrayLenght =  items.length;
      this.profilesArray = items.map((item: any) => 
          ({id: item.key, firstName: item.firstName, lastName: item.lastName, appLink: item.appLink, offerPending: item.offerPending, closingProp: item.closingProp, soldProperty: item.soldProperty, notLooking: item.notLooking}
          
      ));

       



      // this.profilesArray.forEach(function(element) {
      //   if (element.appLink) {
      //     this.appProfilesArray.push(element);
      //   }
      // });offerPending: 
      this.appProfilesArray = [];
      for (let i=0; i<this.profilesArray.length; i++) {
        if (this.profilesArray[i].appLink) {
        this.appProfilesArray.push(this.profilesArray[i]);
        }
        if(this.profilesArray[i].offerPending){
          this.offerPending+=1;
        }
        if(this.profilesArray[i].closingProp){
          this.closingProp+=1;
        }
        if(this.profilesArray[i].soldProperty){
          this.soldProperty+=1;
        }
        if(this.profilesArray[i].notLooking){
          this.notLooking+=1;
        }
      }

      this.startTimer();
    });

    this.invites = af.list('/inviteMessages', ref => ref.orderByChild('sender_id').equalTo(this.uid)).snapshotChanges();
    this.invites.map(actions => 
      actions.map(a => ({ key: a.key, ...a.payload.val() }))
    ).subscribe(items => {
      
      this.newLeadsCount = items.length;
      //this.profilesArray = items.map((item: any) => ({id: item.key, firstName: item.firstName, lastName: item.lastName}));

    });

  }

  encapsulation: ViewEncapsulation.None;

  @Input() index: any = '';
  // @Input() uid: string = '';

  setLabel(value) {
    let string;
    if (value === 650) string = "650-709"
    else if (value === 500) string = "< 650"
    else if (value === 710) string = "710+"
    else string = "Unknown"
    return string
  }

  setActive(e) {
    this.active = e;

  }

  saveSold() {
    let path = "/sold/" + this.uid;
    let clientKey = this.index.key;
    let payload = { clientKey: this.index.key, soldPrice: this.soldHomeValue, commissionRate: this.soldCommissionRate, mls: this.soldMLS }
    this.af.list(path).push(payload);
    let clientPayload = { status: "Sold" };
    this.clientProfileRef.update(clientPayload);
    this.soldSaved = true;
    this.soldDialog = false;
  }

  resetSale() {
    this.soldCommissionRate = null;
    this.soldHomeValue = null;
    this.soldMLS = null;
    this.soldDialog = false;
  }

  checkSold(value) {
    if (value === "Sold") {
      this.soldCommissionRate = null;
      this.soldHomeValue = null;
      this.soldMLS = null;
      this.soldDialog = true;
    }
  }

  showSoldDialog(){
    this.soldDialog = true;
  }

  onSoldDialogClose(event){
    this.soldDialog = event;
  }

  showAppointment(){
    this.showAppointmentDialog = true;
  }

  onAppointmentDialogClose(event){
    this.showAppointmentDialog = event;
  }

  toggleCalendar(item) {
    this.currentItem = item;
    this.dialogVisible = true;
    this.event = new MyEvent;
  }

  saveSchedule() {
    let path = "/calendar/" + this.userUid;
    let myEvent = this.event;
    let clientEvent = <any>{};
    let viewedPath = "/viewed/" + this.index.key + "/" + this.currentItem.$key;
    myEvent.title = "Showing of " + this.currentItem.MlsNumber + " for " + this.firstName + " " + this.lastName;
    myEvent.description = { mls: this.currentItem.MlsNumber, ListingURL: this.currentItem.ListingURL, path: viewedPath };
    clientEvent.start = this.event.start;
    clientEvent.title = "Showing of " + this.currentItem.MlsNumber;
    clientEvent.photo = this.currentItem.Photos ? this.currentItem.Photos.Photo[0].url : null;
    clientEvent.mls = this.currentItem.MlsNumber;
    clientEvent.street = this.currentItem.Address['commons:FullStreetAddress'];

    let clientPath = "/userCalendar/" + this.index.key;
    
    this.af.list(path).push(myEvent);
    this.af.list(clientPath).push(clientEvent);

    this.af.object(viewedPath).update({ scheduledViewing: true });
    this.event = new MyEvent;
    this.currentItem = null;
    this.dialogVisible = false;
  }

  checkActiveEdit() {
    if ((this.index.type > 0) && this.edit) {
      return false;
    } else {
      return true;
    }
  }



  openUrl(e) {
    let url = e.ListingURL;
    if (url) {
      window.open(url, "_blank");
    }

  }

  handleUpdateUserEvent(formFirstName, formLastName, formAge, formPhone, formEmail, formAddress, formBudgetMinPrice, formBudgetMaxPrice, formStatus,
           formCreditRating, formTimeToBuy, formLenderBool, formOfferPending, formClosing, formSoldProperty, formNotLooking) {

    if (this.edit) {
      this.updateUser(formFirstName, formLastName, formAge, formPhone, formEmail, formAddress, formBudgetMinPrice, formBudgetMaxPrice,
        formStatus, formCreditRating, formTimeToBuy, formLenderBool, formOfferPending, formClosing, formSoldProperty, formNotLooking);
    }

    this.edit = !this.edit;
  }

  updateUser(formFirstName, formLastName, formAge, formPhone, formEmail, formAddress, formBudgetMinPrice = "0",
             formBudgetMaxPrice = "900000", formStatus = "New Lead", formCreditRating = "Good", formTimeToBuy = null,
             formLenderBool = null, formOfferPending=false, formClosing=false, formSoldProperty=false, formNotLooking=false) {

    let payload = { firstName: formFirstName, lastName: formLastName, age: formAge, phone: formPhone, email: formEmail,
      address: formAddress, budgetMinPrice: formBudgetMinPrice, budgetMaxPrice: formBudgetMaxPrice, status: formStatus,
      creditRating: formCreditRating, timeToBuy: formTimeToBuy, lenderBool: formLenderBool, offerPending: formOfferPending, closingProp: formClosing, soldProperty: formSoldProperty, notLooking: formNotLooking };
    if (this.active != null) {
      payload['active'] = this.active;
    }
    if (formStatus === "Sold" && !this.soldSaved) {
      
      payload.status = this.origStatus;
    }
    this.clientProfileRef.update(payload);
  }
  handleCancelUserEvent(){
    this.edit = false;
  }
  saveComment() {
    if (this.newComment.length > 3) {
      let createdTs = firebase.database.ServerValue.TIMESTAMP;
      let comment = { comment: this.newComment, createdTs: createdTs };
      this.clientCommentsRef.push(comment).then(() => {
        this.newComment = "";
      });
    }
  }
  startTimer(){
    clearInterval(this.snapShotInterval);
    // alert(this.profilesArray.length)
    if(this.profilesArray.length > 0){
      this.percent = (100 * this.appProfilesArray.length) / 100;
    }else{
      this.percent = 0;
    }
    // alert(this.percent);
    this.deg = (360 * this.percent) / 100;
    //alert(this.deg);
    if (this.percent > 50) {
      this.className = 'progress-pie-chart gt-50';
    } else {
      this.className = 'progress-pie-chart';
    }
    this.ppc.nativeElement.style.transform = 'rotate(' + this.deg + 'deg)';
  }
  

  
  ngOnInit() {
    this.snapShotInterval = setInterval(() => {
      if(this.snapShotInterval && this.percent >=59 ){
        clearInterval(this.snapShotInterval);
      }
      if (this.percent > 50) {
        this.className = 'progress-pie-chart gt-50';
      } else {
        this.className = 'progress-pie-chart';
      }
      this.percent > 99 ? (this.percent = 0) : this.percent++;
      this.deg = (360 * this.percent) / 100;
      this.ppc.nativeElement.style.transform = 'rotate(' + this.deg + 'deg)';
    }, 100);
    
    let commentPath = "/comments/" + this.index.key;

    this.clientCommentsRef = this.af.list(commentPath,  ref => ref.orderByKey())
    this.clientComments = this.clientCommentsRef.snapshotChanges().pipe(
      map(changes => 
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    );
    this.clientComments.subscribe((result) => {
      
    });
    // let pathApp = "/users/" + this.index.key;
    let pathApp = "/appUsers/" + this.index.key;
    let pathUser = "/userGeneratedLeads/" + this.uid + "/" + this.index.key;    
    let appLead = this.index.appLead;
    
    if (appLead) {
      this.clientProfileRef = this.af.object(pathApp);
    } else {
      this.clientProfileRef = this.af.object(pathUser);
    }
    this.clientProfile = this.clientProfileRef.snapshotChanges()
    .pipe(
      map(c => {return ({ key: c.payload.key, ...c.payload.val() })}));
    this.clientProfile.take(1).subscribe((result) => {
      
      this.firstName = result.firstName;
      this.lastName = result.lastName;
      this.origStatus = result.status;
      if(result.id){
        this.clientId = result.id ;
      }
      else{
        this.clientId = result.key;
      }
      
      this.getChatRoom();
    });

    let likePath = "/viewed/" + this.index.key;

    this.likedPropertiesRef = this.af.list(likePath,  ref => ref.orderByChild('liked').equalTo(true))
    this.likedProperties = this.likedPropertiesRef.snapshotChanges().pipe(
      map(changes => 
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    );

    this.likedProperties.subscribe((result) => {
      let sorted = result.sort((a: any, b: any) => {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        let bDate: any = new Date(b.updateTs);
        let aDate: any = new Date(a.updateTs);
        return aDate - bDate;
      });
      this.activityData = [];
      this.activityLabels = [];
      sorted.map(x => this.getDate(x.updateTs));
      this.data = {
        labels: this.activityLabels,
        datasets: [
          {
            label: 'Client Activity',
            data: this.activityData,
            fill: false,
            borderColor: '#07D0F4'
          }
        ]
      };

      Observable.forkJoin(result.map(x => this.getProperty(x))).subscribe((resulta) => {
        this.listings = resulta;
      });
    });
    
  }

  getDate(d) {
    var date = new Date(date);
    var momentDate = moment(d);
    var month = momentDate.format('MMMM');
    var day = momentDate.format('D');
    var label = month + " " + day;
    var data = day;
    var old = moment().subtract(30, 'days')
    if (momentDate < old) {
      //do nothing if older than a certain date
    }

    let contains = this.activityLabels.indexOf(label);
    if (contains > -1) {
      this.activityData[contains] = this.activityData[contains] + 1;
    }
    else {
      this.activityData.push(1);
      this.activityLabels.push(label);
    }




  }

  log(item) {
  }

  clicked() {
    this.show = !this.show;
  }

  getProperty(item) {
    let listingPath = "https://homecodec-cce50.firebaseio.com/listings/all/" + item.$key + ".json";
   
    return Promise.resolve(this.http.get(listingPath).subscribe((listing : any) => {
      listing.clientLiked = item.updateTs;
      if (item.requestViewing) listing.requestViewing = item.requestViewing;
      if (item.scheduledViewing) listing.scheduledViewing = item.scheduledViewing;

      listing.viewedKey = item.$key;
     return listing
    }))
    
    
  

  }
  /**
   *  functiont to get or create chat room
   */

  getChatRoom(){
    if(this.clientId){
      const userRefs = firebase.database().ref('users');
      const userRef = userRefs.child(this.uid+"/chat/"+this.clientId);
      userRef.once('value').then((snapshot: any) => {
      const result = snapshot.val();
      if(result){
        this.chatRoom = result.chat_room_id;
        this.getMessages();
      }else{
        this.createNewChatRoom();
      }
      });
    }
    
  }
  
  createNewChatRoom(){
    const userRefs = firebase.database().ref('users');
    const userRef = userRefs.child(this.uid+"/chat/"+this.clientId);
    const clientRefs = firebase.database().ref('appUsers');
    const clientRef = clientRefs.child(this.clientId+"/chat/"+this.uid);
    const chatRefs = firebase.database().ref('chat');
    // const payload = {chat_room: };
    
    let members = {}
    members[this.uid] = true;
    members[this.clientId] = true;
    this.af.list(`chat`).push({
          chat_between: '',
          chat_id: '',
          members: members,
        }).then((result)=>{
          
          const payload = {chat_room_id: result.key}
          const chatRef = chatRefs.child(result.key);
          chatRef.update({chat_id: result.key});
          userRef.update(payload);
          clientRef.update(payload);
          this.chatRoom =  result.key;
        },
        err => console.log(err)
        );

  }

    /**
   * Function to get messages from the corresponding chat
   * 
   *
   */
  // @param {*} chat_id
  // @memberof MessageComponent
  getMessages() {
    const chat_id = this.chatRoom;
    // this.messageIndex
    // message list from the corresponding chat room
    const messageRef = this.af
      .list(`messages/${chat_id}`, ref =>
        ref.orderByChild('message_ts').limitToLast(this.messageIndex * 10)
      )
      .valueChanges(['child_added']);
    this.message$ = messageRef.subscribe(list => {
      this.messageList = list;

      // this.mapTimerIds[this.chatRoom].clearTime();
      // this.mapTimerIds[this.chatRoom].setTime();

      // new message notifications
      this.messageList = this.messageList.map(item => {
        item.unread = item.message_ts > this.latest_msg_timstamp;
        this.lastTimeStamp = item.message_ts;
        if (item.from_user === this.uid) {
          item.unread = false;
        }
        return item;
      });

      // mark the chat as read once message is loaded
      this.markChatAsRead();
      setTimeout(() => {
        // removed unread notifications after the messages are loaded
        this.messageList = this.messageList.map(item => {
          item.unread = false;
          return item;
        });
      }, 5000);
    });
  }
  /**
   * Function to send message to the corresponding chat
   * @memberof MessageComponent
   */
  sendMessage() {
    if (!!this.messageText) {
      this.af
        .list(`messages/${this.chatRoom}`)
        .push({
          from_user: this.uid,
          message: this.messageText,
          message_read_at: '', // @todo: add read time stamp
          message_ts: firebase.database.ServerValue.TIMESTAMP,
          user_type: 'Agent'
        })
        .then(ref => {
          const chatRef = this.af.list(`chat`);
          chatRef.update(this.chatRoom, {
            latest_msg: this.messageText,
            latest_msg_ts: firebase.database.ServerValue.TIMESTAMP,
            read: false
          });
          this.messageText = '';
        });
    }
  }
   /**
   * Function to mark chat as read after messages are loaded
   * @memberof MessageComponent
   */
  markChatAsRead() {
    const chatRef = this.af.list(`chat`);
    chatRef.update(this.chatRoom, {
      last_read_message_ts: firebase.database.ServerValue.TIMESTAMP,
      read_status: true
    });
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.sendMessage();
    }
  }
  click() {
    this.messageIndex++;
    this.getMessages();
  }
}

export class MyEvent {
  id: number;
  title: string;
  start: string;
  end: Date;
  allDay: boolean = false;
  key: string;
  description: any;
}
