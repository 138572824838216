import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-common-confirm-dialog',
  templateUrl: './common-confirm-dialog.component.html',
  styleUrls: ['./common-confirm-dialog.component.scss']
})
export class CommonConfirmDialogComponent implements OnInit {
  @Input() confirmcommon: boolean;
  @Input() message: string;
  @Input() popupHeader: string;
 // successcommon =false;
 @Output() confirmDialogToggle = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  onClose(ev){
    this.confirmDialogToggle.emit(ev);
    // this.successcommon =false;
  }
}
