import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  blockClass = 'active';
  activeClass = 'inactive';
  constructor() {}

  ngOnInit() {}

  mouseEnter(div) {
    if ((this.blockClass = 'a')) {
      this.blockClass = 'inactive';
      this.activeClass = 'active';
    } else {
      this.blockClass = 'active';
      this.activeClass = 'inactive';
    }
    console.log('mouse enter : ' + div);
  }

  mouseLeave(div) {
    if ((this.blockClass = 'A')) {
      this.blockClass = 'active';
      this.activeClass = 'inactive';
    } else {
      this.blockClass = 'inactive';
      this.activeClass = 'active';
    }
    console.log('mouse leave :' + div);
  }
  showFullAppointment(shouldOpen) {
    console.log(shouldOpen);

    if (!shouldOpen) {
      this.blockClass = 'active';
      this.activeClass = 'inactive';
    } else {
      this.blockClass = 'inactive';
      this.activeClass = 'active';
    }
  }
}
