import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    /* Loop through all dropdown buttons to toggle between hiding and showing its dropdown content - This allows the user to have multiple dropdowns without any conflict */
    var dropdown = document.getElementsByClassName('dropdown-btn');
    var i;
    for (i = 0; i < dropdown.length; i++) {
      dropdown[i].addEventListener('click', function() {
        this.classList.toggle('active');
        var dropdownContent = this.nextElementSibling;
        if (dropdownContent.style.display === 'block') {
          dropdownContent.style.display = 'none';
        } else {
          dropdownContent.style.display = 'block';
        }
      });
    }
  }
  dropDownClose() {
    var dropdownBtn = document.getElementsByClassName('dropdown-btn');
    var i;
    for (i = 0; i < dropdownBtn.length; i++) {
      dropdownBtn[i].classList.remove('active');
    }
    var dropdown = document.getElementsByClassName('dropdown-container');
    var i;
    for (i = 0; i < dropdown.length; i++) {
      var dropdownContent = dropdown[i];
      if ((<any>dropdownContent).style.display === 'block') {
        (<any>dropdownContent).style.display = 'none';
      }
    }
  }
}
