import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { AngularFireDatabase, AngularFireList} from 'angularfire2/database';
import { SharedService } from '../../../../shared/shared.service';
import * as firebase from 'firebase/app';
import { Observable} from 'rxjs';
import { Fieldset } from 'primeng/primeng';
import { eventNames } from 'cluster';
import * as Papa from "papaparse/papaparse.js";
import { last, first } from 'rxjs/operators';
@Component({
  selector: 'app-invite-dialog',
  templateUrl: './invite-dialog.component.html',
  styleUrls: ['./invite-dialog.component.scss']
})
export class InviteDialogComponent implements OnInit {
  areaDropdown: any;
  teamDropdown: any;
  items: any;
  showTabBox = true;
  @Input() invite: boolean;
  @Output() displayChange = new EventEmitter();
  inviteForm: FormGroup;
  @Input() inviteName: string = "";
  initialPassword: string ="";
  initialConfirmPassword: string ="";
  @Input() inviteEmail: string = "";
  payForAgent: boolean = false;
  emailError: boolean = false;
  validLogoError: boolean =  false;
  validEmailError: boolean = false;
  nameError: boolean = false;
  payAllMemberPopup: any =false;
  agentName: string = "";
  brokerPicture: string = "";
  uid: string;
  roleOptions: any;
  loggedInUserRole:string = ""
  teamList: Observable<any []>;
  teams: any;
  @Input() team: any;
  @Input() role: any;
  admin: string = "";
  adminName: string = "";
  payAllMember: boolean;
  
  confirmation: boolean = false;
  invitee: string = "";
  planType: string = "";
  planRate: string = "";
  successMessag = "";
  successHeader = "";
  successcommon: boolean= false;
  isMultipleInvite: boolean = false;
  isInitialUploade:boolean = false;
  file: any;
  fileError: boolean = false;
  fileName: string = "";
  passwordError: boolean = false;
  confirmPasswordError: boolean = false;
  matchPassError: boolean = false;
  NoInviteError: boolean = false;
  errorArray = [];
  discountPlanRate: number;
  @Input() resendInvitation: boolean = false;
  constructor(private formBuilder: FormBuilder, private _sharedService: SharedService, private af: AngularFireDatabase) {
    this.roleOptions = [{name: 'agent'}];
    this.uid = this._sharedService.user.uid;
  }
  showMultipleInvite(ev){
    this.isMultipleInvite = ev;
    if (ev){
      this.payAllMemberPopup = false;
    }
  }
  ngOnInit() {
    this.inviteForm = this.formBuilder.group({
      agents: this.formBuilder.array([this.createItem()])
    });
    const storageRef = firebase.storage().ref().child('users/'+this.uid+'/brokerpicture.jpg');
        storageRef.getDownloadURL().then(
          (url) => {this.brokerPicture = url},
          (error) => {
            this.brokerPicture="";
            this.validLogoError = true;
          }
    );
      this._sharedService.getUserInfo().then(user => {
      this.agentName =  user.firstName +' '+ user.lastName;
      this.loggedInUserRole = user.role? user.role : '';
      this.roleOptions = [{name: 'agent', value: 'agent'},{name:'manager', value: 'manager'}];
      this.admin = user.admin? user.admin : '';
        if(this.admin){
          this._sharedService.getUserInfoById(this.admin).then((admin)=>{
             this.adminName = admin.firstName +' '+ admin.lastName;
             this.payAllMember = admin.payAllMember ? admin.payAllMember : false;
             this.payForAgent = admin.payAllMember ? true : false;
             this.planType = admin.planType ? admin.planType : '';
             this.planRate = admin.adjustedRate ? admin.adjustedRate : 0;
             this.discountPlanRate = admin.adjustedRate;
             if(!admin.discountApplied && this.planType!='planZ'){
              this.discountPlanRate = admin.adjustedRate-10;
             }
             this.getTeams();
          });
        }else{
          this.getTeams();
        }
      });

  }
 
mouseHovering() {
    this.payAllMemberPopup = true;
}
mouseLeaving() {
    this.payAllMemberPopup = false;
}

  // ClosepayAllMemPopUp(){
  //   this.payAllMemberPopup = false;
  // }
  // openpayAllMemPopUp(){
  //   this.payAllMemberPopup = true;
  // }
  getTeams(){
    this.teamList = this.af.list('teams',ref => ref.orderByChild('admin').equalTo(`${this.admin}`)).snapshotChanges();
    this.teamList.map(actions => 
      actions.map(a => ({ key: a.key, ...a.payload.val() }))
    ).subscribe(items => {
        this.teams = [];
        let teamPush = [];
        teamPush['name'] = 'unassigned';
        teamPush['value'] = '';
         teamPush['managerId'] = '';
        teamPush['managerName'] = '';
        this.teams.push(teamPush);
        this.team = teamPush;
        items.map((item)=>{
        let teamPush = [];
        teamPush['name'] = item.name;
        teamPush['value'] = item.key;
        teamPush['managerId'] = item.lead ? item.lead['id']:'';
        teamPush['managerName'] = item.lead ? item.lead['firstName']+' '+item.lead['lastName'] : '';
        this.teams.push(teamPush);
      });

    });
  }
  createItem(): FormGroup {
    return this.formBuilder.group({
      name: '',
      email: '',
      team: '',
      role: ''
    });
  }
  addItem(): void {
    this.items = this.inviteForm.get('agents') as FormArray;
    this.items.push(this.createItem());
  }

  deleteRow(index: number) {
    // control refers to your formarray
    this.items = this.inviteForm.get('agents') as FormArray;
    // remove the chosen row
    this.items.removeAt(index);
  }
  onFormSubmit(inviteForm) {
    /* Any API call logic via services goes here */
  }

  onClose() {
    this.payAllMemberPopup = false;
    this.displayChange.emit(false);
  }
  onChange(event: EventTarget) {
    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;
    this.file = files[0];
    this.fileName = this.file ? this.file.name : '';
  }
  onSave(){
    if(!this.isMultipleInvite){
      this.payAllMemberPopup = false;
      const validate = this.validateInviteDialog();
      if(validate){
        this.updateInviteDetails();
      }
    }
    if(this.isMultipleInvite){
      this.fileError = false;
      if(!this.file){
        this.fileName = "";
        this.fileError = true;
      }else{
        this.fileError = false;
        this.fileName = this.file.name;
        if(this.isInitialUploade){
          const multiValidate = this.multipleInviteValidate()
          if(multiValidate){
            this.updateMultipleInvitation();
          }
        }else{
          this.updateMultipleInvitation();
        }
      }
    }
  }
  multipleInviteValidate(){
    this.passwordError = false;
    this.confirmPasswordError = false;
    this.matchPassError = false;
    if(!this.initialPassword){
      this.passwordError = true;
      return false;
    }
    if(!this.initialConfirmPassword){
      this.confirmPasswordError = true;
      return false;
    }
    if(this.initialPassword != this.initialConfirmPassword){
      this.matchPassError = true;
      return false;
    }
    return true;
  }
  updateInviteDetails(){
    this.displayChange.emit(false);

    const userRef = firebase.database().ref('users');
    userRef.orderByChild('email').equalTo(this.inviteEmail).once('value').then((snapshot: any) => {
      const result = snapshot.val();
      let managerId;
      let managerName;
      if(this.loggedInUserRole =='manager'){
        managerId = this.uid;
        managerName = this.agentName;
      }else{
        managerId = this.team? this.team['managerId']:'';
        managerName = this.team? this.team['managerName']:'';
      }
      const plan = this.payForAgent ? this.planType : '';
      const rate = this.payForAgent ? this.planRate : '';
      let formRequest = {senderId: this.uid, senderName: this.agentName, receiverName: this.inviteName, email: this.inviteEmail, payForAgent: this.payForAgent, teamRole: this.role? this.role['name']:'agent', team: this.team? this.team['value']: '',teamName: this.team? this.team['name']:'', userId: result? Object.keys(result)[0]:null, accepted: false, admin: this.admin, managerId: managerId, managerName: managerName, adminName: this.adminName, planType: plan, rate: rate, invitedDate: Date.now()};
      this.af.list('/inviteAgents').push(formRequest);
      this.clearInviteDialog();
      if(this.resendInvitation){
        this.successMessag = 'Invitation has been resent successfully.';
        this.successHeader = "Invitation Resent";
        this.successcommon = true;
      }else{
        this.successMessag = 'Invitation has been sent successfully.';
        this.successHeader = "Invitation Sent";
        this.successcommon = true;
      }
      
    });

  }
  updateMultipleInvitation(){
    var file = this.file;
    Papa.parse(file, {
      complete: (results: any) => {
        this.pushInvitations(results);
      }
    });
        
  }
  pushInvitations(results: any){
    let count = 0;
    this.errorArray = [];
    results.data.forEach((item: any) => {
      let firstName = item[0];
      let lastName = item[1];
      let email = item[2];
      let teamName = item[3];
      let role = 'agent';
      let teamId = ""; 
      if(firstName.trim() !='' && lastName.trim() !='' && email.trim() !='')
        {
          const valid = this.validateMultipleInvite(firstName,lastName, email);
          console.log(valid);
          if(valid){
            if(item[4]){
              if(item[4].toLowerCase()=='manager'){
                role = 'manager';
              }
            }  
              if(teamName){
                const found = this.teams.findIndex(k =>  k.name.toLowerCase()==teamName.toLowerCase());
                if(found!==-1){
                  teamId = this.teams[found].value;
                }else{
                  teamName = "unassigned";  
                }
              }else{
                teamName = "unassigned";
              }
              const plan = this.payForAgent ? this.planType : '';
              const rate = this.payForAgent ? this.planRate : '';
              if(this.isInitialUploade){
                  let formRequest = {firstName: firstName, lastName: lastName, email: email, paidByAdmin: this.payForAgent, role: role, teamId: teamId, teamName: teamName, admin: this.admin, managerId: '', managerName: '', adminName: this.adminName, planType: plan, rate: rate, createdDttm: Date.now(), teamJoinedAt: Date.now(), initialUpload: true, password: this.initialPassword};
                  this.af.list('/inviteAgents').push(formRequest).then(()=>{
                    
                  });
                  count ++;
              }else{
                let formRequest = {senderId: this.uid, senderName: this.agentName, receiverName: firstName+' '+lastName, email: email, payForAgent: this.payForAgent, teamRole: role, team: teamId, teamName: teamName, userId: 0, accepted: false, admin: this.admin, managerId: '', managerName: '', adminName: this.adminName, planType: plan, rate: rate, invitedDate: Date.now()};
                this.af.list('/inviteAgents').push(formRequest).then(()=>{
                });
                count++;
              }
          }
        }
     });
     if(count>0){
      this.NoInviteError = false;
      this.successMessag = this.isInitialUploade ? 'Intial upload succesfully.' : count + ' Invitations has been sent successfully.';
      this.successHeader = this.isInitialUploade ? "Intial Upload" :"Invitation Sent";
      this.successcommon = true;
      this.clearInviteDialog();
      this.displayChange.emit(false);
     }else{
      this.NoInviteError = true;
     }
     
    // this.messageService.msgs.push({ severity: 'success', summary: 'Import Complete', detail: count +  ' Clients have been imported successfully' });
    
    // this.router.navigateByUrl("/clientprofiles");
  }
  updateMultipleUsers(){

  }


  validateInviteDialog(){
    this.nameError = false;
    this.emailError  = false;
    this.validEmailError = false;
    this.validLogoError = false;
    if(this.brokerPicture == ""){
      this.validLogoError = true;
      return false;
    }
    if(this.inviteName==""){
      this.nameError = true;
      return false;
    } 
    if(this.inviteEmail==""){
      this.emailError = true;
      return false;
    } 
    if(this.inviteEmail!=""){
      const validEmail = this.validateEmail(this.inviteEmail);
      if(!validEmail){
        this.validEmailError = true;
        return false;
      }
    }
    return true;
  }

 validateMultipleInvite(firstName, lastName, email){
  const firstNameError = !firstName ? 'Enter first name.' : '';
  const lastNameError = !lastName ? 'Enter last name.' : '';
  let emailError = !email ? 'Enter email.' : '';
  if(email){
    const validEmail = this.validateEmail(email);
    if(!validEmail){
      emailError = 'Enter valid email.';
    }
  }
  if(!firstNameError && !lastNameError && !emailError){
    return true;
  }else{
    const errorItem = {firstNameError: firstNameError, lastNameError: lastNameError, emailError: emailError};
    this.errorArray.push(errorItem);
    return false;
  }
  
 }


  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  clearInviteDialog(){
    this.inviteName = "";
    this.inviteEmail = "";
    this.initialPassword = "";
    this.initialConfirmPassword = "";
    this.file = "";
    this.fileError = false;
    this.fileName = "";
  }
  onConfirmationClose(ev){
    this.confirmation  = false;
    if(ev){
      // this.displayChange.emit(false);
      this.updateInviteDetails();
    }
    
  }
  onSuccesscommonClose(ev){
    this.successcommon =false;
  }
  // Work against memory leak if component is destroyed
  ngOnDestroy() {
    // this.displayChange.unsubscribe();
  }
}
