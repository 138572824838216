import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import {  trigger, transition, style, animate, state   } from '@angular/animations';
import { Observable, } from 'rxjs';
import { map } from 'rxjs/operators';

import {AngularFireDatabase, AngularFireList} from 'angularfire2/database';
import { SharedService } from '../shared/shared.service';
import * as firebase from 'firebase/app';
import {Validators,FormControl,FormGroup,FormBuilder} from '@angular/forms';
import {Message} from 'primeng/primeng';
import {ConfirmationService} from 'primeng/primeng';
@Component({
  moduleId: module.id,
  selector: 'tasks',
  templateUrl: 'tasks.component.html',
  styleUrls: ['tasks.component.scss'],
  providers: [ConfirmationService],
  animations: [
    trigger('isVisibleChanged', [
      state('1' , style({ opacity: 1 })),
      state('0', style({ opacity: 0})),
      transition('1 => 0', animate('500ms')),
      transition('0 => 1', animate('500ms'))
    ])
  ]
})
export class TasksComponent implements OnInit {
   encapsulation: ViewEncapsulation.None;
  user = this._sharedService.user;
  tasksRef: AngularFireList<any>;
  tasks : Observable<any[]>;

  title: string;
  shortDescription : string = "";
  taskType: string = "Reach Out";
  taskTypeFilter: string = "All";
  msgs : Message[] = [];
  taskTypeFilter2: string = "All";
  taskTypes = [{label:'All', value: "All"}, {label:'Reach Out', value: "Reach Out"}, {label:'Schedule Appointment', value: "Schedule Appointment"}, {label:'Reply Back', value: "Reply Back"}]
  newTaskTypes = [{label:'Reach Out', value: "Reach Out"}, {label:'Schedule Appointment', value: "Schedule Appointment"}, {label:'Reply Back', value: "Reply Back"}];

  taskFilter: string = "new";
  inprogressCount: number = 0;
  allCount: number = 0;
  completeCount: number = 0;
  taskFilterAll: boolean;
  selectedAll: string;
  selectedNew: string = "selected";
  selectedComplete: string;
  checked = true;
  showNew : boolean = true;
  selectedTask;
  showTaskModal = false;
  splitButtonOptions = [{label: 'Delete', icon: 'fa-close', command: (task) => {
                this.removeTask(task);
            }}
        ];


  constructor(private confirmationService: ConfirmationService, private af: AngularFireDatabase, private _sharedService : SharedService ) {

        let path = "/tasks/" + this.user.uid;
      this.tasksRef = this.af.list(path);//.map((array) => array.reverse()) as AngularFireList<any[]>;

    this.tasks = this.tasksRef.snapshotChanges().pipe(
      map(changes => 
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    );
    this.tasksRef.valueChanges().subscribe((results : any) => {
      this.inprogressCount = 0;
      this.allCount = 0;
      this.completeCount = 0
      results.forEach((item) => {

        item.complete ? this.completeCount++ : this.inprogressCount++;
        this.allCount++;
      })

  
    })
  }

  sleep()
  {
    setTimeout(()=>{return true}, 2000);
   
  }

   confirm() {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to delete this task? This action cannot be undone.',
            accept: () => {
                 this.msgs = [{severity:'info', summary:'Confirmed', detail:'You have accepted'}];
            },
               reject: () => {
                this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
            }
        });
    }

  submit() {
   // event.preventDefault();
    this.createTask();
    this.showTaskModal = false;

  }

  createTask(){
    let createdTs = firebase.database.ServerValue.TIMESTAMP;
    let updatedTs = createdTs;
    let object = { title: this.title, shortDescription: this.shortDescription, complete: false, taskType: this.taskType, createdTs: createdTs, edit: false, collapsed: true, updatedTs : updatedTs };
    return this.tasksRef.push(object).then(() => {
      this.taskType = "Reach Out";
    });
  }

 

  removeTask(task) {
    return this.tasksRef.remove(task.key);
  }

  afterToggle(task: any, collapsed)
  {
    if(!task.edit)
    {
      this.updateTask(task, collapsed);
    }
  }

  updateTask(task: any, collapsed : boolean = false, updateTs: boolean = false) {
      var updatedTs;
      var edit;
      task.shortDescription = task.shortDescription ? task.shortDescription : "";
    if(!task.shortDescription && !task.edit)
      {
        collapsed = true;
      }
  if(updateTs && task.shortDescription)
    {
      collapsed =false;
    }
        
      
    if(updateTs)
    
      {
        updatedTs = firebase.database.ServerValue.TIMESTAMP;
        edit = !task.edit;


      }
      else{
       updatedTs = task.updatedTs
       edit = task.edit
      }
        
    return this.tasksRef.update(task.key, {title: task.title,  shortDescription: task.shortDescription, edit: edit, collapsed: collapsed, updatedTs : updatedTs, taskType : task.taskType});
  }
  completeTask(task: any) {
    console.log(task)
    
     let updatedTs = firebase.database.ServerValue.TIMESTAMP;
        return this.tasksRef.update(task.key, { complete: task.complete, collapsed: true, updatedTs : updatedTs } ).then(()=>
      {
        this.msgs = [];
        task.complete ?   this.msgs.push({severity:'success', summary:'Task Marked Complete!'}) :   this.msgs.push({severity:'info', summary:'Task Marked Incomplete!'});
       
      });
  
  }
  

  ngOnInit() {
    this.taskType = this.taskType;
  }

}

