import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { AngularFireDatabase, AngularFireList} from 'angularfire2/database';
import { InputMask } from 'primeng/primeng';
import { SharedService } from '../shared.service';
import * as firebase from 'firebase/app';
import { Router } from '@angular/router';
@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.scss']
})
export class AgentComponent implements OnInit, OnChanges {
  uid: string;
  user: any;
  userRef = firebase.database().ref('users');
  value = 0;
  display : boolean = false;
  sendInviteDialog = false;
  sendMessageDialog =false;
  GettingStarted = false;
  AppDownload = false;
  LinkCopied = false;
  agentDetails: any = {};
  orderForm: FormGroup;
  items : any;
  brokerPicture = '';
  profilePicture= '';
  role : string = 'agent'
  inviteMessage : string = "";
  inviteFirstName : string = ""
  inviteEmail : string = ""
  inviteLastName : string = ""
  invitePhoneNumber : string = "";  //test number : 15005550009 +15005550009
  isSendEmail: boolean = true;
  firstNameError : boolean = false;
  lastNameError : boolean = false;
  emailError : boolean = false;
  phoneNumberError : boolean = false;
  validEmailError : boolean = false;
  validMessageTypeError : boolean = false;
  validLogoError : boolean = false;
  isSendTextDisabled : boolean;
  isSendText: boolean = true;
  rapidPageValue= ``;
  networkLink: string = "";
  incompleteStep: boolean = false;
  @ViewChild('myInputMask') myInputMask: InputMask;
  text = "Replace text with email template: “Hey it’s [Agent Name]. Please use the link below to download my Realtor app. This is the easiest way for us to communicate while you’re searching for a home. Use it to browse and favorite any properties that you like, request viewings, or chat with me directly. I may also use it to send over necessary documents.[Network Link]"
  constructor(private _sharedService: SharedService, private formBuilder: FormBuilder, private af: AngularFireDatabase, private _router: Router) {
    this.uid = this._sharedService.user.uid;
  }

  ngOnChanges(): void {
    // console.log(this.agentData);
    // this.value = this.agentData
    //   ? this.agentData.account_progress_setup
    //     ? Number(this.agentData.account_progress_setup)
    //     : 0
    //   : 0;
  }

  ngOnInit() {
    /*
    this._sharedService.getUserInfo().then(user => {
      this.agentDetails = {
        image: '../../assets/images/profile.png',
        agent_name: user.firstName + ' ' + user.lastName,
        position: user.brokerAddress,
        company_name: user.brokerName,
        network_link: 'http://homecodec.com/agent_co',
        account_progress_setup: this.getProfileCompletePercentage(user)
      };
      // console.log('m: '+user.role);
      if(user.role){
        this.role = user.role;
      }
    });
    */
    const uid = firebase.auth().currentUser.uid;
    const userRef = this.userRef.child(uid);

    userRef.on('value',(snapshot: any) => {
      const user = snapshot.val();
      this.agentDetails = {
        image: '../../assets/images/profile.png',
        agent_name: user.firstName + ' ' + user.lastName,
        position: user.brokerAddress,
        company_name: user.brokerName,
        network_link: 'http://homecodec.com/agent_co',
        account_progress_setup: this.getProfileCompletePercentage(user)
      };
      this.networkLink = user.networkLink ? user.networkLink : '';
      // console.log('m: '+user.role);
      if(user.role){
        this.role = user.role;
      }
    //  this.brokerPicture = user.brokerPicture ? user.brokerPicture : '';
      this.profilePicture = user.profilePicture ? user.profilePicture : '';

      this.inviteMessage= `Hey it's ${this.agentDetails.agent_name}. Please use the link below to download my Realtor app. This is the easiest way for us to communicate while you're searching for a home.`;
    });


   // const storageRef = firebase.storage().ref().child('users/'+this.uid+'/brokerpicture.jpg');
    const storageRef = firebase.storage().ref().child('users/'+this.uid+'/profilePicture.jpg');
    
        // storageRef.getDownloadURL().then(
        //   (url) => {this.setBrokerPicture(url)},
        //   (error) => {
        //     this.brokerPicture="";
        //     this.validLogoError = true;
        //   }
        //   );

    this.orderForm = this.formBuilder.group({
      sendText: '',
      sendEmail: '',
      inviteLink: this.text,
      items: this.formBuilder.array([this.createItem()])
    });

    if(!sessionStorage.getItem('gettingStarted')){
      this.GettingStarted=true;
    }
  }

  setBrokerPicture(url){
    //this.brokerPicture = url;
    this.profilePicture = url;
    
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      emailId: '',
      phNumber: ''
    });
  }
  addItem(): void {
    this.items = this.orderForm.get('items') as FormArray;
    this.items.push(this.createItem());
  }

  deleteRow(index: number) {
    // control refers to your formarray
    this.items = this.orderForm.get('items') as FormArray;
    // remove the chosen row
    this.items.removeAt(index);
  }
  showDialog() {
    //this.display = true;
  }
  onFormSubmit() {
    this.user = this.orderForm.value;
    this.display = true;
    /* Any API call logic via services goes here */
  }

  showInvitDialog() {
    if(this.networkLink){
      this.sendInviteDialog = true;
    }else{
      this.incompleteStep = true;
    }
  }
  onSendInviteClose() {
    this.sendInviteDialog = false;
  }
  onCloseIncomplete(ev){
    this.incompleteStep = false;
    this._router.navigate(["settings/account-details"]);
  }
  showMessgaeDialog() {
    const validate = this.validateInviteDialog()
    if(validate){
      this.sendInviteEmail();
      this.sendInviteDialog = false;
      this.sendMessageDialog = false;
    }
  }
  isInputMaskFilled(event) {
    if(!this.myInputMask.filled) {
      this.invitePhoneNumber =  '';
    }else{
      this.invitePhoneNumber = this.myInputMask.value;
    }
  }
  validateInviteDialog(){
    this.firstNameError  = false;
    this.lastNameError  = false;
    this.emailError  = false;
    this.phoneNumberError  = false;
    this.validEmailError = false;
    this.validMessageTypeError = false;
    this.validLogoError = false;
    // if(this.brokerPicture==""){
    //   this.validLogoError = true;
    //   return false;
    // }
    if(this.profilePicture==""){
      this.validLogoError = true;
      return false;
    }
    if(this.inviteFirstName == ""){
      this.firstNameError = true;
      return false;
    }
    if(this.inviteLastName == ""){
      this.lastNameError = true;
      return false;
    }
    // if(this.isSendEmail && this.inviteEmail == ""){
    //   this.emailError =  true;
    //   return false;
    // }
    // if(this.inviteEmail!=""){
    //   const validEmail = this.validateEmail(this.inviteEmail);
    //   if(!validEmail){
    //     this.validEmailError = true;
    //     return false;
    //   }
    // }
    if(this.isSendText && this.invitePhoneNumber == ""){
      this.phoneNumberError =  true;
      return false;
    }
    // if(!this.isSendEmail && !this.isSendText){
    //   this.validMessageTypeError = true;
    //   return false;
    // }
    return true;
  }
  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
 sendInviteEmail(){
    const html = "<h3>Welcome</h3>"
    let formRequest = {sender_id: this.uid, sender_name: this.agentDetails.agent_name, receiver_first_name: this.inviteFirstName, receiver_last_name: this.inviteLastName ,email: this.inviteEmail, message: this.inviteMessage, html: html, phone_number: `+1${this.invitePhoneNumber}` };
    this.af.list('/inviteMessages').push(formRequest);
    this.clearInviteDialog();
    alert("Invitation has been sent successfully.");
  }
  clearInviteDialog(){
    this.inviteMessage = "";
    this.inviteFirstName = "";
    this.inviteEmail = "";
    this.inviteLastName = "";
    this.invitePhoneNumber = "";
    this.isSendEmail = true;
    this.isSendText = true;
  }
  onSendMessageClose() {
    this.sendMessageDialog = false;
  }

  CloseInstructionPopUp(){
    this.GettingStarted = false;
    sessionStorage.setItem('gettingStarted', '1');
    // if(!sessionStorage.getItem('appDownload')){
    //   this.AppDownload=true;
    // }
  }

  NextInstuctionPopUp(){
    this.GettingStarted = false;
    sessionStorage.setItem('gettingStarted', '1');
    this.AppDownload=true;
  }

  CloseAppDownloadPopUp(){
    this.AppDownload = false;
    //sessionStorage.setItem('appDownload', '1');
    //this.LinkCopied =true;
  }
  CloseLinkedCopiedPopUp(){
    this.LinkCopied=false;
  }

  getProfileCompletePercentage(user){
    // let keysArray = Object.keys(user);
    let keysArray = this.userActiveFieldArray();
    const totalCount = keysArray.length;
    if(totalCount==0) return 0;
    let remainingCount = 0;
    let completedFieldCount = 0;
    keysArray.map((key) => {
      if(user[key]){
        completedFieldCount++;
      }else{
        remainingCount++;
      }
    });
    // return Math.round((totalCount - remainingCount) * 100 / totalCount);
    return Math.round((completedFieldCount) * 100 / totalCount);
  }


  // Account set up percentage is not correct. It should be number of fields populated divided by number of fields on Account set up and account detail (12 fields) multiplied by 100 (rounded to 0 decimal)
  userActiveFieldArray(){
  const userFields =  ["firstName", "lastName", "phoneNumber", "brokerAddress",  "brokerCity", "brokerLicense", "brokerName", "brokerPhoneNumber", "brokerState", "brokerZip", "userLicense"]
  return userFields;
  }
  

}
