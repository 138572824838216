import { NgModule, Pipe } from '@angular/core';
import { InactivereportComponent } from './inactivereport.component';
import { InactivereportRoutingModule } from './inactivereport-routing.module';
import { DialogModule } from 'primeng/primeng';
import { CalendarModule } from 'primeng/primeng';
import { CheckboxModule } from 'primeng/primeng';
import { InputTextareaModule } from 'primeng/primeng';
import { DataTableModule } from 'primeng/primeng';

import { CommonModule } from '@angular/common';
import { SharedFileModule } from '../shared/shared.module';

@Pipe({
  name: 'inactive',
  pure: false
})
export class InactivePipe {
  transform(values) {
    if (values) {
      var today = new Date();
      var inactive = today.setDate(today.getDate() - 7);
      let filtered = values.filter(item => {
        if (item.lastActive <= inactive) {
          return item;
        }
      });
      return filtered;
    }
  }
}
@NgModule({
  imports: [
    CommonModule,
    DataTableModule,
    InputTextareaModule,
    DialogModule,
    CalendarModule,
    CheckboxModule,
    InactivereportRoutingModule,
    SharedFileModule
  ],
  declarations: [InactivereportComponent, InactivePipe],
  exports: [InactivereportComponent]
})
export class InactivereportModule {}
