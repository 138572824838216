import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase} from 'angularfire2/database';
import { SharedService } from '../../../../shared/shared.service';
import * as firebase from 'firebase/app';
@Component({
  selector: 'app-my-leads',
  templateUrl: './my-leads.component.html',
  styleUrls: ['./my-leads.component.scss']
})
export class MyLeadsComponent implements OnInit {
  items: any;
  uid: string = '';
  email: string = '';
  billingInfo: any;
  profilesArray: any;
  display: boolean = false;
  /**edit budget popup varialbe */
  dailyBudget: number;
  monthlyBudget: number;
  maxLeadPurchasePrice: number; 
  isLeadPurchase: boolean;
  remainingMonthlyBudget: number;
  successcommon: boolean = false;
  successHeader: string = "";
  successMessage: string = "";
  constructor(private af: AngularFireDatabase, private _shared: SharedService) {
    this.uid = this._shared.user.uid;
    this.email = this._shared.user.email;
    this.items = [
      // { month: 'feb', amt: '343' },
      // { month: 'jan', amt: '2250' },
      // { month: 'feb', amt: '343' },
      // { month: 'feb', amt: '343' },
      // { month: 'feb', amt: '343' },
      // { month: 'feb', amt: '343' },
      // { month: 'jan', amt: '2250' },
      // { month: 'feb', amt: '343' },
      // { month: 'feb', amt: '343' },
      // { month: 'feb', amt: '343' },
      // { month: 'dec', amt: '343' }
    ];
  }

  ngOnInit() {
    this.items = [
      { title: 'Chat messages', notify: 'All notifications are turn on', email: false, text: true, crm: true },
      {
        title: 'Activities tab',
        notify: 'All notifications are turn off',
        email: false,
        text: false,
        crm: false
      },
    ];
      this._shared.getBilling().then((result) => {
        if (result) {
          this.billingInfo = result;
          if (this.billingInfo.monthlyFee) {
            const curDate = new Date();
            this.items.push({
              month: this.epochToMonth(curDate.getTime()) ,
              totalAmount: this.billingInfo.monthlyFee.amount
            });
          //   this.items.push({
          //     charge: this.billingInfo.monthlyFee.type,
          //     qty: 1,
          //     price: this.billingInfo.monthlyFee.amount,
          //     total: this.billingInfo.monthlyFee.amount});
          // }
          if (this.billingInfo.profiles) {
          // this.billingInfo.profiles.subscribe((snapshot: any) => {
              const profilesArray = [];
              // this.additionalProfilesCount = 0;
              const profilesArrayKeys = Object.keys(this.billingInfo.profiles);
              profilesArrayKeys.forEach(key => {
                // this.additionalProfilesCount += snap.val().qty;
                profilesArray.push({
                          id: key,
                          amount: this.billingInfo.profiles[key].amount,
                          qty: this.billingInfo.profiles[key].qty,
                          type: this.billingInfo.profiles[key].type,
                          date: this.billingInfo.profiles[key].date
                      });
                      return false;
                  });
                this.profilesArray = profilesArray;
               // this.addItemsToBillingHistory();
            // });
          }

        }
      }
    });
    this.getCurrentSettings();
  }

  showDialog() {
    this.display = true;
  }

  addItemsToBillingHistory() {
    let totalAmount = 0;
    this.profilesArray.forEach(item => {
      console.log(item);
      const curDate = new Date();
      const curMonth = curDate.getMonth();
      const itemDate = new Date(item.date);
      const itemMonth = itemDate.getMonth();
      if (curMonth > itemMonth) {
        const monthInChar = this.epochToMonth(curMonth);
        console.log(monthInChar); 
        // totalAmount += item.amount;
        if(this.items.indexOf(monthInChar)==-1){
          this.items.push({
            month: monthInChar ,
            totalAmount: item.amount
          });
        }
        
      }
    });
  }

  getBillTotal(){
    let totalAmount = 0;
    this.items.forEach(item => {
      totalAmount += item.total;
    });
    return totalAmount;
  }

  epochToDateString(epoch) {
    // adding 30 days in epoch
    const d = new Date(epoch + 30 * 24 * 3600 * 1000);
    return d;
  }

  epochToMonth(epoch){
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const d = new Date(epoch);
    return monthNames[d.getMonth()];
  }

  getNextMonthDate() {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const d = new Date();
    let nextMonth = d.getMonth() + 1;
    if (nextMonth > 11) {
      nextMonth = 0;
    }
    return '01 ' + monthNames[nextMonth] + ' ' + d.getFullYear();
  }
  savePurchaseSettings(){
    this.display = false;
    let payload = {dailyBudget: this.dailyBudget, monthlyBudget: this.monthlyBudget, maxLeadPurchasePrice: this.maxLeadPurchasePrice, purchaseLeads: this.isLeadPurchase};
    let settingRef = firebase.database().ref('leadPurchaseSettings');
    const settingRefs = settingRef.child(this.uid);
    settingRefs.update(payload).then(
      (result: any) => {
        this.successHeader = "MY LEAD";
        this.successMessage = "Budget updated successfully.";
        this.successcommon = true;
        // this.getCurrentSettings();
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  getCurrentSettings(){
    let settingRef = firebase.database().ref('leadPurchaseSettings');
    const settingRefs = settingRef.child(this.uid);
    // settingRefs.once('value').then((snapshot: any) => {
      settingRefs.on('value', (snapshot) => {
      const result = snapshot.val();
      if(result){
        this.dailyBudget =  result.dailyBudget ? result.dailyBudget:0;
        this.monthlyBudget = result.monthlyBudget ? result.monthlyBudget : 0;
        this.maxLeadPurchasePrice = result.maxLeadPurchasePrice ? result.maxLeadPurchasePrice : 0;
        this.isLeadPurchase =  result.purchaseLeads ? result.purchaseLeads : false;
        const spent = result.spent ? result.spent : 0;
        const remaining = this.monthlyBudget - spent;
        this.remainingMonthlyBudget = remaining > 0 ? remaining:0;
      }
      
    });
  }
  onSuccesscommonClose(ev){
    this.successcommon = false;
  }
}
