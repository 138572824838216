import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import { SharedService } from '../shared/shared.service';
import * as firebase from 'firebase/app';

@Component({
  moduleId: module.id,
  selector: 'settings',
  templateUrl: 'settings.component.html',
  styleUrls: ['settings.component.css'],
})
export class SettingsComponent implements OnInit {
  firstName: string = "";
  lastName: string = "";
  email: string = "";
  phoneNumber: string = "";
  display: boolean = false;
  androidLink: string;
  iosLink: string;
  stripe: any;
  cards: AngularFireList<any[]>;
  uid: string;
  addCC: boolean = true;
  subscription: boolean = false;
  currentPlan: string;
  showAppLinks: boolean = false;
  switchTab: boolean = false;
  loading: boolean = false;
  planID: string;
  loadSub: boolean = false;
  trialCode: string = "EXPO413";
  confirmSubscription: boolean = false;
  planAmount: string;
  useTrial: boolean = false;
  showPromoInput: boolean = true;
  selectedPlan: string;
  brokerAddress: string;
  brokerName: string;
  showCancelDialog: boolean = false;
  brokerPicture: any;
  profilePicture: any;
  subscriptionObs: AngularFireObject<any>;
  





  constructor(private af: AngularFireDatabase, private _shared: SharedService) {
    this.uid = this._shared.user.uid;
    this.cards = this.af.list('/stripe/' + this.uid + '/sources/data/');
    this.af.object('/stripe/' + this.uid + '/subscribe/').valueChanges().subscribe((result) => {
      let value = result
      let id = this.planID;
      if (value) {
      }
      else {
        this.loading = false;
      }
    });
    this.subscriptionObs = this.af.object('/stripe/' + this.uid + '/subscriptions/data/0');      
    
 


    this.cards.valueChanges().subscribe((result) => {
      if (result.length > 0) {
        this.addCC = false;
        this.loading = false;
      }
      else {
        this.addCC = true;
      }
    })

    this.subscriptionObs.valueChanges().subscribe((result) => {
      if(result)
      {

      
      if (result.status) {
        this.subscription = true;
        this.currentPlan = result.plan.id;
        this.planID = result.id;
        this.showCancelDialog = false;
        this.confirmSubscription = false;
        if (this.currentPlan == 'Agent_Team_Monthly') {

        }

      }
    }
      else {

        this.subscription = false;
        this.currentPlan = "free";
        this.loading = false;
        this.showCancelDialog = false;

   
      }


    });

  }

  showDialog() {

    this.display = !this.display;

  }



  subscribe(plan: string, trial: string) {
    if (this.addCC) {
      alert("Please add a credit card before selecting a plan")
      this.openCheckout();
    }
    else {
      //this.firebaseService.updateSubscription(plan, trial);
      this.selectedPlan = plan;
      this.confirmSubscription = true;
    }
  }

  addPromo(code: string) {
    if (code == this.trialCode && !this.currentPlan)
    //if(code == this.trialCode)
    {
      this.useTrial = true;
      this.showPromoInput = false;
    }

    else {
      alert("Invalid code, please try again.");
    }
  }



  updateProfilePicture(event: EventTarget) {
    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;
    //this.profilePictureFile = files[0];
    let profilePictureFile = files[0];
    this._shared.updateProfilePicture(profilePictureFile).then((result) => {
      this.profilePicture = result;
    });


  }

  updateBrokerPicture(event: EventTarget) {
    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;
    let brokerPictureFile = files[0];
    this._shared.updateBrokerPicture(brokerPictureFile).then((result) => {
      this.brokerPicture = result;
    });
  }


  accept() {
    if (this.useTrial) {
      this._shared.updateSubscription(this.selectedPlan, 14);
      //this.confirmSubscription = false;
      this.loading = true;
    }

    else {
      this._shared.updateSubscription(this.selectedPlan, null);
      // this.confirmSubscription = false;
      this.loading = true;
    }


  }



  decline() {
    this.confirmSubscription = false;
  }

  submit(event: any) {
    event.preventDefault();
    let payload = {firstName: this.firstName, lastName: this.lastName, email : this.email, phoneNumber : this.phoneNumber, brokerName : this.brokerName, brokerAddress : this.brokerAddress}
    this._shared.updateUserInfo(payload);
    if (!this.currentPlan) {
      this.switchTab = true;
    }
  }


  cancelDialog() {

    this.showCancelDialog = true;


  }



  cancelSubscription() {
    this._shared.cancelSubscription().then((result) => {
      this.loading = true;
    });
  }

  public openCheckout() {

    let handler = (<any>window).StripeCheckout.configure({
      key: 'pk_live_d6Pby9G8K0qvdMLMQGwlLzln',
      locale: 'auto',
      token: (token: any) => {
           
        this.loading = true;
        return this._shared.updateStripe(token).then((result) => {
          this.addCC = false;
        });
      }
    });
    handler.open({
      name: 'HomeCodec',
      image: '../assets/images/icon.png',
      email: this.email,
      allowRememberMe: false,
      panelLabel: 'Save'
    });

  }

  selectPlan(i)
  {
    if(i === 0)
      {
        let payload = {activePlan : true};
        this._shared.updateUserInfo(payload);
        this._shared.cancelSubscription();
        this.currentPlan = "free";
        this._shared.pushMessage("Plan has been switched to the free tier. Changes may take a few minutes to take affect.", "success", "Plan Update:");
        window.scrollTo(0,0);
       
      }
      else if (i === 1){
        
        let plan ='Agent_Team_Monthly';
        this._shared.updateSubscription(plan);
        this.currentPlan = plan;
        this._shared.pushMessage("Plan has been switched to the Agent tier. Changes may take a few minutes to take affect.", "success", "Plan Update:");
        window.scrollTo(0,0);
        this.confirmSubscription = false;
        
  

      }
  }

  setBrokerPicture(url){
    this.brokerPicture = url;
  }


  ngOnInit() {
    this._shared.getUserInfo().then((result) => {
      if (result) {
        this.firstName = result.firstName;
        this.lastName = result.lastName;
        this.email = result.email;
        this.phoneNumber = result.phoneNumber;
        this.brokerAddress = result.brokerAddress;
        this.brokerName = result.brokerName;
        this.stripe = result.stripe;
        this.profilePicture = result.profilePicture;
        //this.brokerPicture = result.brokerPicture;
        //this.brokerPicture = this.afStorage.ref('/users/brokerpicture.jpg').getDownloadURL();
        const storageRef = firebase.storage().ref().child('users/'+this.uid+'/brokerpicture.jpg');
        storageRef.getDownloadURL().then(
          url => this.setBrokerPicture(url)
          );
        if(result.activePlan)
          {
            this.currentPlan = "free";
          }


      }
    }); 

  
    let dynamicLink ="https://g967t.app.goo.gl/?link=https://www.homecodec.com?id=" + this.uid + "&apn=com.homecodecns.homecodec";
    let alink = "https://play.google.com/store/apps/details?id=com.homecodecns.homecodec&referrer=utm_source%3Dportal%26utm_content%3D" + this.uid;
    let ilink = "https://ios.homecodec.com/login;key=" + this.uid;
    this.iosLink = ilink.trim();
    //this.androidLink = alink.trim();
    this.androidLink = dynamicLink.trim();
  }


}
