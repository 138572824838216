import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ButtonModule } from 'primeng/primeng';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import { SharedService } from '../../shared/shared.service';
import { CanActivate, Router, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { map, combineLatest  } from 'rxjs/operators';
import * as firebase from 'firebase/app';


declare var google: any;


@Component({
  moduleId: module.id,
  selector: 'app-properties-infodetails',
  templateUrl: 'properties-infodetails.component.html',
  styleUrls: ['properties-infodetails.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PropertiesInfoDetailsComponent implements OnInit {
  iconBase: string;
  msgs: any[];
  chatList: any;
  items: any;
  display: boolean = false;
  show: boolean = false;
  billingForm: FormGroup;
  locationForm: FormGroup;
  showMap= false;
  showFilterDialog = false;
  showPropertyDialog = false;

  options: any;
  overlays: any[];
  infoWindow: any;
  val: number;
  listingDetailsRef: any;
  user = this._sharedService.user;
  listingDetails: any;
  userName: string = "";
  userPhoneNumber: string = "";
  profilePicture: string = "";
  area: string = "";
  city: string = "";
  price: string = "";
  state: string = "";
  zip: string = "";
  description: string = "";
  propertyType : string = "";
  numberOfBeds :  string = "";
  numberOfBaths : string = "";
  propertyImage : string = "";
  listingId : string = "";
  
  constructor(private formBuilder: FormBuilder, private _sharedService: SharedService, private currentRoute: ActivatedRoute, private af: AngularFireDatabase) {
  }
  ngOnInit() {
   this.currentRoute.params.subscribe(params => {
       let listingDetailsPath = "/propertyListing/" + this.user.uid + "/" + params['listingId'];
       this.listingId = params['listingId'];
       this.af.object(listingDetailsPath).valueChanges().subscribe(data => {
          this.area = data['area'];
          this.city = data['city'];
          this.price = data['price'];
          this.state = data['state'];
          this.description   = data['description'];
          this.propertyType  = data['propertyType'];
          this.numberOfBeds  = data['numberOfBeds'];
          this.numberOfBaths = data['numberOfBaths'];
          this.zip = data['zip'];
        });
    });
    this._sharedService.getUserInfo().then((result) => {
        if (result) {
          this.userName = result.firstName + ' ' + result.lastName;
          this.userPhoneNumber = result.phoneNumber;
          this.profilePicture = result.profilePicture;
        }});
    let imgPath = "/propertyListing/" + this.user.uid + "/" + this.listingId;
    const storageRef = firebase.storage().ref().child(imgPath + '/propertyImage1.jpg');
      storageRef.getDownloadURL().then(
            url => this.setPropertyPicture(url)
            );
  }
  clicked() {
    this.show = !this.show;
  }
  setPropertyPicture(url){
      this.propertyImage = url; 
  }

}



