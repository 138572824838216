import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { ButtonModule } from 'primeng/primeng';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { SharedService } from '../shared/shared.service';



@Component({
  moduleId: module.id,
  selector: 'inactivereport',
  templateUrl: 'inactivereport.component.html',
  styleUrls: ['inactivereport.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InactivereportComponent implements OnInit {
 clients: Observable<any[]>;
 uid : any;
 teamDropdown : any;
 loaded : boolean = true;

  constructor(private _shared : SharedService, private _ngZone: NgZone, private af: AngularFireDatabase) {    
    //this.teamDropdown = [{ name: 'Iron Ways' }, { name: 'Keyhole' }];
  }



  

  


  ngOnInit() {
   // this.uid = [{ name: 'Iron Ways' }, { name: 'Keyhole' }];
    this.uid = this._shared.user.uid;
    let path = "/users/"
    this.clients = this.af.list(path,  ref => ref.orderByChild('assignedTo').equalTo(this.uid)).valueChanges();


  }


}



