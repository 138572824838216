import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AngularFireDatabase, AngularFireList} from 'angularfire2/database';
import { SharedService } from '../../shared/shared.service';
import { Observable } from 'rxjs';
import * as firebase from 'firebase/app';
import { element } from 'protractor';
@Component({
  selector: 'app-team-management',
  templateUrl: './team-management.component.html',
  styleUrls: ['./team-management.component.scss']
})
export class TeamManagementComponent implements OnInit {
  teamDropdown: any;
  display: boolean = false;
  remove: boolean = false;
  invite: boolean = false;
  subscription: boolean = false;
  role: string = "agent";
  showManagerButton: boolean = false;
  showSubscriptionButton: boolean = false;
  showInviteButton: boolean = false;
  showSettingsButton: boolean = false;
  membershipPopup: any = false;
  spendMonthlyPopup: any = false;
  users: Observable<any []>;
  agents: any;
  pendingAgents: any;
  admin: string = "";
  teamLength = 0;
  userCount = 0;
  pendingUserCount = 0;
  teams: Observable<any []>;
  teamArray: any;
  invitationRef: Observable<any []>;
  Object:Object;
  isAdmin = false;
  paidByAdmin = false;
  adminName = "";
  managerName = "";
  managerId = "";
  uid = "";
  removeConfirmation = "";
  selectedAgent: any;
  agentBudget: any;
  selecteFirstName = "";
  selecteLastName = "";
  agentTeamId = "";
  agentRole = "";
  agentTeamName = "";
  pageCall = "agent";
  activeFilter = true;
  pendingFilter = false;
  canUpdateAgent = true;
  selectedTeam = "";
  payAllMember: boolean;
  teamDisplay: boolean = false;
  showMonthlyLead: boolean = true;
  team: boolean = false;
  teamDetails = '';
  teamId = '';
  teamMembers = [];
  selectedTeamName = "";
  popupHeader = "Create Team";
  //used to resend invite
  inviteFullName: string = "";
  inviteEmail: string = "";
  inviteRole: any;
  inviteTeam: any;
  resendInvitation: boolean = false;
  searchedMembers: any[];
  allAgentData: any[]; //agent data without filter
  // adminIndividualLead: boolean;
  adminManagedLead: boolean;
  payConfirmation: boolean = false;
  isManaged: boolean;
  userId: string = "";
  adjustedRate: number;
  userName: string = "";
  selectedIndex: number;
  planType: string = "";
  renewalDate: string = "";
  adminBillingDate: string;
  adminLeadBudget: string;
  constructor(private af: AngularFireDatabase, private _sharedService: SharedService, private cd: ChangeDetectorRef) {
    this.teamDropdown = [{ name: 'Iron Ways' }, { name: 'Keyhole' }];
    this.uid = this._sharedService.user.uid;
    this._sharedService.getUserInfo().then(user => {
        // console.log('m: '+user.role);
        this.admin = user.admin ? user.admin : '';
        this.adminName = user.adminName ? user.adminName : '';
        this.managerId = user.managerId ? user.managerId : '';
        this.managerName = user.managerName ? user.managerName : '';
        this.adjustedRate = user.adjustedRate;
        this.planType = user.planType;
        this.renewalDate = user.renewalDate;
        if(user.role){
          this.isAdmin = user.role == 'admin'? true : false;
          this.role = user.role;
           if(this.role == 'admin' || this.role == 'manager'){
             this.showInviteButton = true;
             this.showSettingsButton = true;
             this.pendignInvitations();
           }
           
           this.activeMemberList();
        }
       
    });
    this._sharedService.getAdminInfo().then(admin => {
      this.payAllMember = admin.payAllMember ? admin.payAllMember : false;
      this.adminManagedLead = admin.managedLead;
      this.adminLeadBudget = admin.leadBudget ? admin.leadBudget:0;
      this.adminBillingDate = admin.billingDate ? admin.billingDate : Date.now();
      if(admin.planType == 'planZ'){
        this.adminBillingDate = admin.trialExpired;
      }
    });
    
  }
  
  activeMemberList(){
    this.getMemberList();
    this.getTeams();
    if(this.role == 'agent'){
      this.canUpdateAgent = false;
      this.agents = [];
    }
  }
  /**
   * get member list for manager as well as for admin.
   */
  getMemberList(){
    this.users = this.af.list('users',ref => ref.orderByChild('admin').equalTo(`${this.admin}`)).snapshotChanges();
    this.users.map(actions => 
      actions.map(a => ({ key: a.key, ...a.payload.val() }))
    ).subscribe(items => {
      this.userCount = items.length>0 ? items.length: 0;
      this.agents = [];
      this.allAgentData = [];
      items.map((item: any) => {
        const joinedDate = item.role == 'admin' ? this.toEpoch(item.createdDttm) : (item.teamJoinedAt? this.toEpoch(item.teamJoinedAt):'');  
            const trialExpired =  this.comapareTrialExpired(item.trialExpired);
            const totalLeadBudget = this.adminManagedLead ? this.adminLeadBudget : item.leadBudget;
            let agentItem = {id: item.key, firstName: item.firstName, lastName: item.lastName, email: item.email, role: item.role, teamName: item.teamName, createdDttm: joinedDate, paidByAdmin: item.paidByAdmin, teamId: item.teamId? item.teamId : '', planType: item.planType, rate: item.rate, profilePicture:  item.profilePicture ? item.profilePicture : '', phoneNumber: item.phoneNumber ? item.phoneNumber : '', trialExpired: trialExpired, totalLeadBudget: totalLeadBudget};
            this.agents.push(agentItem);
            this.allAgentData.push(agentItem);
      });
      this.sortUsersByRole();
   });
  }
  sortUsersByRole(){
    if(this.role == 'admin'){
      this.agents.sort(this.sortUsersDesc);
    }else{  
      this.agents.sort(this.sortUsers);
    }
  }
  comapareTrialExpired(trialExpiredDates){
    const trialExpiredDate = this.toEpoch(trialExpiredDates);
    const todayDate = this.toEpoch(Date.now());
    const date1 = new Date(trialExpiredDate);
    const date2 = new Date(todayDate);
    const trialExpired =  date2 > date1  ? true : false;
    return trialExpired;
  }

  /* function to get get admin details into member array not used*/
  getAdminMember(){
    this._sharedService.getUserInfoById(this.admin).then((item)=>{
      let adminItem =  {id: this.admin, firstName: item.firstName, lastName: item.lastName, email: item.email, role: item.role, teamName: item.teamName, createdDttm: item.teamJoinedAt? this.toEpoch(item.teamJoinedAt) : '', paidByAdmin: item.paidByAdmin, teamId: item.teamId? item.teamId : '',planType: item.planType, rate: item.rate, profilePicture:  item.profilePicture ? item.profilePicture : '', phoneNumber: item.phoneNumber ? item.phoneNumber : ''};
      this.agents.push(adminItem);
      if(this.agents.length>1){
        this.agents.sort(this.sortUsers);
      }
    });
  }
  /** function to get manager details into member array not used*/
  getManagerMember(){
      this._sharedService.getUserInfoById(this.managerId).then((item)=>{
        let managrItem =  {id: this.admin, firstName: item.firstName, lastName: item.lastName, email: item.email, role: item.role, teamName: item.teamName, createdDttm: item.teamJoinedAt? this.toEpoch(item.teamJoinedAt) : '', paidByAdmin: item.paidByAdmin, teamId: item.teamId? item.teamId : '', planType: item.planType, rate: item.rate, profilePicture:  item.profilePicture ? item.profilePicture : '',  phoneNumber: item.phoneNumber ? item.phoneNumber : ''};
        this.agents.push(managrItem);
        if(this.agents.length>1){
          this.agents.sort(this.sortUsers);
        }
      });
  }

  getProfileCount(){
    // const appUserRef = firebase.database().ref('userGeneratedLeads').child(item.key);
    //       const ref = appUserRef.orderByChild('assignedTo').equalTo(item.key);
    //       let userArray = [];
    //       ref.once('value').then((snapshot) => {
    //         const result = snapshot.val();
    //         snapshot.forEach((element)=>{
    //           userArray.push(element);
    //         });
    //       })
  }
  getTeams(){
    this.teams = this.af.list('teams',ref => ref.orderByChild('admin').equalTo(`${this.admin}`)).snapshotChanges();
    this.teamArray = [];
    
    this.teams.map(actions => 
      actions.map(a => ({ key: a.key, ...a.payload.val() }))
    ).subscribe(items => {
      this.teamLength = items.length;
    //push default all team 
      let defaultSelection = {label: 'All', value: '0', managerId: '', managerName: ''};
      this.teamArray.push(defaultSelection);
      //push unassigned team..
      const unAssigned = {label: 'unassigned', value: '1', managerId: '', managerName: ''};
      this.teamArray.push(unAssigned);
      items.sort(this.sortTeams)
      items.map((item)=>{
        // let teamPush = [];
        let teamPush = {label: item.name, value: item.key, managerId: item.lead ? item.lead['id']: '', managerName: item.lead ? item.lead['firstName']+' '+item.lead['lastName']:''};
        this.teamArray.push(teamPush);
      });
      this.selectedTeam = this.teamArray[0];
      console.log(this.teamArray);
    });
  }
  pendignInvitations(){
    this.invitationRef = this.af.list('inviteAgents',ref => ref.orderByChild('admin').equalTo(`${this.admin}`)).snapshotChanges();
    this.invitationRef.map(actions => 
      actions.map(a => ({ key: a.key, ...a.payload.val() }))
    ).subscribe(items => {
      this.pendingUserCount = 0;
      this.pendingAgents = [];
      items.map((item)=>{
        if(!item.accepted){
          this.pendingUserCount += 1;
          item['firstName'] = item.receiverName;
          item['lastName'] = '';
          item['role'] = item.teamRole;
          item['paidByAdmin'] = item.payForAgent;
          item['createdDttm'] = item.invitedDate ? this.toEpoch(item.invitedDate) : '';
          // item.firstName + ' ' + item.lastName}  {{item.email}} {{item.teamName
          this.pendingAgents.push(item);
        }
      });
    });
  }
  sortUsersDesc(a, b) {
    if(a.role && b.role){
      const name1 = a.role.toLowerCase();
      const name2 = b.role.toLowerCase();
      if(name1>name2){
        return -1;
      }
      if(name1<name2){
        return 1;
      }
    }
    return 0;
  }
  sortUsers(a, b) {
    if(a.role && b.role){
      const name1 = a.role.toLowerCase();
      const name2 = b.role.toLowerCase();
      if(name1<name2){
        return -1;
      }
      if(name1>name2){
        return 1;
      }
    }
    return 0;
  }
  sortTeams(a, b) {
    if(a.name && b.name){
      const name1 = a.name.toLowerCase();
      const name2 = b.name.toLowerCase();
      if(name1<name2){
        return -1;
      }
      if(name1>name2){
        return 1;
      }
    }
    return 0;
  }
  ngOnInit() {

  }
  openPayConfirm(item, index){
    console.log(item);
    console.log(index);
    console.log(this.agents[index]);
    // item.id, item.paidByAdmin=!item.paidByAdmin, item.firstName + ' ' + item.lastName
    this.isManaged = !item.paidByAdmin;
    this.userId = item.id;
    this.userName = item.firstName + ' ' + item.lastName;
    this.selectedIndex = index;
    this.payConfirmation= true;
  }
  onConfirmationClose(ev){
    if(this.payConfirmation){
      if(ev){
        this.updatePayForAgent();  
      }else{
       this.activeMemberList();
      }
        this.payConfirmation = false;  
        this.cd.detectChanges();  
     }
  }
  updatePayForAgent(){
    let payload;
    if(this.isManaged){
      payload = {paidByAdmin: true, planType: this.planType, rate: this.adjustedRate, adjustedRate: this.adjustedRate, unpaidByAdminDate: ""};
      this.updateBillingLog(this.userId, this.adminBillingDate);
    }else{
      payload = {paidByAdmin: this.isManaged, planType: '', rate: 0, adjustedRate: 0, unpaidByAdminDate: Date.now(), billingDate: this.adminBillingDate};
      this.cancelBillingLog();  
    }
    this._sharedService.updateUserInfoById(this.userId, payload).then(()=>{
      console.log('Updated billing settings');
    });
  }

  markAsManager(){
     var payload = { role: 'team manager' }
     this._sharedService.updateUserInfo(payload).then(() => {
        alert('Role has been successfully updated.');
        this.role = 'team manager' 
      });
  }

  showEdit(user) {
    this.selectedAgent = user;
    this.selecteFirstName = user.firstName;
    this.selecteLastName = user.lastName;
    this.agentTeamId = user.teamId;
    this.agentTeamName = user.teamName;
    this.agentRole = user.role;
    if(this.role == 'manager'){
      if(this.adminManagedLead || (!this.adminManagedLead && user.id != this.uid)){
        this.showMonthlyLead = false;
      }else{
        this.showMonthlyLead = true;
      }
    }
    // this.display = true;
    if(this.adminManagedLead){
      this.agentBudget = this.adminLeadBudget;
      this.display = true;
    }else{
      /*let settingRef = firebase.database().ref('leadPurchaseSettings');
      const settingRefs = settingRef.child(user.id);
      settingRefs.once('value', (snapshot) => {
      const result = snapshot.val();
      if(result){
        this.agentBudget = result.monthlyBudget ? result.monthlyBudget : '';
      }
    });*/
      this._sharedService.getUserInfoById(this.uid).then((res) => {
         if(res){
           this.agentBudget = res.leadBudget ? res.leadBudget : 0;
           this.display = true;
         }
      });
    }
    
    
  }
  showRemoveDialog(user) {
    if(this.canUpdateAgent){
      this.selectedAgent = user;
      this.selecteFirstName = user.firstName;
      this.selecteLastName = user.lastName;
      this.agentTeamId = user.teamId;
      this.agentTeamName = user.teamName;
      this.agentRole = user.role;
      this.remove = true;
      this.removeConfirmation = "Are you sure you want to remove this agent from your account? You will be unable to manage and view this agent.";
    }
    
  }

  removeInvite(user){
    const inviteRef = firebase.database().ref('inviteAgents').child(user.key);
         inviteRef.remove().then(()=>{
          // this.pendingAgents.push(item);
          const index = this.agents.indexOf(user);
          if (index !== -1) {
            this.agents.splice(index, 1);
          }
           alert("Invitation has been removed successfully.");

         });
    
  }
  showInviteDialog(item: any = null) {
    if(item){
      this.inviteFullName = item.firstName + ' ' + item.lastName;
      this.inviteEmail = item.email;
      this.resendInvitation = true;
      this.inviteTeam = item.team ? {value: item.team, name: item.teamName, managerId:'', managerName: ''}:{value: '', name:'unassigned', managerId:'', managerName: ''};
      // this.inviteRole = [];
      this.inviteRole = {name: item.role, value: item.role};
      console.log(this.inviteRole);
      console.log(item);
    }else{
      this.inviteFullName ='';
      this.inviteEmail ='';
      this.inviteTeam ='';
      this.inviteRole ='';
      this.resendInvitation = false;
    }
    this.invite = true;
  }
  onInviteDialogClose(event) {
    this.invite = event;
  }
  onRemoveDialogClose(event) {
    this.remove = event;
  }
  onEditDialogClose(event) {
    this.display = event;
  }
  showSubscriptionDialog() {
    this.subscription = true;
  }
  onSubscriptionDialogClose(event){
    this.subscription = event;
  }
  toEpoch(epoch,format='datetime'){
    
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    epoch = Number(epoch);
    var date = new Date(epoch);

    var year = date.getFullYear();
    var month = monthNames[date.getMonth()];
    var day = date.getDate();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    // September 4, 2018
    return  month +" "+ day +", " + year;
  }
  applyActiveFilter(){
    this.activeFilter = true;
    this.pendingFilter = false;
    this.canUpdateAgent = true;
    this.teamDisplay = false;
    this.activeMemberList();
  }
  applyPendingFilter(){
    this.canUpdateAgent = false;
    this.agents = [];
    this.agents = this.pendingAgents;
    this.activeFilter = false;
    this.pendingFilter = true;
    this.teamDisplay = false;
  }
  applyTeamFilter(){
    this.canUpdateAgent = false;
    this.agents = [];
    // this.agents = this.pendingAgents;
    this.activeFilter = false;
    this.pendingFilter = false;
    this.teamDisplay = true;
  }
  /**get agent list by team */
  getAgentListByTeam(ev){
    console.log(ev);
    /**get active agent list by team... */
    if(this.activeFilter){
      if(this.selectedTeam == '0'){
        this.activeMemberList();
      }else if(this.selectedTeam == '1'){
        this.getActiveAgentByTeam(true);
      }else{
        this.getActiveAgentByTeam(false);
      }
      
    }
    console.log(this.selectedTeam);
  }
  getActiveAgentByTeam(byName){
    let teamMembers;
    if(byName){
        teamMembers = this.af.list('users',ref => ref.orderByChild('admin').equalTo(`${this.admin}`)).snapshotChanges();
      teamMembers.map(actions => 
        actions.map(a => ({ key: a.key, ...a.payload.val() }))
      ).subscribe(members => {
        this.agents = [];
        this.allAgentData = [];
        members.map((item)=>{
            console.log(item);
            const joinedDate = item.role == 'admin' ? this.toEpoch(item.createdDttm) : (item.teamJoinedAt? this.toEpoch(item.teamJoinedAt):'');  
            const totalLeadBudget = this.adminManagedLead ? this.adminLeadBudget : item.leadBudget;
            const trialExpired =  this.comapareTrialExpired(item.trialExpired);
            if(item.teamName == 'Unassigned' || item.teamName == 'unassigned' || !item.teamId){
              let agentItem = {id: item.key, firstName: item.firstName, lastName: item.lastName, email: item.email, role: item.role, teamName: item.teamName, createdDttm: joinedDate, paidByAdmin: item.paidByAdmin, teamId: item.teamId? item.teamId : '', planType: item.planType, rate: item.rate, profilePicture:  item.profilePicture ? item.profilePicture : '', phoneNumber: item.phoneNumber ? item.phoneNumber : '', trialExpired: trialExpired, totalLeadBudget: totalLeadBudget};
              this.agents.push(agentItem);
              this.allAgentData.push(agentItem);
           }
        });
      });
    }else{
      teamMembers = this.af.list('users',ref => ref.orderByChild('teamId').equalTo(`${this.selectedTeam}`)).snapshotChanges();
      teamMembers.map(actions => 
        actions.map(a => ({ key: a.key, ...a.payload.val() }))
      ).subscribe(members => {
        this.agents = [];
        this.allAgentData = [];
        members.map((item)=>{
              const totalLeadBudget = this.adminManagedLead ? this.adminLeadBudget : item.leadBudget;
              const trialExpired =  this.comapareTrialExpired(item.trialExpired);
              let agentItem = {id: item.key, firstName: item.firstName, lastName: item.lastName, email: item.email, role: item.role, teamName: item.teamName, createdDttm: item.teamJoinedAt? this.toEpoch(item.teamJoinedAt) : '', paidByAdmin: item.paidByAdmin, teamId: item.teamId? item.teamId : '', planType: item.planType, rate: item.rate, profilePicture:  item.profilePicture ? item.profilePicture : '', phoneNumber: item.phoneNumber ? item.phoneNumber : '', trialExpired: trialExpired, totalLeadBudget: totalLeadBudget};
              this.agents.push(agentItem);
              this.allAgentData.push(agentItem);
        });
      });
    }
    this.sortUsersByRole();
     
    }

    showTeamDialog() {
      this.teamDetails = '';
      this.teamId = '';
      this.teamMembers = [];
      this.selectedTeamName = "";
      this.popupHeader = "Create Team";
      this.team = true;
    }
    onTeamDialogClose(event, flag) {
     
      this.team = event;
    }
    /**search member functionality */
    // search members....
  searchSuggestion(event) {
    if(this.activeFilter){
      this.activeMemberSearch(event)
    }
    
  }
  selectedSearchedAgent(ev){
    if(this.activeFilter){
      this.activeSearchMemberSelected(ev);
    }
  }
  clearSearchBox(){
    if(this.activeFilter){
      this.clearActiveSearch();
    }
  }

  /** active member search */
  activeMemberSearch(event){
    this.searchedMembers = [];
    this.agents = [];
    for(let i = 0; i < this.allAgentData.length; i++) {
        let agent = this.allAgentData[i];
        const name = agent.firstName +' '+ agent.lastName;
        if(name.toLowerCase().indexOf(event.query.toLowerCase()) == 0 ) {
            this.searchedMembers.push(name);
            this.agents.push(agent);
        }
    }
  }
  activeSearchMemberSelected(ev){
    this.agents = [];
    for(let i = 0; i < this.allAgentData.length; i++) {
      let agent = this.allAgentData[i];
      const name = agent.firstName +' '+ agent.lastName;
      if(name.toLowerCase().indexOf(ev.toLowerCase()) == 0 ) {
          this.agents.push(agent);
      }
    }
  }
  clearActiveSearch(){
    this.agents = [];
    this.allAgentData.map((agent)=>{
      this.agents.push(agent);
    });
  }



  openMembershipPopup(){
    this.spendMonthlyPopup =false;
    this.membershipPopup = true;  
  }
  closeMembershipPopup(){
    this.membershipPopup = false;
  }
  openSpendPopup(){
    this.membershipPopup =false;
    this.spendMonthlyPopup = true;
  }
  closeSpendPopup(){
    this.spendMonthlyPopup = false;
  }

  updateBillingLog(userId, renewalDate){
    this._sharedService.updateBillingLog(userId, renewalDate, this.adjustedRate)
   /* const billingRef = firebase.database().ref('billing');   
    const billingRefs = billingRef.child(this.uid);
    const payload = {type: 'monthlyFee', amount: this.adjustedRate, date: renewalDate, description: 'Monthly subscription charges', payFor: userId, cancelled: false};
    billingRefs.push(payload);*/
  }

  cancelBillingLog(){
    this._sharedService.cancelBillingLog(this.userId);
  //   const billingPath = "/billing/"+this.uid; 
  //   const billingRef = firebase.database().ref(billingPath);
  //   billingRef.orderByChild('type').equalTo('monthlyFee').once('value').then(snapshot => {
  //       const result = snapshot.val();
  //       const resultArrayKeys = Object.keys(result);
  //       resultArrayKeys.forEach(key => {
  //         if(result[key].payFor==this.userId){
  //           const billingRef = firebase.database().ref('billing');   
  //           const billingRefs = billingRef.child(this.uid).child(key);
  //           billingRefs.update({cancelled: true});
  //         }
  //       })
  //     })
  // }
  }
}
