import { map } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ActivatedRouteSnapshot } from '@angular/router';
import { auth } from 'firebase/app';
import { Observable } from 'rxjs';
import { SharedService } from '../../shared/shared.service';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject
} from 'angularfire2/database';

@Component({
  selector: 'app-chatroom',
  templateUrl: './chat-room.component.html',
  styleUrls: ['./chat-room.component.scss']
})
export class ChatRoomComponent implements OnInit, OnChanges {
  onicko: any = {};
  public map_id = {};
  @Input() leadData: any[] = [];
  @Input() user_id: any;
  @Output() selectedLead: EventEmitter<any> = new EventEmitter();
  @Output() mapId: EventEmitter<any> = new EventEmitter();
  show = false;
  show1 = false;
  showArray: boolean[] = [];
  chatList: any;
  uid:string;
  linkedClientList: Observable<any>;
  linkedClientRef: AngularFireList<any>;
  clientList: any;



  private items: MenuItem[];

  constructor(private db: AngularFireDatabase, private _shared: SharedService) {
    this.uid = this._shared.user.uid;
    this.getChatRooms();
    console.log(this.uid);
    let userGeneratedLeadsPaths = "/userGeneratedLeads/" + this.uid;
    this.linkedClientRef = this.db.list(userGeneratedLeadsPaths);
    this.linkedClientList = this.linkedClientRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key,  ...c.payload.val() }))
      )
    );
  }

  ngOnChanges() {
    this.getChatRooms();
  }

  getChatRooms() {
    const chatRooms$ = this.db
      .list('chat', ref =>
        ref.orderByChild(`members/${this.user_id}`).equalTo(true)
      )
      .valueChanges();
    chatRooms$.subscribe(list => {
      console.log(list);
      list.map(value => {
        // Finding member details from the chat
        const members = Object.keys(value['members']);
        const index = members.indexOf(this.user_id);
        if (index > -1) {
          members.splice(index, 1);
        }
        const memberId = members[0];
        console.log(memberId);
        memberDetails(memberId, value);
      });
      if (list.length > 0) {
        this.chatList = list;
        this.chatList.map(chat => {
          this.onicko[chat.chat_id] = true;
          this.map_id[chat.chat_id] = {
            setTime: () => {
              setTimeout(() => {
                this.onicko[chat.chat_id] = false;
              }, 1000 * 3);
            },
            clearTime: () => {
              clearTimeout(this.map_id[chat.chat_id].setTime);
            }
          };
        });
        this.mapId.emit(this.map_id);
        this.chatList.sort((a, b) => -(a.latest_msg_ts - b.latest_msg_ts));
        this.showArray = new Array(this.chatList.length);
        this.showArray.fill(false);
        console.log(this.chatList);
      } else {
        console.log('ni');
        console.log(this.chatList);
        // this.chatList = [];
      }
    });

    const memberDetails = (memberId, value) => {
      // const userRef = this.db.object(`users/${memberId}`).valueChanges();
      const userRef = this.db.object(`appUsers/${memberId}`).valueChanges();
      userRef.subscribe(userDetails => {
        if(userDetails){
          value.userDetails = userDetails;
          value.userDetails['chat_to'] = memberId;
        }
      });
    };
  }

  ngOnInit() {
    this.items = [
      {
        items: [
          { label: 'Mark as read' },
          { label: 'Block user' },
          { label: 'Delete chat' }
        ]
      }
    ];
  }

  checkIfLatest(timestamp: number): boolean {
    const date = new Date();
    return (date.getTime() - timestamp) / 1000 <= 300;
  }

  /**
   * Function to pass chat details to message components
   * @param {*} chat
   * @memberof ChatRoomComponent
   */
  clickLead(chat: any) {
    console.log('h');
    console.log(chat);
    chat.userDetails['chat_id'] = chat.chat_id;
    this.selectedLead.emit(chat);
  }
  clickedNewChat(chat: any){
//     active_ts: 
// ""
// chat_between: 
// "person3"
// chat_id: 
// "-LEUI8Yfx0qBfBZVhr_R"
// chat_status: 
// "Active"
// last_read_message_ts: 
// 1530579390885
// latest_msg: 
// "adfqwerqwerqwer"
// latest_msg_ts: 
// ""
    this.db
        .list(`chat/${this.uid}`)
        .push({
          chat_between: chat.firstName,
        })
        .then(ref => {
          const chatRef = this.db.list(`chat`);
          chatRef.update(ref.key, {
            chat_id: ref.key
          }).then((ref)=>{
            console.log(ref);
            // this.clickLead(ref);
          });
        });
  }


  clicked() {
    this.show = !this.show;
  }

  click(index?: number) {
    if (index > -1) {
      this.showArray[index] = !this.showArray[index];
    } else {
      this.show1 = !this.show1;
    }
  }

  /**
   * Function to delete a particular chat
   * @memberof ChatRoomComponent
   */
  deleteChat(chatId: string) {
    if (chatId) {
      this.db
        .list('chat')
        .remove(chatId)
        .then(_ => {
          this.db.list('messages').remove(chatId);
        });
    }
    this.selectedLead.emit('');
  }

  /**
   * Function to block a particular user
   * @param {string} chatId chat id of the user to be blocked
   * blocked items are moved to blacklist tab
   * @memberof ChatRoomComponent
   */
  blockUser(chatId: string) {
    const user = {};
    user[`${this.user_id}`] = false;
    const deactivate = {
      // members: user,
      chat_status: 'Deleted'
    };
    this.db.list(`chat`).update(chatId, deactivate);
    this.selectedLead.emit('');
  }

  // checkUserInteraction() {
  //   setTimeout(() => {
  //     console.log('chat works');
  //   }, 1000 * 40);
  // }
}
