import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { SharedService } from '../shared/shared.service';
import {AngularFireDatabase, AngularFireList} from 'angularfire2/database';
import * as Papa from "papaparse/papaparse.js";
import {Router} from '@angular/router';
import { MessagesService } from "../shared/messages.service";
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import {Subject} from 'rxjs/Subject';
@Component({
  moduleId: module.id,
  selector: 'importclients',
  templateUrl: 'importclients.component.html',
  styleUrls: ['importclients.component.scss'],
})
export class ImportclientsComponent implements OnInit {
  areaDropdown: any;
  teamDropdown: any;
  items: any;
  // @Input() showSuccess: boolean;
  // @Output() displayChange = new EventEmitter();
  inviteForm: FormGroup;
  showSuccess: boolean = false;
  display: boolean = false;
  remove: boolean = false;
  newclient: boolean = false;


  file: any;
  uid;
  userClientRef : AngularFireList<any>;
  @Input() isPopupCall: boolean = false;
  @Input() saveFromDialog: Subject<boolean>;
  @Output() displayChange = new EventEmitter();
  constructor( private messageService: MessagesService, private router : Router, private af : AngularFireDatabase, private _shared : SharedService, private formBuilder: FormBuilder) {

    this.teamDropdown = [{ name: 'Iron ways' }];
    this.areaDropdown = [
      { name: 'Team Manager' },
      {
        name: 'Sales Agent'
      }
    ];
   }

  onChange(event: EventTarget) {
    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;
    this.file = files[0];


  }


  submit(e: Event) {
    event.preventDefault();
    var file = this.file;
Papa.parse(file, {
      complete: (results: any) => {
        this.pushClients(results);
      }

    });
    
  }

   pushClients(results: any) {
    let count = 0;
    results.data.forEach((item: any) => {
  
      let firstName = item[0];
      let lastName = item[1];
      let email = item[2];
      let phone = item[3];

      email ? email = email : email = "";
      phone ? phone = phone : phone = "";
if(firstName && lastName)
  {
    this.userClientRef.push({firstName : firstName, lastName : lastName, email: email, phone : phone, status: "New Lead", userGenerated: true, assignedTo : this.uid, active : true });
    count++;
    
  }
    
    })
    if(this.isPopupCall){
      this.displayChange.emit(true);
      return;
    }
    this.messageService.msgs.push({ severity: 'success', summary: 'Import Complete', detail: count +  ' Clients have been imported successfully' });
    
    this.router.navigateByUrl("/clientprofiles");
  }



  ngOnInit() {

    this.uid = this._shared.user.uid;
    let path = "/userGeneratedLeads/" + this.uid;
    this.userClientRef = this.af.list(path);
    this.inviteForm = this.formBuilder.group({
      agents: this.formBuilder.array([this.createItem()])
    });
  }
 

  showEdit() {
    this.display = true;
  }
  showRemoveDialog() {
    this.remove = true;
  }
  showNewDialog() {
    this.newclient = true;
  }
  onNewDialogClose(event) {
    this.newclient = event;
  }
  showSuccessDialog() {
    this.showSuccess = true;
  }




 


  createItem(): FormGroup {
    return this.formBuilder.group({
      name: '',
      email: '',
      team: '',
      role: ''
    });
  }
  addItem(): void {
    this.items = this.inviteForm.get('agents') as FormArray;
    this.items.push(this.createItem());
  }

  deleteRow(index: number) {
    // control refers to your formarray
    this.items = this.inviteForm.get('agents') as FormArray;
    // remove the chosen row
    this.items.removeAt(index);
  }
  onFormSubmit(inviteForm) {
    console.log(inviteForm);
    /* Any API call logic via services goes here */
  }

  onClose() {
    //this.displayChange.emit(false);
  }
  finishLater(){
    if(this.isPopupCall){
      this.displayChange.emit(false);
    } 
  }

  // Work against memory leak if component is destroyed
  ngOnDestroy() {
    // this.displayChange.unsubscribe();
  }
}



