import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { Observable } from 'rxjs';
import * as firebase from "firebase/app";
import { ScheduleModule, CalendarModule } from 'primeng/primeng';
import * as moment from 'moment/moment';
import { SharedService } from '../shared/shared.service';


@Component({
    moduleId: module.id,
    selector: 'calendar',
    templateUrl: 'calendar.component.html',
    styleUrls: ['calendar.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class CalendarComponent implements OnInit {
    tasks: AngularFireList<any[]>;

    //events: AngularFireList<any[]>;
    //events : any;
    header: any;
  
    event: MyEvent = new MyEvent;
    eventObs: AngularFireList<any>
    dialogVisible: boolean = false;
    today = Date();
    idGen: number = 100;
    events: any[] = [];

    loaded = false;
    newAppointment = false;
    newTask = false;


    taskTitle: string = '';
    taskDate: any;
    titleError: boolean = false;
    startDateError: boolean = false;
    startTimeError: boolean = false;
    endTimeError: boolean = false;
    invalidTimeError: boolean = false;
    validLogoError: boolean = false;
    successcommon: boolean = false;
    taskStartTime: any;
    taskEndTime: any;
    taskLocation: string = "";
    taskNote: string = "";
    taskReminder: boolean = false;
    taskType: any;
    tasktypeDropdown: any;
    selectedEvent: string;
    clientArray: any;
    userArray: any;
    selectedClient: any;
    uid: any;
    constructor(private cd: ChangeDetectorRef, private af: AngularFireDatabase, private _sharedService: SharedService) {
  
     
        // this.tasktypeDropdown = [{ name: 'Call' }, { name: 'Meeting' }, { name: 'Research' }, { name: 'Task' },{ name: 'Tour' }, { name: 'Other' }];
        this.tasktypeDropdown = [{ name: 'Call' }, { name: 'Meeting' }, { name: 'Research' }, { name: 'Task' },{ name: 'Tour' }, { name: 'Other' }];



    }

    openUrl(e) {
        if (e) {
          window.open(e, "_blank");
        }
    
      }

    ngOnInit() {


        this.header = {
            left: 'prev,next today, month,agendaWeek,agendaDay',
            center: 'title',
            right:''
            // right: 'month,agendaWeek,agendaDay'
        };
        this.uid = this._sharedService.user.uid;

        let uid = this._sharedService.user.uid;
        let path = "/calendar/" + uid;
        this.eventObs = this.af.list(path);

//TODO Refactor events into an observable and change from foreach to map
        this.af.list(path).snapshotChanges().subscribe((items) => {
           let tempArray = [];
            items.forEach((item) => {
               let toPush = item.payload.val();
               toPush['key'] = item.payload.key;
               tempArray.push(toPush);
            });
            this.events = tempArray
        });
        this.loaded = true;
        this.getClientList();
    }
    getClientList(){
        
       
        let uid = this._sharedService.user.uid;
        const userRefs = firebase.database().ref('appUsers');
        const userRef = userRefs.orderByChild('agentId').equalTo(uid);
        userRef.once('value',(snapshot: any) => {
        const result = snapshot.val();
        if(result){
            let tempArray = [];
            this.userArray = [];
            // const defaultValue = {name:'--Select Client--', id:null};
            // this.userArray.push(defaultValue);
            tempArray = Object.entries(result).map(item => ({...item[1], key: item[0]}));
            tempArray.map((item)=>{
                const userName = `${item.firstName} ${item.lastName}`;
                const obj = {name: userName, id: item.key};
                this.userArray.push(obj);
            });
        }
        });
    }
    newAppointmentButtonClick(){
        this.newAppointment = true;
    }
    
    onNewAppointmentDialogClose(event){
        this.newAppointment = event;
    }
   
    
    onTaskDialogClose(event){
        this.newTask = event;
    }

    handleDayClick(event: any) {
        this.event = new MyEvent();
  
        this.event.start = event.date.format('YYYY-MM-DD hh:mm A');
        if(this.event.description) this.event.description = event.description;
        this.dialogVisible = true;
        this.event.allDay = false;
        //trigger detection manually as somehow only moving the mouse quickly after click triggers the automatic detection
        this.cd.detectChanges();

    }

    handleDrop(e: any) {

        this.event = new MyEvent();
        this.event.title = e.event.title;
        if(this.event.description) this.event.description = e.event.description;
        let start = e.event.start;
        let end = e.event.end;


        if (end) {
            this.event.end = end.format('YYYY-MM-DD HH:mm');

        }
        this.event.key = e.event.key;

        this.event.id = e.event.key;
        this.event.start = start.format('YYYY-MM-DD HH:mm');

        this.event.allDay = e.event.allDay;
        this.eventObs.update(this.event.key, this.event);
         this.event = new MyEvent;

    }

   /*  handleEventClick(e: any) {
        console.log(e);
        this.event = new MyEvent();
        //let start = e.calEvent.start.format('YYYY-MM-DD HH:mm A');;
        let start = moment(e.calEvent.start, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD hh:mm A');
        this.event.start = start;
        if(this.event.description) this.event.description = e.calEvent.description;
        
        if(e.calEvent.end)
        {   
            //let end = e.calEvent.end.format('YYYY-MM-DD HH:mm');
           let end = moment(e.calEvent.end,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD hh:mm A');
            this.event.end = end
           
        }
        
        // const startTime = moment(e.calEvent.start, 'YYYY-MM-DD HH:mm').format("HH:mm");
        // const endTime = moment(e.calEvent.end, 'YYYY-MM-DD HH:mm').format("HH:mm");
        // const activityStartDate = e.calEvent.start;
        // // moment(e.calEvent.start, 'YYYY-MM-DD HH:mm').format("YYYY-MM-DD");;
        // // const activityEndDate = `${moment(this.taskDate).format("YYYY-MM-DD")} ${endTime}`;
        
        // this.taskTitle = e.calEvent.title;
        // this.taskDate = activityStartDate;
        // this.taskStartTime = startTime;
        // this.taskEndTime = endTime;

        this.event.title = e.calEvent.title;
        this.event.key = e.calEvent.key;
        this.event.id = e.calEvent.key;


        this.event.allDay = e.calEvent.allDay;
        this.dialogVisible = true;
        // this.newTask = true;
    } */

    saveEvent(event: any) {
        //update
        this.dialogVisible = false;
        this.event.start = moment(this.event.start, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm');
   
        if (this.event.end) {
       
            this.event.end = moment(this.event.end, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm');
        }


        if (!this.event.title) {
            this.event.title = "";
        }
        if (this.event.key) {
            this.eventObs.update(event.key, this.event);
            this.event = new MyEvent;
        }
        //new
        else {
            this.event.id = this.idGen++;
            this.eventObs.push(this.event).then((item)=>
            {
                this.eventObs.update(item.key, {id: item.key});
            });
            this.event = new MyEvent;
        }

        
    }

    deleteEvent(event: any) {
        this.eventObs.remove(event.key);
     
        if(event.description)
            {
                let path = event.description.path;
                this.af.object(path).update({ scheduledViewing: false });
            }
    
        this.dialogVisible = false;
    }


    /**new code to store activity on calendar */
    handleEventClick(e: any) {
        console.log(e)
        this.selectedEvent = e.calEvent.key;
        this.taskTitle = e.calEvent.title;
        this.event.allDay = e.calEvent.allDay;
        this.idGen = e.calEvent.id;
        this.taskDate = new Date(e.calEvent.start);
        this.taskStartTime = moment(e.calEvent.start, 'YYYY-MM-DD HH:mm').format('HH:mm');
        this.taskEndTime =  moment(e.calEvent.end, 'YYYY-MM-DD HH:mm').format('HH:mm');
        this.taskLocation = e.calEvent.location;
        this.taskNote = e.calEvent.description;
        this.taskReminder = e.calEvent.reminder
        this.taskType = {name: e.calEvent.type};
        this.newTask = true;
    }



    newTaskButtonClick(e: any){
        if(e){
            this.taskDate = new Date(e.date);
         }
        this.newTask = true;
    }
    saveActivity(){
        const validate = this.validate(); 
        if(validate){
          const startTime = moment(this.taskStartTime).format("HH:mm");
          const endTime = moment(this.taskEndTime).format("HH:mm");
          const activityStartDate = `${moment(this.taskDate).format("YYYY-MM-DD")} ${startTime =="Invalid date"? this.taskStartTime:startTime}`;
          const activityEndDate = `${moment(this.taskDate).format("YYYY-MM-DD")} ${startTime =="Invalid date"? this.taskEndTime:endTime}`;
          const idGen = this.selectedEvent ? this.idGen:this.idGen++;
          const payload = {start: activityStartDate, end: activityEndDate, title: this.taskTitle, description: this.taskNote, reminder: this.taskReminder, location: this.taskLocation, type: this.taskType ? this.taskType['name']: '', id: idGen};
          let uid = this._sharedService.user.uid;
          let path = "/calendar/" + uid;
          let agentRecordId: any;
          let clientRecordId: any;
          this.eventObs = this.af.list(path);
          if(this.selectedEvent){
            this.eventObs.update(this.selectedEvent, payload);
            this.newTask = false;
            this.clearActivityPopup();
            alert('Activity updated successfully.');
          }else{
            this.eventObs.push(payload).then((item)=>{
                if(item){
                  agentRecordId = item.key;
                  this.eventObs.update(item.key, {id: item.key});
                  this.newTask = false;
                  this.clearActivityPopup();
                  alert('Activity added successfully.');
                }
            });
            /**add entry to app user table. */
            if(this.selectedClient && this.selectedClient.id){
                let clientPath = "/calendar/" + this.selectedClient['id'];
                let clientObj = this.af.list(clientPath);
                clientObj.push(payload).then((item)=>{
                    if(item){
                      clientRecordId = item.key;
                      clientObj.update(item.key, {id: item.key});
                    }
                    this.addToCard(payload, agentRecordId, clientRecordId)
                });
                
                
            }
          }
         
        }
      }
      deleteTask(){
    
      }
      addToCard(payload: any, agentRecordId: any, clientRecordId: any){
        const clientId = this.selectedClient['id'];
        const cardRef = firebase.database().ref("toursCard");
        const cardData = {
          title: payload.title, description: payload.description, allDay:false, start: payload.start, end: payload.end, agentId: this.uid, clientId: this.selectedClient['id'], notes:'', confirmed:false };
        cardRef.push(cardData).then((res)=>{
          if(res){
            let clientPath = "/calendar/" + clientId;
            let clientObj = this.af.list(clientPath);
            let agentPath = "/calendar/" + this.uid;
            this.eventObs = this.af.list(agentPath);
            clientObj.update(clientRecordId, {cardId: res.key});
            this.eventObs.update(agentRecordId, {cardId: res.key});
          }
        })
      }

      onClose() {
        // this.displayChange.emit(false);
        this.newTask = false;
        this.clearActivityPopup();
      }
      clearActivityPopup(){
          this.taskTitle="";
          this.taskDate = "";
          this.taskStartTime = "";
          this.taskEndTime = "";
          this.taskLocation = "";
          this.taskNote = "";
          this.taskReminder = false;
          this.taskType = "";
          this.selectedEvent = '';
          this.idGen = 100;
          this.titleError = false;
          this.startDateError =false;
          this.startTimeError =false;
          this.endTimeError =false;
          this.invalidTimeError = false;

      }
      validate(){
        this.titleError = false;
        this.startDateError =false;
        this.startTimeError =false;
        this.endTimeError =false;
        this.invalidTimeError = false;
        if(!this.taskTitle){
          this.titleError = true;  
          return false;
        }
        if(!this.taskDate){
          this.startDateError = true;
          return false;
        } 
        if(!this.taskStartTime){
          this.startTimeError = true;
          return false;
        } 
        if(!this.taskEndTime){
          this.endTimeError = true;
          return false;
        }
        var beginningTime = moment(this.taskStartTime, 'h:mm');
        var endTime = moment(this.taskEndTime, 'h:mm');
        const isSame = endTime.isSame(beginningTime);
        const isSmaller = endTime.isBefore(beginningTime);
        if(isSame || isSmaller) {
            this.invalidTimeError = true;
            return false;
        }


        return true;
      }



}

export class MyEvent {
    id: number;
    title: string;
    start: any;
    end: any;
    allDay: boolean = true;
    key: string;
    description: any = "";
}










