import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import {AngularFireDatabase, AngularFireList} from 'angularfire2/database';
// import { SharedService } from '../shared/shared.service'; 
import { SharedService } from '../../../shared/shared.service'; 

@Component({
  selector: 'app-new-dialog',
  templateUrl: './new-dialog.component.html',
  styleUrls: ['new-dialog.component.scss']
})
export class NewDialogComponent implements OnInit {
  areaDropdown: any;
  teamDropdown: any;
  items: any;
  @Input() newclient: boolean;
  @Output() displayChange = new EventEmitter();
  // inviteForm: FormGroup;

  firstName : string;
  lastName : string;
  email : string = " ";
  phone : string = " ";
  userClientRef : AngularFireList<any>;
   uid : string; 

 constructor(private formBuilder: FormBuilder, private af : AngularFireDatabase, private _shared : SharedService) { }

  ngOnInit() {
    this.uid = this._shared.user.uid;
    let path = "/userGeneratedLeads/" + this.uid;
    this.userClientRef = this.af.list(path);
  }

  // createItem(): FormGroup {
  //   return this.formBuilder.group({
  //     name: '',
  //     email: '',
  //     team: '',
  //     role: ''
  //   });
  // }
  // addItem(): void {
  //   this.items = this.inviteForm.get('agents') as FormArray;
  //   this.items.push(this.createItem());
  // }

  // deleteRow(index: number) {
  //   // control refers to your formarray
  //   this.items = this.inviteForm.get('agents') as FormArray;
  //   // remove the chosen row
  //   this.items.removeAt(index);
  // }
  onFormSubmit(inviteForm) {
    console.log(inviteForm);
    /* Any API call logic via services goes here */
  }

  onClose() {
    this.displayChange.emit(false);
  }

  onSave() {
    this.displayChange.emit(true);
    this.newClient(this.firstName , this.lastName, this.email, this.phone).then(
        (result) =>{},
        (error)=>{alert("There was an error")});
  }
  
  newClient(firstName : string, lastName : string, email: string, phone : string = ""){
    return this.userClientRef.push({firstName : firstName, lastName : lastName, email: email, phone : phone, status: "New Lead", })
    .then((response) => {}, (error)=>{console.log(error)})
  }
  // Work against memory leak if component is destroyed
  ngOnDestroy() {
    // this.displayChange.unsubscribe();
  }
}
