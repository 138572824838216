import { NgModule, Pipe } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientProfilesComponent } from './clientprofiles.component';
import { ClientProfilesRoutingModule } from './clientprofiles-routing.module';
import { PaginatorModule } from 'primeng/primeng';
import { DetailsComponent } from './details/details.component';
import { TabViewModule } from 'primeng/primeng';
import { ProgressBarModule } from 'primeng/primeng';
import { GaugeModule } from 'angular-gauge';
import { CheckboxModule } from 'primeng/primeng';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/primeng';
import { SharedModule,DataTableModule } from 'primeng/primeng';
import { InplaceModule } from 'primeng/primeng';
import { InputMaskModule } from 'primeng/primeng';
import { DropdownModule } from 'primeng/primeng';
import { ChartModule } from 'primeng/primeng';
import { MomentModule } from 'ngx-moment';
import { InputTextareaModule } from 'primeng/primeng';
import { DialogModule } from 'primeng/primeng';
import { CalendarModule } from 'primeng/primeng';
import { TableModule } from 'primeng/table';
import { HttpClientModule } from '@angular/common/http';
import { SharedFileModule } from '../shared/shared.module';
import { NewDialogComponent } from './dialog-component/new-dialog/new-dialog.component';
import { SuccessDialogComponent } from './dialog-component/success-dialog/success-dialog.component';
import { SoldDialogComponent } from './dialog-component/sold-dialog/sold-dialog.component';
import { AppointmentDialogComponent } from './dialog-component/client-appointment-dialog/appointment-dialog.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ActivityComponent } from './details/activity/activity.component';
import { ToursComponent } from './details/tours/tours.component';
import { NotesComponent } from './details/notes/notes.component';
import {OrderListModule} from 'primeng/orderlist';
import {ConfirmDialogModule} from 'primeng/confirmdialog';

@Pipe({
  name: 'reverse',
  pure: false
})
export class ReversePipe {
  transform(values) {
    if (values) {
      return values.reverse();
    }
  }
}

@Pipe({
  name: 'valid',
  pure: false
})
export class ValidPipe {
  transform(values: any, limit: any) {
    if (values) {
      let filtered = values.filter(x => x.firstName != undefined && x.firstName != 'undefined');
      filtered = filtered.slice(0, limit);
      return filtered;
    }
  }
}

@NgModule({
  imports: [
    HttpClientModule,
    DialogModule,
    CalendarModule,
    InputTextareaModule,
    MomentModule,
    ChartModule,
    DropdownModule,
    InputMaskModule,
    InplaceModule,
    TabViewModule,
    ClientProfilesRoutingModule,
    CommonModule,
    PaginatorModule,
    ProgressBarModule,
    GaugeModule,
    CheckboxModule,
    FormsModule, ReactiveFormsModule,
    TooltipModule,
    SharedModule,
    TableModule,
    SharedFileModule,
    DataTableModule,
    InfiniteScrollModule,
    OrderListModule,
    ConfirmDialogModule,
    
  ],
  declarations: [ClientProfilesComponent, DetailsComponent, ReversePipe, ValidPipe, NewDialogComponent, SuccessDialogComponent, SoldDialogComponent, AppointmentDialogComponent, ActivityComponent, ToursComponent, NotesComponent],
  exports: [ClientProfilesComponent]
})
export class ClientProfilesModule {}
