import { Component, OnInit, Input } from '@angular/core';
import { Observable, Timestamp, Subscription } from 'rxjs';
import * as firebase from 'firebase/app';
import * as moment from 'moment/moment';
import {Router} from '@angular/router';
@Component({
  selector: 'app-tours',
  templateUrl: './tours.component.html',
  styleUrls: ['./tours.component.scss']
})
export class ToursComponent implements OnInit {

  constructor(private router : Router) { }
  clientId: string;
  @Input() clientDetails:any;
  tourArray = [];
  ngOnInit() {

    
    this.clientDetails.subscribe((res: any)=>{
      if(res){
        this.clientId =  res.key;
        this.toursCard();
      }
    }
    )


  }
  toursCard(){
    if(this.clientId){
      const cardRef = firebase.database().ref("toursCard");
      const query = cardRef.orderByChild("clientId").equalTo(this.clientId);
      query.on('value',(snapshot: any) => {
        const result = snapshot.val();
        if(result){
          this.tourArray = Object.entries(result).map(item => ({...item[1], key: item[0]}));
        }
        });
    }
    
  }
  getToursClass(endDate: any, isConfirmed: boolean){
    let className = 'pending';
    var nowDate = moment(Date.now());
    var newEndDate = moment(endDate);
    const isPast = newEndDate.isBefore(nowDate);
    if(isPast){
      className = 'completed';
    }
    if(!isPast && isConfirmed){
      className = 'confirmed';
    }
    if(!isPast && !isConfirmed){
      className = 'pending';
    }
    return className;
    
  }
  getFormatedDate(startDate: any){
    const formatedDate = moment(startDate.split(' ')[0], 'YYYY-MM-DD').format('MMM D, YYYY');
    return formatedDate;
  }
  createTour(){
    this.router.navigateByUrl('/calendar');
}
}
