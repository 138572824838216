import { SharedService } from '../shared/shared.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import { AngularFireAuth } from 'angularfire2/auth';
import { MenuItem } from 'primeng/api';
import { ChatService } from './chat.service';
import { copyStyles } from '@angular/animations/browser/src/util';

@Component({
  selector: 'chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {
  value: number = 50;
  private items: MenuItem[];
  show: boolean = false;
  show1: boolean = false;

  agentDetails: any = {};
  leadDetails: any[] = [];
  chatData: any = {};
  messageData: any[] = [];
  chatRoomData: any = {};
  mapIds:any = {};

  // User details
  userId: any;
  userDetails = {};
  constructor(
    public _shared: SharedService,
    public db: AngularFireDatabase,
    public afAuth: AngularFireAuth,
    private chatService: ChatService
  ) {}

  ngOnInit() {
    this._shared.authState$.subscribe(user => {
      this.userId = user.uid;
    });

    // this._shared.getUserInfo().then(user => {
    //   this.agentDetails = {
    //     image: '../../assets/images/profile.png',
    //     agent_name: user.firstName + ' ' + user.lastName,
    //     position: user.brokerAddress,
    //     company_name: user.brokerName,
    //     network_link: 'http://homecodec.com/agent_co',
    //     account_progress_setup: 60
    //   };
    // });

    // this.db.list('chat').push({
    //   active_ts: '',
    //   chat_between: 'person3',
    //   chat_status: 'Active',
    //   last_read_message_ts: '',
    //   members: {
    //     POzZmpb3LIZulXz4QaCs0rhUJPH1: true
    //   },
    //   start_ts: '1528459014'
    // });

    this.leadDetails = [
      {
        lead_name: 'Morgan Nitch',
        last_message: 'this is a random message to denote the last message in the chat with Morgan Nitch',
        last_message_time: '6:01 pm',
        last_seen: '12:27 pm',
        image_link: '../../assets/images/profile-image.jpeg'
      },
      {
        lead_name: 'Luke Skywalker',
        last_message: 'this is a random message to denote the last message in the chat with Luke Skywalker',
        last_message_time: '4:16 pm',
        last_seen: '11:57 am',
        image_link: 'http://iconbug.com/data/1f/256/251ef945f26446caa2d84eaa2627d4f4.png'
      },
      {
        lead_name: 'Darth Vader',
        last_message: 'this is a random message to denote the last message in the chat with Darth Vader',
        last_message_time: '2:31 pm',
        last_seen: '3:02 pm',
        image_link:
          'https://vignette.wikia.nocookie.net/soulcalibur/images/4/43/Darth_Vader_Icon.jpg/revision/latest?cb=20170313045402'
      },
      {
        lead_name: 'Princess Leia',
        last_message: 'this is a random message to denote the last message in the chat with Princess Leia',
        last_message_time: '12:34 pm',
        last_seen: '1:27 pm',
        image_link: 'https://clipart.info/images/ccovers/1513358464Princess%20Leia%20transparent%20PNG.png'
      },
      {
        lead_name: 'Han Solo',
        last_message: 'this is a random message to denote the last message in the chat with Han Solo',
        last_message_time: '11:01 am',
        last_seen: '11:27 am',
        image_link: 'http://icons.iconarchive.com/icons/jonathan-rey/star-wars-characters/256/Han-Solo-02-icon.png'
      }
    ];

    // this.chatData = this.leadDetails[1];

    this.chatData = this.leadDetails[0];
    this.getMessages(this.leadDetails[0].lead_name);
  }

  loadMapId(event: string) {
    this.mapIds = event;
  }

  loadChatData(event: string) {
    this.chatRoomData = event;
    [this.chatData] = this.leadDetails.filter(lead => lead.lead_name === event);
    this.getMessages(event);
  }

  getMessages(lead_name: string) {
    this.chatService.getMessages(lead_name).subscribe(msgs => {
      this.messageData = msgs;
    });
  }

  clicked() {
    this.show = !this.show;
  }

  click() {
    this.show1 = !this.show1;
  }
}
