import { style } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireDatabase, AngularFireList} from 'angularfire2/database';
import { SharedService } from '../../shared/shared.service';

@Component({
  selector: 'app-snapshot-statistics',
  templateUrl: './snapshot-statistics.component.html',
  styleUrls: ['./snapshot-statistics.component.css']
})
export class SnapshotStatisticsComponent implements OnInit {
  className: string;
  percent: number = 0;
  deg: number;
  uid: string;
  profiles: Observable<any []>;
  invites: Observable<any []>;
  profilesArray: any;
  profilesArrayLenght: number = 0;
  appProfilesArray: any;
  newLeadsCount: number = 0;
  snapShotInterval: any;
  offerPending: number = 0;
  closingProp: number = 0;
  soldProperty: number = 0;
  notLooking: number = 0;

  @ViewChild('ppc') ppc;
  @ViewChild('progressChart') progressChart;

  constructor(private af: AngularFireDatabase, private _sharedService: SharedService) {
    this.uid = this._sharedService.user.uid;
    this.profiles = af.list('userGeneratedLeads/' + this.uid).snapshotChanges();
    this.profiles.map(actions => 
      actions.map(a => ({ key: a.key, ...a.payload.val() }))
    ).subscribe(items => {
      //this.profilesArray = items;

      this.profilesArrayLenght =  items.length;
      this.profilesArray = items.map((item: any) => 
          ({id: item.key, firstName: item.firstName, lastName: item.lastName, appLink: item.appLink, offerPending: item.offerPending, closingProp: item.closingProp, soldProperty: item.soldProperty, notLooking: item.notLooking}
          
      ));

      // this.profilesArray.forEach(function(element) {
      //   if (element.appLink) {
      //     this.appProfilesArray.push(element);
      //   }
      // });offerPending: 
      this.appProfilesArray = [];
      for (let i=0; i<this.profilesArray.length; i++) {
        if (this.profilesArray[i].appLink) {
        this.appProfilesArray.push(this.profilesArray[i]);
        }
        if(this.profilesArray[i].offerPending){
          this.offerPending+=1;
        }
        if(this.profilesArray[i].closingProp){
          this.closingProp+=1;
        }
        if(this.profilesArray[i].soldProperty){
          this.soldProperty+=1;
        }
        if(this.profilesArray[i].notLooking){
          this.notLooking+=1;
        }
      }

      this.startTimer();
    });

    this.invites = af.list('/inviteMessages', ref => ref.orderByChild('sender_id').equalTo(this.uid)).snapshotChanges();
    this.invites.map(actions => 
      actions.map(a => ({ key: a.key, ...a.payload.val() }))
    ).subscribe(items => {
      console.log(items);
      this.newLeadsCount = items.length;
      //this.profilesArray = items.map((item: any) => ({id: item.key, firstName: item.firstName, lastName: item.lastName}));

    });


    /*
    this.profiles = af.list('/appUsers', ref => ref.orderByChild('agentId').equalTo(this.uid)).snapshotChanges();
    this.profiles.map(actions => 
      actions.map(a => ({ key: a.key, ...a.payload.val() }))
    ).subscribe(items => {
      //this.agents = items;
      this.profilesArray = items.map((item: any) => ({id: item.key, firstName: item.firstName, lastName: item.lastName}));
      this.profilesArray.forEach(function(element) {

      });

    });
    */


  }

  startTimer(){
    clearInterval(this.snapShotInterval);
    // alert(this.profilesArray.length)
    if(this.profilesArray.length > 0){
      this.percent = (100 * this.appProfilesArray.length) / 100;
    }else{
      this.percent = 0;
    }
    // alert(this.percent);
    this.deg = (360 * this.percent) / 100;
    //alert(this.deg);
    if (this.percent > 50) {
      this.className = 'progress-pie-chart gt-50';
    } else {
      this.className = 'progress-pie-chart';
    }
    this.ppc.nativeElement.style.transform = 'rotate(' + this.deg + 'deg)';
  }
  
  ngOnInit() {
    
    this.snapShotInterval = setInterval(() => {
      if(this.snapShotInterval && this.percent >=59 ){
        clearInterval(this.snapShotInterval);
      }
      if (this.percent > 50) {
        this.className = 'progress-pie-chart gt-50';
      } else {
        this.className = 'progress-pie-chart';
      }
      this.percent > 99 ? (this.percent = 0) : this.percent++;
      this.deg = (360 * this.percent) / 100;
      this.ppc.nativeElement.style.transform = 'rotate(' + this.deg + 'deg)';
    }, 100);
  }
}
