import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  items: any;

  constructor() {}

  ngOnInit() {
    this.items = [
      { title: 'Chat messages', notify: 'All notifications are turn on', email: false, text: true, crm: true },
      {
        title: 'Activities tab',
        notify: 'All notifications are turn off',
        email: false,
        text: false,
        crm: false
      },
      {
        title: 'Un-linked clients block',
        notify: 'Only text notifications.',
        email: false,
        text: false,
        crm: true
      },
      {
        title: 'Linked clients block',
        notify: 'Only text notifications.',
        email: false,
        text: false,
        crm: true
      },
      { title: 'Upcoming tasks', notify: 'Only text notifications.', email: false, text: false, crm: true },
      {
        title: 'Upcoming appointment',
        notify: 'All notifications are turn on',
        email: true,
        text: true,
        crm: true
      },
      {
        title: 'Chat window on notifications section',
        notify: 'All notifications are turn off',
        email: false,
        text: false,
        crm: false
      },
      {
        title: 'Requested an appointment',
        notify: 'All notifications are turn off',
        email: false,
        text: false,
        crm: false
      },
      {
        title: 'Client activation anfter inactivity',
        notify: 'All notifications are turn off',
        email: false,
        text: false,
        crm: false
      }
    ];
  }
}
