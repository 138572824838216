import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PropertiesComponent } from './properties.component';
import { MyPropertiesComponent } from './myproperties/myproperties.component';
import { PropertiesInfoDetailsComponent } from './properties-infodetails/properties-infodetails.component';
import { ManualPropertiesInfoDetailsComponent } from './manual-properties-infodetails/manual-properties-infodetails.component';
import { NewListingComponent } from './new-listing/new-listing.component';
import { AuthGuard }      from '../shared/auth.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild([
      { path: 'properties', component: PropertiesComponent,  canActivate: [AuthGuard], },
      { path: 'myproperties', component: MyPropertiesComponent,  canActivate: [AuthGuard], },
      { path: 'new-listing', component: NewListingComponent,  canActivate: [AuthGuard], },
      { path: 'properties-infodetails/:listingId', component: PropertiesInfoDetailsComponent,  canActivate: [AuthGuard], },
      { path: 'manual-properties-infodetails', component: ManualPropertiesInfoDetailsComponent,  canActivate: [AuthGuard], },
      
    ])
  ],
  exports: [RouterModule]
})
export class PropertiesRoutingModule { }
