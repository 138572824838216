import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-appointment-calendar-dialog',
  templateUrl: './appointment-calendar-dialog.component.html',
  styleUrls: ['./appointment-calendar-dialog.component.scss']
})
export class AppointmentCalendarDialogComponent implements OnInit {
  @Input() appointment: boolean;
  @Output() displayChange = new EventEmitter();
  events: any[];
  timezoneDropdown : any;
  starttimeDropdown : any;
  endtimeDropdown : any;
  header: any;
  headerConfig: any;
  constructor() {
    this.timezoneDropdown = [{ name: 'UTC +2' }, { name: 'UTC +3' }, { name: 'UTC +4' }]; 
    this.starttimeDropdown = [{ name: '12:00 AM' }, { name: '01:00 AM' }, { name: '02:00 AM' }, { name: '03:00 AM' }, { name: '04:00 AM'}, { name: '05:00 AM' }, { name: '06:00 AM' }, { name: '07:00 AM' }, { name: '08:00 AM' }, { name: '09:00 AM' }, { name: '10:00 AM' }, { name: '11:00 AM' }  ]; 
    this.endtimeDropdown = [{ name: '12:00 AM' }, { name: '01:00 AM' }, { name: '02:00 AM' }, { name: '03:00 AM' }, { name: '04:00 AM'}, { name: '05:00 AM' }, { name: '06:00 AM' }, { name: '07:00 AM' }, { name: '08:00 AM' }, { name: '09:00 AM' }, { name: '10:00 AM' }, { name: '11:00 AM' }  ];  
  }

  ngOnInit() {
    this.headerConfig = {
      left: 'prev',
      center: 'title',
      right: 'next'
    };
    this.events = [
      {
        title: 'All Day Event',
        start: '2018-07-29'
      },
      {
        title: 'Long Event',
        start: '2018-08-07'
      },
      {
        title: 'Repeating Event',
        start: '2018-08-09T16:00:00'
      },
      {
        title: 'Repeating Event',
        start: '2018-08-16T16:00:00'
      },
      {
        title: 'Conference',
        start: '2018-08-11'
      }
    ];
  }

  onClose() {
    this.displayChange.emit(false);
  }

  // Work against memory leak if component is destroyed
  ngOnDestroy() {
    // this.displayChange.unsubscribe();
  }
}
