import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import {LoginRoutingModule} from './login-routing.module'
import { FormsModule }        from '@angular/forms';
import {DialogModule} from 'primeng/primeng';
import {ButtonModule} from 'primeng/primeng';
import {InputTextModule} from 'primeng/primeng';
import {PasswordModule} from 'primeng/primeng';
import {StepsModule,MenuItem} from 'primeng/primeng';
import { InputMaskModule } from 'primeng/primeng';
@NgModule({
  imports: [
    CommonModule,
    LoginRoutingModule,
    FormsModule,
    DialogModule,
    ButtonModule,
    InputTextModule,
    PasswordModule,
    StepsModule,
    InputMaskModule
    
  ],
  declarations: [LoginComponent]
})
export class LoginModule { }

