import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase} from 'angularfire2/database';
import { SharedService } from '../../shared/shared.service';

@Component({
  selector: 'app-billing-plan',
  templateUrl: './billing-plan.component.html',
  styleUrls: ['./billing-plan.component.scss']
})
export class BillingPlanComponent implements OnInit {

  uid: string;
  email: string = '';
  planType: string = '';
  role: string = '';
  paidByAdmin: boolean;
  constructor(private af: AngularFireDatabase, private _shared: SharedService) {
    this.uid = this._shared.user.uid;
    this.email = this._shared.user.email;
  }

  ngOnInit() {
    this._shared.getUserInfo().then((result) => {
      if (result) {
        this.planType = result.planType;
        this.role = result.role;
        this.paidByAdmin = result.paidByAdmin;
       }
    });
  }

}
