import { NgModule } from '@angular/core';
import { SettingsComponent } from './settings.component';
import { SettingsRoutingModule } from './settings-routing.module';
import { DialogModule, SliderModule } from 'primeng/primeng';
import { TabViewModule } from 'primeng/primeng';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { ButtonModule } from 'primeng/primeng';
import { SharedFileModule } from '../shared/shared.module';
import { Ng5SliderModule } from 'ng5-slider';
// import { SettingOverviewComponent } from './setting-overview/setting-overview.component';
// import { SettingConfirmDialogComponent } from './billing-plan/components/dailog-components/setting-confirm-dialog/setting-confirm-dialog.component';
// import { BillingPlanComponent } from './billing-plan/billing-plan.component';
// import { NextBillComponent } from './billing-plan/components/next-bill/next-bill.component';
// import { BillingHistoryComponent } from './billing-plan/components/billing-history/billing-history.component';
// import { ManagePlanComponent } from './billing-plan/components/manage-plan/manage-plan.component';
// import { BillingMethodComponent } from './billing-plan/components/billing-method/billing-method.component';
// import { ViewAllHistoryComponent } from './billing-plan/components/billing-history/view-all-history/view-all-history.component';
// import { TeamManagementComponent } from './team-management/team-management.component';

@NgModule({
  imports: [
    ButtonModule,
    HttpModule,
    FormsModule,
    CommonModule,
    TabViewModule,
    DialogModule,
    SettingsRoutingModule,
    SharedFileModule,
    SliderModule,
    Ng5SliderModule
  ],
  declarations: [SettingsComponent],
  exports: [SettingsComponent]
})
export class SettingsModule {}
