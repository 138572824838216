import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { SharedService } from '../../shared/shared.service';
import * as firebase from 'firebase/app';
import { InputMask } from 'primeng/primeng';
import { Options } from 'ng5-slider';
import {Subject} from 'rxjs/Subject';
@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit {
  user: any;
  brokerAddresses: any;
  accountForm: FormGroup;
  items: any;

  firstName: string = "";
  lastName: string = "";
  email: string = "";
  phoneNumber: string = "";
  display: boolean = false;
  androidLink: string;
  iosLink: string;
  stripe: any;
  uid: string;
  addCC: boolean = true;
  subscription: boolean = false;
  currentPlan: string;
  showAppLinks: boolean = false;
  switchTab: boolean = false;
  loading: boolean = false;
  planID: string;
  loadSub: boolean = false;
  trialCode: string = "EXPO413";
  confirmSubscription: boolean = false;
  planAmount: string;
  useTrial: boolean = false;
  showPromoInput: boolean = true;
  selectedPlan: string;
  brokerAddress: string = "";
  brokerCity: string = "";
  brokerState: string = "";
  brokerZip: string = "";
  brokerName: string = "";
  showCancelDialog: boolean = false;
  brokerPicture: any;
  profilePicture: any;
  brokerPhoneNumber: string = "";
  userRole: string;
  userLicense: string = "";
  brokerLicense: string = "";
  minBedValue: number = 10;
  searchDistance: number = 0;
  highValue: number = 25;
  isAgreement: boolean = false;
  distanceOptions: Options = {
    floor: 25,
    ceil: 100,
    step: 25,
    showSelectionBar: true,
    // showTicks: true,
    showTicksValues: true
  };
  companyName: string = "";
  searchByCompany: boolean;
  relatorSearch: boolean;
  successcommon: boolean = false;
  successHeader: string = "Account Details";
  successMessage: string = "";
  @ViewChild('myInputMask') myInputMask: InputMask;
  @ViewChild('myInputMaskBroker') myInputMaskBroker: InputMask;
  @Input() isPopupCall: boolean = false;
  @Input() saveFromDialog: Subject<boolean>;
  @Output() displayChange = new EventEmitter();
  phoneNumberError: boolean = false;
  relatorSearchError: boolean = false;
  errorMessage: string = "";
  networkLinkError: boolean = false;
  constructor(private formBuilder: FormBuilder, private _shared: SharedService) {
      this.user = this._shared.user;
    }
    ngOnInit() {
      // this.accountForm = this.formBuilder.group({
      //   items: this.formBuilder.array([this.createItem()])
      // });
      this.accountForm = this.formBuilder.group({
        items: this.formBuilder.array([])
      });
      if(this.isPopupCall){
        this.saveFromDialog.subscribe(v => { 
          this.submit(false);
        });
      }
      
      this._shared.getUserInfo().then((result) => {
        if (result) {
          this.firstName = result.firstName;
          this.lastName = result.lastName;
          this.email = result.email;
          this.phoneNumber = result.phoneNumber ? result.phoneNumber : '';
          this.brokerAddress = result.brokerAddress ? result.brokerAddress : '';
          this.brokerCity = result.brokerCity;
          this.brokerState = result.brokerState;
          this.brokerZip = result.brokerZip;
          this.brokerName = result.brokerName;
          this.stripe = result.stripe;
          this.profilePicture = result.profilePicture;
          this.brokerPicture = result.brokerPicture;
          this.brokerPhoneNumber = result.brokerPhoneNumber ? result.brokerPhoneNumber : '';
          this.userRole = result.role;
          this.userLicense = result.userLicense ? result.userLicense : '';
          this.companyName = result.companyName ? result.companyName : '';
          this.searchByCompany = result.searchByCompany ? result.searchByCompany : false;
          this.searchDistance = result.searchDistance ? result.searchDistance : 0;
          this.relatorSearch = result.relatorSearch ? result.relatorSearch : false;
          this.isAgreement = result.isAgreement ? result.isAgreement : false;
          this.brokerLicense = this.getStringByCheckingNull(result.brokerLicense);
          if(result.activePlan)
            {
              this.currentPlan = "free";
            }

            //this.accountForm.patchValue(result.brokerAddresses);
          
            this.items = this.accountForm.get('items') as FormArray;
            if(result.brokerAddresses && result.brokerAddresses.items){
              for (let i=0;i<result.brokerAddresses.items.length;i++){
                this.items.push(this.formBuilder.group(result.brokerAddresses.items[i]));
              }
            }
            
            if(this.items.length==0){
              this.accountForm = this.formBuilder.group({
                items: this.formBuilder.array([this.createItem()])
              });
            }
          
        }
      }); 

      // const storageRef = firebase.storage().ref().child('users/'+this.user.uid+'/brokerpicture.jpg');
      // storageRef.getDownloadURL().then(
      //       url => this.setBrokerPicture(url)
      //       );

      // const storageRef_profile = firebase.storage().ref().child('users/'+this.user.uid+'/profilepicture.jpg');
      // storageRef_profile.getDownloadURL().then(
      //       url => this.setProfilePicture(url)
      //       );

    }

    setBrokerPicture(url){
      this.brokerPicture = url; 
    }

    setProfilePicture(url){
      this.profilePicture = url; 
    }
 

  createItem(): FormGroup {
    return this.formBuilder.group({
      add: '',
      city: '',
      state: '',
      zip: ''
    });
  }
  addItem(): void {
    this.items = this.accountForm.get('items') as FormArray;
    this.items.push(this.createItem());
  }

  deleteRow(index: number) {
    // control refers to your formarray
    this.items = this.accountForm.get('items') as FormArray;
    // remove the chosen row
    this.items.removeAt(index);
  }

  submit(event: any) {
    if(event){
      event.preventDefault();
    }
    const validate = this.validateAccDetails(); 
    if(!validate){
      return;
    }
    this.networkLinkError = false;
    this._shared.checkNetworkLink(this.firstName, this.lastName).then((res)=>{
      if(res){
        this.updateAccountDetails(res);
      }else{
        this.errorMessage = "Network link is already taken, please update name suffix.";
        this.networkLinkError = true;
      }
    })
    if (!this.currentPlan) {
      this.switchTab = true;
    }

  }
  updateAccountDetails(networkLink){
    /* Any API call logic via services goes here */
    let payload = {firstName: this.firstName, lastName: this.lastName, email : this.email, phoneNumber : this.phoneNumber, userLicense: this.userLicense, brokerName : this.brokerName, brokerAddress : this.brokerAddress, brokerCity: this.brokerCity, brokerState: this.brokerState, brokerZip:this.brokerZip, brokerPhoneNumber: this.brokerPhoneNumber, brokerLicense: this.brokerLicense, companyName: this.companyName, searchByCompany: this.searchByCompany, searchDistance: this.searchDistance, relatorSearch: this.relatorSearch, isAgreement: this.isAgreement, networkLink: networkLink};
    this._shared.updateUserInfo(payload).then((result)=>{
        if(this.isPopupCall){
          this.displayChange.emit(true);
          return;
        }
        this.successcommon = true;
        this.successMessage = "Account details has been updated successfully."
        // alert("");
    });
  }
  validateAccDetails(){
    this.phoneNumberError = false;
    this.relatorSearchError = false;
    this.networkLinkError = false;
    this.errorMessage = "";
    if(this.phoneNumber==""){
      this.phoneNumberError=true;
      this.errorMessage="Phone number is required.";
      return false;
    }
    if(!this.relatorSearch){
      this.relatorSearchError=true;
      this.errorMessage="Relator search is required.";
      return false;
    }
    return true;
  }
  finishLater(){
    if(this.isPopupCall){
      this.displayChange.emit(false);
    } 
  }

  updateProfilePicture(event: EventTarget) {
    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;
    //this.profilePictureFile = files[0];
    let profilePictureFile = files[0];

    // preview image
    /*
    var reader = new FileReader();
      reader.readAsDataURL(profilePictureFile); // read file as data url
      reader.onload = (event:any) => { // called once readAsDataURL is completed
        this.profilePicture = event.target.result;
      }
    */
        this._shared.getOrientation(profilePictureFile, (orientation) => {
        this._shared.getBase64(profilePictureFile, orientation, (base64Image) => {
           this.profilePicture = base64Image;
           this._shared.updateProfilePicture(base64Image);
        });
     });

    // this._shared.updateProfilePicture(profilePictureFile).then((result) => {
    //   //this.profilePicture = result;
    // });

  }


  updateBrokerPicture(event: EventTarget) {
    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;
    let brokerPictureFile = files[0];
// preview image
/*
    var reader = new FileReader();
      reader.readAsDataURL(brokerPictureFile); // read file as data url
      reader.onload = (event:any) => { // called once readAsDataURL is completed
        this.brokerPicture = event.target.result;
      }
*/
      this._shared.getOrientation(brokerPictureFile, (orientation) => {
        this._shared.getBase64(brokerPictureFile, orientation, (base64Image) => {
           this.brokerPicture = base64Image;
           this._shared.updateBrokerPicture(base64Image);
        });
     });

    // this._shared.updateBrokerPicture(brokerPictureFile).then((result) => {
    //   //this.brokerPicture = brokerPictureFile;
    // });
  }

  getStringByCheckingNull(str1){
    return str1 ? str1 : '';
  }
  isInputMaskFilled(event) {
    if(!this.myInputMask.filled) {
      this.phoneNumber =  '';
    }else{
      this.phoneNumber = this.myInputMask.value;
    }
  }
  isInputMaskFilledBroker(event) {
    if(!this.myInputMaskBroker.filled) {
      this.brokerPhoneNumber =  '';
    }else{
      this.brokerPhoneNumber = this.myInputMaskBroker.value;
    }
  }
  onSuccesscommonClose(ev){
    this.successcommon =false;
  }
}
