import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'app-property-dialog',
  templateUrl: './property-dialog.component.html',
  styleUrls: ['property-dialog.component.scss']
})

export class PropertyDialogComponent implements OnInit {
  areaDropdown: any;
  teamDropdown: any;
  items: any;
  @Input() showDialog: boolean;
  @Input() data: any;
  @Output() displayChange = new EventEmitter();
  inviteForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.teamDropdown = [{ name: 'Iron ways' }];
    this.areaDropdown = [
      { name: 'Team Manager' },
      {
        name: 'Sales Agent'
      }
    ];
  }

  ngOnInit() {
    this.inviteForm = this.formBuilder.group({
      agents: this.formBuilder.array([this.createItem()])
    });
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      name: '',
      email: '',
      team: '',
      role: ''
    });
  }
  addItem(): void {
    this.items = this.inviteForm.get('agents') as FormArray;
    this.items.push(this.createItem());
  }

  deleteRow(index: number) {
    // control refers to your formarray
    this.items = this.inviteForm.get('agents') as FormArray;
    // remove the chosen row
    this.items.removeAt(index);
  }
  onFormSubmit(inviteForm) {
    console.log(inviteForm);
    /* Any API call logic via services goes here */
  }
  onOpen(){
    console.log(this.data);
  }

  onClose() {
    this.displayChange.emit(false);
  }

  onSave() {
    this.displayChange.emit(true);
  }

  // Work against memory leak if component is destroyed
  ngOnDestroy() {
    // this.displayChange.unsubscribe();
  }
}
