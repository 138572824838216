import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-all-history',
  templateUrl: './view-all-history.component.html',
  styleUrls: ['./view-all-history.component.scss']
})
export class ViewAllHistoryComponent implements OnInit {
  display: boolean = false;
  display1: boolean = false;

  cars1: any[];
  cars2: any[];
  cars3: any[];
  cols: any[];


  ngOnInit() {

    this.cols = [
      { field: 'client', header: 'Client' },
      { field: 'date', header: 'Date Acquired' },
      { field: 'price', header: 'Price' }
    ];
  }


  showInvoiceDialog() {
    this.display1 = true;
  }
  onInvoiceDialogClose(event) {
    this.display1 = event;
  }
  showTeamInvoiceDialog() {
    this.display = true;
  }
  onTeamInvoiceDialogClose(event) {
    this.display = event;
  }
}
