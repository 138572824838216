import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { InactivereportComponent } from './inactivereport.component';
import { AuthGuard }      from '../shared/auth.guard';


@NgModule({
  imports: [
    RouterModule.forChild([
      { path: 'inactivereport', component: InactivereportComponent,  canActivate: [AuthGuard]}
    ])
  ],
  exports: [RouterModule]
})
export class InactivereportRoutingModule { }
