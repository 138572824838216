import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-view-full-requested-appoinment',
  templateUrl: './view-full-requested-appoinment.component.html',
  styleUrls: ['./view-full-requested-appoinment.component.scss']
})
export class ViewFullRequestedAppoinmentComponent implements OnInit {
  teamDropdown: any;
  myClasses = 'inactive';
  @Output() showLess = new EventEmitter();

  constructor() {}

  ngOnInit() {
    this.teamDropdown = [{ name: 'Iron Ways' }, { name: 'Keyhole' }];
  }
  addClasses(ev) {
    if (this.myClasses == ev) {
      this.myClasses = 'inactive';
    } else {
      this.myClasses = 'active';
    }
  }
  showLessClass(ev) {
    this.showLess.emit(true);
  }
}
