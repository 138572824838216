import { NgModule } from '@angular/core';
import { CalendarComponent } from './calendar.component';
import { CalendarRoutingModule } from './calendar-routing.module';
import { ScheduleModule } from 'primeng/primeng';
import { DialogModule, DropdownModule } from 'primeng/primeng';
import { CalendarModule } from 'primeng/primeng';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CheckboxModule } from 'primeng/primeng';
import { SharedFileModule } from '../shared/shared.module';
import { NewAppointmentDialogComponent } from './new-appointment-dialog/new-appointment-dialog.component';
import { NewTaskComponent } from './new-task-dialog/new-task-dialog.component';

@NgModule({
  imports: [
    FormsModule, ReactiveFormsModule, 
    CommonModule,
    CheckboxModule,
    CalendarModule,
    DialogModule,
    CalendarRoutingModule,
    ScheduleModule,
    SharedFileModule,
    DropdownModule
  ],
  declarations: [CalendarComponent, NewAppointmentDialogComponent, NewTaskComponent],
  exports: [CalendarComponent]
})
export class PersonalCalendarModule {}
