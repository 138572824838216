import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import * as firebase from 'firebase/app';
import { AngularFireDatabase, AngularFireList} from 'angularfire2/database';
import { SharedService } from '../../../../shared/shared.service';
import { Observable} from 'rxjs';
@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  @Input() confirmation: boolean;
  @Input() isManaged: boolean;
  @Input() uid: string;
  @Input() inviteName: string;
  @Input() planRate: string;
  @Output() displayChange = new EventEmitter();
  // invite: boolean = false;
 
  constructor(private _sharedService: SharedService, private af: AngularFireDatabase, private cd: ChangeDetectorRef) {

  }

  ngOnInit() {
  
  }
 
  onClose(ev){
    this.confirmation = false;
    this.displayChange.emit(ev);
  }







}
