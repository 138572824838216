import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../../shared/shared.service';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { QueryReadType } from '@angular/core/src/render3/interfaces/query';
@Component({
  selector: 'app-manage-plan',
  templateUrl: './manage-plan.component.html',
  styleUrls: ['./manage-plan.component.scss']
})
export class ManagePlanComponent implements OnInit {
  display: boolean = false;
  show: boolean = false;
  val: number = 250;
  uid:string;
  email:string = "";
  planType: string = "";
  canPurchaseLead = false;
  myClients: any;
  myProfiles: any;
  additionalProfilesCount: 0;
  showUpgradePlanDialog = false;
  showConfirmUpgradeDialog =false;
  showThankYouPlanDialog = false;
  value = 50;
  admin: string = "";
  paidUserCount: number = 0;
  slefPaidUserCount: number = 0;
  managedMemberCost: number = 0;
  selfPaidMemberCost: number = 0;
  paidByAdmin: boolean;
  planAmount: number = 0;
  role: string;
  displayPlan: boolean = false;
  updatePlanCall: boolean = true;
  unpaidByAdminDate: string = "";
  constructor(private af: AngularFireDatabase, private _shared: SharedService) {
    this.uid = this._shared.user.uid;
    this.email = this._shared.user.email;
  }

  ngOnInit() {
    this._shared.getUserInfo().then((result) => {
      if (result) {
        this.planType = result.planType;
        this.admin = result.admin;
        this.paidByAdmin = result.paidByAdmin;
        this.planAmount = result.adjustedRate ? result.adjustedRate : 0; 
        this.role = result.role;
        this.unpaidByAdminDate = result.unpaidByAdminDate ? result.unpaidByAdminDate : '';
        /** get managed and self paid user count and respective amount. */
        this.role == 'admin' ? this.getPaidByAdmin() : this.getIndividualPlan();
       }
    });


      this._shared.getMyClients().then((result) => {
        if (result) {
            this.myClients = Object.keys(result);
        }
      });

      // let path = "/billing";
      // this.teamListingRef = this.af.list(path);

      const billingRef = firebase.database().ref('billing/' + this.uid + '/profiles');
      billingRef.once('value').then((snapshot: any) => {
        // const result = snapshot.val();
       // if (result) {
          // this.myProfiles = result;
          const profilesArray = [];
          this.additionalProfilesCount = 0;
          snapshot.forEach(snap => {
            this.additionalProfilesCount += snap.val().qty;
            profilesArray.push({
                      id: snap.key,
                      amount: snap.val().amount,
                      qty: snap.val().qty,
                      type: snap.val().type,
                      date: snap.val().date
                  });
                  return false;
              });
              this.myProfiles = profilesArray;
       // }
      });

      const purchaseLeadRef = firebase.database().ref('leadPurchaseSettings/' + this.uid + '/purchaseLeads');
      // purchaseLeadRef.once('value').then((snapshot: any) => {
        purchaseLeadRef.on('value', (snapshot) => {
        if (snapshot) {
          this.canPurchaseLead = snapshot.val();
        }
      });

    /*
    const getCardDetails = firebase.functions().httpsCallable('getCardDetails');
    getCardDetails({uid: this.uid}).then(function(result) {
      // Read result of the Cloud Function.
      const cards = result.data;
      //console.log('cards:');
      //console.log(result);
      // ...
    });
    */
  }

  // vinayak on 4 feb 2019
  increaseProfiles() {
    const count = this.val;
    this.show = false;
    const uid = firebase.auth().currentUser.uid;
    // const billingRef = this.billingRef.child(uid).child('monthlyFee');
    const curDate = new Date();
    const curDateNumber = curDate.getTime();
    const billingRef = firebase.database().ref('billing/' + this.uid + '/profiles');
    return billingRef.push({ type: 'additional profiles', qty: count, amount: count * 10 / 250, date: curDateNumber }).then(
      result => {
        this.additionalProfilesCount += count;
        return result;
      },
      error => {
        console.log(error);
      }
    );
  }

  togglePurchageLeadButton() {
    if(this.planType=='planZ' || this.unpaidByAdminDate){
      this.updatePlanCall = false;
    }
    this.displayPlan = true;
    // if (this.planType === 'free') {
    //   this.showUpgradeDialog(null);
    //   return;
    // }
    // if (this.canPurchaseLead) {
    //   this.canPurchaseLead = false;
    // } else {
    //   this.canPurchaseLead = true;
    // }
    // const purchaseLeadRef = firebase.database().ref('leadPurchaseSettings/' + this.uid);
    // purchaseLeadRef.update({purchaseLeads: this.canPurchaseLead});
  }

  getProfileUsagePercent() {
    let myClientsCount = 0;
    if (this.myClients) {
      myClientsCount = this.myClients.length;
    }
    const percent = myClientsCount * 100 / (this.additionalProfilesCount + 750);
    return percent.toFixed(2);
  }

  showDialog() {
    if (this.planType === 'free') {
      this.showUpgradeDialog(null);
    } else {
      this.display = true;
    }
  }
  showIncrease() {
    this.show = true;
  }
  showUpgradeDialog(event){
    if(this.planType!='paid'){
      this.showUpgradePlanDialog = true;
    }
  }

  onUpgradePlanClose(){
    this.showUpgradePlanDialog = false;
  }
  onUpgradeYourPlan(event){
    this.showUpgradePlanDialog = false;
    this.showConfirmUpgradeDialog = true;
  }
  onUpgradeYourPlanClose(){
    this.showConfirmUpgradeDialog = false;
  }

  onThankYouUpgrade(event){
    this.openCheckout();
  }
  onThankYouUpgradeClose(){
    this.showThankYouPlanDialog = false;
  }

  public openCheckout() {
    // key: 'pk_live_d6Pby9G8K0qvdMLMQGwlLzln',
        const handler = (<any>window).StripeCheckout.configure({
          key: 'pk_test_SgYFBIjFUHb2tuhGSIrKnpHb',
          locale: 'auto',
          token: (token: any) => {
            return this._shared.updateStripe(token).then((result) => {
              console.log(result);
              this.showConfirmUpgradeDialog = false;
              this.showThankYouPlanDialog = true;
              const payload = {planType: 'paid'};
              this._shared.updateUserInfo(payload).then(() => {
                this.planType = 'paid';
              });
            });
          }
        });
        handler.open({
          name: 'HomeCodec',
          image: '../assets/images/icon.png',
          email: this.email,
          allowRememberMe: false,
          panelLabel: 'Save'
        });
  }
  getPaidByAdmin(){
    this.paidUserCount = 0;
    this.managedMemberCost = 0;
    const userRef = firebase.database().ref('users');
    const paidUserRef = userRef.orderByChild('admin').equalTo(this.admin);
    paidUserRef.once('value').then((snapshot: any) => {
        snapshot.forEach((child) => {
          const result = child.val();
          if(result){
             if(result.paidByAdmin || child.key == this.admin){
               this.paidUserCount += 1;
               this.managedMemberCost += result.adjustedRate;
             }else{
               this.slefPaidUserCount += 1;
               this.selfPaidMemberCost += result.adjustedRate;
             }
          }
        });
     
    });
  }
  getIndividualPlan(){
    this.paidUserCount = 0;
    this.managedMemberCost = 0;
    this.selfPaidMemberCost = this.planAmount;
    this.slefPaidUserCount = 1;
  }
  onPlanDialogClose(event){
    this.displayPlan = false;
    console.log(event);
  }
}
