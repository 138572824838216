import { NgModule } from '@angular/core';
import { RegisterComponent } from './register.component';
import { RegisterRoutingModule } from './register-routing.module';
import {DialogModule} from 'primeng/primeng';
import {TabViewModule} from 'primeng/primeng';
import { CommonModule }       from '@angular/common';
import { FormsModule }   from '@angular/forms';
import { HttpModule} from '@angular/http';
import {StepsModule,MenuItem} from 'primeng/primeng';
import {PanelModule ,ChartModule, DropdownModule, ButtonModule } from 'primeng/primeng';
import { CheckboxModule } from 'primeng/primeng';
import { SelectPlanComponent } from '../shared/select-plan-component/select-plan.component';
import { InputMaskModule } from 'primeng/primeng';
// selectPlanComponent
@NgModule({
  imports: [PanelModule, StepsModule, HttpModule,  FormsModule, CommonModule, TabViewModule, DialogModule, RegisterRoutingModule, CheckboxModule, ButtonModule, InputMaskModule],
  declarations: [RegisterComponent, SelectPlanComponent],
  exports: [RegisterComponent]
})
export class RegisterModule { 




}
