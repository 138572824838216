import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { SharedService } from '../../shared/shared.service'; 

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {
  areaDropdown: any;
  teamDropdown: any;
  items: any;
  @Input() showDialog: boolean;
  // @Input() newfilter: boolean;
  @Output() displayChange = new EventEmitter();
  // inviteForm: FormGroup;
  propertyFilterRef : AngularFireList<any>;
  filterName: string = "";


  constructor(private formBuilder: FormBuilder, private af : AngularFireDatabase, private _shared : SharedService) {
    this.teamDropdown = [{ name: 'Iron ways' }];
    this.areaDropdown = [
      { name: 'Team Manager' },
      {
        name: 'Sales Agent'
      }
    ];
  }

  ngOnInit() {
    // this.inviteForm = this.formBuilder.group({
    //   agents: this.formBuilder.array([this.createItem()])
    // });
    let path = "/propertyFilter/";
    this.propertyFilterRef = this.af.list(path);

  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      name: '',
      email: '',
      team: '',
      role: ''
    });
  }
  addItem(): void {
    // this.items = this.inviteForm.get('agents') as FormArray;
    // this.items.push(this.createItem());
  }

  deleteRow(index: number) {
    // // control refers to your formarray
    // this.items = this.inviteForm.get('agents') as FormArray;
    // // remove the chosen row
    // this.items.removeAt(index);
  }
  onFormSubmit(inviteForm) {
    console.log(inviteForm);
    
    /* Any API call logic via services goes here */
  }
  newFilter(filterName : string){
   return this.propertyFilterRef.push({filterName : filterName})
    .then((response) => {
      console.log(response.key);
    }, (error)=>{console.log(error)})
  }

  onClose() {
    this.displayChange.emit(false);
    // this.showDialog=false;
  }

  onSave() {
    
    this.displayChange.emit(true);
    this.showDialog=false;
    
    console.log(this.filterName);
    this.newFilter(this.filterName).then(
    (result) =>{},
    (error)=>{alert("There was an error");
        console.log(error);
     });
  }

  // Work against memory leak if component is destroyed
  ngOnDestroy() {
    // this.displayChange.unsubscribe();
  }
}
