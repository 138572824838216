import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireDatabase, AngularFireList} from 'angularfire2/database';
import { SharedService } from '../../../../shared/shared.service';

@Component({
  selector: 'app-subscription-payee-dialog',
  templateUrl: './subscription-payee-dialog.component.html',
  styleUrls: ['./subscription-payee-dialog.component.scss']
})
export class SubscriptionPayeeDialogComponent implements OnInit {
  @Input() subscription: boolean;
  @Output() displayChange = new EventEmitter();

  user = this._sharedService.user;
  subscriberListingRef : AngularFireList<any>;
  users: Observable<any []>;
  subcribersList: Observable<any []>;
  teamMembers: any;
  agents: any;
  subscribers: any;
  uid: string;
  leadFirstName: string;
  leadLastName: string;
  role: string = "agent"
  teamName = '';
  teamNameError = false;
  addedItems: any;
  removedItems: any;

  constructor( private af: AngularFireDatabase, private _sharedService: SharedService){
    this.uid = this._sharedService.user.uid;
    let path = "/subscribers/" + this.uid;
    this.subscriberListingRef = af.list(path);
    this.teamMembers = [];
    this.addedItems = [];
    this.removedItems = [];
    this._sharedService.getUserInfo().then((result) => {
        if (result) {
          this.leadFirstName = result.firstName;
          this.leadLastName = result.lastName;
          this.role = result.role;
        }
      }); 

   
    // this.users.subscribe((res) => {
    //   this.agents = res;
    //   this.agents.sort(this.sortUsers);
    // });
    // above code giving only values and not keys

    this.users = af.list('users').snapshotChanges();
    this.users.map(actions => 
      actions.map(a => ({ key: a.key, ...a.payload.val() }))
    ).subscribe(items => {
      //this.agents = items;
      this.agents = items.map((item: any) => (
        {id: item.key, firstName: item.firstName, lastName: item.lastName, userRole: item.role? item.role:'', showRole: item.role=='team lead' || item.role =='team manager'? true : false}));
        this.agents.sort(this.sortUsers);
        this.removeTeamanager();
        this.getSubscriber();
    });
  }

  ngOnInit() {

  }

  removeTeamanager(){
      this.agents.map(
        (a, index) => {
         const isExists = a.id == this.uid;
         if(isExists){
            this.agents.splice(index, 1);
          }
      });
  }
  addToTeam(user) {
    this.teamMembers.push(user);
    this.teamMembers.sort(this.sortUsers);
    const index = this.agents.indexOf(user);
    if (index !== -1) {
      this.agents.splice(index, 1);
    }
    //update new item array and remove item array.
    if(this.subscribers){
      const subindex = this.subscribers.indexOf(user);
      if(subindex==-1){
        this.addedItems.push(user);
      }else{
        const removIndex = this.removedItems.indexOf(user);
        if(removIndex!==-1){
          this.removedItems.splice(removIndex, 1);
        }
      }
    }
  }

  removeFromTeam(user) {
    this.agents.push(user);
    this.agents.sort(this.sortUsers);
    const index = this.teamMembers.indexOf(user);
    if (index !== -1) {
      this.teamMembers.splice(index, 1);
    }
    //update new item array and remove item array.
    if(this.subscribers){
      const subIndex = this.subscribers.indexOf(user);
      if(subIndex!==-1){
        this.removedItems.push(user);
      }else{
        const addedIndex = this.addedItems.indexOf(user); 
        if(addedIndex!==-1){
          this.addedItems.splice(addedIndex, 1);  
        }
      }
    }
  }

  sortUsers(a, b) {
    if(a.firstName &&  a.lastName && b.firstName && b.lastName){
      const name1 = a.firstName.toLowerCase() + a.lastName.toLowerCase();
      const name2 = b.firstName.toLowerCase() + b.lastName.toLowerCase();
      if ( name1 < name2 ) {
        return -1;
      }
      if (name1 > name2 ) {
        return 1;
      }
    }
    
    return 0;
  }

  onClose(event) {
    // // this.displayChange.emit(false);
    console.log(event);
    if (event) {
        this.createSubscribers(this.teamMembers);
      }
      else{
        this.teamMembers = [];
        if(this.subscribers){
           this.subscribers.map((a,index)=>{
              this.teamMembers.push(a);
          }); 
           this.updateAgentList();
        }

        this.addedItems = [];
        this.removedItems = [];        
      }
    this.displayChange.emit(false);
    // this.teamMembers = [];
    // this.users.map(actions => 
    //   actions.map(a => ({ key: a.key, ...a.payload.val() }))
    // ).subscribe(items => {
    //   //this.agents = items;
    //   this.agents = items.map((item: any) => ({id: item.key, firstName: item.firstName, lastName: item.lastName}));
    //   this.agents.sort(this.sortUsers);
    // });
  }

  createSubscribers(allMemebers){
    // let allMemebers = this.teamMembers;
   let members = allMemebers.map(({ userRole: role, ...rest }) => ({ role, ...rest }));
    members = members.filter(function( obj ) {
      delete obj.showRole
      return obj;
    });

   let addedItems;
   if(this.addedItems){
      addedItems = this.addedItems.map(({ userRole: role, ...rest }) => ({ role, ...rest }));
      addedItems = addedItems.filter(function( obj ) {
        delete obj.showRole
        return obj;
      });  
   } 
   let removedItems;
   if(this.removedItems){
      removedItems = this.removedItems.map(({ userRole: role, ...rest }) => ({ role, ...rest }));
      removedItems = removedItems.filter(function( obj ) {
        delete obj.showRole
        return obj;
      });  
   } 
   
    if(this.subscribers){
      this.updateSubscribers(addedItems, removedItems);   
    }else{
      this.createNewSubscribers(members);   
    }
  }

  createNewSubscribers(members){
     this.subscriberListingRef.push({members : members})
        .then((response) => {
          alert('Subscriber has been added successfully');
          this.getSubscriber();
        }, (error)=>{
          alert(error.message)
        });
  }

  updateSubscribers(addedItems,removedItems){
    console.log(addedItems);
    console.log(removedItems);
  }

  getSubscriber(){
    this.subcribersList = this.subscriberListingRef.snapshotChanges(); 
    this.subcribersList.map(actions => 
      actions.map(a => ({ key: a.key, ...a.payload.val() }))
    ).subscribe(
     items => {
        if(items[0]){
          if(items[0].members){
            this.subscribers = items[0].members.map((item: any) => (
              {id: item.id, firstName: item.firstName, lastName: item.lastName, userRole: item.role? item.role:'', showRole: item.role=='team lead' || item.role =='team manager'? true : false}
            ));
          }
        }
        if(this.subscribers){
          // const currentSubscribers = this.subscribers;
          this.teamMembers = [];
          this.subscribers.map((a,index)=>{
              this.teamMembers.push(a);
          }); 
          // this.teamMembers = this.subscribers; 
          this.updateAgentList();
        }
        
        
      }
    );
  }

  updateAgentList(){
    if(this.agents && this.teamMembers){
      this.teamMembers.map(
        (i,index) =>{
          this.agents.map(
            (a, index) => {
             const isExists = a.id == i.id;
             if(isExists){
                this.agents.splice(index, 1);
              }
          })
      });  
    }

  }


  // Work against memory leak if component is destroyed
  ngOnDestroy() {
    // this.displayChange.unsubscribe();
  }
 
}
