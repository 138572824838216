import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ButtonModule } from 'primeng/primeng';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { SharedService } from '../../shared/shared.service'; 
import * as firebase from 'firebase/app';
import 'firebase/storage';

declare var google: any;

@Component({
  moduleId: module.id,
  selector: 'app-new-listing',
  templateUrl: 'new-listing.component.html',
  styleUrls: ['new-listing.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NewListingComponent implements OnInit {
  iconBase: string;
  user: any;
  msgs: any[];
  items: any;
  display: boolean = false;
  show: boolean = false;
  billingForm: FormGroup;
  locationForm: FormGroup;
  showMap= false;
  showFilterDialog = false;
  showPropertyDialog = false;

  options: any;
  overlays: any[];
  infoWindow: any;
  val: number;

  area : string = "";
  price: string = "";
  city : string = "";
  state: string = "";
  zip: string = "";
  description: string = "";
  propertyListingRef : AngularFireList<any>;
  uid : string; 
  propertyTypes : any;
  activePropertyType: number;
  propertyType : string = "";
  numberOfBeds :  string = "";
  numberOfBaths : string = "";
  propertyPicture: string = "./assets/images/imageuploade.png";
  storageRef = firebase.storage().ref();
  propertyListingRef1 = firebase.database().ref('propertyListing');
  propertyPictureFile: any;


 constructor(private formBuilder: FormBuilder, private af : AngularFireDatabase, private _shared : SharedService) { 
     this.propertyTypes = [{id: 1, name: 'Single family'}, {id: 2, name: 'Condo'}, {id: 3, name: 'Commercial'}, {id: 4, name: 'Penthause '}, {id: 5, name: 'Town Home '}, {id: 6, name: "Forester's house"},];
 }
  ngOnInit() {
    this.uid = this._shared.user.uid;
    // let path = "/userGeneratedLeads/" + this.uid;
    let path = "/propertyListing/" + this.uid;
    this.propertyListingRef = this.af.list(path);
  }
  // clicked() {
  //   this.show = !this.show;
  // }
  submit(){
    this.newListing(this.area , this.price, this.city, this.state, this.zip, this.description, this.propertyType, this.numberOfBeds, this.numberOfBaths).then(
        (result) =>{},
        (error)=>{alert("There was an error");
          console.log(error);
        });
  }
  newListing(area : string, price : string, city : string, state : string, zip : string, description : string, propertyType : string, numberOfBeds : string, numberOfBaths : string){
   return this.propertyListingRef.push({area : area, price : price, city: city, state  : state, zip: zip, description: description, propertyType: propertyType, numberOfBeds: numberOfBeds, numberOfBaths: numberOfBaths })
    .then((response) => {
      console.log(response.key);
       this.updatePropertyPicture(this.propertyPictureFile, response.key);
    }, (error)=>{console.log(error)})
  }
  selectedPropertyType(id) {
    this.activePropertyType = id;
    this.propertyType = this.propertyTypes[id-1].name;
    console.log(this.propertyTypes[id-1].name);
 }
 

  selectPropertyPicture(event: EventTarget) {
    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;
    this.propertyPictureFile = files[0];
    // let propertyPictureFile = files[0];

    // preview image
    var reader = new FileReader();
      reader.readAsDataURL(this.propertyPictureFile); // read file as data url
      reader.onload = (event:any) => { // called once readAsDataURL is completed
        this.propertyPicture = event.target.result;
      }
      // this.updatePropertyPicture(propertyPictureFile);

    //this._shared.updateProfilePicture(profilePictureFile).then((result) => {
      
   // });


  }
 
 updatePropertyPicture(image: any, listingId) {
    // const uid = this.user.uid;
    const propertyListingRef1 = this.propertyListingRef1.child(this.uid).child(listingId);
    // propertyListingRef1 = firebase.database().ref('propertyListing');
    // "/propertyListing/" + this.uid
    const imageRef = this.storageRef
      .child('propertyListing')
      .child(this.uid)
      .child(listingId)
      .child('propertyImage1.jpg');
    return imageRef.put(image).then(
      result => {
        const downloadURL = result.downloadURL;
        return propertyListingRef1.update({ propertyPicture: downloadURL }).then(
          result => {
            return downloadURL;
          },
          error => {
            console.log(error);
          }
        );
      },
      error => {
        console.log(error);
      }
    );
  }

}



