import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ButtonModule } from 'primeng/primeng';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { SharedService } from '../../shared/shared.service';
import { map, combineLatest  } from 'rxjs/operators';
import { ConfirmationService } from 'primeng/primeng';



declare var google: any;

@Component({
  moduleId: module.id,
  selector: 'app-myproperties',
  templateUrl: 'myproperties.component.html',
  styleUrls: ['myproperties.component.css'],
  providers: [ConfirmationService],
  encapsulation: ViewEncapsulation.None
})
export class MyPropertiesComponent implements OnInit {
  iconBase: string;
  user: any;
  msgs: any[];
  items: any;
  display: boolean = false;
  show: boolean = false;
  billingForm: FormGroup;
  locationForm: FormGroup;
  showMap= false;
  showFilterDialog = false;
  showPropertyDialog = false;

  options: any;
  overlays: any[];
  infoWindow: any;
  val: number;
  clientPropertyRef: AngularFireList<any>;
  properties: Observable<any>;
  combined :Observable<any>;
  uid: string;
  limit = 25;
  rows = 10;

  constructor( private af: AngularFireDatabase, private confirmationService: ConfirmationService, private _ngZone: NgZone, private _sharedService: SharedService){
    this.uid = this._sharedService.user.uid;
    let propertyListingPath = "/propertyListing/" + this.uid;

    this.clientPropertyRef = this.af.list(propertyListingPath, ref => ref.orderByChild('archived').equalTo(null));
    this.properties = this.clientPropertyRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key,  ...c.payload.val() }))
      )
    );

    // this.combined = Observable.combineLatest (this.properties, (...arrays) => arrays.reduce((acc, array) => [...acc, ...array], []));
  //   this.combined.subscribe((x)=>
  // {
    
 
  // })
    this.properties.subscribe((x) => {
      
    })
  }
  // constructor(private formBuilder: FormBuilder) {}
  paginate(event) {
    //event.first = Index of the first record
    //event.rows = Number of rows to display in new page
    //event.page = Index of the new page
    //event.pageCount = Total number of pages
    this.rows = event.rows;

  }
  ngOnInit() {
    this.items = [
      {
        items: [
          { label: 'Mark as read' },
          { label: 'Block user' },
          { label: 'Delete chat' }
        ]
      }
    ];
  }
  clicked() {
    this.show = !this.show;
  }


}



