import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import { SharedService } from '../../../shared/shared.service';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { map, combineLatest  } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-linked-clients',
  templateUrl: './linked-clients.component.html',
  styleUrls: ['./linked-clients.component.scss']
})
export class LinkedClientsComponent implements OnInit {
  teamDropdown: any;
  myClasses = 'inactive';
  myBgClasses = '';
  btnClass = ''; //'btn-grey';
  uid:string;
  linkedClientList: Observable<any>;
  linkedClientRef: AngularFireList<any>;
  constructor(private af: AngularFireDatabase, private _shared: SharedService) {
    this.uid = this._shared.user.uid;
    // console.log(this.uid);
  }

  ngOnInit() {
    this.teamDropdown = [{ name: 'Iron Ways' }, { name: 'Keyhole' }];
    
    let userGeneratedLeadsPaths = "/userGeneratedLeads/" + this.uid;
    this.linkedClientRef = this.af.list(userGeneratedLeadsPaths);
    this.linkedClientList = this.linkedClientRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, checked: false,  ...c.payload.val() }))
      )
    );
    this.linkedClientList.subscribe((x) => {
      // console.log(x);
    })
  }
  addClasses(ev) {
    if (this.myClasses == ev) {
      this.myClasses = 'inactive';
      this.myBgClasses = '';
      this.btnClass = 'btn-grey';
    } else {
      this.myClasses = 'active';
      this.myBgClasses = 'bg-color';
      this.btnClass = '';
    }
  }

  clearCheck() {
    // this.linkedClientList.map((res)=>{
    //   res.checked = false;
    //   console.log(res);
    // });
    this.linkedClientList.subscribe((x) => {
      //console.log(x);
      x.map((res)=>{
        res.checked = false;
      })
    })
  }
}
