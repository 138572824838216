import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { SharedService } from "../../shared/shared.service"
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import { AngularFireDatabase, AngularFireList} from 'angularfire2/database';
import { Observable} from 'rxjs';
import * as firebase from 'firebase/app';
import { InputMask } from 'primeng/primeng';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  encapsulation: ViewEncapsulation.None;
  
  userform: FormGroup;
  submitted: boolean;
  display = true;
  basicLogin : boolean = false;
  email = "";
  password = "";
  verifyPassword ="";
  firstName: string = "";
  lastName: string = "";
  phone: string = "";
  signUp : boolean = false;
  sentConfirmationEmail : boolean =false;
  forgotPassword : boolean =false;
  sentResetPasswordEmail : boolean =false;
  items = [
    { label: 'Register' },
    { label: 'Choose Plan' },
    { label: 'Confirm Settings' }
  ];
  userNameError : boolean = false;
  passwordError : boolean = false;
  emailRegError : boolean = false;
  passwordRegError : boolean = false;
  verifyPasswordError : boolean = false;
  errorMessage = "";
  firstNameError: boolean = false;
  lastNameError: boolean = false;
  phoneNumberError: boolean = false;


  rdoPlan = "";
  signup=false;
  planSelected = false;
  planError = false;
  inviteList: Observable<any []>;
  validInvitation = false;
  inviter = "";
  admin = "";
  agentDetail: AngularFireList<any>;
  emailDisable = false;
  paidByAdmin: boolean = false;
  invitedDate: string = "";
  @ViewChild('myInputMask') myInputMask: InputMask;

  constructor(private params: ActivatedRoute, private router : Router, private _shared: SharedService, private route: ActivatedRoute, private af: AngularFireDatabase) {
    // console.log(this.display);
    //       console.log(this.signup);
    const firstParam: string = this.route.snapshot.queryParamMap.get('id');
    const userExistParam: string = this.route.snapshot.queryParamMap.get('user');
    if(userExistParam && userExistParam=='0'){
      this.signup=true;
    }

    this.inviter = firstParam;
    if(firstParam){
      const inviteRef = firebase.database().ref('inviteAgents');
      const inviteRefs = inviteRef.child(firstParam);
      inviteRefs.once('value').then((snapshot: any) => {
        const result = snapshot.val();
          if(result){
              this.validInvitation = true;
              this.emailDisable = true;
              this.invitedDate = result.invitedDate;
              this.admin = result.admin;
              this.email = result.email;
              if(result.userId){
                this.signup = false;
              }else{
                this.signup = true;
              }

              if(result.payForAgent){
                this.planSelected = true;
                this.rdoPlan = result.planType;
                this.paidByAdmin = true;
              }
            }
      });
    }

         
   }


loginFB()
{
  this._shared.loginFB().then((x)=>
  {

  }).catch(y => console.log(y));
}

gotToRegister(){
  this.signup = true;
  this.planSelected = false;
  this.rdoPlan = "";
}


selectedPlan(){
  if(this.rdoPlan){
    console.log('plan selected: '+ this.rdoPlan);
    this.planSelected = true;
    this.planError = false;
  }else{
    this.planError = true;
  }
}
registerEmail()
{
  this.emailRegError=false;
  this.passwordRegError=false;
  this.verifyPasswordError =false;
  this.firstNameError = false;
  this.lastNameError = false;
  this.phoneNumberError = false;
  if(this.firstName==""){
    this.firstNameError=true;
    this.errorMessage="First name is required";
    return;
  }
  if(this.lastName==""){
    this.lastNameError=true;
    this.errorMessage="Last name is required";
    return;
  }
  if(this.email==""){
    this.emailRegError=true;
    this.errorMessage="Email is required";
    return;
  }
  if(this.phone==""){
    this.phoneNumberError=true;
    this.errorMessage="Phone number is required";
    return;
  }
  if(this.password==""){
    this.passwordRegError=true;
    return;
  }
  if(this.password!=this.verifyPassword){
    this.verifyPasswordError=true;
    return;
  }
  this._shared.register(this.email, this.password).then((result)=>
  {
    if(result.status)
      {
        let inviteId = null;
        if(this.validInvitation && this.inviter){
          inviteId =  this.inviter;
        }

        var payload = {email: this.email, planType: this.rdoPlan, invitationId: inviteId, paidByAdmin: this.paidByAdmin, firstName: this.firstName, lastName: this.lastName, phoneNumber: this.phone, invitedDate: this.invitedDate, admin: this.admin};


        /**imp note- below is temp payload for beta test only on trial mode, please uncomment above payload to restart select plan flow */
        
        // var payload = {email: this.email, planType: 'planZ', trialExpired:this._shared.trialExpireDate, invitationId: inviteId, paidByAdmin: this.paidByAdmin, firstName: this.firstName, lastName: this.lastName, phoneNumber: this.phone, invitedDate: this.invitedDate, admin: this.admin};
        this._shared.insertNewUser(result.userId, payload);
        if(!inviteId){
          this._shared.sendVerificationEmail().then((result)=>{
            this.display = false;
            this.sentConfirmationEmail = true;
          }, (error)=>{
            //alert(error.message);
            console.log(error.message);
          });
        }else{
          this.router.navigateByUrl("/register");
        }
      }
  
    else{
      //alert("Invalid password");
      this.emailRegError=true;
      if(result.error.code=="auth/invalid-email"){
        this.errorMessage="Invalid email address.";
      }else{
        this.errorMessage=result.error.message;
      }
    }
  }, (error)=>
{
});
}

clickedConfirmationEmailOk()
{
  // this.sentConfirmationEmail = false;
  // this.display = false;
  // this.router.navigateByUrl("/");
  this.logout();
}

loginEmail()
{
  this.userNameError = false;
  this.passwordError = false;
  if(this.email==""){
    this.userNameError=true;
    return;
  }
  this._shared.basicLogin(this.email, this.password).then((result)=>
{
  if(result.status)
    {
       this.display = false;
       
       if(this.validInvitation && this.inviter){
        // inviteId =  this.inviter;
        this._shared.updateUserInfo({invitationId: this.inviter});
        // const inviteRef = firebase.database().ref('inviteAgents');
        // const inviteRefs = inviteRef.child(this.invitationId);
        // inviteRefs.update({accepted: true});
      }
       this.router.navigateByUrl("/")
    }

  else{
    if(result.error.code=="auth/user-not-found"){
      this.userNameError = true;
    }else{
      //alert(result.error.message);
      this.passwordError = true;
    }
    //alert("Invalid password");
  }
});
}

logout()
{ 
  this.router.navigateByUrl("/login");
   this._shared.logout().then(()=>
  {
    
  });
}


sendResetPasswordLink()
{
  this.userNameError=false;
  if(this.email.length > 0)
    {
      this._shared.resetPassword(this.email).then((result)=>{
          if(result){
            this.sentResetPasswordEmail=true;
            this.forgotPassword = false;
          }
      });
    }
    else{
      this.userNameError = true;
    }
}
  ngOnInit() {
   
    this.params.params.subscribe(
      //executed each time new params arrive
      params => {
        let signup = params.signup;
       if(signup)
        {
          console.log('sing')
          this.signUp = true;
          this.display = true;
          console.log(this.display);
          console.log(this.signup);
        }
        
      }
  );
  }
  isInputMaskFilled(event) {
    if(!this.myInputMask.filled) {
      this.phone =  '';
    }else{
      this.phone = this.myInputMask.value;
    }
  }
}
