import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase} from 'angularfire2/database';
import { SharedService } from '../../../../shared/shared.service';

@Component({
  selector: 'app-billing-history',
  templateUrl: './billing-history.component.html',
  styleUrls: ['./billing-history.component.scss']
})
export class BillingHistoryComponent implements OnInit {
  items: any;
  uid: string = '';
  email: string = '';
  billingInfo: any;
  profilesArray: any;

  constructor(private af: AngularFireDatabase, private _shared: SharedService) {
    this.uid = this._shared.user.uid;
    this.email = this._shared.user.email;
    this.items = [
      // { month: 'feb', amt: '343' },
      // { month: 'jan', amt: '2250' },
      // { month: 'feb', amt: '343' },
      // { month: 'feb', amt: '343' },
      // { month: 'feb', amt: '343' },
      // { month: 'feb', amt: '343' },
      // { month: 'jan', amt: '2250' },
      // { month: 'feb', amt: '343' },
      // { month: 'feb', amt: '343' },
      // { month: 'feb', amt: '343' },
      // { month: 'dec', amt: '343' }
    ];
  }

  ngOnInit() {
      this._shared.getBilling().then((result) => {
        if (result) {
          this.billingInfo = result;
          if (this.billingInfo.monthlyFee) {
            const curDate = new Date();
            this.items.push({
              month: this.epochToMonth(curDate.getTime()) ,
              totalAmount: this.billingInfo.monthlyFee.amount
            });
          //   this.items.push({
          //     charge: this.billingInfo.monthlyFee.type,
          //     qty: 1,
          //     price: this.billingInfo.monthlyFee.amount,
          //     total: this.billingInfo.monthlyFee.amount});
          // }
          if (this.billingInfo.profiles) {
          // this.billingInfo.profiles.subscribe((snapshot: any) => {
              const profilesArray = [];
              // this.additionalProfilesCount = 0;
              const profilesArrayKeys = Object.keys(this.billingInfo.profiles);
              profilesArrayKeys.forEach(key => {
                // this.additionalProfilesCount += snap.val().qty;
                profilesArray.push({
                          id: key,
                          amount: this.billingInfo.profiles[key].amount,
                          qty: this.billingInfo.profiles[key].qty,
                          type: this.billingInfo.profiles[key].type,
                          date: this.billingInfo.profiles[key].date
                      });
                      return false;
                  });
                this.profilesArray = profilesArray;
               // this.addItemsToBillingHistory();
            // });
          }

        }
      }
    });
  }



  addItemsToBillingHistory() {
    let totalAmount = 0;
    this.profilesArray.forEach(item => {
      console.log(item);
      const curDate = new Date();
      const curMonth = curDate.getMonth();
      const itemDate = new Date(item.date);
      const itemMonth = itemDate.getMonth();
      if (curMonth > itemMonth) {
        const monthInChar = this.epochToMonth(curMonth);
        console.log(monthInChar); 
        // totalAmount += item.amount;
        if(this.items.indexOf(monthInChar)==-1){
          this.items.push({
            month: monthInChar ,
            totalAmount: item.amount
          });
        }
        
      }
    });
  }

  getBillTotal(){
    let totalAmount = 0;
    this.items.forEach(item => {
      totalAmount += item.total;
    });
    return totalAmount;
  }

  epochToDateString(epoch) {
    // adding 30 days in epoch
    const d = new Date(epoch + 30 * 24 * 3600 * 1000);
    return d;
  }

  epochToMonth(epoch){
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const d = new Date(epoch);
    return monthNames[d.getMonth()];
  }

  getNextMonthDate() {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const d = new Date();
    let nextMonth = d.getMonth() + 1;
    if (nextMonth > 11) {
      nextMonth = 0;
    }
    return '01 ' + monthNames[nextMonth] + ' ' + d.getFullYear();
  }

}
