import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ImportclientsComponent } from './importclients.component';
import { AuthGuard }      from '../shared/auth.guard';



@NgModule({
  imports: [
    RouterModule.forChild([
      { path: 'importclients', component: ImportclientsComponent,  canActivate: [AuthGuard]}
    ])
  ],
  exports: [RouterModule]
})
export class ImportclientsRoutingModule { 

  
}
