import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-social-sharing-dialog',
  templateUrl: './social-sharing-dialog.component.html',
  styleUrls: ['./social-sharing-dialog.component.scss']
})
export class SocialSharingDialogComponent implements OnInit {
  @Input() showSocialPopup: boolean;
  @Output() displayChange = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }
  onClose() {
    this.displayChange.emit(false);
  }
  
}
