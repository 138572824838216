import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireDatabase, AngularFireList} from 'angularfire2/database';
import { SharedService } from '../../../../shared/shared.service';
import * as firebase from 'firebase/app';
@Component({
  selector: 'app-create-team-dialog',
  templateUrl: './create-team-dialog.component.html',
  styleUrls: ['./create-team-dialog.component.scss']
})
export class CreateTeamDialogComponent implements OnInit {
  @Input() team: boolean;
  @Input() teamDetails:any;
  @Input() teamId:any;
  @Input() teamName:any;
  @Input() teamMembers:any;
  @Input() popupHeader = "Create team";
  @Output() displayChange = new EventEmitter();
  currentTeamLead: any;
  currentTeamName: any;
  teamListingRef : AngularFireList<any>;
  users: Observable<any []>;
  // teamMembers: any;
  agents: any;
  uid: string;
  leadFirstName: string;
  leadLastName: string;
  role: string = "agent"
  // teamName = '';
  teamNameError = false;
  admin: string = "";
  invalidMangerCount: boolean = false;
  userCurrentTeam = "";
  exitingMembers: any;
  successMessag = '';
  successHeader = "Team";
  successcommon: boolean = false;
  confirmcommon: boolean = false;
  confirmMessage: string = "";
  userToRemove: any;
  constructor( private af: AngularFireDatabase, private _sharedService: SharedService){
    this.uid = this._sharedService.user.uid;
    this._sharedService.getUserInfo().then((result) => {
        if (result) {
          this.leadFirstName = result.firstName;
          this.leadLastName = result.lastName;
          this.role = result.role;
          this.admin = result.admin ? result.admin : '';
          this.userCurrentTeam = result.team ? result.team : '';
          this.getMemberList();
        }
        
      }); 
      console.log('ci');
  }
  getMemberList(){
    this.teamMembers = [];
    this.users = this.af.list('users',ref => ref.orderByChild('admin').equalTo(`${this.admin}`)).snapshotChanges();
    this.users.map(actions => 
      actions.map(a => ({ key: a.key, ...a.payload.val() }))
    ).subscribe(items => {
       this.agents = [];
        items.map((item)=>{
          // if(item.key != this.admin || !item.teamId)
       
        if(!item.teamId){
          let agentItem = {id: item.key, firstName: item.firstName, lastName: item.lastName, role: item.role, profilePicture: item.profilePicture ? item.profilePicture : ''};
          this.agents.push(agentItem);
          
            // if(item.key != this.admin && item.key != this.uid && !item.teamId){
          // if(this.role=='admin'){
          //   let agentItem = {id: item.key, firstName: item.firstName, lastName: item.lastName, role: item.role};
          //   this.agents.push(agentItem);
          // }else{
          //   if(item.role!='manager'){
          //     let agentItem = {id: item.key, firstName: item.firstName, lastName: item.lastName, role: item.role};
          //     this.agents.push(agentItem);
          //   }
          // }
          
        }
      });

      if(this.agents.length>1){
        this.agents.sort(this.sortUsers);
      }
      
    });

    let path = "/teams";
    this.teamListingRef = this.af.list(path);
  }
  onOpen(){
    this.currentTeamName = this.teamName;
    // const teamLead =  this.teamDetails.lead ? this.teamDetails.lead : '';
    // this.currentTeamLead = teamLead ? teamLead.id : '';
    if(this.teamId){
      this.getTeamMemberList();
    }
  }
  ngOnInit() {

  }
  /**get current members of team */
  getTeamMemberList(){
    if(this.teamMembers.length>1){
      this.teamMembers.sort(this.sortUsers);
    }
  }

  addToTeam(user) {
    console.log(user);
    this.teamMembers.push(user);
    this.teamMembers.sort(this.sortUsers);

    const index = this.agents.indexOf(user);
    if (index !== -1) {
      this.agents.splice(index, 1);
    }
  }

  removeFromTeam(user) {
    if(user.teamId){
      // const del = confirm('Are you sure you want to remove agent from team.');
      this.confirmMessage = "Are you sure you want to remove agent from team.";
      this.confirmcommon = true;
      this.userToRemove = user;
      // if(del){
      //   // this.removeTeamMeber(user);
      //   this.removeTeamMember(user);
      // }

    }else{
      this.agents.push(user);
      this.agents.sort(this.sortUsers);
      const index = this.teamMembers.indexOf(user);
      if (index !== -1) {
        this.teamMembers.splice(index, 1);
      }
    }
    
  }

  sortUsers(a, b) {
    if(a.firstName &&  a.lastName && b.firstName && b.lastName){
      const name1 = a.firstName.toLowerCase() + a.lastName.toLowerCase();
      const name2 = b.firstName.toLowerCase() + b.lastName.toLowerCase();
      if ( name1 < name2 ) {
        return -1;
      }
      if (name1 > name2 ) {
        return 1;
      }
    }
    
    return 0;
  }

  onClose(event) {
    this.teamNameError = false;
    if (event) {
      if (this.teamName.trim().length > 0) {
        this.createTeam(this.teamName, this.teamMembers);
      }else{
        this.teamNameError = true;
        return;
      }
    }else{
      this.clearTeamDetails();
      this.displayChange.emit(false);
    }
    
  }


  /**new flow of create team. */
  createTeam(name, members){
    let creator = {id:this.uid, firstName: this.leadFirstName, lastName: this.leadLastName}
    let newMemberArray = [];
    members.map((item)=>{
      newMemberArray[item.id] = item;
    });
    if(this.teamId){
      this.updateTeam(name,newMemberArray, members);
    }else{
      let memberPayload = {name : name,  admin: this.admin, members : newMemberArray, createdDttm: Date.now(), creator: creator};
      this.displayChange.emit(false);
      this.teamListingRef.push(memberPayload)
      .then((response) => {
        // alert('Team has been created successfully.');
        this.successMessag = 'Team has been created.';
        this.successcommon =true;
        this.updateMembersTeamDetails(response.key, name, members);
      }, (error)=>{
        this.clearTeamDetails();
        alert(error.message)
      });
    }
  }

  updateMembersTeamDetails(teamId, teamName, teamMembers = []){
    const userPayload = {teamId: teamId, teamName: teamName, teamJoinedAt: Date.now()};
    teamMembers.map((result)=>{
      this._sharedService.updateUserInfoById(result.id,userPayload).then(() => {
        console.log('member details updated.');
        this.clearTeamDetails();
      });
    });
  }

  /**new flow user remove flow.... */
  removeTeamMember(user){
      const agentData = { teamId: '', teamName: 'unassigned'};
      this._sharedService.updateUserInfoById(user.id, agentData);
      const teamRef = firebase.database().ref('teams').child(this.teamId);
      let teamPath =  teamRef.child('members');
      teamPath = teamPath.child(user.id);
      teamPath.remove().then(()=>{
        const index: number = this.teamMembers.indexOf(user);
         if (index !== -1) {
           this.teamMembers.splice(index, 1);
         }
        // alert('Member has been removed from team.');
        this.successMessag = 'Member has been removed from team.';
        this.successcommon = true;
      });
  }

  /**new flow for team update */
  updateTeam(name, newMemberArray=[], members){
    const teamRef = firebase.database().ref('teams').child(this.teamId);
    const teamData = {name: name, updatedDtttm: Date.now()};
    teamRef.update(teamData).then(()=>{
      this.displayChange.emit(false);
      // alert('Team detail has been updated.');
      this.successMessag = 'Team information has been updated.';
      this.successcommon = true;
     // this.updateMembersTeamName(name, newMemberArray);
      Object.keys(newMemberArray).map(key => {
        if(!newMemberArray[key].teamId){
          const agentItem = {id: newMemberArray[key].id, firstName: newMemberArray[key].firstName,  lastName: newMemberArray[key].lastName, role: newMemberArray[key].role};
          let memRef = teamRef.child('members');
          memRef = memRef.child(key);
          memRef.update(agentItem);
        } 
      });
        this.updateMembersTeamName(name, members);
     });

    console.log(newMemberArray);

  }
  updateMembersTeamName(name, newMemberArray){
    console.log(newMemberArray);
    let userPayload;
    newMemberArray.map((result)=>{
      if(result.teamId){
        userPayload = {teamName: name}
      }else{
        userPayload = {teamName: name, teamId: this.teamId, teamJoinedAt: Date.now()};
      }
      this._sharedService.updateUserInfoById(result.id,userPayload).then(() => {
        console.log('member details updated.');
        this.clearTeamDetails();
      });
    });
  }

  clearTeamDetails(){
    // this.teamName='';
    // this.teamMembers = [];
    // this.invalidMangerCount = false;
    this.getMemberList();
  }

  onSuccesscommonClose(ev){
    this.successcommon = false;
  }

  onConfirmClose(ev){
    if(ev){
      // this.removeTeamMeber(user);
      this.removeTeamMember(this.userToRemove);
    }
    this.confirmcommon = false;
  }








/*****
 * 
 * 
 */

  // /* old flow of create Tema*/
  // createTeamOLD(name, members){
  //   let newMemberArray = [];
  //   let managerCount = 0;
  //   let managerDetails = [];
  //   members.map((item)=>{
  //     if(item.role=='manager'){
  //       managerCount +=1;
  //       managerDetails.push(item);
       
  //     }else{
  //       newMemberArray[item.id] = item;
  //     }
  //   });
  //   console.log(managerDetails);
  //   if(managerCount<=1){
  //     let adminPayload;
  //     let creator = {id:this.uid, firstName: this.leadFirstName, lastName: this.leadLastName}
  //     this.displayChange.emit(false);
  //     let lead;
  //     if(this.teamId){
  //       /**update team */
  //       if(this.role=='admin'){
  //         if(managerDetails.length==1){
  //             lead = {id: managerDetails[0].id, firstName: managerDetails[0].firstName, lastName: managerDetails[0].lastName};
  //         }
  //         const teamDetails = {name : name, lead: lead ? lead : '', members: newMemberArray,  updatedDtttm: Date.now()}; 
  //         const teamRef = firebase.database().ref('teams').child(this.teamId);
  //         console.log(newMemberArray);
  //         teamRef.update(teamDetails).then(()=>{
  //           const mapped = Object.keys(newMemberArray).map(key => 
  //             {
  //               const agentItem = {id: newMemberArray[key].id, firstName: newMemberArray[key].firstName, lastName: newMemberArray[key].lastName};
  //               let memRef = teamRef.child('members');
  //               memRef = memRef.child(key);
  //               memRef.update(agentItem);
  //             });
  //            this.updateUsersTeamDetails(this.teamId,lead, name, members);
  //         });
  //         alert('Team has been updated successfully.');
  //       }
  //       if(this.role == 'manager'){
  //         const teamDetails = {name : name, members: newMemberArray, updatedDtttm: Date.now()};
  //         const teamRef = firebase.database().ref('teams').child(this.teamId);
  //         teamRef.update(teamDetails).then(()=>{
  //           const mapped = Object.keys(newMemberArray).map(key => 
  //             {
  //               const agentItem = {id: newMemberArray[key].id, firstName: newMemberArray[key].firstName, lastName: newMemberArray[key].lastName};
  //               let memRef = teamRef.child('members');
  //               memRef = memRef.child(key);
  //               memRef.update(agentItem);
  //             });
  //             this.updateUsersTeamDetails(this.teamId,lead, name, members);
  //         });
  //         alert('Team has been updated successfully.');
  //       }
  //     }else{
  //       /**create new team */

  //       if(this.role=='admin'){
  //         console.log(managerDetails[0]);
  //        if(managerDetails.length==1){
  //          lead = {id: managerDetails[0].id, firstName: managerDetails[0].firstName, lastName: managerDetails[0].lastName};
  //        }
  //         adminPayload = {name : name, lead: lead ? lead : '', admin: this.admin,members : newMemberArray, createdDttm:Date.now(), creator: creator};
  //       }
  //       if(this.role == 'manager'){
  //           adminPayload = {name : name, lead: creator, admin: this.admin,members : newMemberArray, createdDttm:Date.now(), creator: creator};
  //       }
  //       this.teamListingRef.push(adminPayload)
  //       .then((response) => {
  //         console.log(response);
  //         alert('Team has been created successfully.');
  //         this.updateUsersTeamDetails(response.key,lead, name, members);
  //       }, (error)=>{
  //         this.clearTeamDetails();
  //         alert(error.message)
  //       });
  //     }
  //   }else{
  //       this.invalidMangerCount = true;
  //   }
  // }
  // updateUsersTeamDetails(teamId, leadDetails, teamName="", teamMembers = []){
  //       // this.teamName, this.teamMembers
  //   let userPaylod;
  //   if(this.role=='manager'){
  //     const mgrName = this.leadFirstName+' '+ this.leadLastName;
  //     userPaylod = {
  //       teamName: teamName, teamId: teamId, teamJoinedAt: Date.now(), createdDttm: Date.now(), managerId: this.uid, managerName: mgrName
  //     }
  //     console.log(this.userCurrentTeam);
  //     if(!this.userCurrentTeam){
  //       this.updateManagerTeams(userPaylod);
  //     }
  //   }
    
  //   if(this.role == 'admin'){
  //     let managrId;
  //     let managerName;
  //       if(leadDetails){
  //         managrId = leadDetails.id ? leadDetails.id : '';
  //         managerName = leadDetails.firstName ? leadDetails.firstName +' '+ leadDetails.lastName : '';
  //       }
  //       userPaylod = {
  //         teamName: teamName, teamId: teamId, teamJoinedAt: Date.now(), createdDttm: Date.now(), managerId: managrId ? managrId: '', managerName: managerName ? managerName : ''
  //     }
  //   }
  //   if(userPaylod){
  //     teamMembers.map((result)=>{
  //       this._sharedService.updateUserInfoById(result.id,userPaylod).then(() => {
  //         console.log('member details updated.');
  //         this.clearTeamDetails();
  //       });
  //     });
  //   }
  // }

  // updateManagerTeams(userPaylod){
  //    let managerPayload = {
  //       teamName: userPaylod.teamName, teamId: userPaylod.teamId
  //     }
  //     this._sharedService.updateUserInfo(managerPayload).then(() => {
  //       console.log('manager details updated.');
  //       // this.clearTeamDetails();
  //     });
  // }
  // updateCreatorRole(){
  //    var payload = { role: 'manager' }
  //    this._sharedService.updateUserInfo(payload).then(() => {
  //     // console.log('role updated successfully');
  //     });
  // }

  // /**OLD Remove team member functionality start here..... */
  // removeTeamMeberOld(user){
  //   if(user.role){
  //     this._sharedService.getUserInfoById(user.id).then((res)=>{
  //        if(res.teamId == this.teamId){
  //           const agentData = { teamId: '', teamName: ''};
  //           this._sharedService.updateUserInfoById(user.id, agentData);     
  //        }
  //     });
  //     /**remove lead from team */
  //     const teamRef = firebase.database().ref('teams').child(this.teamId);
  //     const teamPath = teamRef.child('lead');
  //     teamPath.remove().then(()=>{
  //       const index: number = this.teamMembers.indexOf(user);
  //       if (index !== -1) {
  //           this.teamMembers.splice(index, 1);
  //       }
  //       alert('Team manager has been removed successfully.');
  //       this.removeMembersManger();
  //     });
  //   }else{
  //     // irstName: "maheshs" id: "TfPRPBY0NfWh54pk30RVMYtcwXt1" lastName: "rediff" teamId: "TfPRPBY0NfWh54pk30RVMYtcwXt1"
  //     const agentData = { teamId: '', teamName: 'unassigned'};
  //     this._sharedService.updateUserInfoById(user.id, agentData);
  //     const teamRef = firebase.database().ref('teams').child(this.teamId);
  //     let teamPath =  teamRef.child('members');
  //     teamPath = teamPath.child(user.id);
  //     teamPath.remove().then(()=>{
  //       const index: number = this.teamMembers.indexOf(user);
  //       if (index !== -1) {
  //           this.teamMembers.splice(index, 1);
  //       }
  //       alert('Agent has been removed from team.');
  //     });
  //   }
  // }
  // removeMembersManger(){
  //   let teamMembers = this.af.list('users',ref => ref.orderByChild('teamId').equalTo(`${this.teamId}`)).snapshotChanges();
  //   teamMembers.map(actions => 
  //     actions.map(a => ({ key: a.key, ...a.payload.val() }))
  //   ).subscribe(members => {
  //     members.map((member)=>{
  //       let userId = member.key;
  //       let userPayLoad = { managerId: "", managerName: "" };
  //       this._sharedService.updateUserInfoById(userId, userPayLoad);
  //     });
  //   });
  // }

  /** Remove team member functionality end here..... */

  // Work against memory leak if component is destroyed
  ngOnDestroy() {
    // this.displayChange.unsubscribe();
  }
 
}
