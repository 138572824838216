import { Component, OnInit } from '@angular/core';
import {MessagesService} from "../shared/messages.service"
import { SharedService } from "../shared/shared.service"
import {Observable} from 'rxjs'
import * as firebase from 'firebase/app';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
authState : Observable<any>;
selectPlan: boolean = false;
displayPlan: boolean = false;
membershipEnds: string;
daysCount: number = 0;
unpaidByAdmin: boolean = false;
billingDate: string;
updatePlanCall: boolean = false;
  constructor(public _shared: SharedService,  public messageService : MessagesService) { }
  ngOnInit() {

    this.authState = this._shared.authState$;
    
    
  }
  ngAfterViewInit() {
    if(this.authState){
      this.getUserInfo();
    }
    }
  logout()
  {
    this._shared.logout();
  }
  getUserInfo(){
    // differenceDay
    const uid = firebase.auth().currentUser.uid;
    let userRefs = firebase.database().ref('users');
    userRefs = userRefs.child(uid);
    userRefs.on('value', (snapshot) => {
      const result = snapshot.val();
      if (result && result.registrationCompleted && !result.invitationId) {
        if(!result.paidByAdmin){
          if(!result.planType){
            this.selectPlan = true;
            if(result.unpaidByAdminDate){
              const reaminingDays = this._shared.differenceDay(result.billingDate);
              const dayCount = reaminingDays;
              this.daysCount =  dayCount >=0 ? dayCount : 0;
              this.billingDate = this._shared.toEpoch(result.billingDate);
              this.unpaidByAdmin = true;
              this.membershipEnds = "";
              
              // this.displayPlan = true;
              
            }
          }else{
            if(result.planType == 'planZ'){
              this.daysCount = this._shared.differenceDay(result.trialExpired);
              this.daysCount =  this.daysCount >=0 ? this.daysCount : 0;
              this.billingDate = this._shared.toEpoch(result.trialExpired);
              this.unpaidByAdmin = true;
              this.selectPlan = true;
              // this.displayPlan = true;
            }else{
              this.selectPlan = false;
              this.unpaidByAdmin = false;
            }
          }
        }
      }
    });
  }
  selectPlanPopup(){
    this.displayPlan = true;
  }
  onPlanDialogClose(event){
    this.displayPlan = false;
    console.log(event);
  }
}
