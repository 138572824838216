import { Component, OnInit, Input, ViewEncapsulation, NgZone } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import { SharedService } from '../shared/shared.service';
import * as firebase from 'firebase/app';
import { Message } from 'primeng/primeng';
import { ConfirmationService } from 'primeng/primeng';
import { CanActivate, Router, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { LeadStatus } from "../shared/leadstatus.enum";
import { MessagesService } from "../shared/messages.service"
import {Observable} from 'rxjs/Rx'
import { map, combineLatest  } from 'rxjs/operators';

@Component({
  moduleId: module.id,
  selector: 'clientprofiles',
  templateUrl: 'clientprofiles.component.html',
  styleUrls: ['clientprofiles.component.scss'],
  providers: [ConfirmationService]
})
export class ClientProfilesComponent implements OnInit {
  encapsulation: ViewEncapsulation.None;
  tabIndex = 0;
  user = this._sharedService.user;
  clientProfilesAppRef: AngularFireList<any>;
  clientProfilesApp: Observable<any>;

  newclient: boolean = false;
  showSuccess = false;

  openTabsRef: AngularFireList<any>;
  openTabs: Observable<any>;

  clientProfilesUserRef: AngularFireList<any>;
  clientProfilesUser: Observable<any>;


  combined :Observable<any>;

  currentPlanRef: AngularFireObject<any>
  currentPlan: Observable<any>;

  openTabsLength;
  clientProfilesAppIndex = 0;
  rows = 10;
  confirmDelete = false;
  assignMemberDialog = false;
  removeClientDialog = false;
  leadStatus = LeadStatus;
  filter = null;
  limit = 25;
  appProfilesLength = 0;
  currentItem;
  edit = false;
  statusTypes = [{ label: 'All', value: null }, { label: "New Lead", value: 'New Lead' }, { label: "Qualified", value: 'Qualified' }, { label: "Offers Pending", value: 'Offers Pending' }, { label: "Closing", value: 'Closing' }, { label: "Sold", value: 'Sold' }];

  activeTypes = [{ label: 'All', value: null }, { label: "Active Clients", value: 'Active Clients' }, { label: "Inactive Clients", value: 'Inactive Clients' }];

 cols = [
    { field: 'firstName'},
    { field: 'lastName' },
    { field: 'email' }
];


  constructor(private messageService: MessagesService, private _ngZone: NgZone, private currentRoute: ActivatedRoute, private confirmationService: ConfirmationService, private af: AngularFireDatabase, private _sharedService: SharedService) {

    this.currentPlanRef = this.af.object('/stripe/' + this.user.uid + '/subscriptions/data/0/plan/id');
    this.currentPlan = this.currentPlanRef.valueChanges();

    this.currentPlan.subscribe((result) => {
      if (result) {
        if (result.value === "Agent_Team_Monthly") {
          this.limit = 750;
        }
      }

      else {
        this.limit = 25;
      }
    })
    // let path = "/users/"
    let path = "/appUsers/"
    let tabPath = "/openTabs/" + this.user.uid;
    let userLeadPath = "/userGeneratedLeads/" + this.user.uid;
    this.openTabsRef = this.af.list(tabPath);
    this.openTabs = this.openTabsRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ deleteKey: c.payload.key, ...c.payload.val() }))
      )
    );

    this.clientProfilesAppRef = this.af.list(path, ref => ref.orderByChild('agentId').equalTo(this.user.uid));
    this.clientProfilesApp = this.clientProfilesAppRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, appLead : true, ...c.payload.val() }))
      )
    );

    this.clientProfilesUserRef = this.af.list(userLeadPath, ref => ref.orderByChild('archived').equalTo(null));
    this.clientProfilesUser = this.clientProfilesUserRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, appLead : false, ...c.payload.val() }))
      )
    );


 

    this.combined = Observable.combineLatest (this.clientProfilesUser, this.clientProfilesApp, (...arrays) => arrays.reduce((acc, array) => [...acc, ...array], []));
    this.combined.subscribe((x)=>
  {
 
  })

    this.clientProfilesApp.subscribe((result: any) => {
  
      let filtered = result.filter((x) => (x.firstName != undefined && x.firstName != "undefined"));

      if (filtered.length > this.limit) {
        let overage = filtered.length - this.limit;
        this.addMessage(overage);
      }

    })
    this.openTabs.subscribe((items) => {
      this.openTabsLength = items.length;


    })

    this.clientProfilesUser.subscribe((x) => {
    })

  }

  handleChange(e) {

    //this.tabIndex = e.index;
  }

  addMessage(overage) {
    this.messageService.msgs.push({ severity: 'warn', summary: 'Limited Account:', detail: "You've reached your profile limit of " + this.limit + ". You have " + (overage) + " client profiles pending. Please upgrade your account to view them." });

  }

  tabtest(i) {
    this.tabIndex = i;
  }

  getAppDetails(i, appLead, key) {
    return this.clientProfilesApp
      .subscribe((result: any) => {
        let filtered = result.filter((x) => (x.firstName != undefined && x.firstName != "undefined"));
    
        let profile = filtered.filter((p) => {
          return p.key === key
        })[0];

        var match = false;
        return this.openTabs
          .subscribe((tabResult: any) => {
            if (tabResult.length > 0) {
              tabResult.some((item, index) => {
                if (item.key === profile.key) {

                  this.tabIndex = (index + 1);
                  return match = true;

                }
                else {
                }

              })
            }

            if (match) {
              return
            }
            else {
              let fullName = (profile.firstName ? profile.firstName : "No Name " + i) + " " + (profile.lastName ? profile.lastName : " ");
              let object = { key: profile.key, name: fullName, appLead: appLead };
              return this.openTabsRef.push(object).then(() => {
                return this.tabIndex = this.openTabsLength;
              });
            }



          })

      })
  }

  getImportDetails(i, appLead, key) {
    return this.clientProfilesUser
      .subscribe(result => {
        let filtered = result.filter((x) => (x.firstName != undefined && x.firstName != "undefined"));
        let profile = filtered.filter((p) => {
          return p.key === key
        })[0];
        var match = false;
        return this.openTabs
          .subscribe(tabResult => {
            if (tabResult.length > 0) {
              tabResult.some((item, index) => {
                if (item.key === profile.key) {
                  this.tabIndex = (index + 1);
                  return match = true;

                }


              })
            }





            if (match) {
              return
            }

            else {
              let fullName = (profile.firstName ? profile.firstName : "No Name " + i) + " " + (profile.lastName ? profile.lastName : " ");
              let object = { key: profile.key, name: fullName, appLead: appLead };

              return this.openTabsRef.push(object).then(() => {
                return this.tabIndex = this.openTabsLength + 1;
              });
            }








          })




      })
  }

  archive() {
    this.clientProfilesUserRef.update(this.currentItem.key, { archived: true });
    this.confirmDelete = false;
    this.currentItem = null;
  }

  openTab(i, item) {


    if (item.appLead) {

      this.getAppDetails(i, item.appLead, item.key);
    }
    else {
      this.getImportDetails(i, item.appLead, item.key);
    }
    window.scrollTo(0, 0);

  }
  handleClose(e) {
    let test = true;

    let index = e.index - 1;

  this.openTabs.take(1)
      .subscribe(result => {
        let tab = result[index];
        this.tabIndex = 0
        this.openTabsRef.remove(tab.deleteKey)
      })
    //this.openTabs.remove(this.openTabs[e.index]);




  }


  paginate(event) {
    //event.first = Index of the first record
    //event.rows = Number of rows to display in new page
    //event.page = Index of the new page
    //event.pageCount = Total number of pages
    this.rows = event.rows;

  }


  testme() {
    this.openTabsRef.push({ stuff: 55 });
  }

  showNewDialog() {
    this.newclient = true;
  }
  onNewDialogClose(event) {
    if (event) {
      this.newclient = false;
      this.showSuccess = true;
    } else {
      this.newclient = event;
    }
  }

  onSuccessDialogClose(event) {
    this.showSuccess = event;
  }

  ngOnInit() {

    /*
        let count = 0;
        while (count < 10)
        {
      this.openTabs.push({stuff: count});
      count++
        }
        */

  }


  ShowassignMemberDialog() {
    this.assignMemberDialog = true;
  }
  onAssignMemberClose() {
    this.assignMemberDialog = false;
  }
  ShowremoveClientDialog() {
    this.removeClientDialog = true;
  }
  onRemoveClientClose() {
    this.removeClientDialog = false;
  }

  
  
}
