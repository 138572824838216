import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { MessagesService } from '../shared/messages.service';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { SharedService } from '../shared/shared.service';
import * as moment from 'moment/moment';
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  cities: { name: string; code: string }[];
  uid;
  clientsApp: AngularFireList<any>;
  clientsUser: AngularFireList<any>;
  appointments: Observable<any[]>;
  taskActivity: AngularFireList<any>;
  commissionStats: AngularFireList<any>;
  today = new Date();

  totalOpen = 0;
  totalCompleted = 0;
  totalCalls = 0;

  todayOpen = 0;
  todayCompleted = 0;
  todayCalls = 0;

  monthOpen = 0;
  monthCompleted = 0;
  monthCalls = 0;

  commissionTotal = 0;
  homesSold = 0;

  activeInactiveData = {
    labels: ['Active', 'Inactive'],
    datasets: [
      {
        data: [0, 0],
        backgroundColor: ['#00cfff', 'gray'],
        hoverBackgroundColor: ['#00cfff', 'gray']
      }
    ]
  };

  clientStatusData;

  statusCount = [0, 0, 0, 0, 0, 0];

  testValue = 35;

  ///// STATS/////
  activeCount = 0;
  inactiveCount = 0;
  invitationId = "";
  firstName = "";
  lastName = "";
  teamList: Observable<any []>;
  removedBy = "";
  removedAdminName = "";
  removedByAdmin: boolean = false;
  displayPlan: boolean = false;
  planType: string;
  connectedByAdmin: boolean = false;
  adminName: string;
  paidByAdmin: boolean;
  //////////////
  constructor(
    private _shared: SharedService,
    private af: AngularFireDatabase,
    public afAuth: AngularFireAuth,
    private messageService: MessagesService
  ) {
    this.cities = [{ name: 'Team', code: 'NY' }, { name: 'Member', code: 'RM' }];

    this.uid = this._shared.user.uid;
    const appPath = '/users/';
    const taskPath = '/tasks/' + this.uid;
    const commissionPath = '/sold/' + this.uid;
    this.commissionStats = this.af.list(commissionPath);

    this.commissionStats.valueChanges().subscribe(result => {
      this.homesSold = result.length;
      this.commissionTotal = 0;
      result.forEach(element => {
        const rate = Number(element.commissionRate) / 100;
        const price = Number(element.soldPrice);
        const profit = price * rate;
        this.commissionTotal = this.commissionTotal + profit;
      });
    });

    this.taskActivity = this.af.list(taskPath);
    this.taskActivity.valueChanges().subscribe(result => {
      result.forEach(x => {
        if (x.complete) {
          this.totalCompleted = this.totalCompleted + 1;
        }
        if (!x.complete) {
          this.totalOpen = this.totalOpen + 1;
        }
        if (moment(x.updatedTs).isSame(Date.now(), 'day')) {
          if (x.complete) {
            this.todayCompleted = this.todayCompleted + 1;
          } else {
            this.todayOpen = this.todayOpen + 1;
          }
        }

        if (moment(x.updatedTs).isSame(Date.now(), 'month')) {
          if (x.complete) {
            this.monthCompleted = this.monthCompleted + 1;
          } else {
            this.monthOpen = this.monthOpen + 1;
          }
        }
      });
    });

    const calendarPath = '/calendar/' + this.uid;

    this.appointments = this.af.list(calendarPath).valueChanges();
    this.clientsApp = this.af.list(appPath, ref => ref.orderByChild('assignedTo').equalTo(this.uid));

    this.clientsApp.valueChanges().subscribe(result => {
      this.checkActive(result);
      this.getStatusCount(result);
    });

    const userLeadPath = '/userGeneratedLeads/' + this.uid;
    this.clientsUser = this.af.list(userLeadPath);

    this.clientsUser.valueChanges().subscribe(result => {
      this.checkActive(result);
      this.getStatusCount(result);
    });

    this._shared.getUserInfo().then((result) => {
      if (result) {
        console.log(result);
        this.firstName = result.firstName ? result.firstName : '';
        this.lastName = result.lastName ? result.lastName : '';
        this.removedBy = result.removedBy ? result.removedBy : '';
        this.removedAdminName = result.removedAdminName ? result.removedAdminName : '';
        this.planType = result.planType ? result.planType : '';
        this.adminName = result.adminName ? result.adminName : '';
        this.paidByAdmin = result.paidByAdmin ? result.paidByAdmin : '';
        console.log(result.removedAdminName);
        if(result.invitationId){
          this.invitationId = result.invitationId;
          this.updateUserTeam();
        }
        if(this.removedBy){
          this.removedByAdmin = true;
          // alert('You have been disconnected from '+this.removedAdminName);
        }
        if((!this.removedByAdmin && result.unpaidByAdminDate) || this.planType == 'planZ'){
          this.checkForUnpaidByAdmin();
        }
        
      }
    });


  }
  updateUserTeam(){
    if(this.invitationId){
      const inviteRef = firebase.database().ref('inviteAgents');
      const inviteRefs = inviteRef.child(this.invitationId);
      inviteRefs.once('value').then((snapshot: any) => {
        const result = snapshot.val();
          if(result){
            if(result.team!=''){
              const teamRef = firebase.database().ref('teams').child(result.team);
              let teamRefs;
              let teamPath;
              let payload = {};
              // if(result.teamRole=="manager"){
              //   teamRefs = teamRef.child('lead');
              //   // teamRefs =  teamPath.child(this.uid);
              //   payload = {id: this.uid, firstName: this.firstName, lastName: this.lastName};
              // }else{
                teamPath = teamRef.child('members');
                teamRefs =  teamPath.child(this.uid);
                payload = {id: this.uid, firstName: this.firstName, lastName: this.lastName, createdDttm: Date.now(), role: result.teamRole};
              // }
              teamRefs.update(payload);
            }
            //update current user role as per invitation and invitor Id as admin.
            let managerId = "";
            let managerName = "";
            if(result.teamRole == 'manager'){
              managerId = this.uid;
              managerName =  this.firstName +' '+ this.lastName;
            }else{
              managerId = result.managerId;
              managerName =  result.managerName;
            }
            
            const userPayload = {role:  result.teamRole, teamName: result.teamName, teamId: result.team, admin: result.admin, teamJoinedAt: Date.now(), changeDate: Date.now(), paidByAdmin: result.payForAgent, adminName: result.adminName, managerId: managerId, managerName: managerName, planType: result.planType, rate: result.rate, unpaidByAdminDate: ''};
            this._shared.updateUserInfo(userPayload).then(() => {
              this.paidByAdmin = result.payForAgent;
              this.planType = result.planType;
              this.adminName = result.adminName;
              this.removeInvitationId();
            });
              inviteRefs.update({accepted: true});   
          }
        });
    }
  }
  removeInvitationId(){
    const userRef = firebase.database().ref('users').child(this.uid);
    const userPath = userRef.child('invitationId');
    userPath.remove().then(()=>{
      this.connectedByAdmin = true;
    });
  }

  getStatusCount(values) {
    var statusTypes = ['Not Looking', 'New Lead', 'Qualified', 'Offers Pending', 'Closing', 'Sold'];
    values.forEach(element => {
      if (element.status === statusTypes[0]) {
        this.statusCount[0] = this.statusCount[0] + 1;
      }
      if (element.status === statusTypes[1]) {
        this.statusCount[1] = this.statusCount[1] + 1;
      }
      if (element.status === statusTypes[2]) {
        this.statusCount[2] = this.statusCount[2] + 1;
      }
      if (element.status === statusTypes[3]) {
        this.statusCount[3] = this.statusCount[3] + 1;
      }
      if (element.status === statusTypes[4]) {
        this.statusCount[4] = this.statusCount[4] + 1;
      }
      if (element.status === statusTypes[5]) {
        this.statusCount[5] = this.statusCount[5] + 1;
      }
    });

    this.clientStatusData = {
      labels: ['Not Looking', 'New Lead', 'Qualified', 'Offers Pending', 'Closing', 'Sold'],
      datasets: [
        {
          label: 'Client Status',
          backgroundColor: '#00cfff',
          borderColor: '#1E88E5',
          data: this.statusCount
        }
      ]
    };
  }

  checkActive(values) {
    const totalCount = values.length;
    const today = new Date();
    const inactive = today.setDate(today.getDate() - 7);
    const filtered = values.filter(item => {
      if (item.lastActive <= inactive || item.active === false) {
        return item;
      }
    });

    const inactiveCount = filtered.length;
    const activeCount = totalCount - inactiveCount;
    this.inactiveCount = this.inactiveCount + inactiveCount;
    this.activeCount = this.activeCount + activeCount;
    this.activeInactiveData.datasets[0].data[0] = this.activeCount;
    this.activeInactiveData.datasets[0].data[1] = this.inactiveCount;
  }

  formatDate(obj) {
    return moment(obj).format('h:mma');
  }

  setLabel(value) {
    return Math.round(value) + '/';
  }

  addMessage() {
    this.messageService.msgs.push({
      severity: 'error',
      summary: 'Limited Account:',
      detail:
        ' Your account is running on the free tier and has limited functionality. Please upgrade to enable all features.'
    });
  }
  onRemovedAdminDialogClose(event){
    const payload = {removedBy: '', removedAdminName: ''};
    this._shared.updateUserInfo(payload);
    if(!this.planType){
      // this.displayPlan = true;
    }
  }
  onPlanDialogClose(event){
    // this.displayPlan = false;
  }
  onConnectedAdminDialogClose(event){
    this.connectedByAdmin  = false;
    if(!this.paidByAdmin){
      // this.displayPlan = true;
    }
  }
  ngOnInit() {
    this.activeInactiveData.datasets[0].data = [1000, 500];
  }

  checkForUnpaidByAdmin(){
      // if(!this.paidByAdmin && (this.planType == 'planZ' || !this.planType)){
      //   this.displayPlan = true;    
      // }
  }

}
