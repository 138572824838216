import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TabViewModule, CardModule, InputTextModule, MenuModule, ButtonModule } from 'primeng/primeng';
import { ProgressBarModule } from 'primeng/progressbar';
import { FormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { ChatRoutingModule } from './chat-routing.module';
import { ChatComponent } from './chat.component';
import { MessageComponent } from './message/message.component';
import { ChatRoomComponent } from './chat-room/chat-room.component';
import { SharedFileModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    ChatRoutingModule,
    TabViewModule,
    CardModule,
    InputTextModule,
    ProgressBarModule,
    MenuModule,
    ButtonModule,
    SharedFileModule,
    InfiniteScrollModule,
    FormsModule
  ],
  declarations: [ChatComponent, MessageComponent
    , ChatRoomComponent
  ],
  exports: [
    ChatRoomComponent
  ]
})
export class ChatModule {}
