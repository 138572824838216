export const angularConfig = {
   apiKey: 'AIzaSyAKulLUE9xKpw1pIffr__FGoxXsTCrqsVs',
  authDomain: 'homecodecsandbox.firebaseapp.com',
   databaseURL: 'https://homecodecsandbox.firebaseio.com',
   projectId: 'homecodecsandbox',
   storageBucket: 'homecodecsandbox.appspot.com',
   messagingSenderId: '682334469706'
}

export const devConfig = {
  apiKey: 'AIzaSyB8Okw0POUlTSmVY18RN2xbqPk0NXEOlR0',
  authDomain: 'homecodecsandboxdev.firebaseapp.com',
  databaseURL: 'https://homecodecsandboxdev.firebaseio.com',
  projectId: 'homecodecsandboxdev',
  storageBucket: 'homecodecsandboxdev.appspot.com',
  messagingSenderId: '426755879488'
}



  // Developement server keys
  /*
  apiKey: 'AIzaSyBuJH0DEOC5qN7oK3mTzBcJq_5756NPmEM',
  authDomain: 'homecodec-cce50.firebaseapp.com',
  project_number: '650992398167',
  databaseURL: 'https://homecodec-cce50.firebaseio.com',
  projectId: 'homecodec-cce50',
  storageBucket: 'homecodec-cce50.appspot.com'
};
*/
/* list hub database config */
export const angularListHubConfig = {
  apiKey: 'AIzaSyBr1g79a9DGqK6U88kQSxDsmcS9a30OwRU',
  authDomain: 'homecodeclisthub.firebaseapp.com',
  databaseURL: 'https://homecodeclisthub.firebaseio.com/',
  projectId: 'homecodeclisthub',
  storageBucket: 'homecodeclisthub.appspot.com',
  messagingSenderId: 'x'
}