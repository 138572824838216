import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import * as firebase from 'firebase/app';
import { AngularFireDatabase, AngularFireList} from 'angularfire2/database';
import { SharedService } from '../../../../shared/shared.service';
import { Observable} from 'rxjs';
@Component({
  selector: 'app-edit-agent-dialog',
  templateUrl: './edit-agent-dialog.component.html',
  styleUrls: ['./edit-agent-dialog.component.scss']
})
export class EditAgentDialogComponent implements OnInit {
  areaDropdown: any;
  teamDropdown: any;
  monthlyLeadspendPopup: any = false;
  billingLockedPopup : any = false;
  @Input() display: boolean;
  @Input() userDetails: any;
  @Input() monthlyBudget: any;
  @Input() firstName: any;
  @Input() lastName: any;
  @Input() team: any;
  @Input() teamName: any;
  @Input() selectedRole: any;
  @Output() displayChange = new EventEmitter();
  agentId = "";
  uid: string;
  admin: string;
  role: string;
  roleDropdown: any;
  teamList: Observable<any []>;
  teams: any;
  myRole: string = "agent"
  cars:any;
  currentRole: string = "";
  currentTeamName: string = "";
  currentTeamId: string = "";
  currentBudget: number;
  // individualLead: boolean = true;
  managedLead: boolean;
  leadBudget: number;
  @Input() showMonthlyLead: boolean = true;
  // team = 'Blank Team';
  constructor(private _sharedService: SharedService, private af: AngularFireDatabase) {
    this.uid = this._sharedService.user.uid;
  }

  ngOnInit() {
    
    this._sharedService.getUserInfo().then(user => {
      this.admin = user.admin ? user.admin : '';
      this.role = user.role ? user.role : '';
      this.roleDropdown = [
        {label: 'Manager', value: 'manager'},
        {label: 'Agent', value: 'agent'}
      ];
      this.getTeams();   
    });
    this._sharedService.getAdminInfo().then((admin)=>{
      this.managedLead = admin.managedLead;
      if(admin.managedLead){
       this.monthlyBudget = admin.leadBudget;
      }
      console.log(admin);
    });
 
  }
  onClose() {
    this.billingLockedPopup = false;
    this.monthlyLeadspendPopup = false;
    console.log('cose');
    this.selectedRole = this.currentRole;
    this.monthlyBudget = this.currentBudget;
    this.displayChange.emit(false);
  }
  onOpen(){
    this.currentRole = this.selectedRole;
    this.currentTeamId = this.team;
    this.currentTeamName = this.teamName;
    this.currentBudget = this.monthlyBudget;
    if(this.userDetails){
      this.agentId = this.userDetails.id? this.userDetails.id : '';
    }
  }

  getTeams(){
    if(this.role == 'admin'){
      this.teamList = this.af.list('teams',ref => ref.orderByChild('admin').equalTo(`${this.admin}`)).snapshotChanges();
    }
    if(this.role == 'manager'){
      this.teamList = this.af.list('teams',ref => ref.orderByChild('lead/id').equalTo(`${this.uid}`)).snapshotChanges();
    }

    //this.teamList = af.list('teams').snapshotChanges();
    this.teamList.map(actions => 
      actions.map(a => ({ key: a.key, ...a.payload.val() }))
    ).subscribe(items => {
        console.log(items);
       this.teams = [];
       let teamPush = [];
        teamPush['label'] = 'unassigned';
        teamPush['value'] = '';
         teamPush['managerId'] = '';
        teamPush['managerName'] = '';
        this.teams.push(teamPush);
        // this.team = teamPush;  
      items.map((item)=>{
        let teamPush = [];
        teamPush['label'] = item.name;
        teamPush['value'] = item.key;
        teamPush['managerId'] = item.lead ? item.lead['id']:'';
        teamPush['managerName'] = item.lead ? item.lead['firstName']+' '+item.lead['lastName'] : '';
        this.teams.push(teamPush);
      });

    });
  }

  onSave(){
    this.billingLockedPopup = false;
    this.monthlyLeadspendPopup = false;
   if(this.currentRole == this.selectedRole){
     if(this.currentRole == 'agent'){
        this.updateNameOfAgent();
     }else{
      this.updateNameOfManager();
     }
   }else{
     if(this.currentRole == 'agent'){
       this.updateAgentToManager();
     }else{
       this.updateMangerToAgent();
     }
   }
  //  this.displayChange.emit(true);
  this.display = false;
    alert("User details updated successfully.");
    
    // // check if any role change happen.
    // if(this.currentRole == this.selectedRole && this.team == this.currentTeamId){
    //   //update name of agent only....
    //   this.updateNameDetails();
    //   this.displayChange.emit(false);
    //   alert("User details updated successfully.");
    // }else{
    //   var selectedTeamDetails = this.teams.filter(obj => {
    //     return obj.value === this.team
    //   });
    //   console.log(selectedTeamDetails);
    //   this.updateTeamDetails();
    //   this.displayChange.emit(false);
    //   alert("User details updated successfully.");
    // }
    
}
/** update agent name only... */
updateNameOfAgent(){
  /** update name under team details... */
  this.updateUserObjectName();
  if(this.currentTeamId){
    const teamRefs = firebase.database().ref('teams').child(this.currentTeamId);
    let teamPath =  teamRefs.child('members');
    teamPath = teamPath.child(this.agentId);
    const teamdetails = {firstName: this.firstName,lastName: this.lastName};
    teamPath.update(teamdetails);
  }
  
  /** update subscrition name... */
  this.updateSubscriberName();
  this.updateMonthlyBudget();
}
/**update manager name only... */
updateNameOfManager(){
  this.updateUserObjectName();
  this.updateSubscriberName();
  this.updateMonthlyBudget();
  this.updateLeaderName();
  this.updateCreatorName();
  this.updateNameMembersDetails();
}
/**update name only in user objct */
updateUserObjectName(){
   let namePayload = {firstName: this.firstName, lastName: this.lastName, role: this.selectedRole};
   this._sharedService.updateUserInfoById(this.agentId, namePayload);
}
/**update agent role to manager for current team... */
updateAgentToManager(){
  let namePayload = {firstName: this.firstName, lastName: this.lastName, role: this.selectedRole, managerId: this.agentId, managerName: this.firstName +' '+ this.lastName};
  this._sharedService.updateUserInfoById(this.agentId, namePayload);
  /**remove him from member list of current team... */
  if(this.team){
    this.updateCurrentLeadTeam();
    this.removeAgentFromCurrentTeam();
    this.updateTeamLead();
    this.updateLeadDetailsByTeam();
  }
  this.updateSubscriberName();
  this.updateMonthlyBudget();
}
/** update manager to agent */
updateMangerToAgent(){
  let namePayload = {firstName: this.firstName, lastName: this.lastName, role: this.selectedRole, managerId: '', managerName: '', teamId: '', teamName: 'unassigned'};
  this._sharedService.updateUserInfoById(this.agentId, namePayload);
  this.removeAllTeamLead();
  this.updateSubscriberName();
  this.updateMonthlyBudget();
}
updateCurrentLeadTeam(){
  let teamMembers = this.af.list('users',ref => ref.orderByChild('teamId').equalTo(`${this.team}`)).snapshotChanges();
    teamMembers.map(actions => 
      actions.map(a => ({ key: a.key, ...a.payload.val() }))
    ).subscribe(members => {
      members.map((member)=>{
        let userId = member.key;
        if(userId != this.agentId && member['role'] == 'manager'){
          let userPayLoad = { teamId: '', teamName: '' };
          this._sharedService.updateUserInfoById(userId, userPayLoad);  
        }
      });
    });
}
removeAgentFromCurrentTeam(){
  const teamRefs = firebase.database().ref('teams').child(this.currentTeamId);
  let teamPath =  teamRefs.child('members');
  teamPath = teamPath.child(this.agentId);
  teamPath.remove();
}
updateTeamLead(){
  const teamRefs = firebase.database().ref('teams').child(this.currentTeamId);
  let teamPath =  teamRefs.child('lead');
  const leadDetails = {firstName: this.firstName, lastName: this.lastName, id: this.agentId};
  teamPath.update(leadDetails);
}

//update manager name details for every user which is lead by current agent.
updateLeadDetailsByTeam(){
  let teamMembers = this.af.list('users',ref => ref.orderByChild('teamId').equalTo(`${this.team}`)).snapshotChanges();
  teamMembers.map(actions => 
    actions.map(a => ({ key: a.key, ...a.payload.val() }))
  ).subscribe(members => {
    members.map((member)=>{
      let userId = member.key;
      let userPayLoad = { managerId: this.agentId, managerName: this.firstName +' '+this.lastName };
      this._sharedService.updateUserInfoById(userId, userPayLoad);
    });
  });
}

/**remove mangers all team lead... */
removeAllTeamLead(){
  let teamList = this.af.list('teams',ref => ref.orderByChild('lead/id').equalTo(`${this.agentId}`)).snapshotChanges();
  teamList.map(actions => 
    actions.map(a => ({ key: a.key, ...a.payload.val() }))
  ).subscribe(teams => {
      teams.map((team)=>{
         let teamId =  team.key;
         const teamRef = firebase.database().ref('teams').child(teamId);
         const namePath = teamRef.child('lead');
         namePath.remove();
         /**remove mgr id from all members of current team */
         this.removeAllTeamMembersManger(teamId);
      });
  });
}
removeAllTeamMembersManger(Teamid){
  let teamMembers = this.af.list('users',ref => ref.orderByChild('teamId').equalTo(`${this.team}`)).snapshotChanges();
  teamMembers.map(actions => 
    actions.map(a => ({ key: a.key, ...a.payload.val() }))
  ).subscribe(members => {
    members.map((member)=>{
      let userId = member.key;
      let userPayLoad = { managerId: '', managerName: '' };
      this._sharedService.updateUserInfoById(userId, userPayLoad);
    });
  });
}




  updateTeamDetails(){
    var currentTeamDetails = this.teams.filter(obj => {
      return obj.value === this.currentTeamId
    });
    var selectedTeamDetails = this.teams.filter(obj => {
      return obj.value === this.team
    });
    
    //** remove from exisiting team and role. */
    /**set existing team path as per existing role to remove*/
    if(this.currentTeamId){
      const existingTeamRef = firebase.database().ref('teams').child(this.currentTeamId);
      let exitingTeamPath;
      if(this.currentRole == 'manager'){
        exitingTeamPath = existingTeamRef.child('lead');
        exitingTeamPath.remove();
        // remove current manager team details from user object
        this._sharedService.getUserInfoById(this.agentId).then((result)=>{
          if(result){
            if(result.teamId == this.currentTeamId){
              let mgrPayload = {teamId: '', teamName: ''};
              this._sharedService.updateUserInfoById(this.agentId, mgrPayload);
            }
          }
        });
        //remove all team members manager details from current team...
          this.removeMembersManger();
      }else{
        exitingTeamPath =  existingTeamRef.child('members');
        exitingTeamPath = exitingTeamPath.child(this.agentId);
        exitingTeamPath.remove();
      } 
      /**end of remove team details.... */
    }
    
    if(this.team){
       /**update to selected team */
        /**set selected team path as per selected  role and team to update*/
        const teamRef = firebase.database().ref('teams').child(this.team);
        let selectedTeamPath;
        let payload;
        if(this.selectedRole == 'manager'){
          payload = {id: this.agentId, firstName: this.firstName, lastName: this.lastName};
          selectedTeamPath = teamRef.child('lead');
          selectedTeamPath.update(payload).then(()=>{
            //update each members user object for new manager details.
            this.updateMembersDetails();
            this.updateNameDetails();
          });
        }else{
          selectedTeamPath =  teamRef.child('members');
          selectedTeamPath = selectedTeamPath.child(this.agentId);
          payload = {id: this.agentId, firstName: this.firstName, lastName: this.lastName, createdDttm: Date.now()};
          selectedTeamPath.update(payload).then(()=>{
            //update current agents team detail in user object....
            this.updateCurrentUser(selectedTeamDetails);
            this.updateNameDetails();
          });
        }
        /** end of update flow..... */
    }
   
  }
  /** update current user team details in user object. */
  updateCurrentUser(selectedTeamDetails){
    let userPayLoad = {teamId: selectedTeamDetails[0].value, teamName: selectedTeamDetails[0].label, managerId: selectedTeamDetails[0].managerId, managerName: selectedTeamDetails[0].managerName, teamJoinedAt: Date.now(), firstName: this.firstName, lastName: this.lastName, role: this.selectedRole};
       this._sharedService.updateUserInfoById(this.agentId, userPayLoad);
  }


  //update new manager details for every user from team.
  updateMembersDetails(){
    let teamMembers = this.af.list('users',ref => ref.orderByChild('teamId').equalTo(`${this.team}`)).snapshotChanges();
    teamMembers.map(actions => 
      actions.map(a => ({ key: a.key, ...a.payload.val() }))
    ).subscribe(members => {
      members.map((member)=>{
        let userId = member.key;
        let userPayLoad = { managerId: this.agentId, managerName: this.firstName +' '+this.lastName };
        this._sharedService.updateUserInfoById(userId, userPayLoad);
      });
    });
  }

  //remove exiting manager details for every user from existing team.
  removeMembersManger(){
    let teamMembers = this.af.list('users',ref => ref.orderByChild('teamId').equalTo(`${this.currentTeamId}`)).snapshotChanges();
    teamMembers.map(actions => 
      actions.map(a => ({ key: a.key, ...a.payload.val() }))
    ).subscribe(members => {
      members.map((member)=>{
        let userId = member.key;
        let userPayLoad = { managerId: "", managerName: "" };
        this._sharedService.updateUserInfoById(userId, userPayLoad);
      });
    });
  }

  /** update name details of user every where... */
  updateNameDetails(){
    //update selected user details...
    let namePayload = {firstName: this.firstName, lastName: this.lastName, role: this.selectedRole};
    this._sharedService.updateUserInfoById(this.agentId, namePayload);
    if(this.selectedRole == 'manager'){
      this.updateLeaderName();
      this.updateCreatorName();
      this.updateSubscriberName();
      this.updateMonthlyBudget();
      this.updateNameMembersDetails();
    }
  }


  updateCreatorName(){
    //update all team creator.....
    let createdTeams = this.af.list('teams',ref => ref.orderByChild('creator/id').equalTo(`${this.agentId}`)).snapshotChanges();
    createdTeams.map(actions => 
      actions.map(a => ({ key: a.key, ...a.payload.val() }))
    ).subscribe(teams => {
        teams.map((team)=>{
           let teamId =  team.key;
           const teamRef = firebase.database().ref('teams').child(teamId);
           const namePath = teamRef.child('creator');
           const data = {firstName: this.firstName, lastName: this.lastName};
           namePath.update(data);
        });
    });
  }

  updateLeaderName(){
    //get all lead teams......
    let teamList = this.af.list('teams',ref => ref.orderByChild('lead/id').equalTo(`${this.agentId}`)).snapshotChanges();
    teamList.map(actions => 
      actions.map(a => ({ key: a.key, ...a.payload.val() }))
    ).subscribe(teams => {
        teams.map((team)=>{
           let teamId =  team.key;
           const teamRef = firebase.database().ref('teams').child(teamId);
           const namePath = teamRef.child('lead');
           const data = {firstName: this.firstName, lastName: this.lastName};
           namePath.update(data);
        });
    });
  }

   //update manager name details for every user which is lead by current agent.
   updateNameMembersDetails(){
    let teamMembers = this.af.list('users',ref => ref.orderByChild('managerId').equalTo(`${this.agentId}`)).snapshotChanges();
    teamMembers.map(actions => 
      actions.map(a => ({ key: a.key, ...a.payload.val() }))
    ).subscribe(members => {
      members.map((member)=>{
        let userId = member.key;
        let userPayLoad = { managerId: this.agentId, managerName: this.firstName +' '+this.lastName };
        this._sharedService.updateUserInfoById(userId, userPayLoad);
      });
    });
  }
  updateSubscriberName(){
    //  update name into subscribers object
    const subRef = firebase.database().ref('subscribers').child(this.admin);
    const subPath = subRef.child(this.agentId);
    const subData = {firstName: this.firstName, lastName: this.lastName};
    subPath.update(subData);
  }

  // update monthly budget
  updateMonthlyBudget(){
    if(this.monthlyBudget && !this.managedLead){
      const subRef = firebase.database().ref('leadPurchaseSettings').child(this.agentId);
      const data = {monthlyBudget: this.monthlyBudget};
      subRef.update(data);
      const userRef = firebase.database().ref('users').child(this.agentId);
      userRef.update({leadBudget: this.monthlyBudget});
    }
  }
  ngOnDestroy() {
    // this.displayChange.unsubscribe();
  }
  
  openmonthlyLeadPopup(){
    this.billingLockedPopup = false;
    this.monthlyLeadspendPopup = true;
  }
  ClosemonthlyLeadPopup(){
    this.monthlyLeadspendPopup = false;
  }

  openBillingLockedPopup(){
    this.monthlyLeadspendPopup = false;
    this.billingLockedPopup = true;
  }
  closeBillingLockedPopup(){
    this.billingLockedPopup = false;
  }
}
