import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from '../../../../shared/shared.service';
import * as firebase from 'firebase/app';
import {Subject} from 'rxjs/Subject';
@Component({
  selector: 'app-billing-method',
  templateUrl: './billing-method.component.html',
  styleUrls: ['./billing-method.component.scss']
})
export class BillingMethodComponent implements OnInit {
  @Input() planType: string;
  display: boolean = false;
  show: boolean = false;
  userDetails:any;
  creditCardBox : boolean= true;
  achBox: boolean=false;
  cardHolderName: string = "";
  cardNumber: string = "";
  expDate: string = "";

  accountNickName: string = "";
  accountNumber: string = "";
  routingNumber: string = "";
  uid: string;
  successcommon: boolean = false;
  successHeader: string = "";
  successMessage: string = "";
  achName: string = "";
  achNumber: string = "";
  achRoutingNumber: string = "";
  accountErrorMessage: string = "";
  onOpenCard: Subject<boolean> = new Subject();
  onCloseCard: Subject<boolean> = new Subject();
  constructor(private _shared: SharedService) {
    this.uid = this._shared.user.uid;
  }

  ngOnInit() {
   this._shared.getUserInfo().then((result) => {
        if (result) {
            this.userDetails = result;
        }});

    const stripePath = "/stripe/"+this.uid; 
    const billingRef = firebase.database().ref(stripePath);
    billingRef.once('value').then(snapshot => {
        const result = snapshot.val();
        if(result){
          const resultArrayKeys = Object.keys(result);
          resultArrayKeys.forEach(key => {
            if(key=='card'){
              this.creditCardBox =true;
              this.cardHolderName = `${this.userDetails.firstName} ${this.userDetails.lastName}`;this.cardNumber = result[key].last4;
              this.expDate = `${result[key].expiryMonth}/${result[key].expiryYear}`
            }else{
              this.creditCardBox = false;
              this.achBox =true;
              this.accountNickName = result[key].accountName
              this.accountNumber = result[key].last4;
              this.routingNumber = result[key].routingNumber;
            }

          })
        }
     });

  }
  showCard() {
    // this.display = true;
    this.creditCardBox =true;
    this.achBox = false;
  }
  showAch() {
    // this.show = true;
    this.creditCardBox =false;
    this.achBox =true;
  }
  editDetails(){
    this.creditCardBox ? this.display = true : this.show = true;
    if(this.creditCardBox){
      this.display = true;
      this.onOpenCard.next(true);
    }else{
      this.show = true;
    }
  }
  onCardUpdate(event){
    if(event){
      this.display = false; 
      this.successHeader = "Card Details";
      this.successMessage = "Card details has been updated.";
      this.successcommon = true;
    }else{
      this.display = false; 
    }
  }
  onCardClose(){
    this.onCloseCard.next(true);
  }
  onSaveAch(){
    let validate = this.validateAch();
    console.log(validate);
    if(validate){
      this.updateAccountDetails();
    }
  }
  updateAccountDetails(){
    this.accountErrorMessage = "Loading....";
    let accountDetails = [];
    accountDetails['accountName'] = this.achName;
    accountDetails['accountNumber'] = this.achNumber;
    accountDetails['routingNumber'] = this.achRoutingNumber;
    this._shared.updateAccountDetails(accountDetails).then((result) =>{
      if(result.token){
        this.show = false; 
        this.clearAch();
        this.successHeader = "Account Details";
        this.successMessage = "Account details has been updated.";
        this.successcommon = true;
      }
      if(result.error){
          this.accountErrorMessage = result.error.message;
      }
    });
  }

  onSuccesscommonClose(ev){
    this.successcommon =false;
  }

  validateAch(){
    this.accountErrorMessage = "";
    if(!this.achNumber){
      this.accountErrorMessage = "Please enter account number.";
      return false;
    }
    if(!this.achRoutingNumber){
      this.accountErrorMessage = "Please enter routing number.";
      return false;
    }
    return true;
  }
  allowNumbersOnly(e) {
    var code = (e.which) ? e.which : e.keyCode;
    if (code > 31 && (code < 48 || code > 57)) {
        e.preventDefault();
    }
  }
  cancelAch(){
    this.clearAch();
    this.show = false;
  }
  clearAch(){
    this.accountErrorMessage = "";
    this.achName = "";
    this.achNumber = "";
    this.achRoutingNumber = "";
  }
}
