import { Component, OnInit, Input, Output, EventEmitter, ViewChild, NgModule, ChangeDetectorRef, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { AngularFireDatabase, AngularFireList} from 'angularfire2/database';
import { SharedService } from '../../../shared/shared.service';
import * as firebase from 'firebase/app';
import { Observable} from 'rxjs';
import { Fieldset, InputMask, InputMaskModule} from 'primeng/primeng';

@Component({
  selector: 'app-select-plan-dialog',
  templateUrl: './select-plan-dialog.component.html',
  styleUrls: ['./select-plan-dialog.component.scss'],
})
export class SelectPlanDialogComponent implements OnInit {
  @Input() display: boolean;
  @Output() displayChange = new EventEmitter();
  @Input() rdoPlan: string = 'planA';
  @Input() updatePlanCall: boolean = false;
  loading: boolean = false;
  creditCardAdded: boolean = false;
  cardNumber: string;
  cardNumberOne: string;
  cardNumberTwo: string;
  cardNumberThree: string;
  cardNumberFour: string;
  expiryMonth: string;
  expiryYear: string;
  cvc: string;
  addressZip: string;
  cardOne: string;
  cardTwo: string;
  cardThree: string;
  cardFour: string;
  successMessage: string;
  successHeader: string;
  successcommon: boolean = false;
  isSelectPlanTab: boolean = false;
  @ViewChild('myInputMask') myInputMask: InputMask;
  cardHandler = this.cardOnChange.bind(this);
  @ViewChild('cardInfo') cardInfo: ElementRef;
  @ViewChild('expiry') expiry: ElementRef;
  @ViewChild('ccv') ccv: ElementRef;
  @ViewChild('zip') zip: ElementRef;
  card: any;
  cardExpiry: any;
  cardCcv: any;
  cardZip: any;
  message: string;
  accountName: string = "";
  accountNumber: string = "";
  routingNumber: string = "";
  remainingDays: number = 0;
  planARate: number = 129;
  planBRate: number = 99;
  uid: string;
  billingDate: string;
  // rdoPlan: boolean;
  cardDestroyed: boolean = true;
  closable: boolean = true;
  constructor(private formBuilder: FormBuilder, private _sharedService: SharedService, private af: AngularFireDatabase,  private cd: ChangeDetectorRef) {
    this.uid = this._sharedService.user.uid;
  }
  onOpen(){
    if(!this.isSelectPlanTab && !this.updatePlanCall){
      this.mountCardsField();
    }
    
 }
  ngAfterViewInit() {
   }
   mountCardsField(){
    const style = {
      base: {
        color: '#32325d',
        lineHeight: '18px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        background: '#fcfcfc',
        border:' 1px solid #ccc',
        padding: '8px',
        borderRadius:'3px!important',
        height: '41px !important',
        width: '100% !important',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };
    this.card = elements.create('cardNumber', {style: style});
    this.cardExpiry = elements.create('cardExpiry',{style: style});
    this.cardCcv = elements.create('cardCvc',{style: style});
    this.cardZip = elements.create('postalCode',{style: style});
    this.card.mount(this.cardInfo.nativeElement);
    this.cardExpiry.mount(this.expiry.nativeElement);
    this.cardCcv.mount(this.ccv.nativeElement);
    this.cardZip.mount(this.zip.nativeElement);
    this.cardDestroyed = false;
  } 
  ngOnInit() {
    this._sharedService.getUserInfo().then((user)=>{
      this.billingDate = user.billingDate;
      if(user.billingDate && !this.updatePlanCall && user.planType!='planZ'){
        this.remainingDays = this._sharedService.differenceDay(user.billingDate);
        this.remainingDays = this.remainingDays >= 0 ? this.remainingDays : 0;
      }
      if(user.planType == 'planZ' && !this.updatePlanCall){
        this.remainingDays = this._sharedService.differenceDay(user.trialExpired);
        this.remainingDays = this.remainingDays >= 0 ? this.remainingDays : 0;
      }
      if(this.remainingDays<=0){
        this.closable = false;
      }else{
        this.closable = true;
      }
    });
    this._sharedService.getAdminInfo().then((admin)=>{
       this.planARate = admin.discountApplied ? this.planARate - 10 : this.planARate;
       this.planBRate = admin.discountApplied ? this.planBRate - 10 : this.planBRate;
    });
  }
  cardOnChange({ error }) {
    if (error) {
       console.log(error);
    } else {
      // this.error = null;
    }
    // this.cd.detectChanges();
  }
  onClose(ev){
    console.log(ev);
    if(ev){
      // if(this.rdoPlan && this.creditCardAdded){
      if(this.rdoPlan){
        // this.updatePlanDetails();
        if(this.isSelectPlanTab && !this.updatePlanCall){
          const validate = this.validateAch();
          if(validate){
            this.updateAccountDetails();
          }
        }
        if(!this.isSelectPlanTab && !this.updatePlanCall){
          this.updateCardDetails();
        }
        if(this.updatePlanCall){
          this.updatePlan();
        }
      }
    }else{
      
        if(!this.isSelectPlanTab){
          if(this.card && !this.cardDestroyed){
            this.clearCardFields(); 
            this.unmountCardField();
            this.card = null;
          }
        }
          this.clearAch();
          this.displayChange.emit(ev);
          this.display = false;
        }
      
  }
  
    /** update card details.... */
    updateCardDetails(){
      this.message = 'Loading...';
      this._sharedService.updateCardDetails(this.card).then((res)=>{
        if(res.token){
          this.message = `Success! Card token.`;
          this.clearCardFields(); 
          this.updatePlan();
          this.displayChange.emit(true);
          this.unmountCardField()
        }
        if(res.error){
          this.message = res.error.message;
          // this.displayChange.emit(false);
          this.cd.detectChanges();
        }
      });
    }
    updateAccountDetails(){
      this.message = "Loading....";
      let accountDetails = [];
      accountDetails['accountName'] = this.accountName;
      accountDetails['accountNumber'] = this.accountNumber;
      accountDetails['routingNumber'] = this.routingNumber;
      this._sharedService.updateAccountDetails(accountDetails).then((result) =>{
        if(result.token){
          this.updatePlan();
          this.clearAch();
          this.displayChange.emit(true);
          this.unmountCardField();
        }
        if(result.error){
            this.message = result.error.message;
        }
      });
    }
    updatePlan(){
      const rate = this.rdoPlan == 'planA' ? this.planARate : this.planBRate;
      const payload = {planType: this.rdoPlan, rate: rate, adjustedRate: rate,unpaidByAdminDate: ""};
      this._sharedService.updateUserInfo(payload).then(()=>{
        this.updateBillingLog();
      });
      this.successMessage = 'Plan has been updated successfuly.';
      this.successHeader = "Plan Updated";
      this.successcommon = true;
      if(this.updatePlanCall){
        this.displayChange.emit(true);
      }
    }
    updateBillingLog(){
      const rate = this.rdoPlan == 'planA' ? this.planARate : this.planBRate;
      const date = this.billingDate ? this.billingDate : this._sharedService.billingDate(Date.now());
      // const billingDate = this._sharedService.billingDate(date);
      if(this.updatePlanCall){
        this._sharedService.cancelAllBillingLog().then((res)=>{
          this._sharedService.updateBillingLog(this.uid, date, rate);
        });
      }else{
        this._sharedService.updateBillingLog(this.uid, date, rate);
      }
      /**to do cut amount if billing date is less than today.... */
    }
    clearCardFields(){
      if(!this.isSelectPlanTab && !this.cardDestroyed){
        this.card.clear();
        this.cardExpiry.clear();
        this.cardCcv.clear();
        this.cardZip.clear();
        this.message = "";
      }
    }
    clearAch(){
      this.message = "";
      this.accountName = "";
      this.accountNumber = "";
      this.routingNumber = "";
    }
    validateAch(){
      this.message = "";
      if(!this.accountNumber){
        this.message = "Please enter account number.";
        return false;
      }
      if(!this.routingNumber){
        this.message = "Please enter routing number.";
        return false;
      }
      return true;
    }
    unmountCardField(){
      if(!this.isSelectPlanTab && !this.cardDestroyed){
        this.card.destroy();
        this.cardExpiry.destroy();
        this.cardCcv.destroy();
        this.cardZip.destroy();
        // this.card = null;
        this.cardDestroyed = true;
      }
    }
    ngOnDestroy() {
      // this.unmountCardField();
      // this.displayChange.unsubscribe();
    }
    showSelectPlanTab(ev){
      this.message = "";
      this.cd.detectChanges();
      if(ev){
        if(this.card._empty){
          this.unmountCardField();
          this.card = null;
          this.isSelectPlanTab = ev;
        }else{
          this.message = "Please clear card details switch to ach.";
          this.cd.detectChanges();
        }
      }
      if(!ev){
        if(!this.accountNumber && !this.routingNumber){
          this.isSelectPlanTab = ev;
           if(!this.card){
             this.accountName = "";
             this.cd.detectChanges();
             this.mountCardsField();
           }
        }else{
            this.message = "Please clear account details switch to card.";
            this.cd.detectChanges();
        }
      }
    }

    /**old code not used */
    updatePlanDetails(){  
        // this.message = 'Loading...';
        const validate = this.validation();
        if(validate){
          (<any>window).Stripe.card.createToken({
            number: this.cardNumber,
            exp_month: this.expiryMonth,
            exp_year: this.expiryYear,
            cvc: this.cvc,
            address_zip: this.addressZip,
          }, (status: number, response: any) => {
            console.log(status);
            console.log(response);
            if (status === 200) {
              this.display = false;
              this.displayChange.emit(true);
              
              this.message = `Success! Card token ${response.card.id}.`;
              const amount = this.rdoPlan == 'planA' ? 129 : 99;
              return this._sharedService.updateStripeDetails(response, amount).then((result) => {
                // update details into user object...
                const rate = this.rdoPlan == 'planA' ? '129' : '99';
                const payload = {planType: this.rdoPlan, rate: rate};
                this._sharedService.updateUserInfo(payload).then(()=>{
                });
                this.successMessage = 'Plan has been updated successfuly.';
                this.successHeader = "Plan Updated";
                this.successcommon = true;
                // alert('Plan has been updated successfuly.');  
                this.clearItems();
              });
              
            } else {
              // alert (response.error.message);
              this.message = response.error.message;
              this.cd.detectChanges();
            }
          });
        }
        
      }
      validation(){
        if(!this.cardNumber){
          this.message = "Please enter card details.";
          return false;
        }
        if(this.cardNumber.length < 16){
          this.message = "Incomplete card details.";
          return false;
        }
        if(!this.expiryMonth){
          this.message = "Please enter expiry month.";
          return false;
        }
        if(!this.expiryYear){
          this.message = "Please enter expiry year.";
          return false;
        }
        if(!this.cvc){
          this.message = "Please enter CCV.";
          return false;
        }
        if(!this.addressZip){
          this.message = "Please enter zip code.";
          return false;
        }
        return true;
      }
      clearItems(){
        this.loading = false;
        this.creditCardAdded = false;
        this.cardNumber = '';
        this.cardOne = '';
        this.cardTwo = '';
        this.cardThree = '';
        this.cvc = '';
        this.cardFour = '';
        this.expiryMonth = '';
        this.expiryYear = '';
        this.addressZip = '';
        this.message = '';
      }
      isInputMaskFilled(event) {
        if(!this.myInputMask.filled) {
          this.cardNumber =  '';
        }else{
          this.cardNumber = this.myInputMask.value;
        }
      }
      fillCardNumber(){
        this.cardNumber = "";
        this.cardNumber = this.cardNumber.concat(this.cardOne?this.cardOne:'',this.cardTwo?this.cardTwo:'', this.cardThree?this.cardThree:'', this.cardFour?this.cardFour:'');
      }
      onSuccesscommonClose(ev){
        this.successcommon = false;
      }
  // Work against memory leak if component is destroyed
  logOut(ev){
        this._sharedService.logout();
      }
}
