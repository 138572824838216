import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as firebase from 'firebase/app';
import { AngularFireDatabase, AngularFireList} from 'angularfire2/database';
import { Observable} from 'rxjs';
@Component({
  selector: 'common-success-dialog',
  templateUrl: './common-success-dialog.component.html',
  styleUrls: ['./common-success-dialog.component.scss']
})
export class CommonSuccessDialogComponent implements OnInit {
  @Input() successcommon: boolean;
  @Input() message: string;
  @Input() popupHeader: string;
 // successcommon =false;
 @Output() dialogToggle = new EventEmitter();
  constructor() {

  }

  ngOnInit() {

  }
 

  onClose(){
    this.dialogToggle.emit(false);
    // this.successcommon =false;
  }



  ngOnDestroy() {
  }





}
