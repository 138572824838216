import { NgModule } from '@angular/core';
import { NewclientComponent } from './newclient.component';
import { NewclientRoutingModule } from './newclient-routing.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { RadioButtonModule } from 'primeng/primeng';
import { SharedFileModule } from '../shared/shared.module';
@NgModule({
  imports: [RadioButtonModule, FormsModule, CommonModule, NewclientRoutingModule, SharedFileModule],
  declarations: [NewclientComponent],
  exports: [NewclientComponent]
})
export class NewclientModule {}
