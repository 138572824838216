import { NgModule } from '@angular/core';
import { ImportclientsComponent } from './importclients.component';
import { ImportclientsRoutingModule } from './importclients-routing.module';
import { SharedFileModule } from '../shared/shared.module';
import { NewDialogComponent } from './dialog-component/new-dialog/new-dialog.component';
import { DialogModule, SliderModule } from 'primeng/primeng';
import { CommonModule } from '@angular/common';
import { FormsModule , ReactiveFormsModule} from '@angular/forms';

@NgModule({
  imports: [ImportclientsRoutingModule, SharedFileModule, DialogModule, FormsModule, ReactiveFormsModule, CommonModule],
  declarations: [ImportclientsComponent, NewDialogComponent],
  exports: [ImportclientsComponent]
})
export class ImportclientsModule {}
