import { Component, OnInit } from '@angular/core';
import { FormsModule }        from '@angular/forms';
import { CommonModule }       from '@angular/common';
import { SharedService } from '../shared/shared.service';
import {AngularFireDatabase, AngularFireList} from 'angularfire2/database';

@Component({
  moduleId: module.id,
  selector: 'newclient',
  templateUrl: 'newclient.component.html',
  styleUrls: ['newclient.component.css'],
})
export class NewclientComponent implements OnInit {

firstName : string;
lastName : string;
age : number = null;
gender : string = "Unspecified";
lenderBool : boolean = false;
email : string = " ";
phone : string = " ";
creditRating : string = "Excellent";
timeToBuy : string = "Very Soon 0-3 Months";
userClientRef : AngularFireList<any>;
uid : string;

   
constructor(private af : AngularFireDatabase, private _shared : SharedService) { }

submit()
{
     this.newClient(this.firstName , this.lastName, this.age, this.gender, this.lenderBool, this.email, this.phone, this.creditRating, this.timeToBuy).then(
        (result) =>{},
        (error)=>{alert("There was an error")});

}

log()
{
}

newClient(firstName : string, lastName : string, age : number = null, gender : string = "", lenderBool : boolean = false, email: string, phone : string = "", creditRating : string = "", timeToBuy: string = "")
{
    return this.userClientRef.push({firstName : firstName, lastName : lastName, age: age, gender : gender, lenderBool : lenderBool, email: email, phone : phone, creditRating : creditRating, timeToBuy : timeToBuy, assignedTo : this.uid, status: "New Lead", userGenerated: true, active : true})
    .then((response) => {}, (error)=>{console.log(error)})
    
}

  ngOnInit() {

    this.uid = this._shared.user.uid;
    let path = "/userGeneratedLeads/" + this.uid;
    this.userClientRef = this.af.list(path);
  }

}
