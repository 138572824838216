import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as firebase from 'firebase/app';
import { AngularFireDatabase, AngularFireList} from 'angularfire2/database';
import { SharedService } from '../../../shared/shared.service';
import { Observable} from 'rxjs';
@Component({
  selector: 'app-admin-connected-dialog',
  templateUrl: './admin-connected-dialog.component.html',
  styleUrls: ['./admin-connected-dialog.component.scss']
})
export class AdminConnectedDialog implements OnInit {
  @Output() displayChange = new EventEmitter();
  @Input() display: boolean;
  @Input() adminName: string;
  // invite: boolean = false;
 
  constructor(private _sharedService: SharedService, private af: AngularFireDatabase) {

  }

  ngOnInit() {
  
  }
 
  onClose(ev){
    this.display = false;
    this.displayChange.emit(ev);
  }







}
