import { style } from '@angular/animations';
import { Directive, Input, ElementRef, OnChanges } from '@angular/core';

@Directive({
  selector: '[appProgressBar]'
})
export class ProgressBarDirective implements OnChanges {
  @Input() progressValue;
  constructor(private element: ElementRef) {}
  ngOnChanges() {
    this.progressValue > 100 ? (this.progressValue = 0) : this.progressValue;
    this.element.nativeElement.style.width = this.progressValue + '%';
  }
}
