import { Component, OnInit, Input } from '@angular/core';
import { Observable, Timestamp, Subscription } from 'rxjs';
import * as firebase from 'firebase/app';

import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
  providers: [ConfirmationService]
})
export class ActivityComponent implements OnInit {
  @Input() clientDetails:any;
  clientId: string;
  favoriteArray = [];
  tourArray = [];
  showFavorite: boolean = true;
  showTours: boolean = false;
  confirmDeletion: boolean = false;
  statusTypes = [{ label: "New Lead", value: 'New Lead' }, { label: "Qualified", value: 'Qualified' }, { label: "Offers Pending", value: 'Offers Pending' }, { label: "Closing", value: 'Closing' }, { label: "Sold", value: 'Sold' }, { label: "Not Looking", value: 'Not Looking' }];
  constructor(private confirmationService: ConfirmationService) {
    
   }

  ngOnInit() {
    this.clientDetails.subscribe((res: any)=>{
      if(res){
        this.clientId =  res.key;
        this.getFavoriteList();
        this.getTourList();
      }
    }
    )
    
  }

  getFavoriteList(){
    if(this.clientId){
      const userRefs = firebase.database().ref('favorite');
      const userRef = userRefs.child(this.clientId).child('properties');
      userRef.on('value',(snapshot: any) => {
      const result = snapshot.val();
      if(result){
        this.favoriteArray = Object.entries(result).map(item => ({...item[1], key: item[0]}));
      }
      });
      
    }
  }
  getTourList(){
    if(this.clientId){
      const userRefs = firebase.database().ref('tours');
      const userRef = userRefs.child(this.clientId);
      userRef.on('value',(snapshot: any) => {
      const result = snapshot.val();
      if(result){
        this.tourArray = Object.entries(result).map(item => ({...item[1], key: item[0]}));
      }
      });
      
    }
  }
  swichTab(flag:any){
    switch (flag){
      case 0: {
          this.showFavorite = true;
          this.showTours = false;
          break;
      }
      case 1:{
          this.showTours = true;
          this.showFavorite = false;
          break;
      }
    } 
  }
  removeTour(tourId: any){
    if(tourId){
      this.confirmationService.confirm({
        message: 'Are you sure that you want to remove property?',
        header: 'Tours',
        icon: 'pi pi-exclamation-triangle',
        key: "tourDialog",
        accept: () => {
          const tourPath = firebase.database().ref('tours');
          const tourRef = tourPath.child(this.clientId).child(tourId);
          tourRef.remove().then((res)=> {
            this.showSuccess('Tours');
          });
        },
        reject: () => {
           
        }
    });
      
    }
  }
  removeFavorite(favId: any){
    if(favId){
      this.confirmationService.confirm({
        message: 'Are you sure that you want to remove property?',
        header: 'Favorite',
        icon: 'pi pi-exclamation-triangle',
        key: "favDialog",
        accept: () => {
          const favPath = firebase.database().ref('favorite');
          const favRef = favPath.child(this.clientId).child('properties').child(favId);
          favRef.remove().then((res)=> {
            this.showSuccess('Favorite');
          });

        },
        reject: () => {
           
        }
    });
      
    }
  }
  showSuccess(title: string){
    this.confirmationService.confirm({
      message: 'Property has been removed.',
      header: title,
      icon: 'pi pi-exclamation-triangle',
      key: "sucessDialog",
      accept: () => {
      
      }
  });
  }

  phoneFormatter(phone: any){
    const formatedNumber = phone.match(new RegExp('.{1,4}$|.{1,3}', 'g')).join("-");
    return formatedNumber;
  }
}

