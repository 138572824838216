import { Injectable, OnInit } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject
} from 'angularfire2/database';
import { MessagesService } from '../shared/messages.service';

// Do not import from 'firebase' as you'd lose the tree shaking benefits
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { Router } from '@angular/router';

@Injectable()
export class SharedService implements OnInit {
  public user: firebase.User;
  public userInfo: AngularFireObject<any>;
  public userInfoObj: Observable<any>;
  public authState$: Observable<any>;
  public subscriptionStatus = false;
  public currentPlan = '';
  public planID = '';
  public brokerPicture = '';

  stripeRef = firebase.database().ref('stripe');
  storageRef = firebase.storage().ref();

  userRef = firebase.database().ref('users');
  billingRef = firebase.database().ref('billing');
  billingHistoryRef = firebase.database().ref('billingHistory');

  constructor(
    private messageService: MessagesService,
    private af: AngularFireDatabase,
    private router: Router,
    private afAuth: AngularFireAuth
  ) {
    this.user = null;
    this.authState$ = afAuth.authState;

    this.authState$.subscribe((user: firebase.User) => {
      this.user = user;
      if (this.user) {
        const path = '/users/' + this.user.uid;
        this.userInfo = this.af.object(path);
        this.userInfoObj = this.userInfo.valueChanges();
      }
    });
  }

  pushMessage(detail: string, severity: string = 'warn', summary: string = '') {
    this.messageService.msgs.push({
      severity: severity,
      summary: summary,
      detail: detail
    });
  }

  register(username, password) {
    return this.afAuth.auth
      .createUserWithEmailAndPassword(username, password)
      .then(
        result => {
          //return true;
          return {userId:result.user.uid, status:true, error:null};
        },
        error => {
          //console.log(error);
          //return false;
          return {userId:null, status:false, error:error};
        }
      );
  }

  sendVerificationEmail(){
    var user = firebase.auth().currentUser;
    return user.sendEmailVerification().then(function() {
      // Email sent.
      return "sent";
    }).catch(function(error) {
      // An error happened.
      return error.message;
    });
  }


  checkPlan() {
    return this.userInfo.valueChanges();
  }

  basicLogin(username: string, password: string) {
    return firebase
      .auth()
      .signInWithEmailAndPassword(username, password)
      .then(
        (response: any) => {
          return {status:true, error:null};
        },
        (error: any) => {
          return {status:false, error:error};
          //return false;
        }
      );
  }

  resetPassword(email: string) {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(
        () => {
          //alert('Password reset email has been sent.');
          return true;
        },
        (error: any) => {
          if(error.code=="auth/user-not-found"){
            alert('The email is not registered with us.');
          }else{
            alert(error.message);
            //alert('There was an error resetting your password.');
          }
          return false;
        }
      );
  }
// vinayak on 4 feb 2019
updateBilling(type: string, amount: number) {
  const uid = firebase.auth().currentUser.uid;
  const billingRef = this.billingRef.child(uid).child('monthlyFee');
  const curDate = new Date();
  const curDateNumber = curDate.getTime();
  return billingRef.set({ type: type, amount: amount, date: curDateNumber }).then(
    result => {
      return result;
    },
    error => {
      console.log(error);
    }
  );
}

getBilling() {
  const uid = firebase.auth().currentUser.uid;
  const billingRef = this.billingRef.child(uid);

  return billingRef.once('value').then((snapshot: any) => {
    const result = snapshot.val();
    return result;
  });
}

getMonthlyFeeTotal(uid){
  const billingPath = "/billing/"+uid; 
  const billingRef = firebase.database().ref(billingPath);
  return billingRef.orderByChild('type').equalTo('monthlyFee').once('value').then(snapshot => {
      const result = snapshot.val();
      return result;
 });
}


// vinayak on 4 feb 2019
updateBillingHistory(type: string, amount: any = null) {
    const uid = firebase.auth().currentUser.uid;
    const billingHistoryRef = this.billingHistoryRef.child(uid);
    const curDate = new Date();
    const curDateNumber = curDate.getTime();
    return billingHistoryRef.set({ type: type, amount: amount, date: curDateNumber }).then(
      result => {
        return result;
      },
      error => {
        console.log(error);
      }
    );
  }

  updateSubscription(plan: string, trial: any = null) {
    const uid = firebase.auth().currentUser.uid;
    const stripeRef = this.stripeRef.child(uid).child('subscribe');
    return stripeRef.set({ plan: plan, trial: trial }).then(
      result => {
        return result;
      },
      error => {
        console.log(error);
      }
    );
  }

  updateUserInfo(payload) {
    const uid = firebase.auth().currentUser.uid;
    const userRef = this.userRef.child(uid);

    return userRef.update(payload).then(
      (result: any) => {
        return result;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  updateUserInfoById(uid, payload) {
    const userRef = this.userRef.child(uid);
    return userRef.update(payload).then(
      (result: any) => {
        return result;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  insertNewUser(userId,payload) {
    const uid = userId;
    const userRef = this.userRef.child(uid);
    return userRef.update(payload).then(
      (result: any) => {
        return result;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  cancelSubscription() {
    const uid = firebase.auth().currentUser.uid;
    const stripeRef = this.stripeRef.child(uid).child('deleteSubscription');
    return stripeRef.set(true).then(
      (result: any) => {
        return result;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  /**old UI stripe Details */
  updateStripe(stripe: any, price=null) {
    if(!price){
      price = 49;
    }
    const uid = firebase.auth().currentUser.uid;
    const stripeRef = this.stripeRef.child(uid);
    return stripeRef.update({ token: stripe.id }).then(
      (result: any) => {
        // this.updateBilling('monthly fee', price);
        return result;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  /**new UI stripe Details */
  updateStripeDetails(stripe: any, price=null) {
    if(!price){
      price = 89;
    }
    const uid = firebase.auth().currentUser.uid;
    const stripeRefs = this.stripeRef.child(uid);
    const stripeRef = stripeRefs.child('card');
    const payLoad = {token: stripe.id, last4: stripe.card.last4, expiryMonth: stripe.card.exp_month, expiryYear: stripe.card.exp_year, type: stripe.type, brand: stripe.card.brand, zip: stripe.card.address_zip};
    return stripeRef.update(payLoad).then(
      (result: any) => {
        // this.updateBilling('monthly fee', price);
        return result;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  updateStripeBankDetails(stripe: any, price=null) {
    if(!price){
      price = 89;
    }

    const uid = firebase.auth().currentUser.uid;
    const stripeRefs = this.stripeRef.child(uid);
    const stripeRef = stripeRefs.child('bank_account');
    const payLoad = {token: stripe.id, last4: stripe.bank_account.last4, type: stripe.type, accountName: stripe.bank_account.account_holder_name, accountType: stripe.bank_account.account_holder_type, bankName: stripe.bank_account.bank_name, routingNumber: stripe.bank_account.routing_number, status: stripe.bank_account.status};
    return stripeRef.update(payLoad).then(
      (result: any) => {
        // this.updateBilling('monthly fee', price);
        return result;
      },
      (error: any) => {
        console.log(error);
      }
    );
  }



  getUserInfo() {
    const uid = firebase.auth().currentUser.uid;
    const userRef = this.userRef.child(uid);
    return userRef.once('value').then((snapshot: any) => {
      const result = snapshot.val();
      return result;
    });
  }
  getUserInfoById(uid) {
    const userRef = this.userRef.child(uid);
    return userRef.once('value').then((snapshot: any) => {
      const result = snapshot.val();
      return result;
    });
  }
  checkNetworkLink(firstName, lastName){
    let name = firstName+lastName;
    name = name.replace(/\s/g, "");
    const networkLink = 'https://'+environment['authDomain']+'/'+name;
    const userRef = firebase.database().ref('users');
    return userRef.orderByChild('networkLink').equalTo(networkLink).once('value').then(snapshot => {
        const result = snapshot.val();
        let linkCount = 0;
        if(result){
          const resultArrayKeys = Object.keys(result);
          resultArrayKeys.forEach(key => {
              if(key!=this.user.uid){
                linkCount += 1;
              }
          });
        }
        if(linkCount>0){
          return false;
        }else{
          return networkLink;
        }
      });
  }


  getMyClients() {
    const uid = firebase.auth().currentUser.uid;
    const myClientRef = firebase.database().ref('userGeneratedLeads/' + uid);

    return myClientRef.once('value').then((snapshot: any) => {
      const result = snapshot.val();
      return result;
    });
  }
  getAdminInfo(){
    return this.getUserInfo().then((user)=>{
        return this.getUserInfoById(user.admin).then((admin)=>{
           return admin;
        });
    })
  }
  setBrokerPicture(url){
    this.brokerPicture = url;
  }

  updateProfilePicture(base64Image: any) {
      let metadata = {
        contentType: 'image/jpeg',
        cacheControl: "public, max-age=31536000",
        type: 'image/jpeg'
      };

      const uid = this.user.uid;
      const userRef = this.userRef.child(uid);

      const imageRef = this.storageRef
        .child('users')
        .child(uid)
        .child('profilepicture.jpg');
      return imageRef.putString(base64Image, 'data_url', metadata).then(
        result => {
          console.log(result);
          const storageRef_profile = firebase.storage().ref().child('users/'+uid+'/profilepicture.jpg');
          storageRef_profile.getDownloadURL().then(
              url => this.updateUserInfo({profilePicture: url})
            );
        },
        error => {
          console.log(error);
        }
      );
  }

  updateBrokerPicture(base64Image: any) {
    let metadata = {
              contentType: 'image/jpeg',
              cacheControl: "public, max-age=31536000",
              type: 'image/jpeg'
            };

    const uid = this.user.uid;
    const userRef = this.userRef.child(uid);
    const imageRef = this.storageRef
      .child('users')
      .child(uid)
      .child('brokerpicture.jpg');

    return imageRef.putString(base64Image, 'data_url', metadata).then(
        result => {
          console.log(result);
          const storageRef_profile = firebase.storage().ref().child('users/'+uid+'/brokerpicture.jpg');
                storageRef_profile.getDownloadURL().then(
                    url => this.updateUserInfo({brokerPicture: url})
                  );
       },
        error => {
           console.log(error);
        });
    
    // return imageRef.put(image).then(
    //   result => {
    //     //const downloadURL = result.downloadURL;
    //     const downloadURL = result.ref.getDownloadURL();
    //     return userRef.update({ brokerPicture: downloadURL }).then(
    //       result => {
    //         return downloadURL;
    //       },
    //       error => {
    //         console.log(error);
    //       }
    //     );
    //   },
    //   error => {
    //     console.log(error);
    //   }
    // );
  }
  removeBrokerPicture(){
    const uid = this.user.uid;
    return this.storageRef.child('users/'+uid+'/brokerpicture.jpg').delete().then((result)=>{
      this.brokerPicture = '';
      return true;
    },
    (error)=>{
        console.log(error);
      }
    );
  }
  removeProfilePicture(){
    const uid = this.user.uid;
    return this.storageRef.child('users/'+uid+'/profilepicture.jpg').delete().then((result)=>{
      this.brokerPicture = '';
      return true;
    },
    (error)=>{
        console.log(error);
      }
    );
  }

// vinayak on 19 feb 2019
   getOrientation(file, callback) {

    var reader:any,
    target:EventTarget;
    reader = new FileReader();
    reader.onload = (event) => {

      var view = new DataView(event.target.result);

      if (view.getUint16(0, false) != 0xFFD8) return callback(-2);

      var length = view.byteLength,
        offset = 2;

      while (offset < length) {
        var marker = view.getUint16(offset, false);
        offset += 2;

        if (marker == 0xFFE1) {
          if (view.getUint32(offset += 2, false) != 0x45786966) {
            return callback(-1);
          }
          var little = view.getUint16(offset += 6, false) == 0x4949;
          offset += view.getUint32(offset + 4, little);
          var tags = view.getUint16(offset, little);
          offset += 2;

          for (var i = 0; i < tags; i++)
            if (view.getUint16(offset + (i * 12), little) == 0x0112)
              return callback(view.getUint16(offset + (i * 12) + 8, little));
        }
        else if ((marker & 0xFF00) != 0xFF00) break;
        else offset += view.getUint16(offset, false);
      }
      return callback(-1);
    };

    reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
  };

  getBase64(file, orientation, callback) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var base64 = reader.result;
      this.resetOrientation(base64, orientation, (resetBase64Image) => {
          callback(resetBase64Image);
      });
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }

  resetOrientation(srcBase64, srcOrientation, callback) {
    var img = new Image();

    img.onload = () => {
      var width = img.width,
        height = img.height,
        canvas = document.createElement('canvas'),
        ctx = canvas.getContext("2d");

      // set proper canvas dimensions before transform & export
      if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      // transform context before drawing image
      switch (srcOrientation) {
        case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
        case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
        case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
        case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
        case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
        case 7: ctx.transform(0, -1, -1, 0, height, width); break;
        case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
        default: break;
      }

      // draw image
      ctx.drawImage(img, 0, 0);

      // export base64
      callback(canvas.toDataURL());
    };

    img.src = srcBase64;
  }
// end of changes by vinayak on 19 feb 2019

  loginFB() {
    return this.afAuth.auth.signInWithRedirect(
      new firebase.auth.FacebookAuthProvider()
    );
  }

  logout() {
    return this.afAuth.auth.signOut().then(() => {
      window.location.reload(true);
    });
  }
  trialExpireDate(){
    // const freePeriod = 7;
    const freePeriod = 30;
    let trialDate = new Date();
    trialDate.setDate(trialDate.getDate() + freePeriod);
    const trialExpires = trialDate.getTime();
    return trialExpires;
  }
  renewalDate(trialExpired){
    const date = new Date(trialExpired);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const c = new Date(year + 1, month, day)
    const renew = c.getTime();
    return renew;
  }
  billingDate(startDate){
    const date = new Date(startDate);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    // const c = new Date(year, month + 1, day)
    const c = new Date(year, month, day + 30);
    const renew = c.getTime();
    return renew;
  }
  leapYear(year)
    {
      return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
    }
  toEpoch(epoch,format='datetime'){
    
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      epoch = Number(epoch);
      var date = new Date(epoch);
  
      var year = date.getFullYear();
      var month = monthNames[date.getMonth()];
      var day = date.getDate();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var seconds = date.getSeconds();
      // September 4, 2018
      return  month +" "+ day +", " + year;
    }
    /**update account details .... */
    updateAccountDetails(accountDetails){
      return stripe.createToken('bank_account', {
        country: 'US',
        currency: 'usd',
        routing_number: accountDetails.routingNumber,  //'110000000',
        account_number: accountDetails.accountNumber,  //'000123456789',
        account_holder_name: accountDetails.accountName,
        account_holder_type: 'individual',
      }).then((result) => {
        // Handle result.error or result.token
        console.log(result);
        if(result.token){
          const res = result.token;
          return this.updateStripeBankDetails(res).then((stp) => {
            this.removeStripeDetails(0);
            return result;
          });
        }
        if(result.error){
          return result;
        }
      });
    }
    updateCardDetails(cardDetails){
     return stripe.createToken(cardDetails).then((result) => {
        if(result.token){
          console.log(result.token);
          const res = result.token;
          return this.updateStripeDetails(res).then((stp) => {
             this.removeStripeDetails(1);
             return result;
          });
        }
        if(result.error){
          console.log(result);
          return result;
        }
      });
     
    }
    removeStripeDetails(flag){
      const uid = firebase.auth().currentUser.uid;
      const stripeRefs = this.stripeRef.child(uid);
      const type =  flag == 0 ? 'card' : 'bank_account';
      const stripeRef = stripeRefs.child(type);
      stripeRef.remove();
    }
    
    /**sent date is smaller than today */
    differenceDayReverse(dateToDiff) {   //Get 1 day in milliseconds   
      var one_day=1000*60*60*24;    // Convert both dates to milliseconds
      var date1_ms = Date.now();   
      var date2_ms = dateToDiff;    // Calculate the difference in milliseconds  
      var difference_ms = date1_ms - date2_ms;        // Convert back to days and return   
      return Math.round(difference_ms/one_day); 
     } 
     /**sent date is greater than today */
    differenceDay(dateToDiff) {   //Get 1 day in milliseconds   
      var one_day=1000*60*60*24;    // Convert both dates to milliseconds
      const date1 = new Date(dateToDiff);
      const date2 = new Date(Date.now());
      const year1 = date1.getFullYear();
      const month1 = date1.getMonth();
      const day1 = date1.getDate();
      const year2 = date2.getFullYear();
      const month2 = date2.getMonth();
      const day2 = date2.getDate();
      const c1 = new Date(year1, month1, day1);
      const c2 = new Date(year2, month2, day2);
      const date1Epoch = c1.getTime();
      const date2Epoch = c2.getTime();
      const dif = date1Epoch-date2Epoch;
      return Math.round(dif/one_day); 
     } 
     /**update monthly fee billing log. */
     updateBillingLog(userId, renewalDate, adjustedRate){
      const billingRef = firebase.database().ref('billing');   
      const billingRefs = billingRef.child(this.user.uid);
      const payload = {type: 'monthlyFee', amount: adjustedRate, date: renewalDate, description: 'Monthly subscription charges', payFor: userId, cancelled: false};
     return billingRefs.push(payload).then((res)=>{
         return res;
      });
    }
    /**cancel individual users monthly fee billing */
    cancelBillingLog(userId){
      const billingPath = "/billing/"+this.user.uid; 
      const billingRef = firebase.database().ref(billingPath);
      billingRef.orderByChild('type').equalTo('monthlyFee').once('value').then(snapshot => {
          const result = snapshot.val();
          const resultArrayKeys = Object.keys(result);
          resultArrayKeys.forEach(key => {
            const isBillingLater = this.billingDateGreater(result[key].date);
            if(result[key].payFor==userId && !result[key].paid && isBillingLater){
              const billingRef = firebase.database().ref('billing');   
              const billingRefs = billingRef.child(this.user.uid).child(key);
              billingRefs.update({cancelled: true});
            }
          })
        })
    }
     /**cancel all users users monthly fee billing */
     cancelAllBillingLog(){
      const billingPath = "/billing/"+this.user.uid; 
      const billingRef = firebase.database().ref(billingPath);
     return billingRef.orderByChild('type').equalTo('monthlyFee').once('value').then(snapshot => {
          const result = snapshot.val();
          const resultArrayKeys = Object.keys(result);
          resultArrayKeys.forEach(key => {
            const isBillingLater = this.billingDateGreater(result[key].date);
            if(!result[key].paid && isBillingLater){
              const billingRef = firebase.database().ref('billing');   
              const billingRefs = billingRef.child(this.user.uid).child(key);
              billingRefs.update({cancelled: true});
            }
          });
          return true;
        });
    }
    billingDateGreater(billingDate){
      const today = new Date(Date.now());
      const billDate = new Date(billingDate);
      const isGreater =  billDate > today  ? true : false;
      return isGreater;
    }
  ngOnInit() {}
}
