import { Component, OnInit, Input, Output, EventEmitter, ViewChild, NgModule, ElementRef, ChangeDetectorRef } from '@angular/core';
import { SharedService } from '../shared.service';
import {Subject} from 'rxjs/Subject';
@Component({
  selector: 'app-card-details',
  templateUrl: './card-details.component.html',
  styleUrls: ['./card-details.component.scss']
})
export class CardDetailsComponent implements OnInit {
  @Output() displayChange = new EventEmitter();
  cardHandler = this.cardOnChange.bind(this);
  @ViewChild('cardInfo') cardInfo: ElementRef;
  @ViewChild('expiry') expiry: ElementRef;
  @ViewChild('ccv') ccv: ElementRef;
  @ViewChild('zip') zip: ElementRef;
  @Input() onOpenCard: Subject<boolean>;
  @Input() onCloseCard: Subject<boolean>;
  card: any;
  cardExpiry: any;
  cardCcv: any;
  cardZip: any;
  message: string;
  cardDestroyed: boolean = true;
  constructor(private _sharedService: SharedService, private cd: ChangeDetectorRef) { 

  }

  ngOnInit() {
    this.onOpenCard.subscribe(v => { 
      this.mountCardsField();
   });
   this.onCloseCard.subscribe(v => { 
     if(!this.cardDestroyed)
      {
        this.clearCardFields(); 
        this.unmountCardField();
      }
   });
  }

  ngAfterViewInit() {
  //  this.mountCardsField();
  }
  
  mountCardsField(){
    const style = {
      base: {
        color: '#32325d',
        lineHeight: '18px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        background: '#fcfcfc',
        border:' 1px solid #ccc',
        padding: '8px',
        borderRadius:'3px!important',
        height: '41px !important',
        width: '100% !important',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };
    this.card = elements.create('cardNumber', {style: style});
    this.cardExpiry = elements.create('cardExpiry',{style: style});
    this.cardCcv = elements.create('cardCvc',{style: style});
    this.cardZip = elements.create('postalCode',{style: style});
    this.card.mount(this.cardInfo.nativeElement);
    this.cardExpiry.mount(this.expiry.nativeElement);
    this.cardCcv.mount(this.ccv.nativeElement);
    this.cardZip.mount(this.zip.nativeElement);
    this.cardDestroyed = false;
  }
     /** update card details.... */
     updateCardDetails(){
      this.message = 'Loading...';
      this._sharedService.updateCardDetails(this.card).then((res)=>{
        if(res.token){
          this.message = `Success! Card token.`;
          this.clearCardFields(); 
          this.unmountCardField();
          this.displayChange.emit(true);
        }
        if(res.error){
          this.message = res.error.message;
          // this.displayChange.emit(false);
        }
      });
    }
  cardOnChange({ error }) {
    if (error) {
       console.log(error);
    } else {
      // this.error = null;
    }
    // this.cd.detectChanges();
  }
  onClose(){
    if(!this.cardDestroyed)
    {this.clearCardFields(); 
    this.unmountCardField();
    this.displayChange.emit(false);}
  }
  ngOnDestroy() {
    // this.unmountCardField();
  }
  clearCardFields(){
    this.card.clear();
    this.cardExpiry.clear();
    this.cardCcv.clear();
    this.cardZip.clear();
    this.message = "";
  }
  unmountCardField(){
    this.card.destroy();
    this.cardExpiry.destroy();
    this.cardCcv.destroy();
    this.cardZip.destroy();
    this.cardDestroyed = true;
  }
}
