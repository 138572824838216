import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AngularFireDatabase} from 'angularfire2/database';
import { SharedService } from '../../../../shared/shared.service';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
@Component({
  selector: 'app-global-settings',
  templateUrl: './global-settings.component.html',
  styleUrls: ['./global-settings.component.scss']
})
export class GlobalSettingsComponent implements OnInit {
  uid: string;
  leadBudget: number;
  individualLead: boolean = false;
  managedLead: boolean = false;  //individual lead replaced with lead managed by admin(true/false)
  payAllMember: boolean = false;
  display: boolean = false;
  leadPopup: boolean = false;
  adjustedRate: number;
  confirmcommon: boolean = false;
  confirmationHeader = "Membership Settings";
  confirmationLeadHeader = "Lead Settings";
  confirmMessage: string = "";
  flag: number;
  planType: string = "";
  billingDate: string = "";
  constructor(private af: AngularFireDatabase, private _sharedService: SharedService, private cd: ChangeDetectorRef, private _router: Router, ) {
    this.uid = this._sharedService.user.uid;
  }
  ngOnInit() {
    this.getUserInfo();
  }
  getUserInfo(){
    this._sharedService.getUserInfo().then(user => {
      this.leadBudget = user.leadBudget ? user.leadBudget : 0;
      // this.individualLead = user.individualLead ? user.individualLead : false;
      this.managedLead = user.managedLead ? user.managedLead : false;
      this.payAllMember = user.payAllMember ? user.payAllMember : false;
      this.adjustedRate = user.adjustedRate ? user.adjustedRate : 0;
      this.billingDate = user.billingDate ? user.billingDate : '';
      this.planType = user.planType;
      // (this.leadBudget == 0 && !this.individualLead) ? this.display = true : this.display = false;
       this.showSetBudget();
    });
  }
  updateGlobalSettings(flag){
    this.flag = flag;
    if (flag == 0){

      if(this.payAllMember){
        this.confirmMessage = `You have updated your member access preferences to <span>pay for all members.</span> This means that you agree to cover the monthly subscription cost of <span>$89/member</span>. These changes will appear on your next bill as a prorated charge. By selecting continue, you understand that this changes will be applied to both existing members and future invites.`;
      }else{
        this.confirmMessage = `You have updated your member access preferences to <span>manage individually</span>. This will allow you to choose separate billing preferences for each member of your team. By selecting continue, you understand that any desired changes to existing memberships will need to be updated manually.`;
      }
    
      this.confirmcommon = true;
      // this.updateMemberAccess();
    }else{
      // this.confirmMessage = "Are you sure you want change member leads setings.";
      this.leadPopup = true;
      // this.updateMemberLeads();
    }
  }
  setBudget(){
    this.display = true;
  }

  onBudgetDialogClose(ev){
    this.display = false;
    if(ev){
      this.getUserInfo();
    }
  }
  showSetBudget(){
   
    if(this.managedLead && this.leadBudget == 0){
      this.display = true;

    }else{
      this.display = false;
    } 
  }
  onConfirmClose(ev){
    if(this.confirmcommon){
      if(ev){
        if(this.flag == 0){
          this.updateMemberAccess();
        }else{
          this.updateMemberLeads();
        }
        }else{
          this.payAllMember = !this.payAllMember;
        }
      this.cd.detectChanges()
    }
    this.confirmcommon = false;
   }

   onConfirmLeadClose(ev){
    if(this.leadPopup){
      if(ev){
          this.updateMemberLeads();
      }else{
          this.managedLead = !this.managedLead;
      }
      this.cd.detectChanges()
    }
    this.leadPopup = false;
   }



  updateMemberAccess(){
    const payload = {payAllMember: this.payAllMember};
    this._sharedService.updateUserInfoById(this.uid, payload).then(()=>{
      console.log('settings updated');
       this.updateAllMemberDetails();
     });
  }
  updateMemberLeads(){
   const payload = {managedLead: this.managedLead};
    this._sharedService.updateUserInfoById(this.uid, payload).then(()=>{
      this.showSetBudget();
      console.log('lead settings updated');
     });
  }

  /**update plan for all members under admin */
  updateAllMemberDetails(){
    let teamMembers;
    const userPath = "/users"; 
    const userRef = firebase.database().ref(userPath);
    userRef.orderByChild('admin').equalTo(this.uid).once('value').then(snapshot => {
      const result = snapshot.val();
      const resultArrayKeys = Object.keys(result);
        resultArrayKeys.forEach(key => {
          if(key != this.uid){
            const item = result[key];
            const plantTypes = this.payAllMember ? this.planType : ''; 
            const adjustedRate = this.payAllMember ? this.adjustedRate : 0;
            const payload = {planType: plantTypes, rate: adjustedRate, adjustedRate:  adjustedRate, paidByAdmin: this.payAllMember}
            if(!item.paidByAdmin && this.payAllMember){
              this.updateBillingLog(key, this.billingDate)
              this._sharedService.updateUserInfoById(key, payload);
            }
            
            // if(!this.payAllMember){
            //   const payLoadCancel =  {planType: plantTypes, rate: adjustedRate, adjustedRate:  adjustedRate, paidByAdmin: this.payAllMember,  unpaidByAdminDate: Date.now()}
            //   this._sharedService.updateUserInfoById(key, payLoadCancel);
            //   this.cancelBillingLog()
            // }
          }
        })
      });

  }

  updateBillingLog(userId, renewalDate){
    const billingRef = firebase.database().ref('billing');   
    const billingRefs = billingRef.child(this.uid);
    const payload = {type: 'monthlyFee', amount: this.adjustedRate, date: renewalDate, description: 'Monthly subscription charges', payFor: userId, cancelled: false};
    billingRefs.push(payload);
  }

  cancelBillingLog(){
    const billingPath = "/billing/"+this.uid; 
    const billingRef = firebase.database().ref(billingPath);
    billingRef.orderByChild('type').equalTo('monthlyFee').once('value').then(snapshot => {
        const result = snapshot.val();
        const resultArrayKeys = Object.keys(result);
        resultArrayKeys.forEach(key => {
          if(result[key].payFor!=this.uid){
            const billingRef = firebase.database().ref('billing');   
            const billingRefs = billingRef.child(this.uid).child(key);
            billingRefs.update({cancelled: true});
          }
        })
      })
  }


}
