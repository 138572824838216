import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AuthGuard } from '../shared/auth.guard';
import { NotificationsComponent } from './notifications.component';

@NgModule({
  imports: [
    RouterModule.forChild([{ path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard] }])
  ],
  exports: [RouterModule]
})
export class NotificationsRoutingModule {}
