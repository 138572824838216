import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ButtonModule } from 'primeng/primeng';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { SharedService } from '../shared/shared.service';
import { Options } from 'ng5-slider';
import { map, combineLatest  } from 'rxjs/operators';
import { angularListHubConfig } from './../shared/config/angularfire.config';
import * as firebase from 'firebase/app';
import * as algoliasearch from 'algoliasearch';

declare var google: any;




@Component({
  moduleId: module.id,
  selector: 'app-properties',
  templateUrl: 'properties.component.html',
  styleUrls: ['properties.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PropertiesComponent implements OnInit {
  iconBase: string;
  user: any;
  msgs: any[];
  items: any;
  display: boolean = false;
  show: boolean = false;
  billingForm: FormGroup;
  locationForm: FormGroup;
  showMap= true;
  showFilterDialog = false;
  showPropertyDialog = false;
  showSuccessDialog = false;
  savedFiltersDropdown : any;
  propertytypeDropdown :any;
  propertyType: any;
  isLoading: boolean = false;

  mapOptions: any;
  overlays: any[];
  infoWindow: any;
  val: number;
  Distancevalue: number = 123;
  minBedValue: number = 0;
  maxBedValue: number = 0;
  minbathValue: number = 0;
  maxbathValue: number = 0;
  bedOptions: Options = {
    floor: 0,
    ceil: 10,
    // step: 5
  };
  bathOptions: Options = {
    floor: 0,
    ceil: 5,
    // step: 5
  };
  propertyFilterRef: AngularFireList<any>;
  listHubListRef: AngularFireList<any>;
  filterList: Observable<any>;
  uid: string;
  // propertyList: any;
  clientPropertyRef: AngularFireList<any>;
  properties: Observable<any>;
  combined :Observable<any>;
  propertyList :Observable<any>;
  listhubApp:any;
  propertyArray: any;
  searchKey: string = "";
  searchLocation: string = "";
  mlsButtonText: string = "MLS Search";
  addressButtonText: string = "Address Search";
  propertyData:any;
  algoliaClient: any;
  algoliaIndex: any;
  circleRadius:number = 30/0.00062137;
  markers:any;
  curLat:any;
  curLng:any;
  minPrice: number;
  maxPrice: number;
  filterProp: string = "";
  constructor(private formBuilder: FormBuilder, private af : AngularFireDatabase, private _shared : SharedService) {
    'ngInject';
    if(firebase.apps.length<=1){
      this.listhubApp = firebase.initializeApp(angularListHubConfig, 'listHubs');
    }else{
      this.listhubApp = firebase.apps[1];
    }
    
///////////////
    this.algoliaClient = algoliasearch('QRVJCQ8DW7', '4489da4d6c5cd36ddeb4bd14e4f3fb5f', { protocol: 'https:' });
    this.algoliaIndex = this.algoliaClient.initIndex("properties");
    // this.algoliaIndex.search({
    //  query: 'Millcreek',
    //  })
    // .then((data)=>{
    //  const propertyData = data.hits;
    //  //alert(propertyData);
    //  //console.log(propertyData);
    //  this.propertyArray = propertyData;
    // });
    this.curLat = 40.6757205;
    this.curLng = -111.7934238;
    if (navigator)
    {
    navigator.geolocation.getCurrentPosition( pos => {
        this.curLng = +pos.coords.longitude;
        this.curLat = +pos.coords.latitude;
      });
    }

///////////////////////

    // console.log(this.app);
     // show filter values into drop down
    let path = "/propertyFilter/";
    this.propertyFilterRef = this.af.list(path);
    
    af.list('/propertyFilter').valueChanges().subscribe(items =>{
        this.savedFiltersDropdown = items;
    });
    // this.savedFiltersDropdown = [{ name: 'Iron ways' }];
    this.propertytypeDropdown = [{ name: 'Residential'}, {name: 'Commercial' }, {name: 'Land' }];

    // show my properties list.
    this.uid = this._shared.user.uid;
    let propertyListingPath = "/propertyListing/" + this.uid;
    this.clientPropertyRef = this.af.list(propertyListingPath);
    this.properties = this.clientPropertyRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key,  ...c.payload.val() }))
      )
    );
    // this.propertyList = Observable.combineLatest (this.properties, (...arrays) => arrays.reduce((acc, array) => [...acc, ...array], []));
     
    }

  ngOnInit() {
    
    this.iconBase = 'assets/images/location-icon.png';
    this.billingForm = this.formBuilder.group({
      daily_budget: '',
      monthly_budget: '',
      price: '',
      locations: this.formBuilder.array([this.createItem()])
    });
    this.locationForm = this.formBuilder.group({
      loc_range: ''
    });
    this.mapOptions = {
      // millcreek 42.019475-111.407619
      center: { lat: 42.019475, lng: -111.407619 },
      zoom: 12
    };

    //this.markers = [{lat:32.7767, lng:-96.7970}, {lat:32.8767, lng:-96.7970}, {lat:32.7767, lng:-96.8970}]

    this.initOverlays();
    this.infoWindow = new google.maps.InfoWindow();
  }
  handleOverlayClick(event) {
    this.msgs = [];
    let isMarker = event.overlay.getTitle != undefined;

    if (isMarker) {
      let title = event.overlay.getTitle();
      this.infoWindow.setContent('' + title + '');
      this.infoWindow.open(event.map, event.overlay);
      event.map.setCenter(event.overlay.getPosition());

      this.msgs.push({ severity: 'info', summary: 'Marker Selected', detail: title });
    } else {
      this.msgs.push({ severity: 'info', summary: 'Shape Selected', detail: '' });
    }
  }

  initOverlays() {
    if (!this.overlays || !this.overlays.length) {
      this.overlays = [
        new google.maps.Marker({
          position: { lat: 36.879466, lng: 30.667648 },
          title: 'Konyaalti',
          icon: this.iconBase
        }),
        new google.maps.Marker({
          position: { lat: 36.883707, lng: 30.689216 },
          title: 'Ataturk Park',
          icon: this.iconBase
        }),
        new google.maps.Marker({ position: { lat: 36.885233, lng: 30.702323 }, title: 'Oldtown', icon: this.iconBase }),

        new google.maps.Circle({
          center: { lat: 36.879466, lng: 30.702323 },
          fillColor: '#00c5e9',
          strokeColor: '#00c5e9',
          fillOpacity: 0.15,
          strokeWeight: 1,
          radius: 4000
        })
      ];
    }
  }
  createItem(): FormGroup {
    return this.formBuilder.group({
      place: ''
    });
  }
  addItem(): void {
    this.items = this.billingForm.get('locations') as FormArray;
    this.items.push(this.createItem());
  }

  deleteRow(index: number) {
    // control refers to your formarray
    this.items = this.billingForm.get('locations') as FormArray;
    // remove the chosen row
    this.items.removeAt(index);
  }
  onFormSubmit() {
    
    this.user = this.locationForm.value;
    this.initOverlays();
    console.log(this.user);
    /* Any API call logic via services goes here */
  }
  showMapOrList(showValue){
    if (showValue=='list'){
      this.showMap = false;
    }else{
      this.showMap = true;
    }
  }
  showDialog() {
    this.display = true;
  }
  showLocation() {
    this.show = true;
  }

  onFilterDialogClose(event){
    this.showFilterDialog = false;
    if(event==true){
      this.showSuccessDialog = true;
    }
  }

  onPropertyDialogClose(event){
    this.showPropertyDialog = false;
    if(event==true){
      this.showSuccessDialog = true;
    }
  }

  onSuccessDialogClose(){
    this.showSuccessDialog = false;
  }

  searchPropertyByMls(){
    this.mlsButtonText = "Loading...";
    var listRef = this.listhubApp.database().ref('listings/all');
    // 1489174
    listRef.orderByChild('mlsNumber').startAt(this.searchKey).endAt(this.searchKey+"\uf8ff").limitToLast(1).on('value', (snap) => {
      const result = snap.val();
      this.propertyArray = [];
      for(let key in result){
       if(result.hasOwnProperty(key)){
        this.propertyArray.push(result[key]);
       }
      }
      this.mlsButtonText = "MLS Search";
    });
  }
  searchPropertyByAddress(){
    this.addressButtonText = "Loading..."
    var listRef = this.listhubApp.database().ref('listings/all');
    // 1489174
    listRef.orderByChild('address').startAt(this.searchKey).endAt(this.searchKey+"\uf8ff").limitToLast(100).on('value', (snap) => {
      const result = snap.val();
      this.propertyArray = [];
      for(let key in result){
       if(result.hasOwnProperty(key)){
        this.propertyArray.push(result[key]);
       }
      }
      this.addressButtonText = "Address Search";
    });
  }

  searchPropertyByLocation_Algolia(){
    if(!this.isLoading){
      this.isLoading = true;
      this.algoliaIndex.search({
       query: this.searchKey,
       filters: this.filterProp
       })
      .then((data)=>{
       const propertyData = data.hits;
       this.propertyArray = propertyData;
       this.isLoading = false;
      });
    }
  }
  filter(){
    if(!this.isLoading){
      this.filterProp = '';
      // let filters = '';
      /** price filter */
      if(this.minPrice && this.maxPrice){
        this.filterProp = 'price: '+this.minPrice +' TO '+this.maxPrice;
      }
      /** property Type filter */
      if(this.propertyType){
        this.filterProp += this.filterProp? ' AND propertyType: '+this.propertyType['name'] : 'propertyType: '+this.propertyType['name'];
      }
      /** bedrooms filter */
      if(this.minBedValue >= 0 && this.maxBedValue >= 0 ){
        if(this.minBedValue == 0 && this.maxBedValue == 0){

        }else{
          this.filterProp += this.filterProp? ' AND bedrooms: '+this.minBedValue +' TO '+ this.maxBedValue : ' bedrooms: '+this.minBedValue +' TO '+ this.maxBedValue;  
        }
        
      }
      /** bathrooms filter */
      if(this.minbathValue >= 0 && this.maxbathValue >= 0 ){
        if(this.minbathValue == 0 && this.maxbathValue == 0){

        }else{
          this.filterProp += this.filterProp? ' AND bathrooms: '+this.minbathValue +' TO '+ this.maxbathValue : ' bathrooms: '+this.minbathValue +' TO '+ this.maxbathValue;
        }
        
      }
      console.log(this.filterProp);
      this.isLoading = true;
      this.algoliaIndex.search({
        // page: 2,
        // hitsPerPage: 5
        query: this.searchKey,
        filters: this.filterProp
      })
      .then((data)=>{
        alert('Filter apllied successfully.');
        const propertyData = data.hits;
       this.propertyArray = propertyData;
       this.isLoading = false;
      });
    }
  }
  propertyDialoagDetails(data){
    this.propertyData = data;
    this.showPropertyDialog=true;
  }

  onMouseOver(infoWindow, gm) {

        if (gm.lastOpen != null) {
            gm.lastOpen.close();
        }

        gm.lastOpen = infoWindow;

        infoWindow.open();
  }

  onClickInfoView(id){
    alert(id);
  }


}



