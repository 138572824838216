import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { SharedService } from './shared.service';
import { Permissions } from './permissions.enum';
import { map, take, tap, switchMap } from 'rxjs/operators';
import { auth } from 'firebase';
import { promise } from 'protractor';
import { stat } from 'fs';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private _sharedService: SharedService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const path = next.url[0].path;
    const accessLevel = 10;
    const permission = Permissions[path];
    let emailVerified =  false;  
    return this._sharedService.authState$
      .pipe(
        switchMap(status => {
          console.log(status)
          if(status){
            emailVerified = status.emailVerified;
          }
          if (status !== null) {
            return this._sharedService.userInfoObj;

          }
          else {
            return Observable.of(false);
          }
        }),
        map((user) => {
          console.log(user)
          if (user) {
            console.log(user)
            console.log(emailVerified);
            if(user.invitationId || user.registrationCompleted){
              emailVerified = true;
            }

            if (user.activePlan && user.registrationCompleted && (emailVerified || user.facebookUid) ) {
              console.log('active');
              if (path === 'register' || path === 'login') {
                console.log(path);
                console.log('go home');
                this.router.navigate(['/home']);
              } else {
                console.log(path)
                console.log('lets route');
                return true;
              }
            }
            else {
              if (path === 'register') {
                return true;
              }else{
                this.router.navigate(['/register']);
                return false;
              }

            }


            //return authenticated;
          }
          else if (user === null) {
            
            if (path === 'register') return true;

            else{
              this.router.navigate(['/register']);
              return false;
            }
           
          }
          else {
            if (path === 'login') return true;

            else {
              this.router.navigate(['/login']);
              return false;

            }


          }

        })
      )


  }

}
