import {
  Component,
  OnInit,
  Input,
  AfterViewChecked,
  ElementRef,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { SharedService } from '../../shared/shared.service';
import { Observable, Timestamp, Subscription } from 'rxjs/Rx';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject
} from 'angularfire2/database';
import * as firebase from 'firebase';
import { AngularFireAuth } from 'angularfire2/auth';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, AfterViewChecked, OnDestroy {
  mapId: any;
  chatList: any;
  latest_msg_timstamp: any;
  latestTimeStampHandler: Subscription;
  lastTimeStamp: any;
  keys: string[] = [];
  show = false;
  index = 1;
  items: { items: { label: string }[] }[];
  @Input() messageData: any = {};
  @Input() messages: any[] = [];
  @Input() user_id;
  @Input() mapTimerIds: any = {};
  @Input()
  set chats(chatData) {
    if (this.message$) {
      this.message$.unsubscribe();
    }
    if (this.latestTimeStampHandler) {
      this.latestTimeStampHandler.unsubscribe();
    }
    this.index = 1;
    this.messageList = [];
    if (chatData && chatData.chat_id) {
      this.chatData = chatData;
      this.chatRoom = chatData.userDetails.chat_id;
      this.getMessages(this.chatRoom);
      this.getLatestTimeStamp(this.chatRoom);
    } else {
      this.chatData = {};
      this.messageList = [];
    }
  }

  messageList: any[] = [];
  chatRoom: any;
  toUserDetails: any;
  chatData: any = {};
  messageText = '';

  @ViewChild('chatMessages', { read: ElementRef })
  public panel: ElementRef<any>;

  constructor(public db: AngularFireDatabase) {}
  private message$: any;
  ngOnInit() {
    this.items = [
      {
        items: [
          { label: 'Mark as read' },
          { label: 'Block user' },
          { label: 'Delete chat' }
        ]
      }
    ];
    this.scrollToBottom();
  }

  ngAfterViewChecked() {
    if (this.messageList.length <= 10) {
      this.scrollToBottom();
    }
  }

  scrollToBottom(): void {
    try {
      this.panel.nativeElement.scrollTop = this.panel.nativeElement.scrollHeight;
    } catch (err) {
      console.log(123);
    }
  }

  click() {
    this.index++;
    this.getMessages(this.chatRoom);
  }

  clicked() {
    this.show = !this.show;
  }

  /**
   * Function to get messages from the corresponding chat
   * @param {*} chat_id
   * @memberof MessageComponent
   */
  getMessages(chat_id) {
    // message list from the corresponding chat room
    const messageRef = this.db
      .list(`messages/${chat_id}`, ref =>
        ref.orderByChild('message_ts').limitToLast(this.index * 10)
      )
      .valueChanges(['child_added']);
    this.message$ = messageRef.subscribe(list => {
      this.messageList = list;

      this.mapTimerIds[this.chatRoom].clearTime();
      this.mapTimerIds[this.chatRoom].setTime();

      // new message notifications
      this.messageList = this.messageList.map(item => {
        item.unread = item.message_ts > this.latest_msg_timstamp;
        this.lastTimeStamp = item.message_ts;
        if (item.from_user === this.user_id) {
          item.unread = false;
        }
        return item;
      });

      // mark the chat as read once message is loaded
      this.markChatAsRead();
      setTimeout(() => {
        // removed unread notifications after the messages are loaded
        this.messageList = this.messageList.map(item => {
          item.unread = false;
          return item;
        });
      }, 5000);
    });
  }

  /**
   * Function to mark chat as read after messages are loaded
   * @memberof MessageComponent
   */
  markChatAsRead() {
    const chatRef = this.db.list(`chat`);
    chatRef.update(this.chatRoom, {
      last_read_message_ts: firebase.database.ServerValue.TIMESTAMP,
      read_status: true
    });
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.sendMessage();
    }
  }

  /**
   * Function to send message to the corresponding chat
   * @memberof MessageComponent
   */
  sendMessage() {
    if (!!this.messageText) {
      this.db
        .list(`messages/${this.chatRoom}`)
        .push({
          from_user: this.user_id,
          message: this.messageText,
          message_read_at: '', // @todo: add read time stamp
          message_ts: firebase.database.ServerValue.TIMESTAMP,
          user_type: 'Agent'
        })
        .then(ref => {
          const chatRef = this.db.list(`chat`);
          chatRef.update(this.chatRoom, {
            latest_msg: this.messageText,
            latest_msg_ts: firebase.database.ServerValue.TIMESTAMP,
            read: false
          });
          this.messageText = '';
        });
    }
  }

  getLatestTimeStamp(chatId: string) {
    this.latestTimeStampHandler = this.db
      .object(`chat/${chatId}`)
      .valueChanges()
      .subscribe(value => {
        if (value) {
          this.latest_msg_timstamp = value['latest_msg_ts'];
        }
      });
  }

  /**
   * Destroy the subscription to avoid subscription leak
   * @memberof MessageComponent
   */
  ngOnDestroy() {
    if (this.message$) {
      this.message$.unsubscribe();
    }
    if (this.latestTimeStampHandler) {
      this.latestTimeStampHandler.unsubscribe();
    }
  }
}
