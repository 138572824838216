import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-setting-confirm-dialog',
  templateUrl: './setting-confirm-dialog.component.html',
  styleUrls: ['./setting-confirm-dialog.component.scss']
})
export class SettingConfirmDialogComponent implements OnInit {
  @Input() confirmcommon: boolean;
  @Input() message: string;
  @Input() popupHeader: string;
  @Input() paidByAdmin: boolean;

  @Output() confirmDialogToggle = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }
  onClose(ev){
    this.confirmDialogToggle.emit(ev);
    // this.successcommon =false;
  }
}
