import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SettingsComponent } from './settings.component';
import { AuthGuard }      from '../shared/auth.guard';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { BillingPlanComponent } from './billing-plan/billing-plan.component';


import { ViewAllHistoryComponent } from './billing-plan/components/billing-history/view-all-history/view-all-history.component';
import { TeamManagementComponent } from './team-management/team-management.component';
import { TeamManagementSettingComponent } from './team-management/team-management-setting/team-management-setting.component';
import { NotificationComponent } from './notification/notification.component';
import { SettingOverviewComponent } from './setting-overview/setting-overview.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      { path: 'settings', component: SettingsComponent,  canActivate: [AuthGuard] },
      { path: 'settings/setting-overview', component: SettingOverviewComponent, canActivate: [AuthGuard] },
      { path: 'settings/account-details', component: AccountDetailsComponent, canActivate: [AuthGuard] },
      { path: 'settings/billing-plan', component: BillingPlanComponent, canActivate: [AuthGuard] },
      {
        path: 'settings/billing-plan/history-of-purchases',
        component: ViewAllHistoryComponent,
        canActivate: [AuthGuard]
      },
      { path: 'settings/team-management', component: TeamManagementComponent, canActivate: [AuthGuard] },
      {path: 'settings/team-management/team-management-team-setting',
        component: TeamManagementSettingComponent, canActivate: [AuthGuard] },
      { path: 'settings/notification', component: NotificationComponent, canActivate: [AuthGuard] },
    ])
  ],
  exports: [RouterModule]
})
export class SettingsRoutingModule { 

  
}
