import { NgModule } from '@angular/core';
import { FeedbackComponent } from './feedback.component';
import { FeedbackRoutingModule } from './feedback-routing.module';
import { DialogModule, SliderModule, DropdownModule } from 'primeng/primeng';
import { ButtonModule } from 'primeng/primeng';
import { HttpModule } from '@angular/http';
import { TabViewModule } from 'primeng/primeng';
import { InputTextareaModule, SharedModule } from 'primeng/primeng';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedFileModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';


@NgModule({
  imports: [
    InputTextareaModule,
    TabViewModule,
    DialogModule,
    FeedbackRoutingModule,
    SharedModule,
    FormsModule,
    SharedFileModule,
    ReactiveFormsModule,
    CommonModule,
    ButtonModule,
    HttpModule,
    DialogModule,
    SliderModule,
    DropdownModule
  ],
  declarations: [FeedbackComponent],
  exports: [FeedbackComponent]
})
export class FeedbackModule {}
