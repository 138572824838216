import { Component, OnInit } from '@angular/core';
import {AngularFireDatabase, AngularFireObject, AngularFireList} from 'angularfire2/database';
import { SharedService } from '../shared/shared.service';


@Component({
  moduleId: module.id,
  selector: 'feedback',
  templateUrl: 'feedback.component.html',
  styleUrls: ['feedback.component.css'],
})
export class FeedbackComponent implements OnInit {
  firstName: string = "";
  lastName: string = "";
  email: string = "";
  feedback : string;
  userInfo : AngularFireObject<any>;
  feedbackRef : AngularFireList<any>;
  uid : string;
  showNoResultsFound = false;
  showNeddHelpDialog = false;
  issueDropdown: any;
  
  issueSeverity: string = "";
  subject: string = "";
  message: string = "";
  subjectError  = false;
  messageError  = false;
  issueSeverityError  = false;


  //issueDropdown: { name: string }[];
  constructor(private af: AngularFireDatabase, private _sharedService: SharedService) {
    this.issueDropdown = [{ name: 'Critical'}, { name: 'Important' }, { name: 'Inquiry'}]; 
  }

  submitFeedback()
  {
    let fullFeedback = {firstName: this.firstName, lastName: this.lastName, email: this.email, feedback: this.feedback}
    this.feedbackRef.push(fullFeedback).then((result)=>
  {
    this.feedback = "";
  });
    
  }

  ngOnInit() {
      this.uid = this._sharedService.user.uid;
      let userPath = "/users/" + this.uid;
      this.userInfo = this.af.object(userPath);
      let feedbackPath = "/feedback/" + this.uid;
      this.feedbackRef = this.af.list(feedbackPath);
      this.userInfo.valueChanges().subscribe((result)=>
      {
        this.firstName = result.firstName;
        this.lastName = result.lastName;
        this.email = result.email;
      })
  }

  onSearchClick(){
    if (this.showNoResultsFound){
      this.showNoResultsFound = false;
    }else{
      this.showNoResultsFound = true;
    }
  }

  onShowNeedHelpPopup(event){
      this.showNeddHelpDialog = true;
  }
  
  onNeddHelpClose(){
    this.showNeddHelpDialog = false;
  }
  onNeddHelpSubmit(){
    const isValidate = this.validateInviteDialog();
    if(isValidate){
      const payLoad = {email: this.email, firstName: this.firstName, lastName: this.lastName, subject: this.subject, feedback: this.message,  issueSeverity: this.issueSeverity['name']};
      let feedbackPath = "/feedback/" + this.uid;
      this.af.list(feedbackPath).push(payLoad);
      this.clearFeedbackDialog();
      alert("Feedback has been sent successfully.");
      this.showNeddHelpDialog = false;
    }
  }
  clearFeedbackDialog(){
    this.message = "";
    this.subject = "";
    this.issueSeverity = "";
  }
  startTutorial()
  {
    sessionStorage.removeItem('gettingStarted');
  }
  //validate need help popup.
  validateInviteDialog(){
    this.subjectError  = false;
    this.messageError  = false;
    this.issueSeverityError  = false;
    if(this.issueSeverity==""){
      this.issueSeverityError = true;
      return false;
    }
    if(this.subject == ""){
      this.subjectError = true;
      return false;
    }
    if(this.message == ""){
      this.messageError = true;
      return false;
    }
    return true;
  }


}
