import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Subject} from 'rxjs/Subject';
@Component({
  selector: 'app-import-clients-dialog',
  templateUrl: './import-clients-dialog.component.html',
  styleUrls: ['./import-clients-dialog.component.scss']
})
export class ImportClientsDialogComponent implements OnInit {
  @Input() showClient: boolean;
  @Output() displayChange = new EventEmitter();
  isPopupCall: boolean = true;
  saveAccountDetails: Subject<boolean> = new Subject();
  successcommon: boolean = false;
  successHeader: string = "Import Clients";
  successMessage: string = "";
  constructor() { }

  ngOnInit() {
  }
  onClose() {
    this.displayChange.emit(false);
  }
  onSave(){
    this.displayChange.emit(true);
    this.saveAccountDetails.next(true);
  }
  onSaveData(ev){
    if(ev){
      this.showClient = false;
      this.displayChange.emit(true);    
      this.successcommon = true;
      this.successMessage = "Clients imported successfully."
    }else{
      this.showClient = false;
      this.displayChange.emit(true);      
    }
  }
  onSuccesscommonClose(ev){
    this.successcommon =false;
  }

}
