import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CalendarComponent } from './calendar.component';
import { AuthGuard }      from '../shared/auth.guard';


@NgModule({
  imports: [
    RouterModule.forChild([
      { path: 'calendar', component: CalendarComponent,  canActivate: [AuthGuard]}
    ])
  ],
  exports: [RouterModule]
})
export class CalendarRoutingModule { 

  
}
