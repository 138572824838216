import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

@Component({
  selector: 'app-new-appointment-dialog',
  templateUrl: './new-appointment-dialog.component.html',
  styleUrls: ['new-appointment-dialog.component.scss']
})
export class NewAppointmentDialogComponent implements OnInit {
  areaDropdown: any;
  teamDropdown: any;
  addressDropdown: any;
  timezoneDropdown :any;
  starttimeDropdown :any;
  endtimeDropdown :any;
  items: any;
  @Input() newAppointment: boolean;
  @Output() displayChange = new EventEmitter();
  inviteForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.addressDropdown =[{name: '1330 simpsons Ave Salt Lake City Ut'}];
    this.teamDropdown = [{ name: 'Iron ways' }];
    this.timezoneDropdown = [{ name: 'UTC +2' }, { name: 'UTC +3' }, { name: 'UTC +4' }]; 
    this.starttimeDropdown = [{ name: '12:00 AM' }, { name: '01:00 AM' }, { name: '02:00 AM' }, { name: '03:00 AM' }, { name: '04:00 AM'}, { name: '05:00 AM' }, { name: '06:00 AM' }, { name: '07:00 AM' }, { name: '08:00 AM' }, { name: '09:00 AM' }, { name: '10:00 AM' }, { name: '11:00 AM' }  ]; 
    this.endtimeDropdown = [{ name: '12:00 AM' }, { name: '01:00 AM' }, { name: '02:00 AM' }, { name: '03:00 AM' }, { name: '04:00 AM'}, { name: '05:00 AM' }, { name: '06:00 AM' }, { name: '07:00 AM' }, { name: '08:00 AM' }, { name: '09:00 AM' }, { name: '10:00 AM' }, { name: '11:00 AM' }  ]; 
    this.areaDropdown = [
      { name: 'Team Manager' },
      {
        name: 'Sales Agent'
      }
    ];
  }

  ngOnInit() {
    this.inviteForm = this.formBuilder.group({
      agents: this.formBuilder.array([this.createItem()])
    });
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      name: '',
      email: '',
      team: '',
      role: ''
    });
  }
  addItem(): void {
    this.items = this.inviteForm.get('agents') as FormArray;
    this.items.push(this.createItem());
  }

  deleteRow(index: number) {
    // control refers to your formarray
    this.items = this.inviteForm.get('agents') as FormArray;
    // remove the chosen row
    this.items.removeAt(index);
  }
  onFormSubmit(inviteForm) {
    console.log(inviteForm);
    /* Any API call logic via services goes here */
  }

  onClose() {
    this.displayChange.emit(false);
  }

  // Work against memory leak if component is destroyed
  ngOnDestroy() {
    // this.displayChange.unsubscribe();
  }
}
