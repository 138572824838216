import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from 'angularfire2/database';
import { SharedService } from '../shared/shared.service';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { InputMask } from 'primeng/primeng';
@Component({
  moduleId: module.id,
  selector: 'register',
  templateUrl: 'register.component.html',
  styleUrls: ['register.component.css'],
})
export class RegisterComponent implements OnInit {
  encapsulation: ViewEncapsulation.None;
  firstName: string = "";
  lastName: string = "";
  items = [

    { label: 'Register' },
    { label: 'Choose Plan' },
    { label: 'Confirm Settings' }
  ];
  activeIndex = 2;
  email: string = "";
  phoneNumber: string = "";
  display: boolean = false;
  androidLink: string;
  iosLink: string;
  stripe: any;
  cards: AngularFireList<any[]>;
  uid: string;
  addCC: boolean = true;
  subscription: boolean = false;
  currentPlan: string;
  showAppLinks: boolean = false;
  switchTab: boolean = false;
  loading: boolean = false;
  planID: string;
  loadSub: boolean = false;
  trialCode: string = "EXPO413";
  confirmSubscription: boolean = false;
  planAmount: string;
  useTrial: boolean = false;
  showPromoInput: boolean = true;
  selectedPlan: string;
  brokerAddress: string;
  brokerName: string;
  showCancelDialog: boolean = false;
  brokerPicture: any;
  profilePicture: any;
  subscriptionObs: AngularFireObject<any>;
  paidPlan: boolean = true;
  showCardSignup1 = false;
  showCardSignup2 = false;
  showCardSignup3 =false;
  registrationCompleted = false;
  planType: string = "";
  rdoPlan = "";
  planSelected = false;
  planError = false;
  userRole : string = "";
  agentDetail: AngularFireList<any>;
  invitationId: string = "";
  emailVerified: boolean = false;
  facebookId: "";
  isFbLogin = false;
  paidByAdmin: boolean;
  initialUpload: boolean = false;
  rate: number;
  adminDetails: any;
  admin: string = "";
  adjustedRate: number = 0;
  @ViewChild('myInputMask') myInputMask: InputMask;
  constructor(private _router: Router, private af: AngularFireDatabase, private _shared: SharedService) {
    this.uid = this._shared.user.uid;
    this.email = this._shared.user.email;
    this.cards = this.af.list('/stripe/' + this.uid + '/sources/data/');
    this.af.object('/stripe/' + this.uid + '/subscribe/').valueChanges().subscribe((result) => {
      let value = result
      let id = this.planID;
      if (value) {
      }
      else {
        this.loading = false;
      }
    });
    this.subscriptionObs = this.af.object('/stripe/' + this.uid + '/subscriptions/data/0');
    this.cards.valueChanges().subscribe((result) => {
      if (result.length > 0) {
        this.addCC = false;
        this.loading = false;
      }
      else {
        this.addCC = true;
      }
    })

    this.subscriptionObs.valueChanges().subscribe((result) => {
      if(result)
      {
      if (result.status) {
        this.subscription = true;
        this.currentPlan = result.plan.id;
        this.planID = result.id;
        this.showCancelDialog = false;
        this.confirmSubscription = false;
        if (this.currentPlan == 'Agent_Team_Monthly') {

        }

      }
    }
      else {
        this.subscription = false;
        this.currentPlan = "";
        this.loading = false;
        this.showCancelDialog = false;


      }
   });
   
  }


  subscribe(plan: string) {
    if (this.addCC) {
      alert("Please add a credit card before selecting a plan")
      this.openCheckout();
    }
    else {
      this.selectedPlan = plan;
      this.confirmSubscription = true;
      this._shared.updateSubscription(plan);

    }
  }

  
  updateProfilePicture(event: EventTarget) {
    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;
    let profilePictureFile = files[0];
    this._shared.getOrientation(profilePictureFile, (orientation) => {
        this._shared.getBase64(profilePictureFile, orientation, (base64Image) => {
           this.profilePicture = base64Image;
           this._shared.updateProfilePicture(base64Image);
        });
     });
  }

  updateBrokerPicture(event: EventTarget) {
    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;
    let brokerPictureFile = files[0];
    this._shared.getOrientation(brokerPictureFile, (orientation) => {
        this._shared.getBase64(brokerPictureFile, orientation, (base64Image) => {
           this.brokerPicture = base64Image;
           this._shared.updateBrokerPicture(base64Image);
        });
     });
  }

  removeBrokerPicture(){
    if(this.brokerPicture){
      this._shared.removeBrokerPicture().then((result) => {
        if(result){
          this.brokerPicture = '';
        }
      });
    }
  }
  
  removeProfilePicture(){
    if(this.profilePicture){
      this._shared.removeProfilePicture().then((result) => {
        if(result){
          this.profilePicture = '';
        }
      });
    }
  }
  accept() {
    if (this.useTrial) {
      this._shared.updateSubscription(this.selectedPlan, 14);
      //this.confirmSubscription = false;
      this.loading = true;
    }

    else {
      this._shared.updateSubscription(this.selectedPlan, null);
      // this.confirmSubscription = false;
      this.loading = true;
    }


  }
 
  decline() {
    this.confirmSubscription = false;
  }
  ngOnInit() {
    this._shared.getUserInfo().then((result) => {
      if (result) {
        this.firstName = result.firstName ? result.firstName : '';
        this.lastName = result.lastName ? result.lastName : '';
        //this.email = result.email;
        this.phoneNumber = result.phoneNumber ? result.phoneNumber : '';
        this.brokerAddress = result.brokerAddress ? result.brokerAddress : '';
        this.brokerName = result.brokerName ? result.brokerName : '';
        this.stripe = result.stripe ? result.stripe : '';
        this.profilePicture = result.profilePicture ? result.profilePicture : '';
        this.brokerPicture = result.brokerPicture ? result.brokerPicture : '';
        this.registrationCompleted = result.registrationCompleted ? result.registrationCompleted : ''; 
        this.planType = result.planType;
        this.paidByAdmin = result.paidByAdmin ? true : false;
        this.userRole = result.role  ? result.role: 'agent';
        this.invitationId  = result.invitationId ? result.invitationId : '';
        this.initialUpload  = result.initialUpload ? result.initialUpload : false;
        this.rate = result.rate;
        this.admin = result.admin ? result.admin : '';
        this.adjustedRate  = result.adjustedRate;
        if(this.invitationId){
          this.emailVerified = true;
        }else{
          this._shared.authState$.subscribe(res => {
            if(res){
              this.emailVerified = res.emailVerified;
            }
            
          });
        }
        this.paidPlan = true;
        console.log(result);
        this.facebookId = result.facebookUid ? result.facebookUid : ""; 
        this.isFbLogin = result.facebookUid ? true : false;
        if(!this.planType && !this.paidByAdmin){
          this.activeIndex=1;
        }else{
          this.activeIndex=2;
          this.showCardSignup1 = true;
          let ev;
          this.submit(ev);
        }
             /**get admin details */
        if(this.admin){this.getAdminDetails();}
      }
    });
  
    const userRefs = firebase.database().ref('users');
    const uid = firebase.auth().currentUser.uid;
    const userRef = userRefs.child(uid);

    userRef.on('value',(snapshot: any) => {
      const user = snapshot.val();
      this.rate = user.rate;
      this.planType = user.planType;
      this.admin = user.admin;
      this.adjustedRate  = user.adjustedRate;
    });

    let alink = "https://play.google.com/store/apps/details?id=com.homecodecns.homecodec&referrer=utm_source%3Dportal%26utm_content%3D" + this.uid;
    let ilink = "https://ios.homecodec.com/login;key=" + this.uid;
    this.iosLink = ilink.trim();
    this.androidLink = alink.trim();

      // const storageRef = firebase.storage().ref().child('users/'+this.uid+'/brokerpicture.jpg');
      // storageRef.getDownloadURL().then(
      //       url => this.setBrokerPicture(url)
      //       );

      // const storageRef_profile = firebase.storage().ref().child('users/'+this.uid+'/profilepicture.jpg');
      // storageRef_profile.getDownloadURL().then(
      //       url => this.setProfilePicture(url)
      //       );
  }
  getAdminDetails(){
    /**get admin details */
     this._shared.getAdminInfo().then((result) => {
        if(result){
         this.adminDetails = result;
        }
     });
   
  }
  submit(event: any) {
    // event.preventDefault();
    var activePlan = null;
    var payload = { firstName: this.firstName, lastName: this.lastName, email: this.email, phoneNumber: this.phoneNumber, brokerName: this.brokerName, brokerAddress: this.brokerAddress}

    if (this.items.length > 3) {
      //this.activeIndex = 3
    }

    else {
      activePlan = true;
      payload['activePlan'] = activePlan;
    }
    console.log(this.showCardSignup3);
    if(this.showCardSignup1){
      activePlan = true;
      payload['activePlan'] = activePlan;
      payload['registrationCompleted']=true;
      this.registrationCompleted = true;
    }else{
      payload['registrationCompleted']=false;
    }
    this._shared.updateUserInfo(payload).then(() => {
      if (this.registrationCompleted) {
        this.showCardSignup1=false;
        this.updateSubscriberDetails();
        // this.updateBillDetails();
      }
    });
    if (!this.currentPlan) {
      this.switchTab = true;
    }
  }
  /**
   * if user is invited update invitor role and add user to subscriber list.
   */
  updateSubscriberDetails(){
    if(this.invitationId){
      const inviteRef = firebase.database().ref('inviteAgents');
      const inviteRefs = inviteRef.child(this.invitationId);
      inviteRefs.once('value').then((snapshot: any) => {
        const result = snapshot.val();
          if(result){
              if(result.payForAgent){
                this.updateSubscribeObject(result);
              }
              // update team details
              if(result.team!=''){
                this.updateTeamMember(result);
              }
              /**update user details for invited user */
              this.updateInvitedUserDetails(result);
              inviteRefs.update({accepted: true});
            }else{
              /**update non invited user details... */
              this.updateAdminRole();
              this.removeInvitationId();
            }
      });
    }else{
       if(!this.initialUpload){
          this.updateAdminRole();
       }else{
        const userRef = firebase.database().ref('users').child(this.uid);
        const userPath = userRef.child('initialUpload');
        userPath.remove();
       // this._router.navigate(["/home"]);
        this._router.navigate(["/settings/account-details"]);
        
       }
        
    }
  }
  /*** update details for invited member */
  updateSubscribeObject(result){
    const subscribersPath = firebase.database().ref('subscribers').child(result.senderId);
    const subscriberRef = subscribersPath.child(this.uid);
    const subsPayload = {
      id: this.uid, firstName: this.firstName, lastName: this.lastName, role: result.teamRole
    };
     // update subscriber list
     subscriberRef.update(subsPayload).then((result)=>{
    },
      err => console.log(err)
    );  
    // end of subscriber updation
  }
  updateTeamMember(result){
    const teamRef = firebase.database().ref('teams').child(result.team);
    let teamPath;
    let teamRefs;
    let payload = {};
    teamPath = teamRef.child('members');
    teamRefs =  teamPath.child(this.uid);
    payload = {id: this.uid, firstName: this.firstName, lastName: this.lastName, createdDttm: Date.now(), role: result.teamRole};
    teamRefs.update(payload);
  }

  updateInvitedUserDetails(result){
    // let userPaylod;
    const teamName = result.team ? result.teamName : 'unassigned'
    if(result.payForAgent){ 
      let userPaylod;
        if(!this.adminDetails.discountApplied && this.adminDetails.planType != 'planZ'){
           const discountAmount  = this.adminDetails.adjustedRate ? this.adminDetails.adjustedRate - 10: '';
           userPaylod = {adjustedRate: discountAmount, discountApplied: true, role: result.teamRole, admin: result.admin, teamName: teamName, teamId: result.team, teamJoinedAt: Date.now(), paidByAdmin: result.payForAgent, adminName: result.adminName, createdDttm: Date.now(), changeDate: '', renewalDate: this.adminDetails.renewalDate, startDate: Date.now(), changeType: '', billType: this.adminDetails.billType, network: this.adminDetails.email, freePeriod: this.adminDetails.freePeriod, commitment: this.adminDetails.commitment, trialExpired: this.adminDetails.trialExpired, term: this.adminDetails.term, makePayment: true};
           this.applyDiscount();
        }else{
          userPaylod = {role: result.teamRole, admin: result.admin, teamName: teamName, teamId: result.team, teamJoinedAt: Date.now(), paidByAdmin: result.payForAgent, adminName: result.adminName, createdDttm: Date.now(), changeDate: '', renewalDate: this.adminDetails.renewalDate, startDate: Date.now(), changeType: '', billType: this.adminDetails.billType, network: this.adminDetails.email, freePeriod: this.adminDetails.freePeriod, commitment: this.adminDetails.commitment, trialExpired: this.adminDetails.trialExpired, term: this.adminDetails.term, rate: this.adminDetails.adjustedRate, adjustedRate: this.adminDetails.adjustedRate, makePayment: true};
        }
       
        this._shared.updateUserInfo(userPaylod).then(() => {
          /** to do add prorated formula for late registered user, if invitation date is less than current date. */
          this.removeInvitationId();
          if(this.adminDetails.planType!='planZ'){
            this.updateBillingLog(result.admin, this.adminDetails.billingDate);
          }
          
        });
    }else{
      const trialExpiredDate = this.rdoPlan == 'planZ' ? this._shared.trialExpireDate() : Date.now();
      const renewalDate = this._shared.renewalDate(trialExpiredDate);
      const billingDate = this.rdoPlan == 'planZ' ? '': this._shared.billingDate(trialExpiredDate);
      const commitment = this.rdoPlan == 'planB' ? 'annual' : '';
      const freePeriod = this.rdoPlan == 'planZ' ? 7 : 0;
      const billType = this.planType == 'planZ' ? 'Trial' : 'Monthly Subscription';
      let amount = this.adjustedRate;
      if(!this.adminDetails.discountApplied && this.adminDetails.planType != 'planZ'){
        amount  = this.adminDetails.adjustedRate - 10;
        this.applyDiscount();
      }
      const userPaylod = {role:  result.teamRole, admin: result.admin, teamName: teamName, teamId: result.team, teamJoinedAt: Date.now(), paidByAdmin: result.payForAgent, adminName: result.adminName, createdDttm: Date.now(), changeDate: '', renewalDate: renewalDate, startDate: Date.now(), changeType: '', billType: billType, network: this.adminDetails.email, freePeriod: freePeriod, commitment: commitment, billingDate: billingDate, makePayment: true, adjustedRate: amount, rate: amount};
      this._shared.updateUserInfo(userPaylod).then(() => {
        this.removeInvitationId();
        if(this.rdoPlan != 'planZ'){
          this.updateBillingLog(this.uid, billingDate);
        }
      });  
    }
  }
  
/*** update bill details for new user admin.... not used */
  updateBillDetails(){
    const billType = this.planType == 'planZ' ? 'Trial' : 'Monthly Subscription';
    const trialExpiredDate = this.planType == 'planZ' ?  this._shared.trialExpireDate() : Date.now() ;
    const renewalDate = this._shared.renewalDate(trialExpiredDate);
    //update current user role as per invitation and invitor Id as admin.
    const userPaylod = { createdDttm: Date.now(), changeDate: Date.now(), renewalDate: renewalDate, startDate: Date.now(), changeType: '', billType: billType, leadBudget: 0, managedLead: true, payAllMember: true, network: this.email};
    this._shared.updateUserInfo(userPaylod).then(() => {
      this.removeInvitationId();
    });
  }
  removeInvitationId(){
    const userRef = firebase.database().ref('users').child(this.uid);
    const userPath = userRef.child('invitationId');
    userPath.remove();
    //this._router.navigate(["/home"]);
    this._router.navigate(["/settings/account-details"]);
  }

  /**end of update invited member */
  /** update admin user details direct registeration... */
  updateAdminRole(){
    // mark role as admin if it's not from invitation.
    const billType = this.planType == 'planZ' ? 'Trial' : 'Monthly Subscription';
    const trialExpiredDate = this.planType == 'planZ' ?  this._shared.trialExpireDate() : Date.now();
    const renewalDate = this._shared.renewalDate(trialExpiredDate);
    const billingDate = this.planType == 'planZ' ? '' : this._shared.billingDate(trialExpiredDate);
    //update current user role as per invitation and invitor Id as admin.
    const userPaylod = { createdDttm: Date.now(), changeDate: '', renewalDate: renewalDate, startDate: Date.now(), changeType: '', billType: billType, leadBudget: 0, managedLead: false, payAllMember: true, network: this.email, role: 'admin', admin: this.uid, adminName: this.firstName+' '+this.lastName, discountApplied: false, billingDate: billingDate, makePayment: true};
    this._shared.updateUserInfo(userPaylod).then(() => {
      console.log('Admin created successfully.');
      if(this.planType != 'planZ'){
        this.updateBillingLog(this.uid, billingDate);
      }
     // this._router.navigate(["/home"]);
     this._router.navigate(["/settings/account-details"]);
      
    });
  }
  /**end of admin details updation...**/ 
  updateBillingLog(adminId, renewalDate){
    const billingRef = firebase.database().ref('billing');   
    const billingRefs = billingRef.child(adminId);
    const payload = {type: 'monthlyFee', amount: this.adjustedRate, date: renewalDate, description: 'Monthly subscription charges', payFor: this.uid, cancelled: false};
    billingRefs.push(payload);
  }
/**apply discount for first accept invitation */
  applyDiscount(){
    const discountAmount  = this.adminDetails.adjustedRate ? this.adminDetails.adjustedRate - 10: '';
    const payload = {adjustedRate: discountAmount, discountApplied: true};
    // this._shared.updateUserInfo(payload).then(() => {
    //   console.log('discount applied');
    // });
    this._shared.updateUserInfoById(this.admin, payload).then(() => {
      console.log('discount applied');
    });
    /**add cancel flag for existing entry in billing table, and add new entries for 89 */
    
    const billingPath = "/billing/"+this.admin; 
    const billingRef = firebase.database().ref(billingPath);
    billingRef.orderByChild('type').equalTo('monthlyFee').once('value').then(snapshot => {
        const result = snapshot.val();
        const resultArrayKeys = Object.keys(result);
        resultArrayKeys.forEach(key => {
          const billingRef = firebase.database().ref('billing');   
          const billingRefs = billingRef.child(this.admin).child(key);
          billingRefs.update({cancelled: true});
          const payload = {type: 'monthlyFee', amount: this.adjustedRate, date: result[key].date, description: 'Monthly subscription charges', payFor: result[key].payFor, cancelled: false};
          const newBill = billingRef.child(this.admin);
          newBill.push(payload);
        });
    });
    
  }

  resendVerificationEmail(){
    this._shared.sendVerificationEmail().then((res) => {
      if(res){
        alert("Confirmation Email has been sent.");
      }
    });
  }
  onSelectPlan(event){
      if(event){
        this.activeIndex = 2;
        this.showCardSignup1 = true;
        this.submit(event);
      }
    }  
    
  isInputMaskFilled(event) {
    if(!this.myInputMask.filled) {
      this.phoneNumber =  '';
    }else{
      this.phoneNumber = this.myInputMask.value;
    }
  }


  /**non used */
 
  selectedPlans(){
    if(this.rdoPlan){
      // this.planSelected = true;
      this.planError = false;
      var payload = {planType: this.rdoPlan};
      this._shared.updateUserInfo(payload).then((result)=>{
        // if(!result){
          this.activeIndex=2;
        // }
      });
    }else{
      this.planError = true;
    }
  }

  setBrokerPicture(url){
    this.brokerPicture = url; 
  }

  setProfilePicture(url){
    this.profilePicture = url; 
  }

  public openCheckout() {
    // key: 'pk_live_d6Pby9G8K0qvdMLMQGwlLzln',
        let handler = (<any>window).StripeCheckout.configure({
          key: 'pk_test_SgYFBIjFUHb2tuhGSIrKnpHb',
          locale: 'auto',
          token: (token: any) => {
        
            this.loading = true;
            const amount = this.planType == 'planA' ? 99 : 89;
            return this._shared.updateStripe(token, amount).then((result) => {
              this.addCC = false;
              this.showCardSignup2=false;
              this.showCardSignup3=true;
            });
          }
        });
        handler.open({
          name: 'HomeCodec',
          image: '../assets/images/icon.png',
          email: this.email,
          allowRememberMe: false,
          panelLabel: 'Save'
        });
    
      }

    
  cancelSubscription() {
    this._shared.cancelSubscription().then((result) => {
      this.loading = true;
    });
  }
  
  cancelDialog() {
    this.showCancelDialog = true;
  }

  selectPlan(i) {
    if (i === 1) {
      this.paidPlan = true;
      if (this.items.length < 4) {
        this.items.push({ label: 'Payment' })
      }
    }
    this.activeIndex = 2;
  }

  showDialog() {
    this.display = !this.display;
  }
  addPromo(code: string) {
    if (code == this.trialCode && !this.currentPlan)
    //if(code == this.trialCode)
    {
      this.useTrial = true;
      this.showPromoInput = false;
    }

    else {
      alert("Invalid code, please try again.");
    }
  }
  stripePayementCall(amount, customerId, desc, payFor){
     /*
    const getCardDetails = firebase.functions().httpsCallable('getCardDetails');
    getCardDetails({uid: this.uid}).then(function(result) {
      // Read result of the Cloud Function.
      const cards = result.data;
      //console.log('cards:');
      //console.log(result);
      // ...
    });
    */
  //  makePayment(data.amount, data.customerId, data.desc, data.payFor);

    const payRegisteredUser = firebase.functions().httpsCallable('payRegisteredUser');
    const data = {}
    payRegisteredUser(data);
  }
}
