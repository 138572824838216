import { Component, OnInit, Input, Output, EventEmitter, ViewChild, NgModule, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { AngularFireDatabase, AngularFireList} from 'angularfire2/database';
import { SharedService } from '../shared.service';
import * as firebase from 'firebase/app';
import { Observable} from 'rxjs';
import { Fieldset, InputMask, InputMaskModule} from 'primeng/primeng';
@Component({
  selector: 'app-select-plan',
  templateUrl: './select-plan.component.html',
  styleUrls: ['./select-plan.component.scss'],
})
export class SelectPlanComponent implements OnInit {
  @Output() displayChange = new EventEmitter();
//   @Input() rdoPlan: string;
  rdoPlan: string = 'planZ';
  loading: boolean = false;
  creditCardAdded: boolean = false;
  cardNumber: any;
  cardNumberOne: string;
  cardNumberTwo: string;
  cardNumberThree: string;
  cardNumberFour: string;
  expiryMonth: string;
  expiryYear: string;
  cvc: string;
  message: string;
  addressZip: string;
  cardOne: string;
  cardTwo: string;
  cardThree: string;
  cardFour: string;
  successMessage: string;
  successHeader: string;
  successcommon: boolean = false;
  @ViewChild('myInputMask') myInputMask: InputMask;
  cardHandler = this.cardOnChange.bind(this);
  @ViewChild('cardInfo') cardInfo: ElementRef;
  @ViewChild('expiry') expiry: ElementRef;
  @ViewChild('ccv') ccv: ElementRef;
  @ViewChild('zip') zip: ElementRef;
  blankCardError: boolean = false;
  isSelectPlanTab: boolean = false;
  accountName: string;
  accountNumber: string;
  routingNumber: string;
  card: any;
  cardExpiry: any;
  cardCcv: any;
  cardZip: any;
  adminDetails: any;
  planARate: number = 129;
  planBRate: number = 99;
  // stripeObj: any;
  // stripe = stripe('pk_test_SgYFBIjFUHb2tuhGSIrKnpHb')
  // rdoPlan: boolean;
  constructor(private formBuilder: FormBuilder, private _sharedService: SharedService, private af: AngularFireDatabase, private cd: ChangeDetectorRef) {
    
  }

  showSelectPlanTab(ev){
    this.message = "";
    this.cd.detectChanges();
    if(ev){
      if(this.card._empty){
        this.unmountCardField();
        this.card = null;
        this.isSelectPlanTab = ev;
      }else{
        this.message = "Please clear card details switch to ach.";
        this.cd.detectChanges();
      }
    }
    if(!ev){
      if(!this.accountNumber && !this.routingNumber){
        this.isSelectPlanTab = ev;
         if(!this.card){
           this.accountName = "";
           this.cd.detectChanges();
           this.mountCardsField();
         }
      }else{
          this.message = "Please clear account details switch to card.";
          this.cd.detectChanges();
      }
    }
  }

  ngOnInit() {
    this._sharedService.getUserInfo().then((res)=>{
      if(res){
        if(res.admin){
          this.getAdminInfo();
        }
      }
    })
    
  }
  getAdminInfo(){
    this._sharedService.getAdminInfo().then((result) => {
      if(result){
       this.adminDetails = result;
       if(this.adminDetails.discountApplied){
         this.planARate = this.planARate - 10;
         this.planBRate = this.planBRate - 10;
       }
      }
    });
  }
  ngAfterViewInit() {
    this.mountCardsField();
  }
  mountCardsField(){
    const style = {
      base: {
        color: '#32325d',
        lineHeight: '18px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        background: '#fcfcfc',
        border:' 1px solid #ccc',
        padding: '8px',
        borderRadius:'3px!important',
        height: '41px !important',
        width: '100% !important',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };
    this.card = elements.create('cardNumber', {style: style});
    this.cardExpiry = elements.create('cardExpiry',{style: style});
    this.cardCcv = elements.create('cardCvc',{style: style});
    this.cardZip = elements.create('postalCode',{style: style});
    this.card.mount(this.cardInfo.nativeElement);
    this.cardExpiry.mount(this.expiry.nativeElement);
    this.cardCcv.mount(this.ccv.nativeElement);
    this.cardZip.mount(this.zip.nativeElement);
  }
  onClose(ev){
      if(this.rdoPlan){
        this.updatePlanDetails();
      }
  }
 updatePlanDetails(){  
        let validate;
        if(this.isSelectPlanTab){
          if(this.rdoPlan == 'planZ' && (!this.accountNumber && !this.routingNumber)){
             this.updateBillDetails();
             this.displayChange.emit(true);
          }else{
            validate = this.validateAch();
            if(validate){
              this.updateAccountDetails();
            }
          }
        }else{
          if(this.rdoPlan == 'planZ' && this.card._empty){
            this.updateBillDetails();
            this.unmountCardField();
            this.displayChange.emit(true);
          }else{
            this.updateCardDetails();
          } 
        }
      }
      /** update card details.... */
      updateCardDetails(){
        this.message = 'Loading...';
        stripe.createToken(this.card).then((result) => {
          if(result.token){
            console.log(result.token);
            const res = result.token;
            this.message = `Success! Card token ${res.card.id}.`;
            const amount = this.rdoPlan == 'planA' ? this.planARate : this.rdoPlan =='planB' ?  this.planBRate : 0;
            return this._sharedService.updateStripeDetails(res, amount).then((stp) => {
                this.updateBillDetails();
                this.unmountCardField();
                this.displayChange.emit(true);
            });
          }
          if(result.error){
            console.log(result);
            this.message = result.error.message;
            this.cd.detectChanges();
          }
        });
      }
      updateAccountDetails(){
        this.message = 'Loading...';
        stripe.createToken('bank_account', {
          country: 'US',
          currency: 'usd',
          routing_number: this.routingNumber,  //'110000000',
          account_number: this.accountNumber,  //'000123456789',
          account_holder_name: this.accountName,
          account_holder_type: 'individual',
        }).then((result) => {
          // Handle result.error or result.token
          console.log(result);
          if(result.token){
            const res = result.token;
            this.message = `Success! bank token ${res.bank_account.id}.`;
            const amount = this.rdoPlan == 'planA' ? this.planARate : this.rdoPlan =='planB' ?  this.planBRate : 0;
            return this._sharedService.updateStripeBankDetails(res, amount).then((stp) => {
                this.updateBillDetails();
                this.displayChange.emit(true);
            });
          }
          if(result.error){
            console.log(result);
            this.message = result.error.message;
            this.cd.detectChanges();
          }
        });
      }

      updateBillDetails(){
        const ratePerMonth = this.rdoPlan == 'planA' ? this.planARate : this.rdoPlan =='planB' ?  this.planBRate : 0;
        const freePeriod = this.rdoPlan == 'planZ' ? 7 : 0;
        const trialExpiredDate = this.rdoPlan == 'planZ' ? this._sharedService.trialExpireDate() : Date.now();
        const renewalDate = this._sharedService.renewalDate(trialExpiredDate);
        const commitment = this.rdoPlan == 'planB' ? 'annual' : '';
        //update current user role as per invitation and invitor Id as admin.
        const userPaylod = { freePeriod: freePeriod, trialExpired: trialExpiredDate, term: 'annual', renewalDate: renewalDate, rate: ratePerMonth, adjustedRate: ratePerMonth,paidBy: '', planType: this.rdoPlan, commitment: commitment};
        this._sharedService.updateUserInfo(userPaylod).then(() => {
          // this.removeInvitationId();
        });
      }
      clearItems(){
        this.loading = false;
        this.creditCardAdded = false;
        this.cardNumber = '';
        this.cardOne = '';
        this.cardTwo = '';
        this.cardThree = '';
        this.cvc = '';
        this.cardFour = '';
        this.expiryMonth = '';
        this.expiryYear = '';
        this.addressZip = '';
        this.message = '';
      }
      isInputMaskFilled(event) {
        if(!this.myInputMask.filled) {
          this.cardNumber =  '';
        }else{
          this.cardNumber = this.myInputMask.value;
        }
      }
      fillCardNumber(){
        this.cardNumber = "";
        this.cardNumber = this.cardNumber.concat(this.cardOne?this.cardOne:'',this.cardTwo?this.cardTwo:'', this.cardThree?this.cardThree:'', this.cardFour?this.cardFour:'');
      }
      onSuccesscommonClose(){
        this.successcommon = false;
      }
      
      validation(){
        this.message = "";
        if(!this.cardNumber){
          this.message = "Please enter card details.";
          return false;
        }
        if(this.cardNumber.length < 16){
          this.message = "Incomplete card details.";
          return false;
        }
        if(!this.expiryMonth){
          this.message = "Please enter expiry month.";
          return false;
        }
        if(!this.expiryYear){
          this.message = "Please enter expiry year.";
          return false;
        }
        if(!this.cvc){
          this.message = "Please enter CCV.";
          return false;
        }
        if(!this.addressZip){
          this.message = "Please enter zip code.";
          return false;
        }
        return true;
    }
    validateAch(){
      this.message = "";
      if(!this.accountNumber){
        this.message = "Please enter account number.";
        return false;
      }
      if(!this.routingNumber){
        this.message = "Please enter routing number.";
        return false;
      }
      return true;
    }
    cardOnChange({ error }) {
    if (error) {
       console.log(error);
    } else {
      // this.error = null;
    }
    // this.cd.detectChanges();
  }
  allowNumbersOnly(e) {
    var code = (e.which) ? e.which : e.keyCode;
    if (code > 31 && (code < 48 || code > 57)) {
        e.preventDefault();
    }
  }
  // Work against memory leak if component is destroyed
  ngOnDestroy() {
    // if(!this.isSelectPlanTab){
    //   this.unmountCardField();  
    // }
  }
  unmountCardField(){
    this.card.destroy();
    this.cardExpiry.destroy();
    this.cardCcv.destroy();
    this.cardZip.destroy();
  }
}
