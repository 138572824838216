import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import * as firebase from 'firebase/app';
import { AngularFireDatabase, AngularFireList} from 'angularfire2/database';
import { SharedService } from '../../../../../shared/shared.service';
import { Observable} from 'rxjs';
@Component({
  selector: 'app-set-budget-dialog',
  templateUrl: './set-budget-dialog.component.html',
  styleUrls: ['./set-budget-dialog.component.scss']
})
export class SetBudgetDialogComponent implements OnInit {
  uid: string;
  
  leadBudgetError: boolean = false;
  @Output() displayChange = new EventEmitter();
  @Input() display: boolean;
  @Input() leadBudget: number;
  @Input() managedLead: boolean;
  successcommon: boolean = false;
  successHeader: string = "Monthly Budget";
  successMessage: string = "";
  // team = 'Blank Team';
  constructor(private _sharedService: SharedService, private af: AngularFireDatabase) {
    this.uid = this._sharedService.user.uid;
  }

  ngOnInit() {
    this._sharedService.getUserInfo().then(user => {
      // this.leadBudget = user.leadBudget ? user.leadBudget : 0;
    });
    
 
  }
  onClose() {
    console.log('cose');
    this.leadBudgetError = false;
    this.displayChange.emit(false);
  }
  onSave(){
    this.leadBudgetError = false;
    if(this.leadBudget >= 1 || !this.managedLead){
      const payload = {leadBudget: this.leadBudget};
      this._sharedService.updateUserInfoById(this.uid, payload).then(()=>{
        // alert('Budget updated successfully.');
        this.successcommon = true;
        this.successMessage = "Budget updated successfully.";
        this.displayChange.emit(true);
      });
    }else{
      this.leadBudgetError = true;
    }
    

    // this.displayChange.emit(true);
  }
  onSuccesscommonClose(ev){
    this.successcommon = false;
  }
  // Work against memory leak if component is destroyed
  ngOnDestroy() {
    // this.displayChange.unsubscribe();
  }
}
