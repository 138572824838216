import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as firebase from 'firebase/app';
import { AngularFireDatabase, AngularFireList} from 'angularfire2/database';
import { SharedService } from '../../../../shared/shared.service';
import { Observable} from 'rxjs';
@Component({
  selector: 'app-remove-dialog',
  templateUrl: './remove-dialog.component.html',
  styleUrls: ['./remove-dialog.component.scss']
})
export class RemoveDialogComponent implements OnInit {
  @Input() remove: boolean;
  @Input() message: string;
  @Input() pageCall: string;
  @Input() userDetails: any;
  @Input() firstName: any;
  @Input() lastName: any;
  @Input() team: any;
  @Input() teamName: any;
  @Input() selectedRole: any;
  @Input() teamId: any;
  @Input() leadId: any;
  @Output() displayChange = new EventEmitter();
  agentId = "";
  agentEmail = "";
  uid: string;
  admin: string;
  role: string;
  adminName: string;
  planType: string;
  planRate: string;
  paidByAdmin: boolean;
  adminDetails: any;
  adminBillingDate: string = "";
  constructor(private _sharedService: SharedService, private af: AngularFireDatabase) {
    this.uid = this._sharedService.user.uid;
  }

  ngOnInit() {
    this._sharedService.getUserInfo().then(user => {
      this.admin = user.admin ? user.admin : '';
      this.adminName = user.adminName ? user.adminName : '';
      this.role = user.role ? user.role : '';
      if(this.role == 'admin'){
        // this.roleDropdown = [{ name: 'manager' }, {name: 'agent'}];
      }
      else{
      }
       
    });
    this._sharedService.getAdminInfo().then((admin)=>{
      this.adminDetails = admin;
      this.adminBillingDate = admin.billingDate ? admin.billingDate : Date.now();
      if(admin.planType == 'planZ'){
        this.adminBillingDate = admin.trialExpired;
      }
    });
  }
  onOpen(){
    console.log(this.userDetails);
    if(this.userDetails){
      this.agentId = this.userDetails.id ? this.userDetails.id : '';
      this.agentEmail = this.userDetails.email ? this.userDetails.email : '';
      this.planType = this.userDetails.planType ? this.userDetails.planType : '';
      this.planRate = this.userDetails.rate ? this.userDetails.rate : '';
      this.paidByAdmin = this.userDetails.paidByAdmin ? this.userDetails.paidByAdmin : '';
    }
  }
  onClose(event) {
    console.log(event);
    if(event){
      if(this.pageCall == 'agent'){
        /**Remove agent */
        this.displayChange.emit(event);
        this.removeByAdmin();
        //  if(this.role == 'admin'){
        //   this.removeByAdmin();
        //  }else{
        //   this.removeAgentByManager();
        //  } 
      }else{
        this.displayChange.emit(event);
        if(this.teamId){
          /** remove team functionality  */
         const teamRef = firebase.database().ref('teams').child(this.teamId);
         teamRef.remove().then(()=>{
           this.removeTeamMembers();
           alert("Team has been removed successfully.");
         });
       }
      }
    }else{
      this.displayChange.emit(event);
    }
    
  }
  /** remove team functionality  */
  removeTeamMembers(){
    let teamMembers = this.af.list('users',ref => ref.orderByChild('teamId').equalTo(`${this.teamId}`)).snapshotChanges();
    teamMembers.map(actions => 
      actions.map(a => ({ key: a.key, ...a.payload.val() }))
    ).subscribe(members => {
      members.map((member)=>{
        let userId = member.key;
        let userPayLoad = { teamId: '', teamName: 'unassigned' };
        this._sharedService.updateUserInfoById(userId, userPayLoad);
      });
    });
  }
  /** remove team functionality ends here */



  removeByAdmin(){
    console.log('yes remove me');
    this.removeUser();
    // if(this.selectedRole =='manager'){
    //   console.log('mgr');
    //   this.removeManagerByAdmin();
    // }else{
    //   console.log('agnt');
    //   this.removeAgentByAdmin();
    // }
  }


  removeUser(){
    /* update user details...... */
    let plan = "";
    let rate = "";
    let unpaidByAdminDate;
    if(!this.paidByAdmin && this.planType){
      plan = this.planType;
      rate = this.planRate;
      unpaidByAdminDate = "";
    }else{
      unpaidByAdminDate = Date.now();
    }
    let userData = {admin: this.agentId, adminName: this.firstName+' '+this.lastName, managerId: '', managerName: '', paidByAdmin: false, role: 'admin', teamId: '', teamName: '', planType: plan, rate: rate, adjustedRate: rate, removedBy: this.admin, removedAdminName: this.adminName, removeAgentByAdmin: true, unpaidByAdminDate: unpaidByAdminDate, network: this.agentEmail, billingDate: this.adminBillingDate};
    this._sharedService.updateUserInfoById(this.agentId, userData).then(()=>{
      this.updateRemoveHistory();
      this._sharedService.cancelBillingLog(this.agentId);
    });
     /*find team and remove from team.....*/
     if(this.team){
      const teamRef = firebase.database().ref('teams').child(this.team);
      let teamPath =  teamRef.child('members');
      teamPath = teamPath.child(this.agentId);
      teamPath.remove();
    }
    this.removeLeadPurchase(); /** remove purchase settings for same.  */
    this.removeSubscription(); /**remove subscription for same */
    this.displayChange.emit(false);
    alert('User has been removed from your account.');
  }



   /** functionality to remove manager by admin not used*/
   removeManagerByAdmin(){
    /* update user details...... */
    let plan = "";
    let rate = "";
    let unpaidByAdminDate;
    if(!this.paidByAdmin && this.planType){
      plan = this.planType;
      rate = this.planRate;
      unpaidByAdminDate = "";
    }else{
      unpaidByAdminDate = Date.now();
    }
    let userData = {admin: this.agentId, adminName: this.firstName+' '+this.lastName, managerId: '', managerName: '', paidByAdmin: false, role: 'admin', teamId: '', teamName: '', planType: plan, rate: rate, adjustedRate: rate,removedBy: this.admin, removedAdminName: this.adminName, removeAgentByAdmin: true, unpaidByAdminDate: unpaidByAdminDate};
    this._sharedService.updateUserInfoById(this.agentId, userData).then(()=>{
      this.updateRemoveHistory();
      this._sharedService.cancelBillingLog(this.agentId);
    });
     /*find team and remove from team.....*/
     if(this.team){
      const teamRef = firebase.database().ref('teams').child(this.team);
      let teamPath =  teamRef.child('members');
      teamPath = teamPath.child(this.agentId);
      teamPath.remove();
    }
    // this.removeFromLeadTeams();  //old non used for now.
    // this.updateNameMembersDetails(); //old non used for now.
    this.removeLeadPurchase(); /** remove purchase settings for same.  */
    this.removeSubscription(); /**remove subscription for same */
    this.displayChange.emit(false);
    alert('User has been removed from your account.');

  }
  /**not used */
  removeAgentByManager(){
    if(this.team){
      /*find team and remove from team.....*/
      const teamRef = firebase.database().ref('teams').child(this.team);
      let teamPath =  teamRef.child('members');
      teamPath = teamPath.child(this.agentId);
      teamPath.remove();
    }
    /* update user details...... */
    let userData = {managerId: '', managerName: '', teamId: '', teamName: ''};
    this._sharedService.updateUserInfoById(this.agentId, userData);
    this.displayChange.emit(false);
    alert('User has been removed from your account.');
  }
  
  /**end of remove manager by admin. */

  /** functionality to remove agent by admin */
  removeAgentByAdmin(){
    /*find team and remove from team.....*/
    if(this.team){
      const teamRef = firebase.database().ref('teams').child(this.team);
      let teamPath =  teamRef.child('members');
      teamPath = teamPath.child(this.agentId);
      teamPath.remove();
    }
    let plan = "";
    let rate = "";

    if(!this.paidByAdmin && this.planType){
      plan = this.planType;
      rate = this.planRate;
    }
    
    /* update user details...... */
    let userData = {admin: this.agentId, adminName: this.firstName+' '+this.lastName, managerId: '', managerName: '', paidByAdmin: false, role: 'admin', teamId: '', teamName: '', planType: plan, rate: rate, removedBy: this.admin, removedAdminName: this.adminName, payAllMember: true};
    this._sharedService.updateUserInfoById(this.agentId, userData).then(()=>{
      this.updateRemoveHistory();
    });
    this.removeLeadPurchase(); /** remove purchase settings for same.  */
    this.removeSubscription(); /**remove subscription for same */
    this.displayChange.emit(false);
    alert('User has been removed from your account.');

  }

  updateRemoveHistory(){
    let teamRef = firebase.database().ref('removedAgent').child(this.admin);
    teamRef =  teamRef.child(this.agentId);
    const userDetails = {firstName: this.firstName, lastName: this.lastName, email: this.agentEmail, teamId: this.team, teamName: this.teamName, role: this.selectedRole};
    teamRef.update(userDetails);
  }

   // update monthly budget
   removeLeadPurchase(){
      const subRef = firebase.database().ref('leadPurchaseSettings').child(this.agentId);
      subRef.remove();
  }
  removeSubscription(){
    const subRef = firebase.database().ref('subscribers').child(this.admin);
    const subPath = subRef.child(this.agentId);
    subPath.remove();
  }
  // Work against memory leak if component is destroyed
  ngOnDestroy() {
    // this.displayChange.unsubscribe();
  }

}
