import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DropdownModule, ButtonModule, DialogModule, ScheduleModule ,} from 'primeng/primeng';

import { NotificationsComponent } from './notifications.component';
import { NotificationsRoutingModule } from './notifications-routing.module';
import { SharedFileModule } from '../shared/shared.module';
import { UnLinkedClientsComponent } from './components/un-linked-clients/un-linked-clients.component';
import { LinkedClientsComponent } from './components/linked-clients/linked-clients.component';
import { UpcomingTaskComponent } from './components/upcoming-task/upcoming-task.component';
import { UpcomingAppoinmentComponent } from './components/upcoming-appoinment/upcoming-appoinment.component';
import { RequestedAppoinmentComponent } from './components/requested-appoinment/requested-appoinment.component';
import { ChatModule } from '../chat/chat.module';
import { ViewFullRequestedAppoinmentComponent } from './components/view-full-requested-appoinment/view-full-requested-appoinment.component';
import { AppointmentCalendarDialogComponent } from './components/dialog-components-appointment-calendar-dialog/appointment-calendar-dialog.component';


@NgModule({
  imports: [
    CommonModule,
    NotificationsRoutingModule,
    SharedFileModule,
    ChatModule,
    DropdownModule,
    ButtonModule,
    DialogModule,
    ScheduleModule
  ],
  declarations: [
    NotificationsComponent,
    UnLinkedClientsComponent,
    LinkedClientsComponent,
    UpcomingTaskComponent,
    UpcomingAppoinmentComponent,
    RequestedAppoinmentComponent,
    ViewFullRequestedAppoinmentComponent,
    AppointmentCalendarDialogComponent
  ]
})
export class NotificationsModule {}
