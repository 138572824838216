import { NgModule, Pipe } from '@angular/core';
import { PropertiesComponent } from './properties.component';
import { MyPropertiesComponent } from './myproperties/myproperties.component';
import { NewListingComponent } from './new-listing/new-listing.component';
import { PropertiesRoutingModule } from './properties-routing.module';
import { DialogModule } from 'primeng/primeng';
import { CalendarModule, DropdownModule } from 'primeng/primeng';
import { CheckboxModule } from 'primeng/primeng';
import { InputTextareaModule, GMapModule } from 'primeng/primeng';
import { DataTableModule } from 'primeng/primeng';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabViewModule, CardModule, InputTextModule, MenuModule, ButtonModule } from 'primeng/primeng';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/primeng';
import { CommonModule } from '@angular/common';
import { SharedFileModule } from '../shared/shared.module';
import { FilterDialogComponent } from './property-filter-dialog/filter-dialog.component';
import { PropertyDialogComponent } from './property-dialog/property-dialog.component';
import { PropertiesInfoDetailsComponent } from './properties-infodetails/properties-infodetails.component';
import { ManualPropertiesInfoDetailsComponent } from './manual-properties-infodetails/manual-properties-infodetails.component';
import { Ng5SliderModule } from 'ng5-slider';
import { AgmCoreModule } from '@agm/core';

@Pipe({
  name: 'reverse',
  pure: false
})
export class ReversePipe {
  transform(values) {
    if (values) {
      return values.reverse();
    }
  }
}

@Pipe({
  name: 'valid',
  pure: false
})
export class ValidPipe {
  transform(values: any, limit: any) {
    if (values) {
      // let filtered = values.filter(x => x.firstName != undefined && x.firstName != 'undefined');
      // filtered = filtered.slice(0, limit);
      return values;
    }
  }
}
// @Pipe({
//   name: 'inactive',
//   pure: false
// })
// export class InactivePipe {
//   transform(values) {
//     if (values) {
//       var today = new Date();
//       var inactive = today.setDate(today.getDate() - 7);
//       let filtered = values.filter(item => {
//         if (item.lastActive <= inactive) {
//           return item;
//         }
//       });
//       return filtered;
//     }
//   }
// }
@NgModule({
  imports: [
    CommonModule,
    DataTableModule,
    InputTextareaModule,
    DialogModule,
    CalendarModule,
    CheckboxModule,
    PropertiesRoutingModule,
    SharedFileModule,
    FormsModule,
    ReactiveFormsModule,
    GMapModule,
    TabViewModule,
    CardModule,
    InputTextModule,
    MenuModule,
    ButtonModule,
    DropdownModule,
    Ng5SliderModule,
    TableModule,
    TooltipModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCItCL26PCONOB-QrNG2bcdrYw_3w-2Z-I'
    })
    
  ],
  declarations: [PropertiesComponent, MyPropertiesComponent, NewListingComponent, FilterDialogComponent, PropertyDialogComponent, PropertiesInfoDetailsComponent, ManualPropertiesInfoDetailsComponent, ReversePipe, ValidPipe,],
  exports: [PropertiesComponent,MyPropertiesComponent, NewListingComponent, PropertiesInfoDetailsComponent, ManualPropertiesInfoDetailsComponent]
})
export class PropertiesModule {}
