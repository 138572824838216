export enum Permissions {
    home = 0,
    clientprofiles = 0,
    tasks = 0,
    calendar = 0,
    newclient = 0,
    importclients = 0,
    inactivereport = 0,
    settings = 0,
    feedback = 0,
    register = 0,
    login = 0
   
}
