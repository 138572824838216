import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { AngularFireDatabase, AngularFireList} from 'angularfire2/database';
import { Observable } from 'rxjs';
import { SharedService } from '../shared.service';
import * as firebase from 'firebase/app';
declare var google: any;
@Component({
  selector: 'app-billing-setting-component',
  templateUrl: './billing-setting-component.component.html',
  styleUrls: ['./billing-setting-component.component.scss']
})
export class BillingSettingComponentComponent implements OnInit {
  iconBase: string;
  user: any;
  msgs: any[];
  items: any;
  display: boolean = false;
  show: boolean = false;
  billingForm: FormGroup;
  locationForm: FormGroup;
  isLeadPurchase : boolean = false;
  options: any;
  overlays: any[];
  infoWindow: any;
  val: number;
  uid: string;
  dailyBudget: number = 0;
  monthlyBudget: number = 0;
  maxLeadPurchasePrice: number = 0;
  leadPurchaseDetails: Observable<any []>;
  
  constructor(private formBuilder: FormBuilder, private _sharedService: SharedService, private af: AngularFireDatabase) {
    this.uid = this._sharedService.user.uid;
    this.getCurrentSettings();
    

  }

  ngOnInit() {
    this.iconBase = 'assets/images/location-icon.png';
    this.billingForm = this.formBuilder.group({
      daily_budget: '',
      monthly_budget: '',
      price: '',
      locations: this.formBuilder.array([this.createItem()])
    });
    this.locationForm = this.formBuilder.group({
      loc_range: ''
    });
    this.options = {
      center: { lat: 36.890257, lng: 30.707417 },
      zoom: 12
    };
    this.initOverlays();
    this.infoWindow = new google.maps.InfoWindow();
  }
  handleOverlayClick(event) {
    this.msgs = [];
    let isMarker = event.overlay.getTitle != undefined;

    if (isMarker) {
      let title = event.overlay.getTitle();
      this.infoWindow.setContent('' + title + '');
      this.infoWindow.open(event.map, event.overlay);
      event.map.setCenter(event.overlay.getPosition());

      this.msgs.push({ severity: 'info', summary: 'Marker Selected', detail: title });
    } else {
      this.msgs.push({ severity: 'info', summary: 'Shape Selected', detail: '' });
    }
  }

  initOverlays() {
    if (!this.overlays || !this.overlays.length) {
      this.overlays = [
        new google.maps.Marker({
          position: { lat: 36.879466, lng: 30.667648 },
          title: 'Konyaalti',
          icon: this.iconBase
        }),
        new google.maps.Marker({
          position: { lat: 36.883707, lng: 30.689216 },
          title: 'Ataturk Park',
          icon: this.iconBase
        }),
        new google.maps.Marker({ position: { lat: 36.885233, lng: 30.702323 }, title: 'Oldtown', icon: this.iconBase }),

        new google.maps.Circle({
          center: { lat: 36.879466, lng: 30.702323 },
          fillColor: '#00c5e9',
          strokeColor: '#00c5e9',
          fillOpacity: 0.15,
          strokeWeight: 1,
          radius: 4000
        })
      ];
    }
  }
  createItem(): FormGroup {
    return this.formBuilder.group({
      place: ''
    });
  }
  addItem(): void {
    this.items = this.billingForm.get('locations') as FormArray;
    this.items.push(this.createItem());
  }

  deleteRow(index: number) {
    // control refers to your formarray
    this.items = this.billingForm.get('locations') as FormArray;
    // remove the chosen row
    this.items.removeAt(index);
  }
  onFormSubmit() {
    this.user = this.locationForm.value;
    this.initOverlays();
    console.log(this.user);
    /* Any API call logic via services goes here */
  }
  showDialog() {
    this.display = true;
  }
  showLocation() {
    this.show = true;
  }
  savePurchaseSettings(){
    this.display = false;
    let payload = {dailyBudget: this.dailyBudget, monthlyBudget: this.monthlyBudget, maxLeadPurchasePrice: this.maxLeadPurchasePrice, purchaseLeads: this.isLeadPurchase};
    let settingRef = firebase.database().ref('leadPurchaseSettings');
    const settingRefs = settingRef.child(this.uid);
    settingRefs.update(payload).then(
      (result: any) => {
        this.getCurrentSettings();
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  getCurrentSettings(){
    let settingRef = firebase.database().ref('leadPurchaseSettings');
    const settingRefs = settingRef.child(this.uid);
    // settingRefs.once('value').then((snapshot: any) => {
      settingRefs.on('value', (snapshot) => {
      const result = snapshot.val();
      if(result){
        this.dailyBudget =  result.dailyBudget;
        this.monthlyBudget = result.monthlyBudget;
        this.maxLeadPurchasePrice = result.maxLeadPurchasePrice;
        this.isLeadPurchase =  result.purchaseLeads;
      }
      
    });
  }

}

