import { Injectable } from '@angular/core';
import { ChatModule } from './chat.module';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  constructor(private http: HttpClient) {}

  getMessages(userName: string): any {
    const url = 'assets/messages.json';
    return this.http.get(url).pipe(
      map(response => {
        const data: any[] = response['data'];
        const [messages] = data.filter(item => item.lead_name === userName);
        return messages;
      })
    );
  }
}
