import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upcoming-task',
  templateUrl: './upcoming-task.component.html',
  styleUrls: ['./upcoming-task.component.scss']
})
export class UpcomingTaskComponent implements OnInit {
  teamDropdown: any;
  myClasses = 'inactive';
  btnClass = 'btn-grey';
  constructor() {}

  ngOnInit() {
    this.teamDropdown = [{ name: 'Iron Ways' }, { name: 'Keyhole' }];
  }
  addClasses(ev) {
    if (this.myClasses == ev) {
      this.myClasses = 'inactive';
      this.btnClass = 'btn-grey';
    } else {
      this.myClasses = 'active';
      this.btnClass = '';
    }
  }
}
