import { Component, OnInit } from '@angular/core';
import { Observable} from 'rxjs';
import { AngularFireDatabase, AngularFireList} from 'angularfire2/database';
import { SharedService } from '../../../shared/shared.service';
import { map, combineLatest  } from 'rxjs/operators';
import { Action } from 'rxjs/internal/scheduler/Action';
@Component({
  selector: 'app-team-management-setting',
  templateUrl: './team-management-setting.component.html',
  styleUrls: ['./team-management-setting.component.scss']
})
export class TeamManagementSettingComponent implements OnInit {
  teamDropdown: any;
  display: boolean = false;
  remove: boolean = false;
  team: boolean = false;
  teamListingRef : AngularFireList<any>;
  teamList: Observable<any []>;
  teams: any;
  agents: any;
  uid: string;
  leadFirstName: string;
  leadLastName: string;
  teamName = '';
  teamNameError = false;
  removeItem: any;
  role = "";
  showCreatTeam: boolean = false;
  admin = "";
  message = "";
  teamDetails: any;
  teamId: any;
  teamMembers: any;
  selectedTeamName: any;
  popupHeader = "Create Team";
  removeTeamId: any;
  removeLeadId: any;
  constructor(private af: AngularFireDatabase, private _sharedService: SharedService) {
    this.uid = this._sharedService.user.uid;
   }

  ngOnInit() {
    this.teamDropdown = [{ name: 'Iron Ways' }, { name: 'Keyhole' }];
    this._sharedService.getUserInfo().then(user => {
       this.admin = user.admin? user.admin : '';
       if(user.role){
        this.role = user.role;
         if(this.role == 'admin' || this.role == 'manager' ){
           this.showCreatTeam = true;
         }
        }
        this.getTeams();
      }
      );
  }
  getTeams(){
    // if(this.role == 'admin'){
      this.teamList = this.af.list('teams',ref => ref.orderByChild('admin').equalTo(`${this.admin}`)).snapshotChanges();
      this.teamList.map(actions => 
        actions.map(a => ({ key: a.key, ...a.payload.val() }))
      ).subscribe(items => {
        items.map(item=>{
         item['numberOfAgent'] = item.members ? Object.keys(item.members).length : '';
          let pendingCount = 0;
          /**get pending invitation count from team */
          const invitePending = this.af.list('inviteAgents',ref => ref.orderByChild('team').equalTo(`${item.key}`)).snapshotChanges();
         invitePending.map(actions => 
          actions.map(a => ({ key: a.key, ...a.payload.val() }))
          ).subscribe(pendings => {
              pendings.map((pending)=>{
                console.log(pending['accepted']);
                if(!pending['accepted']){
                  pendingCount+=1;
                }
              })
              item['pendingCount'] = pendingCount;
              pendingCount = 0;
          })
        });

        this.teams = items;
      });
    // }
   
    // //get all team created by lead.
    // if(this.role == 'manager'){
    //   this.teamList = this.af.list('teams',ref => ref.orderByChild('lead/id').equalTo(`${this.uid}`)).snapshotChanges();
    //   this.teamList.map(actions => 
    //     actions.map(a => ({ key: a.key, ...a.payload.val() }))
    //   ).subscribe(items => {
    //     items.map(item=>{
    //       item['numberOfAgent'] = item.members ? Object.keys(item.members).length : '';
    //      })
    //     this.teams = items
    //   });
    // }
  }
  showEdit(team) {
    console.log(team);
    this.selectedTeamName = team.name;
    this.teamDetails = team;
    this.teamId = team.key;
    this.team = true;
    this.popupHeader = "Edit Team Information";
    if(this.teamDetails){
      this.teamMembers = [];
      const teamMember =  this.teamDetails.members;
      const teamLead =  this.teamDetails.lead ? this.teamDetails.lead : '';
      if(teamMember){
        const mapped = Object.keys(teamMember).map(key => 
          {
            let profilePicture = '';
            this._sharedService.getUserInfoById(teamMember[key].id).then((user)=>{
               if(user){
                 profilePicture =  user.profilePicture ? user.profilePicture : '';
               }
               const agentItem = {id: teamMember[key].id, firstName: teamMember[key].firstName, lastName: teamMember[key].lastName, teamId: key, role: teamMember[key].role, profilePicture: profilePicture};
               this.teamMembers.push(agentItem);
            });
            
          });
      }
    }
  }
  showRemoveDialog(item) {
    this.remove = true;
    this.message = "Are you sure you want to remove this team from your account? Any associated members will be unassigned.";
    console.log(item);
    this.removeTeamId =  item.key;
    let leadId;
    if(item.lead){
       leadId = item.lead.id;
    }
    this.removeLeadId = leadId;
    // this.removeItem = item;
  }
  showTeamDialog() {
    this.team = true;
    this.teamDetails = '';
    this.teamId = '';
    this.teamMembers = [];
    this.selectedTeamName = "";
    this.popupHeader = "Create Team";
  }
  onTeamDialogClose(event) {
    this.team = event;
  }
  onRemoveDialogClose(event) {
    if(event){
      // this.af.list("teams").remove(this.removeItem.key.toString());
    }
    this.remove=false;
  }
  onEditDialogClose(event) {
    this.display = event;
  }
   toEpoch(epoch,format='datetime'){
    
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    epoch = Number(epoch);
    var date = new Date(epoch);

    var year = date.getFullYear();
    var month = monthNames[date.getMonth()];
    var day = date.getDate();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    // September 4, 2018
    return  month +" "+ day +", " + year;
  }

}
