import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-requested-appoinment',
  templateUrl: './requested-appoinment.component.html',
  styleUrls: ['./requested-appoinment.component.scss']
})
export class RequestedAppoinmentComponent implements OnInit {
  teamDropdown: any;
  myClasses = 'inactive';
  appointment: boolean = false;
  @Output() showMore = new EventEmitter();
  constructor() {}

  ngOnInit() {
    this.teamDropdown = [{ name: 'Iron Ways' }, { name: 'Keyhole' }];
  }
  addClasses(ev) {
    if (this.myClasses == ev) {
      this.myClasses = 'inactive';
    } else {
      this.myClasses = 'active';
    }
  }
  showMoreClass(ev) {
    this.showMore.emit(true);
  }

  showAppointment() {
    this.appointment = true;
  }
  onAppointmentDialogClose(event) {
    this.appointment = event;
  }
}
