import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase} from 'angularfire2/database';
import { SharedService } from '../../../../shared/shared.service';

@Component({
  selector: 'app-next-bill',
  templateUrl: './next-bill.component.html',
  styleUrls: ['./next-bill.component.scss']
})
export class NextBillComponent implements OnInit {
  items: any;
  uid: string = '';
  email: string = '';
  billingInfo: any;
  profilesArray: any;
  leadsArray: any;
  scheduledDate: string = '';

  constructor(private af: AngularFireDatabase, private _shared: SharedService) {
    this.uid = this._shared.user.uid;
    this.email = this._shared.user.email;
    this.items = [
      // { charge: 'Standard Members', qty: '372', price: '49', total: '343' },
      // { charge: 'Prorated Members', qty: '111', price: '', total: '64' },
      // { charge: 'Purchased Leads', qty: '149', price: '15', total: '750' }
    ];
  }

  ngOnInit() {
    this._shared.getBilling().then((result) => {
        if (result) {
          this.billingInfo = result;
          if (this.billingInfo.monthlyFee) {
            // this.scheduledDate = this.getScheduledDate(this.billingInfo.monthlyFee.date);
            this.items.push({
              charge: this.billingInfo.monthlyFee.type,
              qty: 1,
              price: this.billingInfo.monthlyFee.amount,
              total: this.billingInfo.monthlyFee.amount});
          }
          if (this.billingInfo.profiles) {
           // this.billingInfo.profiles.subscribe((snapshot: any) => {
              const profilesArray = [];
              // this.additionalProfilesCount = 0;
              const profilesArrayKeys = Object.keys(this.billingInfo.profiles);
              profilesArrayKeys.forEach(key => {
                // this.additionalProfilesCount += snap.val().qty;
                profilesArray.push({
                          id: key,
                          amount: this.billingInfo.profiles[key].amount,
                          qty: this.billingInfo.profiles[key].qty,
                          type: this.billingInfo.profiles[key].type,
                          date: this.billingInfo.profiles[key].date
                      });
                      return false;
                  });
                 this.profilesArray = profilesArray;
                 this.addItemsToNextBill();
            // });
          }

          if (this.billingInfo.purchaseLeads) {
               const leadsArray = [];
               const leadsArrayKeys = Object.keys(this.billingInfo.purchaseLeads);
               leadsArrayKeys.forEach(key => {
                 // this.additionalProfilesCount += snap.val().qty;
                 leadsArray.push({
                           id: key,
                           amount: this.billingInfo.purchaseLeads[key].amount,
                           qty: this.billingInfo.purchaseLeads[key].qty,
                           type: this.billingInfo.purchaseLeads[key].type,
                           date: this.billingInfo.purchaseLeads[key].date
                       });
                       return false;
                   });
                  this.leadsArray = leadsArray;
                  this.addLeadsToNextBill();
             // });
           }

        }
    });

    /**new billing structure */
      //get monthly billing
     
      this._shared.getMonthlyFeeTotal(this.uid).then((res)=>{
        console.log(res);
        if(res){
          const resultArrayKeys = Object.keys(res);
          let count = 0;
          let rate = 0;
          let totalAmount = 0;
          resultArrayKeys.forEach(key => {
            if(!res[key].cancelled){
            count += 1;
            rate = res[key].amount;
            totalAmount += rate;
            // this.scheduledDate = this._shared.toEpoch(res[key].date);
          }

        })
          const data = {charge: 'Monthly Fee', qty: count, price: rate,total: totalAmount};
          this.items.push(data);
        }
        
      });

    /**end of new billing structure */
    this._shared.getUserInfo().then(user => {
      this.scheduledDate = this._shared.toEpoch(user.billingDate);
    })
  }

  addItemsToNextBill() {
    this.profilesArray.forEach(item => {
      const curDate = new Date();
      const curMonth = curDate.getMonth();
      const itemDate = new Date(item.date);
      const itemMonth = itemDate.getMonth();
      if (curMonth === itemMonth) {
        this.items.push({
          charge: item.type,
          qty: item.qty,
          price: item.amount / item.qty,
          total: item.amount});
      }
    });
  }

  addLeadsToNextBill() {
    this.leadsArray.forEach(item => {
      const curDate = new Date();
      const curMonth = curDate.getMonth();
      const itemDate = new Date(item.date);
      const itemMonth = itemDate.getMonth();
      if (curMonth === itemMonth) {
        this.items.push({
          charge: item.type,
          qty: item.qty,
          price: item.amount,
          total: item.amount});
      }
    });
  }

  getBillTotal() {
    let totalAmount = 0;
    this.items.forEach(item => {
      totalAmount += item.total;
    });
    return totalAmount;
  }

  getScheduledDate(epoch) {
    // adding 30 days in epoch
    // const d = new Date(epoch + 30 * 24 * 3600 * 1000);
    const d = new Date(epoch);
    const curDate = new Date();
    d.setMonth(curDate.getMonth() + 1);
    return this.formatedDate(d);
  }

  formatedDate(date){
    // const monthNames = ["January", "February", "March", 
    // "April", "May", "June", "July", "August", "September", 
    // "October", "November", "December"];
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", 
    "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    // const date = new Date(dateObject);
    let dd = date.getDate();
    const mm = monthNames[date.getMonth()];
  
    const yyyy = date.getFullYear();
    if(dd<10){
        dd='0'+dd;
    } 
    // if(mm<10){
    //     mm='0'+mm;
    // } 
    //return dd+' '+mm+' '+yyyy;
    return mm+' '+ dd +', ' +yyyy;
  }

  epochToMonth(epoch){
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const d = new Date(epoch);
    return monthNames[d.getMonth()];
  }

  getNextMonthDate() {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const d = new Date();
    let nextMonth = d.getMonth() + 1;
    if (nextMonth > 11) {
      nextMonth = 0;
    }
    return '01 ' + monthNames[nextMonth] + ' ' + d.getFullYear();
  }

}
