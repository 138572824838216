import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ButtonModule } from 'primeng/primeng';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';

declare var google: any;

@Component({
  moduleId: module.id,
  selector: 'app-manual-properties-infodetails',
  templateUrl: 'manual-properties-infodetails.component.html',
  styleUrls: ['manual-properties-infodetails.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ManualPropertiesInfoDetailsComponent implements OnInit {
  iconBase: string;
  user: any;
  msgs: any[];
  chatList: any;
  items: any;
  display: boolean = false;
  show: boolean = false;
  billingForm: FormGroup;
  locationForm: FormGroup;
  showMap= false;
  showFilterDialog = false;
  showPropertyDialog = false;

  options: any;
  overlays: any[];
  infoWindow: any;
  val: number;



  constructor(private formBuilder: FormBuilder) {}
  ngOnInit() {
    this.items = [
      {
        items: [
          { label: 'Mark as read' },
          { label: 'Block user' },
          { label: 'Delete chat' }
        ]
      }
    ];
  }
  clicked() {
    this.show = !this.show;
  }


}



