



import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {SharedService} from '../shared/shared.service';
import { map, take, tap } from 'rxjs/operators';


@Injectable()
export class LoginGuard implements CanActivate {
    constructor(private _sharedService: SharedService, private router: Router) {
   
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean>  {
        return this._sharedService.authState$.pipe(
          take(1),
          map((authState) => !!authState),
          tap(authenticated => {
              console.log("login" + authenticated)
            if (!authenticated) return this.router.navigate(['/'])
          })
        )
      }


}