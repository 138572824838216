import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';

@Component({
  selector: 'app-setting-overview',
  templateUrl: './setting-overview.component.html',
  styleUrls: ['./setting-overview.component.scss']
})
export class SettingOverviewComponent implements OnInit {
  invite: boolean = false;
  inviteFullName: string = "";
  inviteEmail: string = "";
  resendInvitation: boolean = false;
  inviteRole: any;
  inviteTeam: any;
  showAccount: boolean = false;
  showSocialPopup: boolean = false;
  showImportClient: boolean = false;
  displayPlan: boolean = false;
  userDetails:any;
  constructor(private _router: Router,private _shared: SharedService) { }

  ngOnInit() {
    this._shared.getUserInfo().then((user)=>{
      this.userDetails = user;
    })
  }
  showInviteDialog(){
    this.inviteFullName ='';
    this.inviteEmail ='';
    this.inviteTeam ='';
    this.inviteRole ='';
    this.resendInvitation = false;
    this.invite = true;
  }
  showAccountDialog(){
    this.showAccount = true;    
  }
  onInviteDialogClose(event) {
    this.invite = event;
  }
  onAccountDialogClose(event){
    this.showAccount = false;
  }
  showImportDialog(){
    this.showImportClient = true;    
  }
  onImportDialogClose(event){
    this.showImportClient = false;
  }
  showSocialDialog(){
     this.showSocialPopup = true;    
  }
  onSocialDialogClose(event){
    this.showSocialPopup = false;    
  }
  onPlanDialogClose(ev){
    this.displayPlan =false;
  }
  onPlanDialogShow(ev){
    if(!this.userDetails.planType || this.userDetails.planType =='planZ'){
      this.displayPlan =true;
    }else{
      this._router.navigate(["settings/billing-plan"]);
    }
    
  }
}
