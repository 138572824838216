import { NgModule, Pipe } from '@angular/core';
import { TasksComponent } from './tasks.component';
import { TasksRoutingModule } from './tasks-routing.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PanelModule } from 'primeng/primeng';
import { CheckboxModule } from 'primeng/primeng';
import { AccordionModule } from 'primeng/primeng';
import { ButtonModule } from 'primeng/primeng';
import { InputTextareaModule } from 'primeng/primeng';
import { DialogModule } from 'primeng/primeng';
import { DropdownModule } from 'primeng/primeng';
import { InputTextModule } from 'primeng/primeng';
import { TooltipModule } from 'primeng/primeng';
import { GrowlModule } from 'primeng/primeng';
import { InplaceModule } from 'primeng/primeng';
import { ConfirmDialogModule } from 'primeng/primeng';
import { SharedFileModule } from '../shared/shared.module';

@Pipe({
  name: 'reverse',
  pure: false
})
export class ReversePipe {
  transform(values) {
    if (!values) {
      return;
    } else {
      return values.reverse();
    }
  }
}

@NgModule({
  imports: [
    TasksRoutingModule,
    FormsModule,
    CommonModule,
    PanelModule,
    CheckboxModule,
    AccordionModule,
    ButtonModule,
    InputTextareaModule,
    DialogModule,
    DropdownModule,
    InputTextModule,
    TooltipModule,
    GrowlModule,
    InplaceModule,
    ConfirmDialogModule,
    SharedFileModule
  ],
  declarations: [TasksComponent, ReversePipe],
  exports: [TasksComponent]
})
export class TasksModule {}
