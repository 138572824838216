import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { InputMaskModule } from 'primeng/primeng';


import {
  TabViewModule,
  CardModule,
  InputTextModule,
  MenuModule,
  ButtonModule,
  InputSwitchModule,
  InputTextareaModule,
  DropdownModule,
  GMapModule,
  SliderModule
} from 'primeng/primeng';
import { ProgressBarModule } from 'primeng/progressbar';
import { DialogModule } from 'primeng/dialog';

import { AgentComponent } from './agent/agent.component';
import {BillingSettingComponentComponent} from './billing-setting-component/billing-setting-component.component';
import { ProgressBarDirective } from './progress-bar.directive';
// import { CommonConfirmDialogComponent } from './common-confirm-dialog/common-confirm-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    TabViewModule,
    CardModule,
    InputTextModule,
    ProgressBarModule,
    MenuModule,
    DialogModule,
    ButtonModule,
    InputSwitchModule,
    InputTextareaModule,
    DropdownModule,
    ReactiveFormsModule,
    GMapModule,
    SliderModule,
    FormsModule,
    InputMaskModule,
  ],
  declarations: [AgentComponent, BillingSettingComponentComponent, ProgressBarDirective],
  exports: [AgentComponent, BillingSettingComponentComponent, ProgressBarDirective]
})
export class SharedFileModule {}
