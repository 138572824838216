import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { SharedService } from './shared/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  canShow: boolean;
  showMenu = false;
  loaded: boolean;
  constructor(private _sharedService: SharedService) {
    this.loaded = false;
    this.canShow = false;
    this._sharedService.authState$.subscribe(res => {
      if (res) {
        this.canShow = true;
        setTimeout(() => {
          this.loaded = true;
        }, 1000);

        this._sharedService.userInfo.valueChanges().subscribe(result => {
          console.log(result);
          console.log(res);
          let emailVerified = res.emailVerified;
          if(result.registrationCompleted){
            emailVerified = true;
          }
          if (result) {
            if (result.activePlan && result.registrationCompleted && ( emailVerified || result.facebookUid)) {
              this.showMenu = true;
            }
          } else {
            this.showMenu = false;
          }
        });
      } else {
        this.loaded = false;
        this.canShow = false;
        setTimeout(() => {
          this.loaded = true;
        }, 1000);
      }
    });
  }
}
